<template>
    <div>
        <h3>Add a New Diagnostic Test Category</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addDiagnosticTestCategory">
                <b-form-group label="Diagnostic test category name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.testCategoryName.$model" :state="$v.form.testCategoryName.$dirty ? !$v.form.testCategoryName.$error : null" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Add Diagnostic Test Category
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { required, minLength, maxLength } from '@vuelidate/validators';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    data() {
        return {
            // URLs used
            sampleCoordinatorDiagnosticTestCategoriesUrl: getUrl('sampleCoordinatorDiagnosticTestCategoriesUrl'),

            // form data
            form: {
                // inputs
                testCategoryName: null,
                notes: null
            },
            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
    },
    validations: {
        form: {
            // dropdowns
            testCategoryName: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(256)
            }
        }
    },
    mounted() {},
    methods: {
        addDiagnosticTestCategory() {
            const loader = this.$loading.show();
            const component = this;

            const newDiagTestCategory = {
                category_name: this.form.testCategoryName,
                notes: this.form.notes === '' ? null : this.form.notes
            };

            axios.post(this.sampleCoordinatorDiagnosticTestCategoriesUrl, newDiagTestCategory, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
