<template>
    <div class="container-fluid">
        <div class="wrapper">
            <nav id="swimmer-plot-sidebar" class="sidebar" style="font-size: 0.75rem;">
                <a id="swimmer-plot-search-menu" class="btn btn-switch rotate">
                    <i id="option-icon" class="fas fa-chevron-circle-up" /> More Options
                </a>

                <!--- Side Bar menu content begins from here-->
                <section>
                    Participants<br>
                    <span style="font-size:0.5rem;">Show</span>
                    <form>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="showAll" id="swimmer-plot-show-all" value="All" checked>
                            <label class="form-check-label" for="swimmer-plot-show-all">All</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="showAll" id="swimmer-plot-selected" value="Selected">
                            <label class="form-check-label" for="swimmer-plot-selected">Selected</label>
                        </div>
                    </form>
                    <span style="font-size:0.5rem;">Filter by TF (%)</span><br>
                    <span style="font-size:0.5rem; margin-left: 5px;">min</span>
                    <input id="swimmer-plot-tf-slider-min" type="range" min="0" max="100" value="0" step="0.1" style="font-size:0.5rem;width: 75px;">
                    <input id="swimmer-plot-tf-box-min" type="text" value="0" style="font-size:0.5rem;width: 30px">
                    <span style="font-size:0.5rem; margin-left: 5px;">max</span>
                    <input id="swimmer-plot-tf-slider-max" type="range" min="0" max="100" value="50" step="0.1" style="font-size:0.5rem;width: 75px;">
                    <input id="swimmer-plot-tf-box-max" type="text" value="50" style="font-size:0.5rem;width: 30px">
                    <hr>
                    <p />
                    <div class="form-group">
                        <div style="font-size:0.5rem">
                            Enter participant IDs, aliases <br>(from cohort in view)
                        </div>
                        <textarea class="form-control rounded-0" id="swimmer-plot-participants-upload" rows="3" style="margin-bottom: 5px;" />
                        <a id="swimmer-plot-participants-clear" class="btn" style="font-size:0.75rem">Clear</a>
                        <a id="swimmer-plot-participants-submit" class="btn" style="font-size:0.75rem">Submit</a>
                    </div>
                </section>
                <hr>
                <section>
                    Treatment <br>
                    <form>
                        <span style="font-size:0.5rem">Color By</span>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="showAll" id="swimmer-plot-t-color-regimen" value="Drug" checked>
                            <label class="form-check-label" for="swimmer-plot-t-color-regimen">Drug</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="showAll" id="swimmer-plot-t-color-category" value="Category">
                            <label class="form-check-label" for="swimmer-plot-t-color-category">Category</label>
                        </div>
                    </form>
                    <form>
                        <span style="font-size: 0.5rem">Render mode</span>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="showAll" id="swimmer-plot-default-track" value="Default" checked>
                            <label class="form-check-label" for="swimmer-plot-default-track">Default</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="showAll" id="swimmer-plot-expand-track" value="Expand">
                            <label class="form-check-label" for="swimmer-plot-expand-track">Expand</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="showAll" id="swimmer-plot-expand-track-tall" value="Expand V2">
                            <label class="form-check-label" for="swimmer-plot-expand-track-tall">Tall</label>
                        </div>
                    </form>
                    <span style="font-size:0.5rem">Set a reference </span>
                    <select id="tSelector" name="tSelector" class="form-control" style="font-size:0.5rem; width:50%;" />
                </section>
                <hr>
                <section>
                    Display Biospecimen Lollipops<br>
                    <form id="swimmer-plot-bs-form">
                        Type:
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="blood" id="blood" checked>
                            <label class="form-check-label" for="blood">
                                Blood                        
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="tissue" id="tissue" checked>
                            <label class="form-check-label" for="tissue">
                                Tissue                     
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="other" id="other" checked>
                            <label class="form-check-label" for="other">
                                Other                  
                            </label>
                        </div>
                        <br>
                        Quality:
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="low-quality" id="low-quality" checked>
                            <label class="form-check-label" for="low-quality">
                                Low quality
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="other-quality" id="other-quality" checked>
                            <label class="form-check-label" for="other-quality">
                                Others
                            </label>
                        </div>
                        <br>
                        Sample availability: 
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="have-samples" id="have-samples" checked>
                            <label class="form-check-label" for="have-samples">
                                Have sample(s)
                            </label>
                            <div>
                                Sample type: <br>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="ulpwgs" id="ulpwgs" checked>
                                    <label class="form-check-label" for="ulpwgs">
                                        ulpwgs
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="wgs" id="wgs" checked>
                                    <label class="form-check-label" for="wgs">
                                        wgs
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="wes" id="wes" checked>
                                    <label class="form-check-label" for="wes">
                                        wes
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="rnaseq" id="rnaseq" checked>
                                    <label class="form-check-label" for="rnaseq">
                                        rnaseq
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" value="scrnaseq" id="scrnaseq" checked>
                                        <label class="form-check-label" for="scrnaseq">
                                            scrnaseq
                                        </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="panel" id="panel" checked>
                                    <label class="form-check-label" for="panel">
                                        panel
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="no-sample" id="no-sample" checked>
                            <label class="form-check-label" for="no-sample">
                                Have no sample                        
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="pending-sample" id="pending-sample" checked>
                            <label class="form-check-label" for="pending-sample">
                                Have pending sample(s)                        
                            </label>
                        </div>
                    </form>                 
                </section>
                <p />
            </nav>
            <div id="content" style="margin-left: 30px;">
                <div style="margin-left:60px;">
                    <div class="row" style="font-size: 12px;margin-top: 5px; padding-bottom: 5px;">
                        <div class="col-xs-12 col-md-12 col-lg-12" style="margin-top: 5px;">
                            <b>Cohort tables</b>
                            <div id="swimmer-plot-table-filter-info" class="table-filter-info">
                                <!--filtering info will appear in this div-->
                            </div>
                            <div class="row">
                                <!--ToDo: generate these tabs on the fly-->
                                <ul class="nav nav-tabs col-sm-6 col-lg-6" style="margin-bottom: 10px;">
                                    <li class="nav-item">
                                        <a id="swimmer-plot-participants-table-tab" class="nav-link table-tab active">Participants</a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="swimmer-plot-treatments-table-tab" class="nav-link table-tab">Treatments</a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="swimmer-plot-drugs-table-tab" class="nav-link table-tab" style="display: none;">Drugs</a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="swimmer-plot-biospecimens-table-tab" class="nav-link table-tab">Biospecimens</a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="swimmer-plot-samples-table-tab" class="nav-link table-tab">Samples</a>
                                    </li>
                                    <li class="nav-item">
                                        <a id="swimmer-plot-table-hide-tab" class="nav-link table-tab active" style="display: none;">Hide tables</a>
                                    </li>
                                </ul>
                                <div class="btn-group btn-group-sm col-sm-3 col-lg-3" style="margin-bottom: 10px;">
                                    <a id="swimmer-plot-original-data" class="btn btn-outline-secondary">Reset to original data</a>
                                    <a id="swimmer-plot-sync" class="btn btn-outline-secondary">Filter plot by data tables</a>
                                    <a id="swimmer-plot-sort" class="btn btn-outline-secondary">Sort plot by participant table</a>

                                </div>
                            </div>
                            <table width="98%" id="swimmer-plot-table" class="display compact" />
                            <hr style="border-top:2px solid #8c8b8b; ">
                        </div>
                    </div>
                </div>
                <div id="swimmer-plot-toolbar" class="row" style="font-size: 12px;margin-top: 5px; padding-bottom: 5px;">
                    <div class="col-xs-12 col-md-12 col-lg-12" style="margin-top: 5px;">
                        <div class="btn-group btn-group-sm" style="margin-right: 10px;">
                            <div class="btn btn-secondary" style="font-size: 10px;">
                                Height
                            </div>
                            <a id="swimmer-plot-zoom-height-shorten" class="btn btn-outline-secondary" style="font-size: 8px;"><i class="fas fa-minus" /></a>
                            <a id="swimmer-plot-zoom-height" class="btn btn-outline-secondary" style="font-size: 8px;"><i class="fas fa-plus" /></a>
                        </div>
                        <div class="btn-group btn-group-sm" style="margin-right: 10px">
                            <div class="btn btn-secondary" style="font-size: 10px;">
                                Width
                            </div>
                            <a id="swimmer-plot-zoom-width-narrow" class="btn btn-outline-secondary" style="font-size: 8px;"><i class="fas fa-minus" /></a>
                            <a id="swimmer-plot-zoom-width" class="btn btn-outline-secondary" style="font-size: 8px;"><i class="fas fa-plus" /></a>
                        </div>
                        <div class="btn-group btn-group-sm">
                            <div class="btn btn-secondary" style="font-size: 10px;">
                                Zoom
                            </div>
                            <a id="swimmer-plot-refresh" class="btn btn-outline-secondary" style="font-size: 8px;"><i class="fa fa-undo" /></a>
                            <a id="swimmer-plot-zoom-out" class="btn btn-outline-secondary" style="font-size: 8px;"><i class="fas fa-minus" /></a>
                            <a id="swimmer-plot-zoom-in" class="btn btn-outline-secondary" style="font-size: 8px;"><i class="fas fa-plus" /></a>
                        </div>
                        <b style="margin-left: 15px;">Download</b>
                        <div class="btn-group btn-group-sm">
                            <a id="swimmer-plot-download" class="btn btn-outline-secondary">Data</a>
                            <a id="swimmer-plot-download-plot" class="btn btn-outline-secondary">SVG</a>
                            <a id="swimmer-plot-download-config" class="btn btn-outline-secondary">Config</a>
                        </div>
                        <b style="margin-left: 15px;">Upload</b>
                        <div class="btn-group btn-group-sm">
                            <a id="swimmer-plot-upload-config" class="btn btn-outline-secondary" onclick="document.getElementById('file-selector').click();" >Config</a>
                            <input type="file" style="display:none;" id="file-selector" name="file-selector" accept=".txt, .json"/>            
                        </div>
                    </div>
                </div>
                <hr>
                <div id="swimmer-plot" style="margin-top:10px;">
                    <!-- Root DOM for the SVG -->
                    <div id="swimmer-plot-sticky"><span>Sticky</span></div>
                    <div id="swimmer-plot-main" class="scroll"></div>
                </div>
                <div id="swimmer-plot-error-log" style="font-size: 8px;">
                    <!--where informative error goes-->
                </div>
                <div id="svg-download" style="display:none" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SwimmerPlotComponent',
    props: {
        plotData: {
            default: [],
            type: Array,
            required: false
        },
        plotDataType: {
            type: String,
            required: true
        },
        cohortId: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const plotId = 'swimmer-plot';
            const treatmentSelector = 'tSelector';
            const margin = {
                top: 0, // margin-top hides plot legend and x axis due to redundant legend and axes in the sticky session, but they are still rendered for downloading purposes
                right: 50,
                bottom: 300,
                left: 150
            };
            const width = window.innerWidth - 100;
            const trackHeight = 60;
            const plot = new SwimmerPlot(
                this.plotDataType,
                plotId,
                this.cohortId,
                margin,
                width,
                trackHeight,
                treatmentSelector
            );
            plot.init(this.plotData);
        }
    }

};
</script>

<style scoped>
@import '../assets/portal_viz/css/swimmerPlot.css';
@import '../assets/portal_viz/css/swimmerPlotSideBar.css';
@import '../assets/portal_viz/css/timeTable.css';
</style>
