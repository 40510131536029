<template>
    <div>
        <h2>Add Manifest</h2>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addManifest">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cohortOptions" v-model="v$.form.selectedCohort.$model"/>
                </b-form-group> 
                <b-form-group label="Participant ID" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="participantOptions" v-model="v$.form.selectedParticipant.$model" />
                </b-form-group>
                <b-form-group label="Collaborator Sample ID" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="sampleOptions" v-model="v$.form.selectedSample.$model" />
                </b-form-group>
                <b-form-group label="Manifest Sample ID" :description="form.submittedSmIdDescription" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.manifestSampleId.$model" />
                </b-form-group>
                <b-form-group label="Sample Type" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="sampleTypeOptions" v-model="v$.form.selectedSampleType.$model" />
                </b-form-group>
                <!-- <b-form-group label="Submitted Date" :description="form.submittedDateDescription" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.submittedDate.$model" />
                </b-form-group> -->
                <b-form-group label="Source [optional]" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.source" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                <!-- <b-button type="submit" size="sm" style="font-size:0.85rem;"> -->
                    Add Manifest
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { smSampleIdCheck } from '../../utils/input-regex';
import {
    getUrl, getWriteProjectsPromise, getBiospecimenTumorNormalEnumPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createCohortsDict, createParticipantsDict, createBiospecimensDict, createEnumDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            participantsUrl: getUrl('participantsUrl'),
            biospecimensUrl: getUrl('biospecimensUrl'),
            manifestsUrl: getUrl('manifestsUrl'),

            // options for form dropdowns
            cohortOptions: [],
            participantOptions: [],
            sampleOptions: [],
            sampleTypeOptions: [],

            // form data
            form: {
                // dropdowns and selections
                selectedCohort: null,
                selectedParticipant: null,
                selectedSample: null,
                selectedSampleType: null,
                // inputs
                manifestSampleId: null,
                //submittedDate: null,
                source: '',
                notes: '',

                // description for form
                submittedSmIdDescription: 'Format must be "SM-" followed by 5 numbers or letters',
                //submittedDateDescription: 'Accepted entries: a date in the format MM/DD/YYYY, or a date in the format MM/DD/YY'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // dropdowns 
                selectedCohort: { required },
                selectedParticipant: { required },
                selectedSample: { required },
                selectedSampleType: { required },
                // inputs
                manifestSampleId: {
                    required,
                    smSampleIdCheck
                },
                //submittedDate: { required },
                // source: { required }
            }
        }
    },
    watch: {
        'form.selectedCohort': function () {
            // updates the participants dropdown when a new cohort is selected
            this.selectedParticipant = null;
            if (this.form.selectedCohort === null) {
                this.form.selectedParticipant = null;
                this.participantOptions = [];
                this.sampleOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${this.participantsUrl}?cohort_id=${this.form.selectedCohort}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    component.participantOptions = createDropdownOptions(pDict);
                })
                .finally(() => {
                    loader.hide();
                    component.form.selectedParticipant = null;
                });
        },
        'form.selectedParticipant': function () {
            // updates the collaborator sample id dropdown when a new participant is selected 
            this.selectedSample = null;
            if (this.form.selectedParticipant === null) {
                this.form.selectedSample = null;
                this.sampleOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            // const biospecimensUrl = `${this.biospecimensUrl}?cohort_id=${this.form.selectedCohort}&participant_id=${this.form.selectedParticipant}`;
            axios.get(`${this.biospecimensUrl}?cohort_id=${this.form.selectedCohort}&participant_id=${this.form.selectedParticipant}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((sResp) => {
                    const { biospecimens } = sResp.data;
                    const sDict = createBiospecimensDict(biospecimens);
                    component.sampleOptions = createDropdownOptions(sDict);
                })
                .finally(() => {
                    loader.hide();
                    component.form.selectedSample = null;
                });
        }
    },
    mounted() {
        this.initManifestForm();
    },
    methods: {
        initManifestForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getWriteProjectsPromise(),
                getBiospecimenTumorNormalEnumPromise(),
            ])
                .then(axios.spread(( 
                    cohortResp,
                    biospecimenTumorNormalResp
                ) => {
                    const cohorts = createCohortsDict(cohortResp.data.cohorts);
                    const sampleType = createEnumDict(biospecimenTumorNormalResp.data.biospecimen_tumor_normal);
                    component.cohortOptions = createDropdownOptions(cohorts);
                    component.sampleTypeOptions = createDropdownOptions(sampleType);
                }))
                .finally(() => { loader.hide(); });
        },
        addManifest() {
            const loader = this.$loading.show();
            const component = this;

            const newManifestInfo = {
                cohort_id: this.form.selectedCohort,
                participant_id: this.form.selectedParticipant,
                biospecimen_id: this.form.selectedSample,
                manifest_sample_id: this.form.manifestSampleId,
                sample_type: this.form.selectedSampleType,
                //submitted_date: this.form.submittedDate,
                source: this.form.source,
                notes: this.form.notes
            };

            axios.post(this.manifestsUrl, newManifestInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }
};
</script>

<style>

</style>
