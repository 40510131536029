<template>
    <div>
        <h3>View Drug Targets</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="targets-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="targets-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    props: {
        // For when collaborators are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // urls
            sampleCoordTargetsUrl: getUrl('sampleCoordinatorDrugTargetsUrl'),

            // table vars
            exportColumns: [0, 1, 2],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Drug Target', data: 'name' },
                { title: 'Notes', data: 'notes' },
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-target">Edit</span>';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span delete-target">Delete</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    watch: {
    },
    mounted() {
        this.populateTargetsTable();
    },
    methods: {
        populateTargetsTable() {
            const loader = this.$loading.show(); // loader while waiting for targets GET request
            const component = this;

            axios.get(component.sampleCoordTargetsUrl, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((targetResp) => {
                    loader.hide();
                    const targetTableData = targetResp.data.drug_targets;
                    const table = $('#targets-table').DataTable({
                        destroy: true,
                        data: targetTableData,
                        columns: component.tableColumns,
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '70vh',
                        scrollCollapse: true,
                        dom: 'Blfrtip',
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#drug_target',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'drug_targets',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#drug_target'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'drug_targets',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#drug_target'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                });
        },
        deleteTarget(id) {
            const component = this;
            const loader = this.$loading.show();
            const deleteUrl = `${this.sampleCoordTargetsUrl}?id=${id}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.populateTargetsTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete drug target. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        addTableActions(datatable) {
            const component = this;
            $('#targets-table tbody .edit-target').click(function editTarget() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.$router.push({
                    name: 'SampleCoordinatorEditDrugTargetPage',
                    params: {
                        targetId: rowData.id
                    }
                });
            });
            $('#targets-table tbody .delete-target').click(function deleteTarget() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to delete drug target ${rowData.name}?`;
                const msgBoxOptions = {
                    hideHeader: true,
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Delete Target',
                    centered: true
                };

                component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                    .then((deleteConfirmed) => {
                        if (deleteConfirmed) {
                            component.deleteTarget(rowData.id);
                        }
                    });
            });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        }
    }
};
</script>

<style>
#collaborators-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
