import store from '../store';

export default function getUserToken() {
    return store.state.user.token;
};

export async function decodeJwt(token) {
    const { OAuth2Client } = require('google-auth-library');

    /**
     * @description Function to decode and extract user information from Google JWT
     * @param token: JWT returned from google sign in
     * @returns response, either a 500-status error or an object containing user information
     */

    const clientId = '156602588770-qsfhqe333vncebfr3ij987iujbal8jqj.apps.googleusercontent.com';

    try {
        const client = new OAuth2Client(clientId);
        const response = await client.verifyIdToken({
            idToken: token,
            audience: clientId
        });
        return { email: response.payload.email, verified: response.payload.email_verified, name: response.payload.name };
    } catch (error) {
        return { status: 500, data: error };
    }
};