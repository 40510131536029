<template>
    <div>
        <h3>Add Cancer Stage</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addCancerStage">
                <b-form-group label="Cancer Staging System" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cancerStagingSystemsOptions" v-model="v$.form.selectedCancerStagingSystem.$model" />
                </b-form-group>
                <b-form-group label="Cancer Type" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cancerTypeOptions" v-model="v$.form.selectedCancerType.$model" />
                </b-form-group>
                <b-form-group label="Cancer Stage" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.cancerStageName.$model" :state="v$.form.cancerStageName.$error ? false : null" />
                </b-form-group>
                <b-form-group label="Cancer Stage Information [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.stageInformation" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Add Cancer Stage
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    maxLength,
    minLength
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {
    getUrl
} from '../../utils/directory';
import {
    createDropdownOptions,
    createCancerStagingSystemsDict,
    createCancerTypesDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            sampleCoordinatorCancerTypesUrl: getUrl('sampleCoordinatorCancerTypesUrl'),
            sampleCoordinatorCancerStagesUrl: getUrl('sampleCoordinatorCancerStagesUrl'),
            sampleCoordinatorCancerStagingSystemsUrl: getUrl('sampleCoordinatorCancerStagingSystemsUrl'),

            // options for form dropdowns
            cancerStagingSystemsOptions: [],
            cancerTypeOptions: [],

            // form data
            form: {
                // dropdowns
                selectedCancerStagingSystem: null,
                selectedCancerType: null,
                // inputs
                cancerStageName: null,
                stageInformation: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
        'form.selectedCancerStagingSystem': function () {
            // updates the cancer type dropdown when a new staging system is selected
            if (this.form.selectedCancerStagingSystem === null) {
                this.form.selectedCancerType = null;
                this.cancerTypeOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${this.sampleCoordinatorCancerTypesUrl}?staging_system_id=${this.form.selectedCancerStagingSystem}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((cancerTypeResp) => {
                    const cancerTypes = createCancerTypesDict(cancerTypeResp.data.cancer_types);
                    component.cancerTypeOptions = createDropdownOptions(cancerTypes);
                })
                .finally(() => {
                    loader.hide();
                    component.form.selectedCancerType = null;
                });
        }
    },
    validations() {
        return { 
            form: {
                // dropdowns
                selectedCancerStagingSystem: { required },
                selectedCancerType: { required },
                cancerStageName: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(16)
                }
            }
        }
    },
    mounted() {
        this.initCancerStageForm();
    },
    methods: {
        initCancerStageForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.get(component.sampleCoordinatorCancerStagingSystemsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cancerStagingSystems = createCancerStagingSystemsDict(resp.data.cancer_staging_systems);
                    component.cancerStagingSystemsOptions = createDropdownOptions(cancerStagingSystems);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        addCancerStage() {
            const loader = this.$loading.show();
            const component = this;

            const newCancerStage = {
                stage_number: this.form.cancerStageName,
                cancer_type_id: this.form.selectedCancerType,
                stage_information: this.form.stageInformation === '' ? null : this.form.stageInformation
            };

            axios.post(this.sampleCoordinatorCancerStagesUrl, newCancerStage, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
