<template>
    <div>
        <h3>View Manifests</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>

                    <b-form-group label="Participant ID" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!cohortSelected" v-model="selectedParticipant" :options="participantOptions" />
                    </b-form-group>

                    <b-form-group label="Collaborator Sample ID" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!participantSelected" v-model="selectedSample" :options="sampleOptions" />
                    </b-form-group>

                    <b-form-group label="Submitted Date" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!sampleSelected" v-model="selectedDate" :options="dateOptions" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="manifest-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="manifest-table" class="display compact stripe" style="width:100%" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import axios from 'axios';
    import { getUrl } from '../utils/directory';
    import {
        createCohortsDict,
        createParticipantsDict,
        createDropdownOptions,
        createBiospecimensDict,
        createDatesDict
    } from '../utils/form-data-helpers';
    import getUserToken from '../utils/auth';
    import store from '../store';


    export default {
        props: {
            needAlert: { default: false, type: Boolean, required: false },
            ajaxResponse: { default() { return { data: { message: '' } };  }, type: Object, required: false },
            // add update props when support for updates is added 
            // this prop is passed to indicate what type of user is viewing the biospecimens
        },
        data() {
            const storeRoles = store.state.user.roles;
            return {
                // user roles
                roles: storeRoles,

                // cohort specific role info
                readerCohorts: storeRoles.readerCohorts,
                dataEditorCohorts: storeRoles.dataEditorCohorts,
                dataAnalystCohorts: storeRoles.dataAnalystCohorts,
                projectLeadsCohorts: storeRoles.projectLeadCohorts,

                // alert box vars 
                showAlert: this.needAlert,
                alertMessage: this.ajaxResponse.data,
                alertVariant: 'warning',

                // URLs used 
                projectsUrl: getUrl('projectsUrl'),
                participantsUrl: getUrl('participantsUrl'),
                biospecimensUrl: getUrl('biospecimensUrl'),
                manifestsUrl: getUrl('manifestsUrl'),

                // form view controls 
                cohortSelected: false,
                participantSelected:false, 
                sampleSelected: false,

                // cohort dropdown selections
                selectedCohort: null,
                cohortOptions: [],

                // participant dropdown selections
                selectedParticipant: null,
                participantOptions: [],

                // sample dropdown selections
                selectedSample: null,
                sampleOptions: [],

                // date dropdown selections
                selectedDate: null,
                dateOptions: [],

                // table vars
                exportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                tableColumns: [
                    {title: 'DBKey', data: 'db_id'},
                    {title: 'Sample ID', data: 'sample_id'},
                    {title: 'Collaborator Participant ID', data: 'collaborator_participant_id'},
                    {title: 'Collaborator Sample ID', data: 'collaborator_sample_id'},
                    {title: 'Sample Type', data: 'sample_type'},
                    {title: 'Submitted Date', data: 'submitted_date'},
                    {title: 'Notes', data: 'notes'},
                    {title: 'Source', data: 'source'}
                ],

                // add edit and update columns when implemented 
                sColumns: [
                // {
                //     title: 'Edit',
                //     sortable: false,
                //     render(data, type, row) {
                //         return '<span class="text-btn-span edit-manifest">Edit</span>';
                //     }
                // },
                {
                    title: 'Delete',
                    sortable: false,
                    render(data, type, row) {
                        return '<span class="text-btn-span delete-manifest">Delete</span>';
                    }
                }
                ],

                tablePaginationOptions: [
                    [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                    ['All', 10, 25, 50, 100] // pagination display text
                ]
            };
        },
        watch: {
            selectedCohort() {
                // update participants dropdown when new cohort is selected 
                this.selectedParticipant = null;
                if (this.selectedCohort === null) {
                    this.selectedParticipant = null;
                    this.participantOptions = [];
                    this.cohortSelected = false;
                    this.hideTable();
                    return;
                }
                const loader = this.$loading.show();
                const component = this;
                const participantsUrl = this.populateParticipantsUrl();
                axios.get(`${participantsUrl}?cohort_id=${this.selectedCohort}&primary_only=false`, {
                    headers: { Authorization: `Bearer ${getUserToken()}` }
                })
                    .then((pResp) => {
                        const { participants } = pResp.data;
                        const pDict = createParticipantsDict(participants);
                        const allOption = {
                            value: -1,
                            text: 'All'
                        };
                        const pOptions = createDropdownOptions(pDict, true, true);
                        component.participantOptions = [allOption].concat(pOptions);
                        component.cohortSelected = true;
                    })
                    .finally(() => {
                        loader.hide();
                    })
            },

            selectedParticipant() {
                // update samples dropdown when new participant is selected 
                this.selectedSample = null;
                if (this.selectedParticipant === null) {
                    this.selectedSample = null;
                    this.sampleOptions = [];
                    this.participantSelected = false;
                    this.hideTable();
                    return;
                }
                const loader = this.$loading.show();
                const component = this;
                const biospecimensUrl = this.selectedParticipant === -1 ? `${this.populateBiospecimensUrl()}?cohort_id=${this.selectedCohort}` : `${this.populateBiospecimensUrl()}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}`;
                axios.get(biospecimensUrl, {
                    headers: { Authorization: `Bearer ${getUserToken()}` }
                })
                    .then((bResp) => {
                        const { biospecimens } = bResp.data;
                        const bDict = createBiospecimensDict(biospecimens);
                        // component.bDict = bDict;
                        const allOption = {
                            value: -1,
                            text: 'All'
                        };
                        const bOptions = createDropdownOptions(bDict, true, true);
                        component.sampleOptions = [allOption].concat(bOptions);
                        component.participantSelected = true;
                    })
                    .finally(() => {
                        loader.hide(); 
                    });
            },

            selectedSample() {
                this.selectedDate = null;
                // update date dropdown when new sample is selected
                if ( this.selectedSample === null) {
                    this.selectedDate = null;
                    this.dateOptions = [];
                    this.sampleSelected = false;
                    this.hideTable();
                    return;
                }
                const loader = this.$loading.show();
                const component = this;
                // could this be optimized? 
                const baseManifestUrl = component.populateManifestsUrl();
                let manifestUrl;
                if (this.selectedParticipant === -1) {
                    if (this.selectedSample === -1) {
                        manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}`;
                    }
                    else {
                        manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&biospecimen_id=${this.selectedSample}`;
                    }
                }
                else {
                    if (this.selectedSample === -1) {
                        manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}`;
                    }
                    else {
                        manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}&biospecimen_id=${this.selectedSample}`;
                    }
                }
                
                axios.get(manifestUrl, {
                    headers: { Authorization: `Bearer ${getUserToken()}` }
                })
                    .then((mResp) => {
                        const { manifests } = mResp.data;
                        const dDict = createDatesDict(manifests);
                        const allOption = {
                            value: -1,
                            text: 'All'
                        };
                        const dOptions = createDropdownOptions(dDict, true, true);
                        component.dateOptions = [allOption].concat(dOptions);
                        component.sampleSelected = true;
                    })
                    .finally(() => {
                        loader.hide();
                    });
            },
    
            selectedDate() {
                if (this.selectedDate === null) {
                    this.hideTable();
                    return;
                }
                // table must be reset if date changes since number of columns may change based on permissions
                let table = $('#manifest-table');
                if ($.fn.DataTable.isDataTable(table)) {
                    table.DataTable().clear().draw();
                    table.DataTable().destroy();
                    table.empty(); 
                }
                this.getManifestTable();
            }
        },
        beforeMount() {
            this.getCohorts();
        },
        methods: {
            populateCohortsUrl() {
                return this.projectsUrl;
            },
            populateManifestsUrl() {
                return this.manifestsUrl;
            },
            populateParticipantsUrl() {
                return this.participantsUrl;
            },
            populateBiospecimensUrl() {
                return this.biospecimensUrl;
            },
            getCohorts(){
                const loader = this.$loading.show();
                const component = this;
                // let cohortsUrl = component.populateCohortsUrl();
                // console.log(cohortsUrl);
                // axios.get(cohortsUrl, { 
                //     headers: { Authorizaiton: `Bearer ${getUserToken()}` } 
                // })
                axios.get(component.populateCohortsUrl(), { headers: { Authorization: `Bearer ${getUserToken()}` } })
                    .then((cResp) => {
                        const cDict = createCohortsDict(cResp.data.cohorts);
                        component.cohortOptions = createDropdownOptions(cDict);
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        loader.hide(); 
                    });
            },
            deleteManifest(cohortId, manifestId){
                const component = this;
                const loader = component.$loading.show();
                const deleteUrl = `${component.manifestsUrl}?cohort_id=${cohortId}&id=${manifestId}`;
                axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getManifestTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete manifest. Please contact portal administrators.'
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
            },
            addTableActions(datatable) {
            const component = this;
            if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                // $('#manifest-table tbody .edit-manifest').click(function editManifest() {
                //     const rowData = datatable.row($(this).parents('tr')).data();
                //     const selectedCohortOption = component.cohortOptions.filter(x => x.value === component.selectedCohort)[0];
                //     component.$router.push({
                //         name: 'SubmitterEditManifestPage',
                //         params: {
                //             role: component.role,
                //             cohortId: selectedCohortOption.value,
                //             cohortName: selectedCohortOption.text,
                //             biospecimenId: rowData.db_id
                //         }
                //     });
                // });
                $('#manifest-table tbody .delete-manifest').click(function confirmManifestDelete() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete manifest ${rowData.sample_id}?`;
                    const msgBoxOptions = {
                        hideHeader: true,
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Delete manifest',
                        centered: true
                    };

                    component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                        .then((deleteConfirmed) => {
                            if (deleteConfirmed) {
                                component.deleteManifest(component.selectedCohort, rowData.db_id);
                            }
                        });
                });
            }
            },
            updateExportColumnNames(headerRow) {
                const component = this;
                let header = headerRow;
                this.exportColumns.forEach((i) => {
                    const col = component.tableColumns[i];
                    header = header.replace(col.title, col.data);
                });
                return header;
            },
            getColumns() {
                let { tableColumns } = this;
                if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                    tableColumns = tableColumns.concat(this.sColumns);
                }
                return tableColumns;
            },
            updateExportColumnNames(headerRow) {
                const component = this;
                let header = headerRow;
                this.exportColumns.forEach((i) => {
                    const col = component.tableColumns[i];
                    header = header.replace(col.title, col.data);
                });
                header = `${header}${'\tdelete_manifest'}`;
                return header;
            },
            hideTable() {
                $('#manifest-table-wrapper').hide();
            },
            showTable() {
                $('#manifest-table-wrapper').show();
            },
            getManifestTable(){
                const component = this;
                const loader = this.$loading.show();
                const baseManifestUrl = this.populateManifestsUrl();
                let manifestUrl = null;
                if (this.selectedParticipant === -1) {
                    if (this.selectedSample === -1) {
                        if (this.selectedDate === -1) {
                            manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}`;
                        }
                        else{
                            manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&submitted_date=${this.selectedDate}`;
                        }
                    }
                    else {
                        console.log("sample NOT -1")
                        if (this.selectedDate === -1) {
                            manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&biospecimen_id=${this.selectedSample}`;
                        }
                        else{
                            manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&biospecimen_id=${this.selectedSample}&submitted_date=${this.selectedDate}`;
                        }
                    }
                }
                else {
                    if (this.selectedSample === -1) {
                        if (this.selectedDate === -1) {
                            manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}`;
                        }
                        else{
                            manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}&submitted_date=${this.selectedDate}`;
                        }
                    }
                    else {
                        if (this.selectedDate === -1) {
                            manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}&biospecimen_id=${this.selectedSample}`;
                        }
                        else{
                            manifestUrl = `${baseManifestUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}&biospecimen_id=${this.selectedSample}&submitted_date=${this.selectedDate}`;
                        }
                    }
                }

                axios.get(manifestUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                    .then((resp) => {
                        component.showTable();
                        const { manifests } = resp.data;
                        const table = $('#manifest-table').DataTable({
                            destroy: true,
                            data: manifests,
                            columns: component.getColumns(),
                            order: [], // no sorting initially
                            lengthMenu: component.tablePaginationOptions,
                            dom: 'Blfrtip',
                            fixedHeader: {
                                header: true
                            },
                            scrollY: '60vh',
                            scrollCollapse: true,
                            buttons: [
                            {
                                extend: 'copy',
                                title: '#manifest',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-manifests`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#manifest'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                        });
                        component.addTableActions(table);
                    }) 
                    .finally(() => loader.hide());
            }
        }
    };
</script>

<style>

</style>
