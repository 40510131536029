// import Vue from 'vue';

// Bootstrap
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Vuelidate -- form validator
import VuelidatePlugin from '@vuelidate/core';

// Loading Overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// Fontawesome Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAdjust,
    faArrowsAltH,
    faArrowsAltV,
    faChevronCircleDown,
    faChevronCircleUp,
    faDownload,
    faExpandArrowsAlt,
    faFilter,
    faMinus,
    faPlus,
    faSignOutAlt,
    faSpinner,
    faUndo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import NavigationBarComponent from './components/NavigationBarComponent.vue';
import router from './router';
import App from './App.vue';
import store from './store';
import checkUserAccess from './utils/checkAccess';

import { createApp, h, configureCompat} from "vue";

const app = createApp(App)
// Vue.config.productionTip = false;
// adding plugins and global reusable components
app.use(BootstrapVue);
app.use(VuelidatePlugin);
app.use(Loading);
// adding icons used in app to Font Awesome library
library.add(faAdjust,
    faArrowsAltH,
    faArrowsAltV,
    faChevronCircleDown,
    faChevronCircleUp,
    faDownload,
    faExpandArrowsAlt,
    faFilter,
    faMinus,
    faPlus,
    faSignOutAlt,
    faSpinner,
    faUndo);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('navigation-bar-component', NavigationBarComponent);

// const app = createApp({
//     render: () => h(App)
// });

app.use(router);
app.use(store);

configureCompat({
    MODE: 2
});

app.mount("#app");

// new Vue({
//     router,
//     render: h => h(App)
// }).$mount('#app');
