<template>
    <div>
        <h3>Add a Bait Set</h3>
        <hr>
        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addBaitSet">
                <b-form-group label="Bait Set Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.name.$model" :state="$v.form.name.$dirty ? !$v.form.name.$error : null" />
                </b-form-group>
                <b-form-group label="Bait Set Abbreviation" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="$v.form.abbreviation.$model" :state="$v.form.abbreviation.$dirty ? !$v.form.abbreviation.$error : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Add Bait Set
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required, maxLength
} from '@vuelidate/validators';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    data() {
        return {
            sampleCoordinatorBaitSetsUrl: getUrl('sampleCoordinatorBaitSetsUrl'),
            // form data
            form: {
                name: null,
                abbreviation: null
            },
            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            name: {
                required,
                maxLength: maxLength(64)
            },
            abbreviation: {
                maxLength: maxLength(10)
            }
        }
    },
    mounted() {},
    methods: {
        addBaitSet() {
            const loader = this.$loading.show();
            const component = this;
            const newBaitSet = {
                bait_set_name: this.form.name,
                abbreviation: this.form.abbreviation === '' ? null : this.form.abbreviation
            };

            axios.post(this.sampleCoordinatorBaitSetsUrl, newBaitSet, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }
};
</script>
