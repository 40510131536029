<template>
    <div class="container-fluid">
        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>
        <h3 id="home-title">
            Getz Lab Project Portal
        </h3>
    </div>
</template>

<script>
export default {
    name: 'HomePage',
    props: {
        // these props are passed in after a participant is added to a cohort
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false }
    },
    data() {
        return {
            // alert vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning'
        };
    }
};
</script>

<style scoped>
#home-title {
    text-align: center;
}
</style>
