<template>
    <div id="app">
        <navigation-bar-component />
        <br>
        <router-view />
    </div>
</template>

<style>
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        font-size: 14px;
    }

    .text-btn-span {
        color: #46a4ad;
        cursor: pointer;
    }

    .text-btn-span:hover {
        color: #37838a;
        text-decoration: underline;
    }
</style>
