<template>
    <div>
        <h2>Add Biospecimen</h2>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addBiospecimen">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cohortOptions" v-model="v$.form.selectedCohort.$model" />
                </b-form-group>
                <b-form-group label="Participant ID" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="participantOptions" v-model="v$.form.selectedParticipant.$model" />
                </b-form-group>
                <b-form-group label="Collaborator Sample ID" :description="requirements.submittedName" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.submittedName.$model" :state="v$.form.submittedName.$dirty ? !v$.form.submittedName.$error : null" />
                </b-form-group>
                <b-form-group label="Aliases [optional]" :description="requirements.aliases" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.aliases.$model" :state="v$.form.aliases.$dirty ? !v$.form.aliases.$error : null" />
                </b-form-group>
                <b-form-group label="IRB Protocol IDs" label-class="font-weight-bold">
                    <b-form-select multiple required size="sm" :options="irbProtocolOptions" v-model="v$.form.protocolsCollectedUnder.$model" />
                </b-form-group>
                <b-form-group label="Collection date (days from diagnosis) [optional]" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.collectionDateDfd.$model" :state="v$.form.collectionDateDfd.$dirty ? !v$.form.collectionDateDfd.$error : null" />
                </b-form-group>
                <b-form-group label="Tissue site (Uberon anatomy ontology) [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="tissueSiteOptions" v-model="form.tissueSite" />
                </b-form-group>
                <b-form-group label="Tissue site details [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.tissueSiteDetail" />
                </b-form-group>
                <b-form-group label="Original material type" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="origMatTypeOptions" v-model="v$.form.originalMaterialType.$model" :state="v$.form.originalMaterialType.$dirty ? !v$.form.originalMaterialType.$error : null" />
                </b-form-group>
                <b-form-group label="Submitted material type" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="submitMatTypeOptions" v-model="v$.form.submittedMaterialType.$model" :state="v$.form.submittedMaterialType.$dirty ? !v$.form.submittedMaterialType.$error : null" />
                </b-form-group>
                <b-form-group label="Preservation method" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="preserveMethodOptions" v-model="v$.form.preservationMethod.$model" :state="v$.form.preservationMethod.$dirty ? !v$.form.preservationMethod.$error : null" />
                </b-form-group>
                <b-form-group label="Tumor/Normal" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="tumorNormalOptions" v-model="v$.form.tumorNormal.$model" :state="v$.form.tumorNormal.$dirty ? !v$.form.tumorNormal.$error : null" />
                </b-form-group>
                <b-form-group label="Tumor state" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="tumorStateOptions" v-model="v$.form.tumorState.$model" :state="v$.form.tumorState.$dirty ? !v$.form.tumorState.$error : null" />
                </b-form-group>
                <b-form-group label="RQS [optional]" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.rqs.$model" :state="v$.form.rqs.$dirty ? !v$.form.rqs.$error : null" />
                </b-form-group>
                <b-form-group label="DV200 [optional]" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.dv200.$model" :state="v$.form.dv200.$dirty ? !v$.form.dv200.$error : null" />
                </b-form-group>
                <b-form-group label="WES Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.wes" />
                </b-form-group>
                <b-form-group label="WGS Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.wgs" />
                </b-form-group>
                <b-form-group label="RNASeq Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.rnaseq" />
                </b-form-group>
                <b-form-group label="ULPWGS Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.ulpwgs" />
                </b-form-group>
                <b-form-group label="SCRNASeq Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.scrnaseq" />
                </b-form-group>
                <b-form-group label="Panel Sample Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.panel" />
                </b-form-group>
                <b-form-group label="GP Sample ID [optional]" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.gpSampleId" />
                </b-form-group>
                <b-form-group label="GP Submitted Date [optional]" :description="form.gpDateDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.gpSubmittedDate" />
                </b-form-group>
                <b-form-group label="Low-quality sample?" label-class="font-weight-bold">
                    <b-form-checkbox size="sm" v-model="form.is_low_quality" value="true" unchecked-value="false" />
                </b-form-group>
                <b-form-group label="Quality Description [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.quality_description" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-form-group label="Post-Allogeneic Stem Cell Transplant" label-class="font-weight-bold">
                    <b-form-checkbox size="sm" v-model="form.post_allogeneic_transplant" value="true" unchecked-value="false" />
                </b-form-group>

                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Add Biospecimen
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    between,
    maxLength,
    minLength,
    required
} from '@vuelidate/validators';
import { biospecimenIdCheck, biospecimenAliasCheck } from '../../utils/input-regex';
import {
    getUrl, getBiospecimenTumorNormalEnumPromise, getIrbProtocolsPromise, getOriginalMaterialTypeEnumPromise, getPreservationMethodEnumPromise, getWriteProjectsPromise, getSubmittedMaterialTypeEnumPromise, getTumorStateEnumPromise, getUberonAnatomySitesPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createCohortsDict, createEnumDict, createParticipantsDict, createIrbProtocolsDict, createUberonAnatomySitesDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            submitterParticipantsUrl: getUrl('participantsUrl'),
            submitterBiospecimensUrl: getUrl('biospecimensUrl'),

            // options for form dropdowns
            cohortOptions: [],
            participantOptions: [],
            irbProtocolOptions: [],
            tissueSiteOptions: [],
            origMatTypeOptions: [],
            submitMatTypeOptions: [],
            preserveMethodOptions: [],
            tumorNormalOptions: [],
            tumorStateOptions: [],

            // form requirements
            requirements: {
                submittedName: 'Collaborator Sample IDs can only contain alphanumeric characters, periods (.), dashes (-), pluses (+), parentheses, forwardslashes (/), backslashes (\\) and underscores (_).',
                aliases: 'Please input as a comma-separated list. Aliases must only contain alphanumeric characters, spaces, periods (.), dashes (-), pluses (+), parentheses, forwardslashes (/), backslashes (\\) and underscores (_).'
            },

            // form data
            form: {
                // dropdowns and selections
                selectedCohort: null,
                selectedParticipant: null,
                protocolsCollectedUnder: [],
                tissueSite: null,
                originalMaterialType: null,
                submittedMaterialType: null,
                preservationMethod: null,
                tumorNormal: null,
                tumorState: null,
                // inputs
                submittedName: null,
                aliases: null,
                collectionDateDfd: null,
                tissueSiteDetail: null,
                rqs: null,
                dv200: null,
                sm_id: null,
                gp_submitted_date: null,
                notes: '',
                post_allogeneic_transplant: false,
                is_low_quality: false,
                quality_description: '',

                sampleStatuses: {
                    wes: '',
                    wgs: '',
                    rnaseq: '',
                    ulpwgs: '',
                    scrnaseq: '',
                    panel: ''
                },

                // description for form
                sampleStatusDescription: 'Accepted entries: blank entry or a date in the format MM/DD/YYYY',
                gpDateDescription: 'Accepted entries: blank entry, a date in the format MM/DD/YYYY, or a date in the format MM/DD/YY'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // dropdowns
                selectedCohort: { required },
                selectedParticipant: { required },
                protocolsCollectedUnder: { required },
                // inputs
                submittedName: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(64),
                    biospecimenIdCheck
                },
                aliases: {
                    minLength: minLength(3),
                    maxLength: maxLength(1024),
                    biospecimenAliasCheck
                },
                collectionDateDfd: { between: between(-36500, 36500) },
                rqs: { between: between(0, 36) },
                dv200: { between: between(0, 1) },
                originalMaterialType: { required },
                submittedMaterialType: { required },
                preservationMethod: { required },
                tumorNormal: { required },
                tumorState: { required }
            }
        }
    },
    watch: {
        'form.selectedCohort': function () {
            // updates the participants dropdown when a new cohort is selected
            if (this.form.selectedCohort === null) {
                this.form.selectedParticipant = null;
                this.participantOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${this.submitterParticipantsUrl}?cohort_id=${this.form.selectedCohort}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    component.participantOptions = createDropdownOptions(pDict);
                })
                .finally(() => {
                    loader.hide();
                    component.form.selectedParticipant = null;
                });
        }
    },
    mounted() {
        this.initBiospecimenForm();
    },
    methods: {
        initBiospecimenForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getWriteProjectsPromise(),
                getIrbProtocolsPromise(),
                getUberonAnatomySitesPromise(),
                getOriginalMaterialTypeEnumPromise(),
                getSubmittedMaterialTypeEnumPromise(),
                getPreservationMethodEnumPromise(),
                getBiospecimenTumorNormalEnumPromise(),
                getTumorStateEnumPromise()
            ])
                .then(axios.spread((
                    projectResp,
                    protocolsResp,
                    uberonResp,
                    origMaterialResp,
                    submittedMaterialResp,
                    preservationResp,
                    biospecimenTumorNormalResp,
                    tumorStateResp
                ) => {
                    const cohorts = createCohortsDict(projectResp.data.cohorts);
                    const irbProtocols = createIrbProtocolsDict(protocolsResp.data.irb_protocols);
                    const uberonSites = createUberonAnatomySitesDict(uberonResp.data.uberon_anatomy_sites);
                    const originalMaterialTypes = createEnumDict(origMaterialResp.data.original_material_type);
                    const submittedMaterialTypes = createEnumDict(submittedMaterialResp.data.submitted_material_type);
                    const preservationMethods = createEnumDict(preservationResp.data.preservation_method);
                    const tumorNormal = createEnumDict(biospecimenTumorNormalResp.data.biospecimen_tumor_normal);
                    const tumorStates = createEnumDict(tumorStateResp.data.tumor_state);
                    component.cohortOptions = createDropdownOptions(cohorts);
                    component.irbProtocolOptions = createDropdownOptions(irbProtocols, true, false);
                    component.tissueSiteOptions = createDropdownOptions(uberonSites);
                    component.origMatTypeOptions = createDropdownOptions(originalMaterialTypes);
                    component.submitMatTypeOptions = createDropdownOptions(submittedMaterialTypes);
                    component.preserveMethodOptions = createDropdownOptions(preservationMethods);
                    component.tumorNormalOptions = createDropdownOptions(tumorNormal);
                    component.tumorStateOptions = createDropdownOptions(tumorStates);
                }))
                .finally(() => { loader.hide(); });
        },
        addBiospecimen() {
            const loader = this.$loading.show();
            const component = this;

            const newBiospecimenInfo = {
                cohort_id: this.form.selectedCohort,
                participant_id: this.form.selectedParticipant,
                submitted_name: this.form.submittedName,
                submitted_aliases: this.form.aliases === '' ? null : this.form.aliases,
                protocols_collected_under: this.form.protocolsCollectedUnder,
                collection_date_dfd: this.form.collectionDateDfd,
                tissue_site: this.form.tissueSite,
                tissue_site_detail: this.form.tissueSiteDetail,
                original_material_type: this.form.originalMaterialType,
                submitted_material_type: this.form.submittedMaterialType,
                preservation_method: this.form.preservationMethod,
                tumor_normal: this.form.tumorNormal,
                tumor_state: this.form.tumorState,
                rqs: this.form.rqs === '' ? null : this.form.rqs,
                dv200: this.form.dv200 === '' ? null : this.form.dv200,
                sm_id: this.form.gpSampleId === '' ? null : this.form.gpSampleId,
                gp_submitted_date: this.form.gpSubmittedDate === '' ? null : this.form.gpSubmittedDate,
                is_low_quality: this.form.is_low_quality,
                quality_description: this.form.quality_description,
                notes: this.form.notes,
                post_allogeneic_transplant: this.form.post_allogeneic_transplant,
                sample_statuses: this.form.sampleStatuses
            };

            axios.post(this.submitterBiospecimensUrl, newBiospecimenInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }
};
</script>

<style>

</style>
