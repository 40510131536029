<template>
    <div>
        <h3>Validate and Upload PrAn Workspace Attributes</h3>
        <hr>

        <b-alert :show="ajaxComplete" :variant="alertVariant" dismissible>
            {{ ajaxResponse.data.message }}
        </b-alert>

        <b-row>
            <b-col md="4">
                <b-form>
                    <b-form-group>
                        <b-form-file required size="sm" placeholder="Please choose a file" browse-text="Browse files" v-model="$v.form.selectedFile.$model" @change="requireFormValidate" />
                    </b-form-group>

                    <b-button type="validate" size="sm" style="font-size:0.85rem; margin-right:15px;" :disabled="$v.form.$invalid" @click.stop.prevent="validateFile">
                        Validate File
                    </b-button>

                    <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="!validationPassed" @click.stop.prevent="uploadFile">
                        Upload
                    </b-button>
                </b-form>
            </b-col>
        </b-row>

        <b-container fluid v-show="showErrorTable">
            <br>
            <br>
            <b-row>
                <b-col>
                    <table id="error-table" class="display stripe" style="width:100%" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { required } from '@vuelidate/validators';
import getUserToken from '../../utils/auth';
import { getUrl } from '../../utils/directory';
import { LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST } from '../../utils/constants';

export default {
    props: {
        // For when attributes sets are updated
        needAlert: { default: false, type: Boolean, required: false },
        role: {
            type: String,
            required: true,
            validator(val) {
                return [LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // error modal
            alertVariant: 'warning',
            ajaxComplete: false,
            ajaxResponse: {
                data: { message: '' }
            },

            // upload submit button
            validationPassed: false,

            ldaFileUploadUrl: getUrl('leadDataAnalystUploadWorkspaceAttributesUrl'),
            ldaFileValidateUrl: getUrl('leadDataAnalystValidateWorkspaceAttributesUrl'),
            uldaFileUploadUrl: getUrl('uberLeadDataAnalystUploadWorkspaceAttributesUrl'),
            uldaFileValidateUrl: getUrl('uberLeadDataAnalystValidateWorkspaceAttributesUrl'),

            form: {
                selectedFile: null
            },

            // table vars
            showErrorTable: false,
            tableColumns: [
                { title: 'Row', data: 'row' },
                { title: 'Code', data: 'code' },
                { title: 'Error', data: 'message' },
                { title: 'Column', data: 'column' },
                { title: 'Field', data: 'field' },
                { title: 'Value', data: 'value' },
                { title: 'Missing', data: 'missing' },
                { title: 'Unexpected', data: 'unexpected' },
                { title: 'Record', data: 'record' }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    validations: {
        form: {
            // dropdowns
            selectedFile: { required }
        }
    },
    mounted() {},
    methods: {
        populateValidateUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.ldaFileValidateUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uldaFileValidateUrl;
            default:
                console.error('Invalid role for attributes table.');
                return undefined;
            }
        },
        populateUploadUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.ldaFileUploadUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uldaFileUploadUrl;
            default:
                console.error('Invalid role for attributes table.');
                return undefined;
            }
        },
        requireFormValidate() {
            if (this.validationPassed) {
                this.validationPassed = false;
            }
        },
        validateFile() {
            this.ajaxComplete = false;
            this.showErrorTable = false; // resetting and hiding the table
            const loader = this.$loading.show();
            const component = this;
            const url = `${component.populateValidateUrl()}`;
            const formData = new FormData();
            formData.append('load_file', this.form.selectedFile);
            axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((resp) => {
                    if (resp.status === 200) {
                        component.ajaxComplete = true;
                        component.alertVariant = 'warning';
                        component.ajaxResponse = resp;
                        component.validationPassed = true;
                    }
                })
                .catch((err) => {
                    component.form.selectedFile = null;
                    const resp = err.response;
                    if (resp.status === 422) {
                        // validation errors were found
                        if (resp.data.message === undefined) {
                            resp.data.message = resp.data.error;
                        }
                        component.alertVariant = 'danger';
                        component.ajaxComplete = true;
                        component.validationPassed = false;
                        component.ajaxResponse = resp;

                        if (typeof resp.data.error === 'object') {
                            $('#error-table').DataTable({
                                destroy: true,
                                data: resp.data.error,
                                columns: component.tableColumns,
                                order: [], // no sorting, initially
                                lengthMenu: component.tablePaginationOptions,
                                dom: 'Blfrtip',
                                buttons: [
                                    {
                                        extend: 'copy'
                                    },
                                    {
                                        text: 'TSV',
                                        extend: 'csv',
                                        fieldSeparator: '\t',
                                        fieldBoundary: '',
                                        title: 'candr_bulk_upload_validator',
                                        extension: '.txt'
                                    }
                                ]
                            });
                            component.showErrorTable = true;
                        }
                    }
                })
                .finally(() => {
                    loader.hide();
                });
        },
        uploadFile() {
            const loader = this.$loading.show();
            const component = this;
            component.ajaxComplete = false;
            const url = `${component.populateUploadUrl()}`;
            const formData = new FormData();
            formData.append('load_file', this.form.selectedFile);
            axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((resp) => {
                    if (resp.status === 200) {
                        component.ajaxResponse = resp;
                        component.alertVariant = 'warning';
                        component.validationPassed = false;
                        component.ajaxComplete = true;
                    }
                })
                .catch((err) => {
                    const resp = err.response;
                    if (resp.data.message === undefined) {
                        resp.data.message = resp.data.error;
                    }
                    component.ajaxResponse = resp;
                    component.alertVariant = 'danger';
                    component.validationPassed = false;
                    component.ajaxComplete = true;
                })
                .finally(() => {
                    component.form.selectedFile = null;
                    loader.hide();
                });
        }
    }

};
</script>

<style>

</style>
