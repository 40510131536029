<template>
    <div>
        <div class="container-fluid">
            <b-form-select v-model="selectedCohort" class="col-lg-4 col-md-6 col-xs-12" :options="cohorts" />
        </div>
        <br>
        <swimmer-plot-component v-if="plotReady" :plot-data="plotData" :plot-data-type="plotDataType" :cohort-id="cohortId"/>
    </div>
</template>

<script>
import axios from 'axios';
import getUserToken from '../utils/auth';
import { getUrl } from '../utils/directory';
import SwimmerPlotComponent from '../components/SwimmerPlotComponent.vue';

export default {
    name: 'SwimmerPlotPage',
    components: { SwimmerPlotComponent },
    data() {
        return {
            selectedCohort: null,
            cohorts: [
                { value: null, text: 'Select a cohort' }
            ],
            plotData: undefined,
            plotReady: false,
            plotDataType: 'json',
            cohortId: undefined
        };
    },
    watch: {
        selectedCohort(newCohort) {
            const loader = this.$loading.show();
            const component = this;
            component.plotReady = false;
            let cohortName = component.cohorts.filter((d)=>d.value==newCohort)[0].text;
            component.cohortId = cohortName;
            // grab data from participants, treatments, biospecimens, and samples concurrently
            axios.all([
                this.getCohortParticipants(newCohort),
                this.getCohortTreatments(newCohort),
                this.getCohortBiospecimens(newCohort),
                this.getCohortSamples(newCohort),
                this.getCohortDrugs(newCohort)
            ])
                .then(axios.spread((pResp, tResp, bResp, sResp, dResp) => {
                    const data = [
                        pResp.data,
                        tResp.data,
                        bResp.data,
                        sResp.data,
                        dResp.data
                    ];
                    component.plotData = data;
                    component.plotReady = true;
                    loader.hide();
                }));

        // use this if using Cloud SQL Proxy locally --
        // DB connection fails when making concurrent requests
        // through Cloud SQL Proxy...
        // this.getCohortParticipants(newCohort).then(pResp => {
        //     this.getCohortTreatments(newCohort).then(tResp => {
        //         this.getCohortBiospecimens(newCohort).then(bResp => {
        //             this.getCohortSamples(newCohort).then(sResp => {
        //                 // swimmer plot expects data in this order
        //                 let data = [
        //                   pResp.data.participants,
        //                   tResp.data.treatments,
        //                   bResp.data.biospecimens,
        //                   sResp.data.samples
        //                 ];
        //                 config.data = data;
        //                 config.height = (config.trackHeight * config.data[0].length + config.margin.top + config.margin.bottom);
        //                 component.plotConfig = config;
        //                 component.plotReady = true;
        //                 loader.hide();
        //             })
        //         })
        //     })
        // });
        }
    },
    beforeMount() {
        this.getAllowedCohorts();
    },
    methods: {
        getAllowedCohorts() {
            const loader = this.$loading.show();
            const component = this;
            const cohortsUrl = getUrl('cohortsUrl');
            axios.get(cohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const { data } = resp;
                    let cohorts = data.cohorts.map(x => ({ value: x.id, text: x.name }));
                    cohorts = cohorts.sort((a, b) => {
                        if (a.text < b.text) return -1;
                        if (a.text > b.text) return 1;
                        return 0;
                    });
                    component.cohorts = component.cohorts.concat(cohorts);
                    loader.hide();
                })
                .catch((err) => {
                    loader.hide();
                    console.error(err);
                });
        },
        getCohortParticipants(cohortId) {
            const participantsUrl = `${getUrl('participantsUrl')}?cohort_id=${cohortId}&primary_only=false&name_id_only=false`;
            return axios.get(participantsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getCohortTreatments(cohortId) {
            const treatmentsUrl = `${getUrl('treatmentsUrl')}?cohort_id=${cohortId}`;
            return axios.get(treatmentsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getCohortBiospecimens(cohortId) {
            const biospecimensUrl = `${getUrl('biospecimensUrl')}?cohort_id=${cohortId}&name_id_only=false`;
            return axios.get(biospecimensUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getCohortSamples(cohortId) {
            const samplesUrl = `${getUrl('samplesUrl')}?cohort_id=${cohortId}`;
            return axios.get(samplesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getCohortDrugs(cohortId) {
            const expandedTreatmentsUrl = `${getUrl('treatmentsUrl')}?cohort_id=${cohortId}&expanded=true`;
            return axios.get(expandedTreatmentsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        }
    }
};
</script>
