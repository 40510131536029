<template>
    <div>
        <h3>Edit Clinical Update Record</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4" sm="6">
            <b-form @submit.stop.prevent="updateRecord">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="localCohortName" disabled />
                </b-form-group>

                <b-form-group label="Participant ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.participantName" disabled />
                </b-form-group>

                <b-form-group label="Clinical update DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.updateRecordId" disabled />
                </b-form-group>

                <b-form-group label="Clinical update category" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="clinUpdateCategoryOptions" v-model="v$.form.selectedClinUpdateCategory.$model" :state="v$.form.selectedClinUpdateCategory.$error ? false : null" />
                </b-form-group>

                <b-form-group label="Clinical update date (days from diagnosis)" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.updateDateDfd.$model" :state="v$.form.updateDateDfd.$error ? false : null" />
                </b-form-group>

                <b-form-group label="Enable Cancer Staging System" label-class="font-weight-bold">
                    <input type="checkbox" @change="toggleCancerStagingSelected()" :checked="toggleCancerStaging">
                </b-form-group>

                <b-form-group label="Cancer Staging System [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="cancerStagingSystemsOptions" v-model="form.selectedCancerStagingSystem" :disabled="!toggleCancerStaging" />
                </b-form-group>

                <b-form-group label="Cancer Type [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="cancerTypeOptions" v-model="form.selectedCancerType" :disabled="!toggleCancerStaging" />
                </b-form-group>

                <b-form-group label="Cancer Stage [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="cancerStageOptions" v-model="form.selectedCancerStage" :disabled="!toggleCancerStaging" />
                </b-form-group>

                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>

                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Record
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    required,
    between
} from '@vuelidate/validators';
import {
    getUrl, getClinicalUpdateCategoriesPromise, getCancerStagingSystemsPromise
} from '../../utils/directory';
import {
    createDropdownOptions,
    createClinicalUpdateCategoriesDict,
    createCancerStagingSystemsDict,
    createCancerTypesDict,
    createCancerStagesDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    props: {
        cohortId: { type: Number, required: true },
        cohortName: { type: String, required: true },
        recordId: { type: Number, required: true },
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // local prop vars
            localCohortName: this.cohortName,

            // URLs used

            participantUrl: getUrl('participantsUrl'),
            clinicalUpdateUrl: getUrl('clinicalUpdatesUrl'),
            cancerTypesUrl: getUrl('cancerTypesUrl'),
            cancerStagesUrl: getUrl('cancerStagesUrl'),

            // local variables
            cancerTypeName: null,
            cancerStageName: null,
            stagingSystemChanged: false,
            cancerTypeChanged: false,
            toggleCancerStaging: true,

            // options for form dropdowns
            participantOptions: [],
            clinUpdateCategoryOptions: [],
            cancerStagingSystemsOptions: [],
            cancerTypeOptions: [],
            cancerStageOptions: [],

            // form data
            form: {
                // uneditable items that we call the API to figure out
                cohortName: null,
                participantName: null,
                updateRecordId: null,

                // dropdowns
                selectedClinUpdateCategory: null,
                selectedCancerStagingSystem: null,
                selectedCancerType: null,
                selectedCancerStage: null,
                // inputs
                updateDateDfd: null,
                notes: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
        'form.selectedCancerStagingSystem': function () {
            // updates the cancer type dropdown when a new staging system is selected
            if (this.form.selectedCancerStagingSystem === null) {
                this.form.selectedCancerType = null;
                this.cancerTypeOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${this.cancerTypesUrl}?staging_system_id=${this.form.selectedCancerStagingSystem}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((cancerTypeResp) => {
                    const cancerTypes = createCancerTypesDict(cancerTypeResp.data.cancer_types);
                    component.cancerTypeOptions = createDropdownOptions(cancerTypes);
                })
                .finally(() => {
                    loader.hide();
                    if (component.cancerTypeName === null || component.stagingSystemChanged) {
                        component.form.selectedCancerType = null;
                    } else {
                        component.form.selectedCancerType = component.cancerTypeOptions.filter(x => x.text === component.cancerTypeName)[0].value;
                    }
                    component.stagingSystemChanged = true;
                });
        },
        'form.selectedCancerType': function () {
            // updates the cancer type dropdown when a new staging system is selected
            if (this.form.selectedCancerType === null) {
                this.form.selectedCancerStage = null;
                this.cancerStageOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${this.cancerStagesUrl}?cancer_type_id=${this.form.selectedCancerType}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((cancerStageResp) => {
                    const cancerStages = createCancerStagesDict(cancerStageResp.data.cancer_stages);
                    component.cancerStageOptions = createDropdownOptions(cancerStages);
                })
                .finally(() => {
                    loader.hide();
                    if (component.cancerStageName === null || component.cancerTypeChanged) {
                        component.form.selectedCancerStage = null;
                    } else {
                        component.form.selectedCancerStage = component.cancerStageOptions.filter(x => x.text === component.cancerStageName)[0].value;
                    }
                    component.cancerTypeChanged = true;
                });
        }
    },
    validations() {
        return { 
            form: {
                // dropdowns
                selectedClinUpdateCategory: { required },
                updateDateDfd: {
                    required,
                    between: between(-36500, 36500)
                }
            }
        }
    },
    mounted() {
        this.populateClinUpdateForm();
    },
    methods: {
        toggleCancerStagingSelected() {
            const component = this;
            component.toggleCancerStaging = !component.toggleCancerStaging;
        },
        getClinicalUpdateRecordInfoPromise() {
            return axios.get(`${this.clinicalUpdateUrl}?cohort_id=${this.cohortId}&id=${this.recordId}`, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateClinUpdateForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([this.getClinicalUpdateRecordInfoPromise(), getClinicalUpdateCategoriesPromise(), getCancerStagingSystemsPromise()])
                .then(axios.spread((currInfoResp, updateCategoryResp, stagingSystemResp) => {
                    const clinicalUpdateInfo = currInfoResp.data.clinical_updates[0];
                    const clinicalUpdateCategories = createClinicalUpdateCategoriesDict(updateCategoryResp.data.clinical_update_categories);
                    component.clinUpdateCategoryOptions = createDropdownOptions(clinicalUpdateCategories);

                    const cancerStagingSystems = createCancerStagingSystemsDict(stagingSystemResp.data.cancer_staging_systems);
                    component.cancerStagingSystemsOptions = createDropdownOptions(cancerStagingSystems);


                    // current clinical update info -- unchangeable form fiekds
                    component.form.cohortName = component.cohortName;
                    component.form.participantName = clinicalUpdateInfo.participant_id;
                    component.form.updateRecordId = clinicalUpdateInfo.db_id;

                    // current clinical update info -- updateable form fields
                    component.form.selectedClinUpdateCategory = component.clinUpdateCategoryOptions.filter(x => x.text === clinicalUpdateInfo.update_category)[0].value;
                    if (clinicalUpdateInfo.staging_system_name === null) {
                        component.form.selectedCancerStagingSystem = null;
                    } else {
                        component.form.selectedCancerStagingSystem = component.cancerStagingSystemsOptions.filter(x => x.text === clinicalUpdateInfo.staging_system_name)[0].value;
                    }
                    component.form.updateDateDfd = clinicalUpdateInfo.update_date_dfd;
                    component.form.notes = clinicalUpdateInfo.notes;
                    component.cancerTypeName = clinicalUpdateInfo.cancer_type_name;
                    component.cancerStageName = clinicalUpdateInfo.stage_with_information;
                }))
                .finally(() => { loader.hide(); });
        },
        updateRecord() {
            const loader = this.$loading.show();
            const component = this;

            const url = `${this.clinicalUpdateUrl}?cohort_id=${this.cohortId}&id=${this.form.updateRecordId}`;
            const newClinicalUpdateInfo = {
                update_cancer_stage_id: this.form.selectedCancerStage,
                update_category_id: this.form.selectedClinUpdateCategory,
                update_date_dfd: this.form.updateDateDfd,
                notes: this.form.notes === '' ? null : this.form.notes
            };
            axios.put(url, newClinicalUpdateInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'DataEditorViewClinicalUpdatesPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            editedCohortId: component.cohortId,
                        }
                    });
                }).catch((err) => {
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                }).finally(() => loader.hide());
        }
    }
};
</script>

<style>

</style>
