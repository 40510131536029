<template>
    <div>
        <h3>View Production Analysis Snapshots</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="PrAn Workspace Id" label-class="font-weight-bold">
                        <b-form-input size="sm" v-model="form.id" disabled />
                    </b-form-group>
                    <b-form-group label="PrAn Workspace Name" label-class="font-weight-bold">
                        <b-form-input size="sm" v-model="form.name" disabled />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="pran-snapshot-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="pran-snapshot-table" class="display compact stripe" style="width:100%" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';
import { LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST } from '../../utils/constants';

export default {
    name: 'LeadDataAnalystViewPrAnSnapshotsPage',
    props: {
        // these props are passed in after a workspace is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        workspaceId: {
            type: Number,
            required: true
        },
        workspaceName: {
            type: String,
            required: true
        },
        role: { type: String, required: true }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // urls
            leadDataAnalystPrAnSnapshotsUrl: getUrl('leadDataAnalystPrAnSnapshotsUrl'),
            uberLeadDataAnalystPrAnSnapshotsUrl: getUrl('uberLeadDataAnalystPrAnSnapshotsUrl'),

            // form data
            form: {
                // inputs
                id: this.workspaceId,
                name: this.workspaceName
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            },

            // table vars
            exportColumns: [0, 1, 2, 3, 4],
            tableColumns: [
                { title: 'ObjectId', data: 'object_id' },
                { title: 'Snapshot Description', data: 'snapshot_description' },
                { title: 'Cohort Name', data: 'cohort_name' },
                { title: 'User', data: 'user_created' },
                { title: 'Date Created (UTC)', data: 'date_created' },
                {
                    title: 'Revert',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span revert-workspace">Revert</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    watch: {
    },
    mounted() {
        this.populatePrAnSnapshotTable();
    },
    methods: {
        populateSnapshotsUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.leadDataAnalystPrAnSnapshotsUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uberLeadDataAnalystPrAnSnapshotsUrl;
            default:
                console.error('Invalid role for attributes table.');
                return undefined;
            }
        },
        revertPrAnWorkspace(objectId) {
            const component = this;
            const loader = component.$loading.show();
            const revertWorkspaceUrl = `${component.populateSnapshotsUrl()}?object_id=${objectId}`;
            axios.put(revertWorkspaceUrl, {}, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to revert production analysis workspace. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        populatePrAnSnapshotTable() {
            const loader = this.$loading.show();
            const component = this;
            const url = `${component.populateSnapshotsUrl()}?workspace_id=${this.workspaceId}`;

            axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((prAnSnapshotsResp) => {
                    const prAnSnapshotsData = prAnSnapshotsResp.data.pran_snapshots;
                    const table = $('#pran-snapshot-table').DataTable({
                        destroy: true,
                        data: prAnSnapshotsData,
                        columns: component.tableColumns,
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '80vh',
                        scrollCollapse: true
                    });
                    component.addTableActions(table);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => { loader.hide(); });
        },
        addTableActions(datatable) {
            const component = this;

            $('#pran-snapshot-table tbody .revert-workspace').click(function confirmWorkspaceRevert() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to revert this workspace to snapshot: ${rowData.object_id}?`;
                const msgBoxOptions = {
                    hideHeader: true,
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Revert Workspace',
                    centered: true
                };
                component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                    .then((revertConfirmed) => {
                        if (revertConfirmed) {
                            component.revertPrAnWorkspace(rowData.object_id);
                        }
                    });
            });
        }
    }
};
</script>

<style>

</style>
