<template>
  <div>
    <h3>View Collaborator Sync Results for {{ collaboratorName }}</h3>
    <hr />

    <h4>
      {{ accepted_sample_count }} samples accepted into the collaborator's
      delivery workspaces.
    </h4>
    <br />
    <b-container fluid>
      <b-row id="sync-results-table-wrapper">
        <!-- showing/hiding table is controlled by jQuery and DataTables -->
        <table
          id="sync-results-table"
          class="display compact stripe"
          style="width: 100%"
        >
          <caption>
            Sync Results Table
          </caption>
        </table>
      </b-row>
      <hr />
      <!-- Show only when the collaborator name has been fetched and the sync results has been shown -->
      <b-button
        size="sm"
        style="font-size: 0.85rem"
        @click="backtoCollaboratorsView"
        v-show="collaboratorName !== null"
      >
        Go back to collaborators
      </b-button>
      <b-row
        id="unmatched-names-table-wrapper"
        align-v="center"
        align-h="center"
      >
        <table
          id="unmatched-names-table"
          class="display compact stripe"
          style="width: 100%"
        >
          <caption>
            Unmatched Biospecimens in Cohort Table
          </caption>
        </table>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { getUrl } from "../utils/directory";
import getUserToken from "../utils/auth";
import { DATA_EDITOR } from "../utils/constants";

export default {
  props: {
    // sync results from the API
    syncResp: {
      type: Object,
      required: true,
    },
    // this prop is passed to indicate whether a sample coordinator is accesing the sync results
    role: {
      type: String,
      required: true,
      validator(val) {
        return [DATA_EDITOR].indexOf(val) !== -1;
      },
    },
    collaboratorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // urls
      collaboratorsUrl: getUrl("collaboratorsUrl"),

      // inline vars
      collaboratorName: null,
      accepted_sample_count:
        this.syncResp.data.sync_collaborator_data.accepted_sample_count,
      // table vars
      exportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      unmatchedNamesTableColumns: [
        { title: "Biospecimen name", data: "biospecimen_name" },
        { title: "Cohort Name", data: "cohort_name" },
        { title: "Note", data: "note" },
      ],
      resultsTableColumns: [
        { title: "Delivery workspace", data: "delivery_workspace" },
        { title: "Workspace sample name", data: "workspace_sample_name" },
        { title: "Workspace sample sync status", data: "status" },
        { title: "Reject/Overwrite reason", data: "reject_reason" },
        { title: "Workspace sample alias", data: "sample_alias" },
        { title: "Matching biospecimen name", data: "biospecimen_name" },
        { title: "Cohort", data: "cohort_name" },
        {
          title: "Matching biospecimen tumor/normal status",
          data: "biospecimen_tumor/normal",
        },
        { title: "Workspace sample type", data: "workspace_sample_type" },
        { title: "Workspace sample data type", data: "data_type" },
        {
          title: "Workspace experimental strategy",
          data: "experimental_strategy",
        },
        { title: "ULPWGS tumor fraction", data: "ulp_tumor_fraction" },
      ],
      tablePaginationOptions: [
        [-1, 10, 25, 50, 100], // pagination values; -1 displays all
        ["All", 10, 25, 50, 100], // pagination display text
      ],
    };
  },
  mounted() {
    this.populateCollaboratorNameAndTable(this.collaboratorId, this.syncResp);
  },
  computed: {
    unmatchedNames() {
      const unmatchedBiospecimenCohortNames =
        this.syncResp.data.sync_collaborator_data
          .unmatched_biospecimen_cohort_names;
      return Array.isArray(unmatchedBiospecimenCohortNames) &&
        unmatchedBiospecimenCohortNames.length > 0
        ? unmatchedBiospecimenCohortNames
        : null;
    },
  },
  methods: {
    populateCollaboratorNameAndTable(collaboratorId, syncResp) {
      const loader = this.$loading.show();
      const component = this;
      axios
        .get(`${component.collaboratorsUrl}?id=${collaboratorId}`, {
          headers: { Authorization: `Bearer ${getUserToken()}` },
        })
        .then((resp) => {
          const { collaborators } = resp.data;
          component.collaboratorName = collaborators[0].name;
        })
        .then(() => {
          loader.hide();
          this.populateSyncResultsTable(syncResp);
        });
    },
    populateSyncResultsTable(syncResp) {
      const component = this;
      component.showTable();
      const syncCollaboratorData = syncResp.data.sync_collaborator_data;
      const scrollYVar =
        component.unmatchedNames === null ||
        component.unmatchedNames === undefined
          ? "40vh"
          : "30vh";
      $("#sync-results-table").DataTable({
        destroy: true,
        data: syncCollaboratorData.sync_results,
        columns: component.resultsTableColumns,
        order: [], // no sorting, initially
        lengthMenu: component.tablePaginationOptions,
        dom: "Blfrtip",
        scrollY: scrollYVar,
        scrollCollapse: true,
        buttons: [
          {
            extend: "copy",
            title: "#sample",
            exportOptions: { columns: component.exportColumns },
            customize(exportData) {
              const exportRows = exportData.split("\n");
              exportRows.splice(1, 1); // removing extra space that isn't needed
              const headerRow = component.updateExportColumnNames(
                exportRows[1]
              );
              exportRows[1] = headerRow;
              return exportRows.join("\n");
            },
          },
          {
            text: "TSV",
            extend: "csv",
            fieldSeparator: "\t",
            fieldBoundary: "",
            title: "sample",
            extension: ".txt",
            exportOptions: { columns: component.exportColumns },
            customize(exportData) {
              let exportRows = exportData.split("\n");
              const headerRow = component.updateExportColumnNames(
                exportRows[0]
              );
              exportRows[0] = headerRow;
              exportRows = ["#sample"].concat(exportRows);
              return exportRows.join("\n");
            },
          },
        ],
      });
      $("#unmatched-names-table").DataTable({
        destroy: true,
        data: component.unmatchedNames,
        columns: component.unmatchedNamesTableColumns,
        order: [], // no sorting, initially
        lengthMenu: component.tablePaginationOptions,
        dom: "Blfrtip",
        scrollY: "25vh",
        scrollCollapse: true,
        buttons: [],
      });
    },
    backtoCollaboratorsView() {
      this.$router.push({
        name: "SampleCoordinatorViewCollaboratorsPage",
      });
    },
    hideTable() {
      $("#sync-results-table-wrapper").hide();
      $("#unmatched-names-table-wrapper").hide();
    },
    showTable() {
      $("#sync-results-table-wrapper").show();
      if (this.unmatchedNames !== null && this.unmatchedNames !== undefined) {
        $("#unmatched-names-table-wrapper").show();
      } else {
        $("#unmatched-names-table-wrapper").hide();
      }
    },
    updateExportColumnNames(headerRow) {
      const component = this;
      let header = headerRow;
      this.exportColumns.forEach((i) => {
        const col = component.resultsTableColumns[i];
        header = header.replace(col.title, col.data);
      });
      return header;
    },
  },
};
</script>

<style>
#sync-results-table-wrapper,
#unmatched-names-table-wrapper {
  font-size: 0.85rem;
  overflow-x: auto;
}
.center-title {
  text-align: center;
}
caption {
  display: table-caption;
  text-align: center;
  caption-side: top;
  font-size: medium;
  font-weight: bold;
  color: rgb(56, 55, 55);
}
</style>
