<template>
    <div>
        <h3>Add Line Of Treatment</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addLineOfTreatment">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cohortOptions" v-model="$v.form.selectedCohort.$model" />
                </b-form-group>
                <b-form-group label="Line Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.lineName.$model" :state="$v.form.lineName.$dirty ? !$v.form.lineName.$error : null" />
                </b-form-group>
                <b-form-group label="Backbone Drug" label-class="font-weight-bold" :description="form.description">
                    <b-form-select size="sm" :options="drugOptions" v-model="form.selectedDrug" />
                </b-form-group>
                <b-form-group label="Backbone Drug Combination" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="drugCombinationOptions" v-model="form.selectedDrugCombination" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Add Line Of Treatment
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    minLength,
    maxLength
} from '@vuelidate/validators';
import {
    getUrl,
    getSubmitterCohortsPromise,
    getDrugsPromise,
    getDrugCombinationsPromise
} from '../../utils/directory';
import {
    createDropdownOptions,
    createCohortsDict,
    createDrugsDict,
    createDrugCombinationsDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    data() {
        return {
            // URLs used
            sLineOfTreatmentUrl: getUrl('submitterLinesOfTreatmentUrl'),

            // options for form dropdowns
            cohortOptions: [],
            drugOptions: [],
            drugCombinationOptions: [],

            // form data
            form: {
                // dropdowns
                selectedCohort: null,
                selectedDrugCombination: null,
                selectedDrug: null,
                // inputs
                lineName: null,

                // description for backbone drug selection
                description: 'Please select either a backbone drug or a backbone drug combination or neither. Do not select both.'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            // dropdowns
            selectedCohort: { required },
            lineName: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(64)
            }
        }
    },
    mounted() {
        this.initLineOfTreatmentForm();
    },
    methods: {
        initLineOfTreatmentForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getSubmitterCohortsPromise(),
                getDrugsPromise(),
                getDrugCombinationsPromise()
            ])
                .then(axios.spread((cohortResp, drugsResp, drugCombinationsResp) => {
                    const cohorts = createCohortsDict(cohortResp.data.cohorts);
                    const drugs = createDrugsDict(drugsResp.data.drugs);
                    const drugCombinations = createDrugCombinationsDict(drugCombinationsResp.data.drug_combinations);

                    component.cohortOptions = createDropdownOptions(cohorts);
                    component.drugOptions = createDropdownOptions(drugs);
                    component.drugCombinationOptions = createDropdownOptions(drugCombinations);
                }))
                .finally(() => { loader.hide(); });
        },
        addLineOfTreatment() {
            const loader = this.$loading.show();
            const component = this;

            const newLineOfTreatment = {
                cohort_id: this.form.selectedCohort,
                line_name: this.form.lineName,
                backbone_drug_id: this.form.selectedDrug,
                backbone_drug_combination_id: this.form.selectedDrugCombination
            };

            axios.post(this.sLineOfTreatmentUrl, newLineOfTreatment, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
