<template>
    <div>
        <h3>Add Production Analysis Workspace to Cohort</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="6">
            <b-form @submit.stop.prevent="addWorkspace">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cohortOptions" v-model="$v.form.selectedCohort.$model" :state="$v.form.selectedCohort.$dirty ? !$v.form.selectedCohort.$error : null" />
                </b-form-group>
                <b-form-group label="Production Analysis Workspace Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.pranName.$model" :state="$v.form.pranName.$dirty ? !$v.form.pranName.$error : null" />
                </b-form-group>
                <b-form-group label="Description [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.description" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <h6><strong> {{ description.notes }} </strong></h6>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Add Production Analysis Workspace
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    maxLength,
    minLength
} from '@vuelidate/validators';
import {
    getUrl, getLDACohortsPromise, getULDACohortsPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createCohortsDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';
import { LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST } from '../../utils/constants';

export default {
    props: {
        // these props are passed in after a workspace is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        role: {
            type: String,
            required: true,
            validator(val) {
                return [LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // URLs used
            ldaAddPrAnWorkspaceUrl: getUrl('leadDataAnalystPrAnWorkspacesUrl'),
            uldaAddPrAnWorkspaceUrl: getUrl('uberLeadDataAnalystPrAnWorkspacesUrl'),
            // options for form dropdowns
            cohortOptions: [],

            // form info
            form: {
                // dropdowns
                selectedCohort: null,
                pranName: null,
                description: '',
                notes: ''
            },

            // form description
            description: {
                notes: 'NOTE: Initialization of production analysis workspaces for cohorts that contain a large amount of participant and sample data may take multiple minutes. As a result, the loading screen could show for a while after submitting this form.'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            // dropdowns
            selectedCohort: { required },
            pranName: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(64)
            }
        }
    },
    mounted() {
        this.populateAddWorkspaceForm();
    },
    methods: {
        populateAddWorkspaceForm() {
            const loader = this.$loading.show();
            const component = this;
            const roleType = this.role;
            // if the user is an Uber Lead Data Analyst
            if (roleType === UBER_LEAD_DATA_ANALYST) {
                getULDACohortsPromise()
                    .then((cohortsResp) => {
                        loader.hide();
                        const cohorts = createCohortsDict(cohortsResp.data.cohorts);
                        component.cohortOptions = createDropdownOptions(cohorts);
                    });
            // if the user is a Lead Data Analyst
            } else {
                getLDACohortsPromise()
                    .then((cohortsResp) => {
                        loader.hide();
                        const cohorts = createCohortsDict(cohortsResp.data.cohorts);
                        component.cohortOptions = createDropdownOptions(cohorts);
                    });
            }
        },
        populateWorkspacesUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.ldaAddPrAnWorkspaceUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uldaAddPrAnWorkspaceUrl;
            default:
                console.error('Invalid role for PrAn Workspace table.');
                return undefined;
            }
        },
        addWorkspace() {
            const loader = this.$loading.show();
            const component = this;
            const addPrAnWorkspaceUrl = `${component.populateWorkspacesUrl()}`;
            const newWorkspaceInfo = {
                cohort_id: this.form.selectedCohort,
                name: this.form.pranName,
                description: this.form.notes === '' ? null : this.form.description,
                notes: this.form.notes === '' ? null : this.form.notes
            };
            axios.post(addPrAnWorkspaceUrl, newWorkspaceInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                });
        }
    }
};
</script>

<style>

</style>
