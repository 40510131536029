<template>
    <div>
        <h3>Update Production Analysis Workspace Attribute</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateWorkspaceAttribute">
                <b-form-group label="Workspace Attribute DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.id" disabled />
                </b-form-group>
                <b-form-group label="Attribute Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.name.$model" :state="$v.form.name.$dirty ? !$v.form.name.$error : null" />
                </b-form-group>
                <b-form-group label="Attribute Value" label-class="font-weight-bold">
                    <b-form-textarea required size="sm" v-model="$v.form.value.$model" :state="$v.form.value.$dirty ? !$v.form.value.$error : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Update Workspace Attribute
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required, minLength, maxLength
} from '@vuelidate/validators';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';
import { LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST } from '../../utils/constants';

export default {
    name: 'LeadDataAnalystEditWorkspaceAttributesPage',
    props: {
        attributeId: {
            type: Number,
            required: true
        },
        role: { type: String, required: true }
    },
    data() {
        return {
            // urls
            leadDataAnalystWorkspaceAttributesUrl: getUrl('leadDataAnalystWorkspaceAttributesUrl'),
            uberLeadDataAnalystWorkspaceAttributesUrl: getUrl('uberLeadDataAnalystWorkspaceAttributesUrl'),

            // form data
            form: {
                // inputs
                id: this.attributeId,
                name: null,
                value: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(64)
            },
            value: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(256)
            }
        }
    },
    watch: {
    },
    mounted() {
        this.populateWorkspaceAttributeForm();
    },
    methods: {
        populateViewAttributesTableRoutes() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return 'LeadDataAnalystViewWorkspaceAttributesPage';
            case UBER_LEAD_DATA_ANALYST:
                return 'UberLeadDataAnalystViewWorkspaceAttributesPage';
            default:
                console.error('Invalid role');
                return undefined;
            }
        },
        populateAttributesUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.leadDataAnalystWorkspaceAttributesUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uberLeadDataAnalystWorkspaceAttributesUrl;
            default:
                console.error('Invalid role for attributes table.');
                return undefined;
            }
        },
        populateWorkspaceAttributeForm() {
            const loader = this.$loading.show();
            const component = this;
            const url = `${component.populateAttributesUrl()}?id=${this.attributeId}`;

            axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((updateWorkspaceAttributeResp) => {
                    const attributeData = updateWorkspaceAttributeResp.data.workspace_attributes[0];
                    this.form.name = attributeData.attribute_name;
                    this.form.value = attributeData.attribute_value;
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        },
        updateWorkspaceAttribute() {
            const loader = this.$loading.show();
            const component = this;
            const updateUrl = `${component.populateAttributesUrl()}?id=${this.attributeId}`;
            const updatedWorkspaceAttribute = {
                attribute_name: this.form.name,
                attribute_value: this.form.value === '' ? null : this.form.value
            };
            axios.put(updateUrl, updatedWorkspaceAttribute, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: component.populateViewAttributesTableRoutes(),
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            role: component.role
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }
};
</script>

<style>

</style>
