<template>
    <div>
        <h3>View Bait Sets</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="bait-sets-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="bait-sets-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import { LEAD_DATA_ANALYST, SAMPLE_COORDINATOR, UBER_LEAD_DATA_ANALYST } from '../utils/constants';

export default {
    props: {
        // For when bait sets are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        // this prop is passed to indicate what type of user is viewing participants
        role: {
            type: String,
            required: true,
            validator(val) {
                return [SAMPLE_COORDINATOR, LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // urls
            sampleCoordinatorBaitSetsUrl: getUrl('sampleCoordinatorBaitSetsUrl'),
            leadDataAnalystBaitSetsUrl: getUrl('leadDataAnalystBaitSetsUrl'),
            uberLeadDataAnalystBaitSetsUrl: getUrl('uberLeadDataAnalystBaitSetsUrl'),

            // table vars
            exportColumns: [0, 1, 2],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Bait Set Name', data: 'bait_set_name' },
                { title: 'Abbreviation', data: 'abbreviation' }
            ],
            scTableColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-bait-set">Edit</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    watch: {
    },
    mounted() {
        this.populateBaitSetsTable();
    },
    methods: {
        populateBaitSetsUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.sampleCoordinatorBaitSetsUrl;
            case LEAD_DATA_ANALYST:
                return this.leadDataAnalystBaitSetsUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uberLeadDataAnalystBaitSetsUrl;
            default:
                console.error('Invalid role for bait sets table.');
                return undefined;
            }
        },
        getTableColumns() {
            let { tableColumns } = this;
            if (this.role === SAMPLE_COORDINATOR) {
                tableColumns = tableColumns.concat(this.scTableColumns);
            }
            return tableColumns;
        },
        populateBaitSetsTable() {
            const loader = this.$loading.show(); // loader while waiting for bait sets GET request
            const component = this;

            axios.get(component.populateBaitSetsUrl(), {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((baitSetResp) => {
                    loader.hide();
                    const baitSetTableData = baitSetResp.data.bait_sets;
                    const table = $('#bait-sets-table').DataTable({
                        destroy: true,
                        data: baitSetTableData,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '70vh',
                        scrollCollapse: true,
                        dom: 'Blfrtip',
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#bait_set',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'bait_sets',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#bait_set'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'bait_sets',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#bait_set'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                });
        },
        addTableActions(datatable) {
            const component = this;
            $('#bait-sets-table tbody .edit-bait-set').click(function editBaitSet() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.$router.push({
                    name: 'SampleCoordinatorEditBaitSetPage',
                    params: {
                        baitSetId: rowData.id,
                        role: component.role
                    }
                });
            });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        }
    }
};
</script>

<style>
#collaborators-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
