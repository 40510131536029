<template>
    <div>
        <h3>Add a Drug</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addDrug">
                <b-form-group label="Drug Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.name.$model" :state="v$.form.name.$dirty ? !v$.form.name.$error : null" />
                </b-form-group>
                <b-form-group label="Drug category [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="categoryOptions" v-model="form.selectedCategory" />
                </b-form-group>
                <b-form-group label="Drug target [optional] " label-class="font-weight-bold">
                    <b-form-select size="sm" :options="targetOptions" v-model="form.selectedTarget" />
                </b-form-group>
                <b-form-group label="Mechanism [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.mechanism" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-form-group label="URL [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.url" />
                </b-form-group>
                <b-form-group label="Aliases (comma-separated list) [optional]" :description="requirements.aliases" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.aliases.$model" :state="v$.form.aliases.$dirty ? !v$.form.aliases.$error : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Add Drug
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    required,
    minLength,
    maxLength
} from '@vuelidate/validators';
import {
    getUrl, getDrugCategoriesPromise, getDrugTargetsPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createDrugCategoriesDict, createDrugTargetsDict
} from '../../utils/form-data-helpers';
import { drugAliasCheck } from '../../utils/input-regex';
import getUserToken from '../../utils/auth';

export default {
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            addDrugUrl: getUrl('drugsUrl'),

            // options for form dropdowns
            categoryOptions: [],
            targetOptions: [],

            // form data
            form: {
                // dropdowns
                selectedCategory: null,
                selectedTarget: null,
                // inputs
                name: null,
                mechanism: '',
                notes: '',
                url: '',
                aliases: ''
            },

            // form requirements
            requirements: {
                aliases: 'Please input as a comma-separated list. Aliases must only contain alphanumeric characters and embedded spaces.'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return {
            form: {
                name: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(128)
                },
                aliases: {
                    minLength: minLength(2),
                    maxLength: maxLength(64),
                    drugAliasCheck
                }
            }
        }
    },
    mounted() {
        this.initDrugForm();
    },
    methods: {
        initDrugForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getDrugCategoriesPromise(),
                getDrugTargetsPromise()
            ])
                .then(axios.spread((categoriesResp, targetsResp) => {
                    const categories = createDrugCategoriesDict(categoriesResp.data.drug_categories);
                    const targets = createDrugTargetsDict(targetsResp.data.drug_targets);

                    component.categoryOptions = createDropdownOptions(categories);
                    component.targetOptions = createDropdownOptions(targets);
                }))
                .finally(() => { loader.hide(); });
        },
        addDrug() {
            const loader = this.$loading.show();
            const component = this;

            const newDrug = {
                name: this.form.name,
                drug_category_id: this.form.selectedCategory,
                drug_target_id: this.form.selectedTarget,
                mechanism: this.form.mechanism === '' ? null : this.form.mechanism,
                notes: this.form.notes === '' ? null : this.form.notes,
                url: this.form.url === '' ? null : this.form.url,
                submitted_aliases: this.form.aliases === '' ? null : this.form.aliases
            };

            axios.post(this.addDrugUrl, newDrug, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
