<template>
    <div>
        <h3>Update Drug Category</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateCategory">
                <b-form-group label="Drug Category DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.id" disabled />
                </b-form-group>
                <b-form-group label="Drug Category Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.name.$model" :state="v$.form.name.$dirty ? !v$.form.name.$error : null" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Drug Category
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    minLength,
    maxLength
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    props: {
        categoryId: {
            type: Number,
            required: true
        }
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // urls
            drugCategoriesUrl: getUrl('drugCategoriesUrl'),

            // form data
            form: {
                // inputs
                id: this.categoryId,
                name: null,
                notes: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return {
            form: {
                name: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(256)
                }
            }
        }
    },
    watch: {
    },
    mounted() {
        this.populateCategoryForm();
    },
    methods: {
        populateCategoryForm() {
            const loader = this.$loading.show();
            const component = this;
            const url = `${this.drugCategoriesUrl}?id=${this.categoryId}`;

            axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((updateCategoryResp) => {
                    const categoryData = updateCategoryResp.data.drug_categories[0];
                    this.form.name = categoryData.name;
                    this.form.notes = categoryData.notes;
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        },
        updateCategory() {
            const loader = this.$loading.show();
            const component = this;
            const updateUrl = `${this.drugCategoriesUrl}?id=${this.categoryId}`;

            const updatedCategory = {
                name: this.form.name,
                notes: this.form.notes === '' ? null : this.form.notes
            };
            axios.put(updateUrl, updatedCategory, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'AdminViewDrugCategoriesPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }
};
</script>

<style>

</style>
