<template>
    <div>
        <h3>Edit Cancer Type</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="6">
            <b-form @submit.stop.prevent="updateCancerType">
                <b-form-group label="Cancer Type DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.cancerTypeId" disabled />
                </b-form-group>
                <b-form-group label="Cancer Staging System" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.cancerStagingSystemName" disabled />
                </b-form-group>
                <b-form-group label="Cancer Type" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.cancerTypeName.$model" :state="v$.form.cancerTypeName.$dirty ? !v$.form.cancerTypeName.$error : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Cancer Type
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    maxLength,
    minLength,
    required
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {
    getUrl
} from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    name: 'AdminEditCancerTypePage',
    props: {
        cancerStagingSystemName: { type: String, required: true },
        cancerStagingSystemId: { type: Number, required: true },
        cancerTypeId: { type: Number, required: true },
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            sampleCoordinatorCancerTypesUrl: `${getUrl('sampleCoordinatorCancerTypesUrl')}?staging_system_id=${this.cancerStagingSystemId}&id=${this.cancerTypeId}`,

            // form info
            form: {
                // unchangeable items
                cancerStagingSystemName: this.cancerStagingSystemName,
                cancerTypeId: null,

                // input
                cancerTypeName: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // input
                cancerTypeName: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(64)
                }
            }
        }
    },
    mounted() {
        this.populateCancerTypeEditForm();
    },
    methods: {
        getCancerTypeInfo() {
            return axios.get(this.sampleCoordinatorCancerTypesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateCancerTypeEditForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                this.getCancerTypeInfo()
            ])
                .then(axios.spread((cancerTypeResp) => {
                    loader.hide();
                    const currCancerTypeData = cancerTypeResp.data.cancer_types[0];

                    component.form.cancerTypeId = currCancerTypeData.id;
                    // current participant metadata -- input
                    component.form.cancerTypeName = currCancerTypeData.cancer_type_name;
                }));
        },
        updateCancerType() {
            const loader = this.$loading.show();
            const component = this;

            const cancerTypeInfo = {
                cancer_type_name: component.form.cancerTypeName
            };
            axios.put(this.sampleCoordinatorCancerTypesUrl, cancerTypeInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.$router.push({
                        name: 'AdminViewCancerTypesPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            editedCancerStagingSystemId: component.cancerStagingSystemId,
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                });
        }
    }
};
</script>

<style>

</style>
