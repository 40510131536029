<template>
    <div>
        <h3>View Clinical Update Records</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>

                    <b-form-group label="Participant ID" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!cohortSelected" v-model="selectedParticipant" :options="participantOptions" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="clinical-update-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="clinical-update-table" class="display compact stripe" style="width:100%" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import store from '../store'
import {
    createCohortsDict,
    createParticipantsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';

export default {
    props: {
        // these props are passed in after a clinical update record is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCohortId: { default: null, type: Number, required: false },
        // this prop is passed to indicate what type of user is viewing the clinical update records
    },
    data() {
        const storeRoles = store.state.user.roles;
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',
            
            // user roles
            roles: storeRoles,

            // cohort specific role info
            readerCohorts: storeRoles.readerCohorts,
            dataEditorCohorts: storeRoles.dataEditorCohorts,
            dataAnalystCohorts: storeRoles.dataAnalystCohorts,
            projectLeadsCohorts: storeRoles.projectLeadCohorts,

            // checking if there was an edit
            clinicalUpdateEdited: !!this.editedCohortId, // true if this.editedCohortID is undefined

            // URLs used
            projectsUrl: getUrl('projectsUrl'),
            participantsUrl: getUrl('participantsUrl'),
            clinicalUpdatesUrl: getUrl('clinicalUpdatesUrl'),
            // sampleCoordinatorCohortsUrl: getUrl('sampleCoordinatorCohortsUrl'),
            // sampleCoordinatorParticipantsUrl: getUrl('sampleCoordinatorParticipantsUrl'),
            // sampleCoordinatorClinicalUpdatesUrl: getUrl('sampleCoordinatorClinicalUpdatesUrl'),
            // cohortLeadCohortsUrl: getUrl('cohortLeadCohortsUrl'),
            // cohortLeadParticipantsUrl: getUrl('cohortLeadParticipantsUrl'),
            // cohortLeadClinicalUpdatesUrl: getUrl('cohortLeadClinicalUpdatesUrl'),
            // submitterCohortsUrl: getUrl('submitterCohortsUrl'),
            // submitterParticipantsUrl: getUrl('submitterParticipantsUrl'),
            // submitterClinicalUpdatesUrl: getUrl('submitterClinicalUpdatesUrl'),
            // dataAnalystCohortsUrl: getUrl('dataAnalystCohortsUrl'),
            // dataAnalystParticipantsUrl: getUrl('dataAnalystParticipantsUrl'),
            // dataAnalystClinicalUpdatesUrl: getUrl('dataAnalystClinicalUpdatesUrl'),

            // form view controls
            cohortSelected: false,

            // cohort dropdown selections
            selectedCohort: null,
            cohortOptions: [],

            // participant dropdown selections
            selectedParticipant: null,
            participantOptions: [],
            pDict: null, // For saving the participants dictionary from GET request, used for DELETE request

            // table vars
            exportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 10],
            tableColumns: [
                { title: 'DBKey', data: 'db_id' },
                { title: 'Participant ID', data: 'participant_id' },
                { title: 'Update category', data: 'update_category' },
                { title: 'Update date (dfd)', data: 'update_date_dfd' },
                { title: 'Cancer Staging System', data: 'staging_system_name' },
                { title: 'Cancer Type', data: 'cancer_type_name' },
                { title: 'Cancer Stage', data: 'cancer_stage_name' },
                { title: 'Cancer Stage Information', data: 'cancer_stage_information' },
                { title: 'Notes', data: 'notes' },
                { title: 'Submitter', data: 'submitter' },
                { title: 'Primary Cohort', data: 'primary_cohort' },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            deColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span edit-clin-update">Edit</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span delete-clin-update">Delete</span>';
                        }
                        return '';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]

        };
    },
    watch: {
        selectedCohort() {
            // update participants dropdown when new cohort is selected
            if (this.selectedCohort === null) {
                this.selectedParticipant = null;
                this.participantOptions = [];
                this.cohortSelected = false;
                this.hideTable();
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            const participantsUrl = this.populateParticipantsUrl();
            axios.get(`${participantsUrl}?cohort_id=${this.selectedCohort}&primary_only=false`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    component.pDict = pDict;
                    const nullOption = {
                        value: null,
                        text: 'Please select an option'
                    };
                    const allOption = {
                        value: -1,
                        text: 'All'
                    };
                    const pOptions = createDropdownOptions(pDict, true, false);
                    component.participantOptions = [nullOption, allOption].concat(pOptions);
                    component.cohortSelected = true;

                    // checking if a clinical update record was edited
                    if (component.clinicalUpdateEdited) {
                        component.clinicalUpdateEdited = false;
                        component.selectedParticipant = -1;
                    } else {
                        component.selectedParticipant = null;
                    }
                })
                .finally(() => { loader.hide(); });
        },
        selectedParticipant() {
            if (this.selectedParticipant === null) {
                this.hideTable();
                return;
            }
            // table must be reset if participant changes since number of columns may change based on permissions
            let table = $('#clinical-update-table');
            if ($.fn.DataTable.isDataTable(table)) {
                table.DataTable().clear().draw();
                table.DataTable().destroy();
                table.empty(); 
            }
            this.getClinicalUpdatesTable();
        }
    },
    beforeMount() {
        this.getCohorts();
    },
    beforeDestroy() {
        if ($.fn.dataTable.isDataTable('#clinical-update-table')) {
            let table = $('#clinical-update-table').DataTable();
            table.clear().draw();
            table.destroy();
        }
        this.hideTable()
    },
    methods: {
        populateCohortsUrl() {
            return this.projectsUrl;
        },
        populateParticipantsUrl() {
            return this.participantsUrl;
        },
        populateClinicalUpdatesUrl() {
            return this.clinicalUpdatesUrl;
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            const cohortsUrl = this.populateCohortsUrl();
            axios.get(cohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);

                    // check if there was an edited record
                    if (component.clinicalUpdateEdited) {
                        component.selectedCohort = component.editedCohortId;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        deleteClinicalUpdate(cohortId, updateRecordId, participantId) {
            const component = this;
            const loader = component.$loading.show();
            const clinicalUpdatesUrl = this.populateClinicalUpdatesUrl();
            const deleteClinicalUpdateUrl = `${clinicalUpdatesUrl}?id=${updateRecordId}&cohort_id=${cohortId}&participant_id=${participantId}`;
            axios.delete(deleteClinicalUpdateUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getClinicalUpdatesTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete clinical update record. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        getColumns() {
            let { tableColumns } = this;

            if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                tableColumns = tableColumns.concat(this.deColumns);
            }
            return tableColumns;
        },
        addTableActions(datatable) {
            const component = this;
            if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                $('#clinical-update-table tbody .edit-clin-update').click(function editClinUpdateRecord() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const selectedCohortOption = component.cohortOptions.filter(x => x.value === component.selectedCohort)[0];
                    component.$router.push({
                        name: 'DataEditorEditClinicalUpdatePage',
                        params: {
                            cohortId: selectedCohortOption.value,
                            cohortName: selectedCohortOption.text,
                            recordId: rowData.db_id,
                        }
                    });
                });
                $('#clinical-update-table tbody .delete-clin-update').click(function confirmClinUpdateDelete() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete clinical update of category ${rowData.update_category} for participant ${rowData.participant_id}?`;
                    if(confirm(confirmMessage)){
                        component.deleteClinicalUpdate(component.selectedCohort, rowData.db_id, component.pDict[rowData.participant_id]);
                    }
                });
            }
        },
        hideTable() {
            $('#clinical-update-table thead').hide();
            $('#clinical-update-table-wrapper').hide();
        },
        showTable() {
            $('#clinical-update-table thead').show();
            $('#clinical-update-table-wrapper').show();
        },
        getClinicalUpdatesTable() {
            const component = this;
            const loader = this.$loading.show();
            const clinicalUpdatesUrl = this.populateClinicalUpdatesUrl();
            const getClinicalUpdatesUrl = this.selectedParticipant === -1 ? `${clinicalUpdatesUrl}?cohort_id=${this.selectedCohort}` : `${clinicalUpdatesUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}`;

            axios.get(getClinicalUpdatesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const clinicalUpdates = resp.data.clinical_updates;
                    const table = $('#clinical-update-table').DataTable({
                        destroy: true,
                        data: clinicalUpdates,
                        columns: this.getColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#clinical_update',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-clinical_updates`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#clinical_update'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-clinical_updates`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#clinical_update'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .finally(() => loader.hide());
        }
    }

};
</script>

<style>

</style>
