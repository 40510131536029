<template>
    <div>
        <h3>View Lines Of Treatment</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="line-of-treatment-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="line-of-treatment-table" class="display compact stripe" style="width:100%" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import {
    getUrl,
    getSampleCoordinatorCohortsPromise,
    getSubmitterCohortsPromise
} from '../utils/directory';
import getUserToken from '../utils/auth';
import {
    SAMPLE_COORDINATOR,
    SUBMITTER
} from '../utils/constants';
import {
    createCohortsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';

export default {
    props: {
        // these props are passed in after a clinical update record is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCohortId: { default: null, type: Number, required: false },
        // this prop is passed to indicate what type of user is viewing the clinical update records
        role: {
            type: String,
            required: true,
            validator(val) {
                return [SAMPLE_COORDINATOR, SUBMITTER].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // checking if there was an edit
            lineOfTreatmentEdited: !!this.editedCohortId, // true if this.editedCohortID is null

            // URLs used
            sampleCoordinatorCohortsPromise: getSampleCoordinatorCohortsPromise,
            sampleCoordinatorLinesOfTreatmentUrl: getUrl('sampleCoordinatorLinesOfTreatmentUrl'),
            submitterCohortsPromise: getSubmitterCohortsPromise,
            submitterLinesOfTreatmentUrl: getUrl('submitterLinesOfTreatmentUrl'),

            // form view controls
            cohortSelected: false,

            // cohort dropdown selections
            selectedCohort: null,
            cohortOptions: [],

            // table vars
            exportColumns: [0, 1, 2],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Line Name', data: 'line_name' },
                { title: 'Backbone Drug', data: 'backbone_drug' }
            ],
            sColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-line-of-treatment">Edit</span>';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span delete-line-of-treatment">Delete</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]

        };
    },
    watch: {
        selectedCohort() {
            // update participants dropdown when new cohort is selected
            if (this.selectedCohort === null) {
                this.hideTable();
                return;
            }
            this.getLinesOfTreatmentTable();
        }
    },
    beforeMount() {
        this.getCohorts();
    },
    methods: {
        populateCohortsUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.sampleCoordinatorCohortsPromise;
            case SUBMITTER:
                return this.submitterCohortsPromise;
            default:
                console.error('Invalid role for lines of treatment table.');
                return undefined;
            }
        },
        populateLinesOfTreatmentUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.sampleCoordinatorLinesOfTreatmentUrl;
            case SUBMITTER:
                return this.submitterLinesOfTreatmentUrl;
            default:
                console.error('Invalid role for lines of treatment table.');
                return undefined;
            }
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            const cohortsPromise = this.populateCohortsUrl();
            cohortsPromise()
                .then((resp) => {
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);

                    // check if there was an edited line of treatment
                    if (component.lineOfTreatmentEdited) {
                        component.lineOfTreatmentEdited = false;
                        component.selectedCohort = component.editedCohortId;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        getColumns() {
            let { tableColumns } = this;

            if (this.role !== SUBMITTER) {
                return tableColumns;
            }
            // Submitter actions only
            tableColumns = tableColumns.concat(this.sColumns);
            return tableColumns;
        },
        addTableActions(datatable) {
            const component = this;
            if (this.role === SUBMITTER) {
                $('#line-of-treatment-table tbody .edit-line-of-treatment').click(function editLineOfTreatment() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const selectedCohortOption = component.cohortOptions.filter(x => x.value === component.selectedCohort)[0];
                    component.$router.push({
                        name: 'SubmitterEditLineOfTreatmentPage',
                        params: {
                            role: component.role,
                            cohortId: selectedCohortOption.value,
                            cohortName: selectedCohortOption.text,
                            lineId: rowData.id
                        }
                    });
                });

                $('#line-of-treatment-table tbody .delete-line-of-treatment').click(function confirmLineOfTreatmentDelete() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete line of treatment ${rowData.line_name}?`;
                    const msgBoxOptions = {
                        hideHeader: true,
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Delete line of treatment',
                        centered: true
                    };

                    component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                        .then((deleteConfirmed) => {
                            if (deleteConfirmed) {
                                component.deleteLineOfTreatment(component.selectedCohort, rowData.id);
                            }
                        });
                });
            }
        },
        hideTable() {
            $('#line-of-treatment-table-wrapper').hide();
        },
        showTable() {
            $('#line-of-treatment-table-wrapper').show();
        },
        getLinesOfTreatmentTable() {
            const component = this;
            const loader = this.$loading.show();
            const linesOfTreatmentUrl = `${this.populateLinesOfTreatmentUrl()}?cohort_id=${this.selectedCohort}`;
            axios.get(linesOfTreatmentUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const linesOfTreatment = resp.data.lines_of_treatment;
                    const table = $('#line-of-treatment-table').DataTable({
                        destroy: true,
                        data: linesOfTreatment,
                        columns: this.getColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#line_of_treatment',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-lines_of_treatment`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#line_of_treatment'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-lines_of_treatment`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#line_of_treatment'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .finally(() => loader.hide());
        },
        deleteLineOfTreatment(cohortId, lineId) {
            const component = this;
            const loader = component.$loading.show();
            const deleteUrl = `${component.populateLinesOfTreatmentUrl()}?id=${lineId}&cohort_id=${cohortId}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getLinesOfTreatmentTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete line of treatment. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        }
    }

};
</script>

<style>

</style>
