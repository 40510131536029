<template>
    <div>
        <h3>Add Drug Combination</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addDrugCombination">
                <b-form-group label="Drug Combination Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.name.$model" :state="v$.form.name.$dirty ? !v$.form.name.$error : null" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-form-group label="URL [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.url" />
                </b-form-group>
                <b-form-group label="Drugs in combination" label-class="font-weight-bold" description="Press and hold the CMD key to select multiple drugs.">
                    <b-form-select multiple required size="sm" :options="drugOptions" v-model="v$.form.selectedDrugs.$model" />
                </b-form-group>
                <b-form-group label="Drug Combination Aliases (comma-separated list) [optional]" :description="requirements.aliases" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.aliases.$model" :state="v$.form.aliases.$dirty ? !v$.form.aliases.$error : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Add Drug
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    minLength,
    maxLength
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {
    getUrl, getDrugsPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createDrugsDict
} from '../../utils/form-data-helpers';
import { drugCombinationAliasCheck } from '../../utils/input-regex';
import getUserToken from '../../utils/auth';

export default {
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            addDrugCombinationsUrl: getUrl('drugCombinationsUrl'),

            // options for form dropdowns
            drugOptions: [],

            // form data
            form: {
                // dropdowns
                selectedDrugs: [],
                // inputs
                name: null,
                notes: '',
                url: '',
                aliases: ''
            },

            // form requirements
            requirements: {
                aliases: 'Please input as a comma-separated list. Aliases must only contain alphanumeric characters and embedded spaces.'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return {
            form: {
                name: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(128)
                },
                selectedDrugs: {
                    required,
                    minLength: minLength(1)
                },
                aliases: {
                    minLength: minLength(4),
                    maxLength: maxLength(64),
                    drugCombinationAliasCheck
                }
            }
        }
    },
    mounted() {
        this.initDrugCombinationForm();
    },
    methods: {
        initDrugCombinationForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getDrugsPromise()
            ])
                .then(axios.spread((drugsResp) => {
                    const drugs = createDrugsDict(drugsResp.data.drugs);

                    component.drugOptions = createDropdownOptions(drugs, true, false);
                }))
                .finally(() => { loader.hide(); });
        },
        addDrugCombination() {
            const loader = this.$loading.show();
            const component = this;

            const newDrugCombination = {
                name: this.form.name,
                drugs: this.form.selectedDrugs,
                notes: this.form.notes === '' ? null : this.form.notes,
                url: this.form.url === '' ? null : this.form.url,
                submitted_aliases: this.form.aliases === '' ? null : this.form.aliases
            };

            axios.post(this.addDrugCombinationsUrl, newDrugCombination, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
