function createDiagnosticTestCategoriesDict(categories) {
    const dict = {};
    categories.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createClinicalUpdateCategoriesDict(categories) {
    const dict = {};
    categories.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createCohortsDict(cohorts) {
    const dict = {};
    cohorts.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createCollaboratorDict(collaborators) {
    const dict = {};
    collaborators.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createTreatmentCategoriesDict(categories) {
    const dict = {};
    categories.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createDrugCategoriesDict(categories) {
    const dict = {};
    categories.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createDrugTargetsDict(targets) {
    const dict = {};
    targets.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createDrugsDict(drugs) {
    const dict = {};
    drugs.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createDrugCombinationsDict(combinations) {
    const dict = {};
    combinations.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createBaitSetDict(baitSets) {
    const dict = {};
    baitSets.forEach((x) => { dict[x.bait_set_name] = x.id; });
    return dict;
}

function createCancerStagingSystemsDict(cancerStagingSystems) {
    const dict = {};
    cancerStagingSystems.forEach((x) => { dict[x.staging_system_name] = x.id; });
    return dict;
}

function createCancerTypesDict(cancerTypes) {
    const dict = {};
    cancerTypes.forEach((x) => { dict[x.cancer_type_name] = x.id; });
    return dict;
}

function createCancerStagesDict(cancerStages) {
    const dict = {};
    cancerStages.forEach((x) => { dict[x.stage_with_information] = x.id; });
    return dict;
}

/**
 * Creates a dictionary for a list of enums
 * @param {Array} enumList - a list of enum options. Expects each object in the array to have "display" and "name" attributes
 */
function createEnumDict(enumList) {
    const dict = {};
    enumList.forEach((x) => { dict[x.display] = x.name; });
    return dict;
}

function createGenderDict(genders) {
    const dict = {};
    genders.forEach((x) => { dict[x.display] = x.name; });
    return dict;
}

function createRaceDict(races) {
    const dict = {};
    races.forEach((x) => { dict[x.display] = x.name; });
    return dict;
}

function createIcdo3TopoCodesDict(icdo3Codes) {
    const dict = {};
    icdo3Codes.forEach((x) => { dict[`${x.anatomical_site} (${x.code})`] = x.code; });
    return dict;
}

function createIrbProtocolsDict(irbProtocols) {
    const dict = {};
    irbProtocols.forEach((x) => { dict[x.name] = x.id; });
    return dict;
}

function createParticipantsDict(participants) {
    const dict = {};
    participants.forEach((x) => { dict[x.participant_id] = x.db_id; });
    return dict;
}

function createBiospecimensDict(biospecimens) {
    const dict = {};
    biospecimens.forEach((x) => { dict[x.collaborator_sample_id] = x.db_id; });
    return dict;
}

function createSamplesDict(samples) {
    const dict = {};
    samples.forEach((x) => { dict[x.gp_sample_id] = x.db_id; });
    console.log(dict)
    return dict;
}

function createDatesDict(manifests) {
    const dict = {};
    manifests.forEach((x) => { dict[x.submitted_date] = x.submitted_date; });
    return dict;
}

function createUberonAnatomySitesDict(sites) {
    const dict = {};
    sites.forEach((x) => { dict[`${x.anatomy_site} (UBERON:${x.uberon_id})`] = x.uberon_id; });
    return dict;
}

function createUsersDict(users) {
    const dict = {};
    users.forEach((x) => { dict[x.email] = x.id; });
    return dict;
}

function createVitalStatusDict(vitalStatuses) {
    const dict = {};
    vitalStatuses.forEach((x) => { dict[x.display] = x.name; });
    return dict;
}

function createExperimentalStrategyDict(experimentalStrategies) {
    const dict = {};
    experimentalStrategies.forEach((x) => { dict[x.display] = x.name; });
    return dict;
}

function createStopReasonDict(stopReasons) {
    const dict = {};
    stopReasons.forEach((x) => { dict[x.display] = x.name; });
    return dict;
}

function createLineOfTreatmentDisplayDict(lines) {
    const dict = {};
    lines.forEach((x) => { dict[x.line_display] = x.id; });
    return dict;
}

function createLineOfTreatmentNameDict(lines) {
    const dict = {};
    lines.forEach((x) => { dict[x.line_name] = x.id; });
    return dict;
}

/**
 * Function to create a list of options for a Bootstrap form dropdown menu.
 * For each dict entry, the key becomes the displayed text,
 * and the dict value becomes the dropdown item's value.
 * @param {Object} aDict -- dict to turn into dropdown menu items
 * @param {Boolean} sort -- specifies whether or not to sort the dropdown list
 * @param {Boolean} withNull -- to specify if you would like to have a null option
 */
function createDropdownOptions(aDict, sort = true, withNull = true) {
    const nullOption = {
        value: null,
        text: 'Please select an option'
    };
    const options = Object.keys(aDict).map(x => ({ text: x, value: aDict[x] }));
    if (sort) {
        options.sort((a, b) => (a.text < b.text ? -1 : 1));
    }
    if (withNull) {
        return [nullOption].concat(options);
    }
    return options;
}

export {
    createDiagnosticTestCategoriesDict,
    createClinicalUpdateCategoriesDict,
    createCohortsDict,
    createCollaboratorDict,
    createTreatmentCategoriesDict,
    createDrugCategoriesDict,
    createDrugTargetsDict,
    createBaitSetDict,
    createCancerStagingSystemsDict,
    createCancerTypesDict,
    createCancerStagesDict,
    createEnumDict,
    createGenderDict,
    createRaceDict,
    createIcdo3TopoCodesDict,
    createIrbProtocolsDict,
    createParticipantsDict,
    createBiospecimensDict,
    createSamplesDict,
    createDatesDict,
    createUberonAnatomySitesDict,
    createUsersDict,
    createVitalStatusDict,
    createExperimentalStrategyDict,
    createDropdownOptions,
    createStopReasonDict,
    createDrugsDict,
    createDrugCombinationsDict,
    createLineOfTreatmentDisplayDict,
    createLineOfTreatmentNameDict
};
