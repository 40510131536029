// User Role Related Constants
export const USER = 'User';
export const ADMINISTRATOR = 'Administrator';
export const PROJECT_LEAD = 'ProjectLead';
export const DATA_EDITOR = 'DataEditor';
export const DATA_ANALYST = 'DataAnalyst';
export const ROLES_CAN_VIEW_SWIMMER_PLOT = [
    USER,
    ADMINISTRATOR,
    PROJECT_LEAD,
    DATA_EDITOR,
    DATA_ANALYST
];

export const COHORT_LEAD = 'CohortLead';
export const SUBMITTER = 'Submitter';
export const SAMPLE_COORDINATOR = 'SampleCoordinator';
export const LEAD_DATA_ANALYST = 'LeadDataAnalyst';
export const UBER_LEAD_DATA_ANALYST = 'UberLeadDataAnalyst';
