<template>
    <div>
        <h4>Download latest templates for entity uploads</h4>
        <button type="button" @click="onexport">
            Template download
        </button>
    </div>
</template>

<script>
import XLSX from 'xlsx';
import axios from 'axios';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import store from '../store';

export default {
    data() {
        return {
            roles: store.state.user.roles,

            // urls
            entityUploadTemplatesUrl: getUrl('submitterLatestEntityUploadTemplateUrl'),

            templateData: []
        };
    },
    mounted() {
        this.getTemplateInfo();
    },
    methods: {
        populateUrl() {
            if (this.roles.isDataEditor) {
                return this.entityUploadTemplatesUrl;
            } else {
                console.error('Invalid role for template.');
                return undefined;
            }
        },
        getTemplateInfo() {
            const loader = this.$loading.show(); // loader while waiting for categories GET request
            const component = this;
            const url = this.populateUrl();

            axios.get(url, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((resp) => {
                    loader.hide();
                    component.templateData = resp.data.latest_entity_upload_template;
                });
        },
        createSheets(individualEntity) {
            const entityName = { 0: individualEntity.name };

            const headers = individualEntity.entity_headers;
            const headersDict = {};
            for (let i = 0; i < headers.length; i += 1) {
                headersDict[i] = headers[i];
            }
            const entityWS = XLSX.utils.json_to_sheet([entityName], { skipHeader: true, origin: 'A1' });
            XLSX.utils.sheet_add_json(entityWS, [headersDict], { skipHeader: true, origin: 'A2' });

            return entityWS;
        },
        onexport() { // On Click Excel download button
            // export json to Worksheet of Excel
            // only array possible
            const component = this;

            // A workbook is the name given to an Excel file
            const wb = XLSX.utils.book_new();

            for (let i = 0; i < component.templateData.length; i += 1) {
                const entityWS = component.createSheets(component.templateData[i]);
                XLSX.utils.book_append_sheet(wb, entityWS, component.templateData[i].name);
            }

            // export Excel file
            // name of the file is 'entity_templates.xlsx'
            XLSX.writeFile(wb, 'entity_templates.xlsx');
        }
    }
};
</script>
