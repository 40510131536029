<template>
    <div>
        <h3>View Cancer Staging Systems</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="cancer-staging-systems-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="cancer-staging-systems-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import store from '../store';

export default {
    props: {
        // For when staging systems are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
    },
    data() {
        const storeRoles = store.state.user.roles; 
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // user roles
            roles: storeRoles, 

            // urls
            cancerStagingSystemsUrl: getUrl('cancerStagingSystemsUrl'),

            // table vars
            exportColumns: [0, 1],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Staging System', data: 'staging_system_name' },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            scTableColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-cancer-staging-system">Edit</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    // watch: {
    // },
    mounted() {
        this.populateCancerStagingSystemsTable();
    },
    methods: {
        populateCancerStagingSystemsUrl() {
            if (this.roles.isAdmin) {
                return this.cancerStagingSystemsUrl;
            }
            console.error('Invalid role for cancer staging systems table.');
            return undefined;
        },
        getTableColumns() {
            let { tableColumns } = this;
            if (this.roles.isAdmin) {
                tableColumns = tableColumns.concat(this.scTableColumns);
            }
            return tableColumns;
        },
        populateCancerStagingSystemsTable() {
            const loader = this.$loading.show(); // loader while waiting for cancer staging systems GET request
            const component = this;

            axios.get(component.populateCancerStagingSystemsUrl(), {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((cancerStagingSystemResp) => {
                    loader.hide();
                    const cancerStagingSystemTableData = cancerStagingSystemResp.data.cancer_staging_systems;
                    const table = $('#cancer-staging-systems-table').DataTable({
                        destroy: true,
                        data: cancerStagingSystemTableData,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '70vh',
                        scrollCollapse: true,
                        dom: 'Blfrtip',
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#cancer_staging_system',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'cancer_staging_systems',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#cancer_staging_system'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'cancer_staging_systems',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#cancer_staging_system'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                });
        },
        addTableActions(datatable) {
            const component = this;
            $('#cancer-staging-systems-table tbody .edit-cancer-staging-system').click(function editCancerStagingSystem() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.$router.push({
                    name: 'AdminEditCancerStagingSystemPage',
                    params: {
                        stagingSystemId: rowData.id,
                    }
                });
            });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        }
    }
};
</script>

<style>
#collaborators-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
