<template>
    <div>
        <h3>Update Cancer Staging System</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateCancerStagingSystem">
                <b-form-group label="Cancer Staging System DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.id" disabled />
                </b-form-group>
                <b-form-group label="Staging System" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.name.$model" :state="$v.form.name.$dirty ? !$v.form.name.$error : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Update Cancer Staging System
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    minLength,
    maxLength
} from '@vuelidate/validators';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    name: 'SampleCoordinatorEditCancerStagingSystemPage',
    props: {
        stagingSystemId: {
            type: Number,
            required: true
        },
        role: { type: String, required: true }
    },
    data() {
        return {
            // urls
            sampleCoordinatorCancerStagingSystemsUrl: getUrl('sampleCoordinatorCancerStagingSystemsUrl'),

            // form data
            form: {
                // inputs
                id: this.stagingSystemId,
                name: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(64)
            }
        }
    },
    watch: {
    },
    mounted() {
        this.populateCancerStagingSystemForm();
    },
    methods: {
        populateCancerStagingSystemForm() {
            const loader = this.$loading.show();
            const component = this;
            const url = `${this.sampleCoordinatorCancerStagingSystemsUrl}?id=${this.stagingSystemId}`;

            axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((updateCancerStagingSystemResp) => {
                    const cancerStagingSystemData = updateCancerStagingSystemResp.data.cancer_staging_systems[0];
                    this.form.name = cancerStagingSystemData.staging_system_name;
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        },
        updateCancerStagingSystem() {
            const loader = this.$loading.show();
            const component = this;
            const updateUrl = `${this.sampleCoordinatorCancerStagingSystemsUrl}?id=${this.stagingSystemId}`;

            const updatedCancerStagingSystem = {
                staging_system_name: this.form.name
            };
            axios.put(updateUrl, updatedCancerStagingSystem, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'SampleCoordinatorViewCancerStagingSystemsPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            role: component.role
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }
};
</script>

<style>

</style>
