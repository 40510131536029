<template>
    <div>
        <h3>View Diagnostic Test Records</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>

                    <b-form-group label="Participant ID" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!cohortSelected" v-model="selectedParticipant" :options="participantOptions" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="diagnostic-test-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="diagnostic-test-table" class="display compact stripe" style="width:100%" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import {
    SAMPLE_COORDINATOR,
    COHORT_LEAD,
    SUBMITTER,
    DATA_ANALYST
} from '../utils/constants';
import {
    createCohortsDict,
    createParticipantsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';

export default {
    props: {
        // these props are passed in after a diagnostic test record is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCohortId: { default: null, type: Number, required: false },
        // this prop is passed to indicate what type of user is viewing the diagnostic test records
        role: {
            type: String,
            required: true,
            validator(val) {
                return [SAMPLE_COORDINATOR, COHORT_LEAD, SUBMITTER, DATA_ANALYST].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // checking if there was an edit
            diagnosticTestEdited: !!this.editedCohortId, // true if this.editedCohortID is undefined

            // URLs used
            sampleCoordinatorCohortsUrl: getUrl('sampleCoordinatorCohortsUrl'),
            sampleCoordinatorParticipantsUrl: getUrl('sampleCoordinatorParticipantsUrl'),
            sampleCoordinatorDiagnosticTestsUrl: getUrl('sampleCoordinatorDiagnosticTestsUrl'),
            cohortLeadCohortsUrl: getUrl('cohortLeadCohortsUrl'),
            cohortLeadParticipantsUrl: getUrl('cohortLeadParticipantsUrl'),
            cohortLeadDiagnosticTestsUrl: getUrl('cohortLeadDiagnosticTestsUrl'),
            submitterCohortsUrl: getUrl('submitterCohortsUrl'),
            submitterParticipantsUrl: getUrl('submitterParticipantsUrl'),
            submitterDiagnosticTestsUrl: getUrl('submitterDiagnosticTestsUrl'),
            dataAnalystCohortsUrl: getUrl('dataAnalystCohortsUrl'),
            dataAnalystParticipantsUrl: getUrl('dataAnalystParticipantsUrl'),
            dataAnalystDiagnosticTestsUrl: getUrl('dataAnalystDiagnosticTestsUrl'),

            // form view controls
            cohortSelected: false,

            // cohort dropdown selections
            selectedCohort: null,
            cohortOptions: [],

            // participant dropdown selections
            selectedParticipant: null,
            participantOptions: [],
            pDict: null, // For saving the participants dictionary from GET request, used for DELETE request

            // table vars
            exportColumns: [0, 1, 2, 3, 4, 6],
            tableColumns: [
                { title: 'DBKey', data: 'db_id' },
                { title: 'Participant ID', data: 'participant_id' },
                { title: 'Test category', data: 'test_category' },
                { title: 'Test date (dfd)', data: 'test_date_dfd' },
                { title: 'Notes', data: 'notes' },
                { title: 'Submitter', data: 'submitter' },
                { title: 'Primary Cohort', data: 'primary_cohort' },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            submitterColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span edit-diag-test">Edit</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span delete-diag-test">Delete</span>';
                        }
                        return '';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]

        };
    },
    watch: {
        selectedCohort() {
            // update participants dropdown when new cohort is selected
            if (this.selectedCohort === null) {
                this.selectedParticipant = null;
                this.participantOptions = [];
                this.cohortSelected = false;
                this.hideTable();
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            const participantsUrl = this.populateParticipantsUrl();
            axios.get(`${participantsUrl}?cohort_id=${this.selectedCohort}&primary_only=false`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    component.pDict = pDict;
                    const nullOption = {
                        value: null,
                        text: 'Please select an option'
                    };
                    const allOption = {
                        value: -1,
                        text: 'All'
                    };
                    const pOptions = createDropdownOptions(pDict, true, false);
                    component.participantOptions = [nullOption, allOption].concat(pOptions);
                    component.cohortSelected = true;

                    // checking if a diagnostic test record was edited
                    if (component.diagnosticTestEdited) {
                        component.diagnosticTestEdited = false;
                        component.selectedParticipant = -1;
                    } else {
                        component.selectedParticipant = null;
                    }
                })
                .finally(() => { loader.hide(); });
        },
        selectedParticipant() {
            if (this.selectedParticipant === null) {
                this.hideTable();
                return;
            }
            this.getDiagnosticTestsTable();
        }
    },
    beforeMount() {
        this.getCohorts();
    },
    methods: {
        populateCohortsUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.sampleCoordinatorCohortsUrl;
            case COHORT_LEAD:
                return this.cohortLeadCohortsUrl;
            case SUBMITTER:
                return this.submitterCohortsUrl;
            case DATA_ANALYST:
                return this.dataAnalystCohortsUrl;
            default:
                console.error('Invalid role for diagnotic tests table.');
                return undefined;
            }
        },
        populateParticipantsUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.sampleCoordinatorParticipantsUrl;
            case COHORT_LEAD:
                return this.cohortLeadParticipantsUrl;
            case SUBMITTER:
                return this.submitterParticipantsUrl;
            case DATA_ANALYST:
                return this.dataAnalystParticipantsUrl;
            default:
                console.error('Invalid role for diagnostic tests table.');
                return undefined;
            }
        },
        populateDiagnosticTestUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.sampleCoordinatorDiagnosticTestsUrl;
            case COHORT_LEAD:
                return this.cohortLeadDiagnosticTestsUrl;
            case SUBMITTER:
                return this.submitterDiagnosticTestsUrl;
            case DATA_ANALYST:
                return this.dataAnalystDiagnosticTestsUrl;
            default:
                console.error('Invalid role for diagnostic tests table.');
                return undefined;
            }
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            const cohortsUrl = this.populateCohortsUrl();
            axios.get(cohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);

                    // check if there was an edited record
                    if (component.diagnosticTestEdited) {
                        component.selectedCohort = component.editedCohortId;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        deleteDiagnosticTest(cohortId, updateRecordId, participantId) {
            const component = this;
            const loader = component.$loading.show();
            const diagnosticTestsUrl = this.populateDiagnosticTestUrl();
            const deleteDiagnosticTestUrl = `${diagnosticTestsUrl}?id=${updateRecordId}&cohort_id=${cohortId}&participant_id=${participantId}`;
            axios.delete(deleteDiagnosticTestUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getDiagnosticTestsTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete diagnostic test record. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        getColumns() {
            let { tableColumns } = this;

            if (this.role !== SUBMITTER) {
                return tableColumns;
            }
            // Submitter actions only
            tableColumns = tableColumns.concat(this.submitterColumns);
            return tableColumns;
        },
        addTableActions(datatable) {
            const component = this;
            if (this.role === SUBMITTER) {
                $('#diagnostic-test-table tbody .edit-diag-test').click(function editDiagTestRecord() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const selectedCohortOption = component.cohortOptions.filter(x => x.value === component.selectedCohort)[0];
                    component.$router.push({
                        name: 'SubmitterEditDiagnosticTestPage',
                        params: {
                            cohortId: selectedCohortOption.value,
                            cohortName: selectedCohortOption.text,
                            recordId: rowData.db_id,
                            role: component.role
                        }
                    });
                });
                $('#diagnostic-test-table tbody .delete-diag-test').click(function confirmCDiagTestDelete() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete diagnostic test of category ${rowData.test_category} for participant ${rowData.participant_id}?`;
                    const msgBoxOptions = {
                        hideHeader: true,
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Delete diagnostic test',
                        centered: true
                    };

                    component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                        .then((deleteConfirmed) => {
                            if (deleteConfirmed) {
                                component.deleteDiagnosticTest(component.selectedCohort, rowData.db_id, component.pDict[rowData.participant_id]);
                            }
                        });
                });
            }
        },
        hideTable() {
            $('#diagnostic-test-table-wrapper').hide();
        },
        showTable() {
            $('#diagnostic-test-table-wrapper').show();
        },
        getDiagnosticTestsTable() {
            const component = this;
            const loader = this.$loading.show();
            const diagnosticTestsUrl = this.populateDiagnosticTestUrl();
            const getDiagnosticTestsUrl = this.selectedParticipant === -1 ? `${diagnosticTestsUrl}?cohort_id=${this.selectedCohort}` : `${diagnosticTestsUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}`;

            axios.get(getDiagnosticTestsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const diagnosticTests = resp.data.diagnostic_tests;
                    const table = $('#diagnostic-test-table').DataTable({
                        destroy: true,
                        data: diagnosticTests,
                        columns: this.getColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#diagnostic_test',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-diagnostic_tests`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#diagnostic_test'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-diagnostic_tests`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#diagnostic_test'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .finally(() => loader.hide());
        }
    }

};
</script>

<style>

</style>
