<template>
    <div>
        <h3>Report of Sample Tracking</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <!--- Hide the cohort selection dropdown form if showAllCohortsReport is true --->
                    <b-form-group :hidden="showAllCohortsReport" label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>
                    <!--- Hide the daysInput form box only if showAllCohortsReport is false and the cohort has not been selected or 'All Cohorts' option was selected.
                          Always show if showAllCohortsReport is true --->
                    <b-form-group :hidden="showAllCohortsReport === false && (selectedCohort === null || selectedCohort === -1)" label="Days From Today" label-class="font-weight-bold" :description="daysInputDescription">
                        <!--- The onkeypress expression will disable users from hitting enter on the number form input --->
                        <b-form-input type="number" required size="sm" class="col-lg-4 col-md-6 col-xs-12" onkeypress="return event.keyCode != 13;" v-model="daysInput" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <h4 :hidden="hideTableTitles">
                Samples Not Yet Delivered
            </h4>
            <b-row id="undelivered-samples-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="undelivered-samples-table" class="display compact stripe" />
            </b-row>
        </b-container>
        <br><br>
        <b-container fluid>
            <h4 :hidden="hideTableTitles">
                Samples Delivered
            </h4>
            <b-row id="delivered-samples-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="delivered-samples-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../utils/directory';
import {
    createCohortsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';
import getUserToken from '../utils/auth';
import {
    SAMPLE_COORDINATOR
} from '../utils/constants';

export default {
    props: {
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        reportData: {
            type: Object,
            required: false,
            default: null
        },
        role: {
            type: String,
            required: true,
            validator(val) {
                return [SAMPLE_COORDINATOR].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // report view control
            showAllCohortsReport: !!this.reportData, // if report data was passed into the component, then this is a view to generate the asynchronously created all cohorts report

            // URLs used
            scReportsUrl: getUrl('sampleCoordinatorReportSampleTrackingUrl'),
            scCohortsUrl: getUrl('sampleCoordinatorCohortsUrl'),

            // cohort dropdown selections
            selectedCohort: null,
            cohortOptions: [],
            daysInput: 0, // initialize as zero so that all the sample status rows in the undelivered samples table will show in the first generation
            daysInputDescription: 'Input an integer to see undelivered samples where that number of days or more have passed since their submitted date. Input 0 to see all undelivered samples.',

            // table vars
            exportDColumns: [0, 1, 2, 3, 4], // will export these columns for the Delivered Samples table
            deliveredColumns: [
                { title: 'Biospecimen Name', data: 'biospecimen_name' },
                { title: 'Participant Name', data: 'participant_name' },
                { title: 'Delivered Sample Name(s)', data: 'sample_names' },
                { title: 'Sample Status Submitted Date(s)', data: 'submitted_dates' },
                { title: 'Sample Delivery Date(s)', data: 'delivery_dates' }
            ],

            exportUColumns: [0, 1, 2, 3], // will export these columns for the Undelivered Samples table
            undeliveredColumns: [
                { title: 'Biospecimen Name', data: 'biospecimen_name' },
                { title: 'Participant Name', data: 'participant_name' },
                { title: 'Sample Status Submitted Date(s)', data: 'submitted_dates' },
                { title: 'Days Since Submitted Date (From Today)', data: 'days_from_today' }
            ],

            exportDColumnsAllCohorts: [0, 1, 2, 3, 4, 5], // will export these columns for the Delivered Samples table in the all cohorts report
            deliveredColumnsAllCohorts: [
                { title: 'Cohort Name(s)', data: 'cohort_names' },
                { title: 'Biospecimen Name', data: 'biospecimen_name' },
                { title: 'Participant Name', data: 'participant_name' },
                { title: 'Delivered Sample Name(s)', data: 'sample_names' },
                { title: 'Sample Status Submitted Date(s)', data: 'submitted_dates' },
                { title: 'Sample Delivery Date(s)', data: 'delivery_dates' }
            ],

            exportUColumnsAllCohorts: [0, 1, 2, 3, 4], // will export these columns for the Undelivered Samples table in the all cohorts report
            undeliveredColumnsAllCohorts: [
                { title: 'Cohort Name(s)', data: 'cohort_names' },
                { title: 'Biospecimen Name', data: 'biospecimen_name' },
                { title: 'Participant Name', data: 'participant_name' },
                { title: 'Sample Status Submitted Date(s)', data: 'submitted_dates' },
                { title: 'Days Since Submitted Date (From Today)', data: 'days_from_today' }
            ],

            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ],
            hideTableTitles: true,
            rawUndelivSampleData: null
        };
    },
    watch: {
        selectedCohort() {
            // When a new cohort selection is made, hide the reports table. If the selected cohort isn't the null option, execute getReportsTable()
            this.hideTable();
            if (this.selectedCohort === null) {
                return;
            }
            this.getReportsTable();
        },
        daysInput() {
            // When a new value is entered in daysInput, execute generateUnDeliveredSamplesTable() to regenerate with the new day limiter.
            // Pass in the table columns that correspond to a singular cohort or an all cohorts report view.
            if (this.showAllCohortsReport === true) {
                this.generateUndeliveredSamplesTable(this, this.daysInput, this.undeliveredColumnsAllCohorts, this.exportUColumnsAllCohorts);
            } else {
                this.generateUndeliveredSamplesTable(this, this.daysInput, this.undeliveredColumns, this.exportUColumns);
            }
        }
    },
    mounted() {
        // When the component mounts, if reportData was passed in and showAllCohortsReport is true as a result, execute getAlreadycreatedReportsTable().
        // Otherwise, this will be a regular report query so execute getCohorts() to fetch the cohort options.
        if (this.showAllCohortsReport === true) {
            this.getAlreadyCreatedReportsTable();
        } else {
            this.getCohorts();
        }
    },
    methods: {
        populateCohortsUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.scCohortsUrl;
            default:
                console.error('Invalid role for sample table.');
                return undefined;
            }
        },
        populateReportsUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.scReportsUrl;
            default:
                console.error('Invalid role for sample table.');
                return undefined;
            }
        },
        getCohorts() {
            // fetch cohorts for cohort selection dropdown form
            const loader = this.$loading.show();
            const component = this;
            const cohortsUrl = component.populateCohortsUrl();
            axios.get(cohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    // build array of dropdown options for cohorts
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);

                    // append to the array the option of 'All Cohorts' (value of -1)
                    component.cohortOptions.push({
                        value: -1,
                        text: 'All Cohorts'
                    });
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        updateExportColumnNames(headerRow, exportColumns, tableColumns) {
            let header = headerRow;
            exportColumns.forEach((i) => {
                const col = tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        hideTable() {
            $('#delivered-samples-table-wrapper').hide();
            $('#undelivered-samples-table-wrapper').hide();
            this.hideTableTitles = true;
        },
        showTable() {
            $('#delivered-samples-table-wrapper').show();
            $('#undelivered-samples-table-wrapper').show();
            this.hideTableTitles = false;
        },
        getReportsTable() {
            const component = this;
            const loader = this.$loading.show();
            const getReportsUrl = `${this.populateReportsUrl()}?cohort_id=${this.selectedCohort}`;

            axios.get(getReportsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    if (component.selectedCohort === -1) {
                        // if the 'all cohorts' option is selected, the report will be built asynchronously so just display the response message from the API
                        component.alertMessage = resp.data.message;
                        component.showAlert = true;
                    } else {
                        // for all other singular cohort selections, the API will send the report data in a synchronous response
                        component.showTable();
                        const sampleReports = resp.data.sample_tracking_data;

                        const deliveredSampleData = sampleReports.delivered_samples;
                        const undeliveredSampleData = sampleReports.undelivered_samples;

                        // generate tables for delivered and undelivered samples from the data in the API response
                        component.generateDeliveredSamplesTable(component, deliveredSampleData, component.deliveredColumns, component.exportDColumns);
                        component.rawUndelivSampleData = undeliveredSampleData; // save off undelivered samples report data for report regeneration due to daysInput changing
                        component.generateUndeliveredSamplesTable(component, component.daysInput, component.undeliveredColumns, component.exportUColumns);
                    }
                })
                .finally(() => loader.hide());
        },
        getAlreadyCreatedReportsTable() {
            const deliveredSampleData = this.reportData.delivered_samples;
            const undeliveredSampleData = this.reportData.undelivered_samples;
            const component = this;

            // using the data passed into the component for the all cohorts report, generate the tables for delivered and undelivered samples
            component.showTable();
            component.generateDeliveredSamplesTable(component, deliveredSampleData, component.deliveredColumnsAllCohorts, component.exportDColumnsAllCohorts);
            component.rawUndelivSampleData = undeliveredSampleData; // save off undelivered samples report data for report regeneration due to daysInput changing
            component.generateUndeliveredSamplesTable(component, component.daysInput, component.undeliveredColumnsAllCohorts, component.exportUColumnsAllCohorts);
        },
        generateDeliveredSamplesTable(component, deliveredSampleData, columnArray, exportColumnArray) {
            // Iterate through each object containing individual biospecimen data in the deliveredSampleData array.
            // Then, iterate through each of the delivered sample statuses of the current biospecimen object and
            // and join together their experimental strategies, sample names, submitted dates, and delivery dates into concatenated strings to easily display in one row.
            deliveredSampleData.forEach((deliveredSample) => {
                const currDeliveredSample = deliveredSample;
                let joinedSampleNames = '';
                let joinedSubmittedDates = '';
                let joinedDeliveryDates = '';
                Object.keys(currDeliveredSample.sample_statuses).forEach((expStrategy) => {
                    joinedSampleNames = joinedSampleNames.concat(`${expStrategy.toUpperCase()}: ${currDeliveredSample.samples[expStrategy].sample_submitted_name.join()}\n`);
                    joinedSubmittedDates = joinedSubmittedDates.concat(`${expStrategy.toUpperCase()}: ${currDeliveredSample.sample_statuses[expStrategy].submitted_date}\n`);
                    joinedDeliveryDates = joinedDeliveryDates.concat(`${expStrategy.toUpperCase()}: ${currDeliveredSample.samples[expStrategy].sample_delivery_date.join()}\n`);
                });
                currDeliveredSample.sample_names = joinedSampleNames;
                currDeliveredSample.submitted_dates = joinedSubmittedDates;
                currDeliveredSample.delivery_dates = joinedDeliveryDates;
            });

            $('#delivered-samples-table').DataTable({
                destroy: true,
                data: deliveredSampleData,
                columns: columnArray,
                order: [], // no sorting, initially
                lengthMenu: component.tablePaginationOptions,
                dom: 'Blfrtip',
                fixedHeader: {
                    header: true
                },
                scrollY: '60vh',
                scrollCollapse: true,
                buttons: [
                    {
                        extend: 'copy',
                        // modify the title of the tsv data depending on if it is a singular cohort or all cohorts report
                        title: !component.showAllCohortsReport ? `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-delivered-samples-report` : 'all-cohorts-delivered-samples-report',
                        exportOptions: { columns: exportColumnArray },
                        customize(exportData) {
                            const exportRows = exportData.split('\n');
                            exportRows.splice(1, 1); // removing extra space that isn't needed
                            const headerRow = component.updateExportColumnNames(exportRows[1], exportColumnArray, columnArray);
                            exportRows[1] = headerRow;
                            return exportRows.join('\n');
                        }
                    },
                    {
                        text: 'TSV',
                        extend: 'csv',
                        fieldSeparator: '\t',
                        fieldBoundary: '',
                        // modify the title of the tsv data depending on if it is a singular cohort or all cohorts report
                        title: !component.showAllCohortsReport ? `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-delivered-samples-report` : 'all-cohorts-delivered-samples-report',
                        extension: '.txt',
                        exportOptions: { columns: exportColumnArray },
                        customize(exportData) {
                            let exportRows = exportData.split('\n');
                            const headerRow = component.updateExportColumnNames(exportRows[0], exportColumnArray, columnArray);
                            exportRows[0] = headerRow;
                            exportRows = ['#delivered-samples-report'].concat(exportRows);
                            return exportRows.join('\n');
                        }
                    }
                ]
            });
        },
        generateUndeliveredSamplesTable(component, daysInput, columnArray, exportColumnArray) {
            const undeliveredSampleData = component.rawUndelivSampleData;
            const displayUSampleData = []; // the array of data that will be used for the table
            undeliveredSampleData.forEach((undeliveredSample) => {
                // Similar to the Delivered Samples table logic, iterate through each object containing individual biospecimen data in the undeliveredSampleData array.
                // Then, iterate through each of the undelivered sample statuses of the current biospecimen object and, if the sample status is greater than or equal
                // to the current value of daysInput, join together their experimental strategies, sample names, and submitted dates into concatenated strings to easily display in one row.
                // Also, mark the sample status true for table display since it passes the dayInput conditional limiter.
                let displaySample = false; // boolean to control whether this row of undelivered sample data should be included in the table for display
                const currUndeliveredSample = undeliveredSample;
                let joinedSubmittedDates = '';
                let joinedDaysFromToday = '';
                Object.keys(currUndeliveredSample.sample_statuses).forEach((expStrategy) => {
                    const daysPassed = currUndeliveredSample.sample_statuses[expStrategy].days_passed_from_today;
                    if (daysInput === 0 || daysPassed >= daysInput) {
                        joinedSubmittedDates = joinedSubmittedDates.concat(`${expStrategy.toUpperCase()}: ${currUndeliveredSample.sample_statuses[expStrategy].submitted_date}\n`);
                        joinedDaysFromToday = joinedDaysFromToday.concat(`${expStrategy.toUpperCase()}: ${daysPassed}\n`);
                        displaySample = true; // valid sample status so display this row's data
                    }
                });

                // if marked for display, push the undelivered sample status data to the array of status data that will be shown in the Undelivered Samples table
                currUndeliveredSample.days_from_today = joinedDaysFromToday;
                currUndeliveredSample.submitted_dates = joinedSubmittedDates;
                if (displaySample === true) {
                    displayUSampleData.push(currUndeliveredSample);
                }
            });

            // since this Undelivered Samples table can be regenerated, the previous instance of the table must be destroyed and emptied before building the new one
            if ($.fn.DataTable.isDataTable('#undelivered-samples-table')) {
                $('#undelivered-samples-table').DataTable().destroy();
            }
            $('#undelivered-samples-table tbody').empty();
            $('#undelivered-samples-table thead').empty();
            $('#undelivered-samples-table').DataTable({
                destroy: true,
                data: displayUSampleData,
                columns: columnArray,
                order: [], // no sorting, initially
                lengthMenu: component.tablePaginationOptions,
                dom: 'Blfrtip',
                fixedHeader: {
                    header: true
                },
                scrollY: '60vh',
                scrollCollapse: true,
                buttons: [
                    {
                        extend: 'copy',
                        // modify the title of the tsv data depending on if it is a singular cohort or all cohorts report
                        title: !component.showAllCohortsReport ? `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-undelivered-samples-report` : 'all-cohorts-undelivered-samples-report',
                        exportOptions: { columns: exportColumnArray },
                        customize(exportData) {
                            const exportRows = exportData.split('\n');
                            exportRows.splice(1, 1); // removing extra space that isn't needed
                            const headerRow = component.updateExportColumnNames(exportRows[1], exportColumnArray, columnArray);
                            exportRows[1] = headerRow;
                            return exportRows.join('\n');
                        }
                    },
                    {
                        text: 'TSV',
                        extend: 'csv',
                        fieldSeparator: '\t',
                        fieldBoundary: '',
                        // modify the title of the tsv data depending on if it is a singular cohort or all cohorts report
                        title: !component.showAllCohortsReport ? `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-undelivered-samples-report` : 'all-cohorts-undelivered-samples-report',
                        extension: '.txt',
                        exportOptions: { columns: exportColumnArray },
                        customize(exportData) {
                            let exportRows = exportData.split('\n');
                            const headerRow = component.updateExportColumnNames(exportRows[0], exportColumnArray, columnArray);
                            exportRows[0] = headerRow;
                            exportRows = ['#undelivered-samples-report'].concat(exportRows);
                            return exportRows.join('\n');
                        }
                    }
                ]
            });
        }
    }
};
</script>

<style>

</style>
