<template>
    <div>
        <h3>View Cancer Stages</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Cancer Staging System" label-class="font-weight-bold">
                        <b-form-select v-model="selectedCancerStagingSystem" class="col-lg-4 col-md-6 col-xs-12" :options="stagingSystemOptions" />
                    </b-form-group>

                    <b-form-group label="Cancer Type" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!cancerStagingSystemSelected" v-model="selectedCancerType" :options="cancerTypeOptions" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="cancer-stage-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="cancer-stage-table" class="display compact stripe" style="width:100%" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import {
    createCancerStagingSystemsDict,
    createCancerTypesDict,
    createDropdownOptions
} from '../utils/form-data-helpers';
import store from '../store';

export default {
    props: {
        // these props are passed in after a cancer stage is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCancerStagingSystemId: { default: null, type: Number, required: false },
    },
    data() {
        const storeRoles = store.state.user.roles;
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // user roles
            roles: storeRoles,

            // URLs used
            cancerStagingSystemsUrl: getUrl('cancerStagingSystemsUrl'),
            sampleCoordinatorCancerTypesUrl: getUrl('sampleCoordinatorCancerTypesUrl'),
            sampleCoordinatorCancerStagesUrl: getUrl('sampleCoordinatorCancerStagesUrl'),

            // form view controls
            cancerStagingSystemSelected: false,

            // dropdown selections
            selectedCancerStagingSystem: null,
            stagingSystemOptions: [],

            // cancer type dropdown selections
            selectedCancerType: null,
            cancerTypeOptions: [],
            pDict: null, // For saving the cancer types dictionary from GET request, used for DELETE request

            // table vars
            exportColumns: [0, 1, 2, 3, 4],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Stage Number', data: 'stage_number' },
                { title: 'Stage Information', data: 'stage_information' },
                { title: 'Staging System Name', data: 'staging_system_name' },
                { title: 'Cancer Type Name', data: 'cancer_type_name' },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            sampleCoordinatorColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-cancer-stage">Edit</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]

        };
    },
    watch: {
        selectedCancerStagingSystem() {
            // update cancer types dropdown when new staging system is selected
            if (this.selectedCancerStagingSystem === null) {
                this.selectedCancerType = null;
                this.cancerTypeOptions = [];
                this.cancerStagingSystemSelected = false;
                this.hideTable();
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            const cancerTypesUrl = this.populateCancerTypesUrl();
            axios.get(`${cancerTypesUrl}?staging_system_id=${this.selectedCancerStagingSystem}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((resp) => {
                    const cancerTypes = createCancerTypesDict(resp.data.cancer_types);
                    component.cancerTypeOptions = createDropdownOptions(cancerTypes);

                    component.cancerStagingSystemSelected = true;

                    component.selectedCancerType = null;
                })
                .finally(() => { loader.hide(); });
        },
        selectedCancerType() {
            if (this.selectedCancerType === null) {
                this.hideTable();
                return;
            }
            this.getCancerStagesTable();
        }
    },
    beforeMount() {
        this.getCancerStagingSystems();
    },
    methods: {
        popuateCancerStagingSystemsUrl() {
            if (this.roles.isAdmin) {
                return this.cancerStagingSystemsUrl;
            }
            console.error('Invalid role for cancer stages table.');
            return undefined;
        },
        populateCancerTypesUrl() {
            if (this.roles.isAdmin) {
                return this.sampleCoordinatorCancerTypesUrl;
            }
            console.error('Invalid role for cancer stages table.');
            return undefined;
        },
        populateCancerStagesUrl() {
            if (this.roles.isAdmin) {
                return this.sampleCoordinatorCancerStagesUrl;
            }
            console.error('Invalid role for cancer stages table.');
            return undefined;
        },
        getCancerStagingSystems() {
            const loader = this.$loading.show();
            const component = this;
            const cancerStagingSystemsUrl = this.popuateCancerStagingSystemsUrl();
            axios.get(cancerStagingSystemsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cancerStagingSystems = createCancerStagingSystemsDict(resp.data.cancer_staging_systems);
                    component.stagingSystemOptions = createDropdownOptions(cancerStagingSystems);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        getColumns() {
            let { tableColumns } = this;

            if (!this.roles.isAdmin) {
                return tableColumns;
            }
            // SC actions only
            tableColumns = tableColumns.concat(this.sampleCoordinatorColumns);
            return tableColumns;
        },
        addTableActions(datatable) {
            const component = this;
            if (this.roles.isAdmin) {
                $('#cancer-stage-table tbody .edit-cancer-stage').click(function editCancerStage() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const selectedCancerStagingSystemOption = component.stagingSystemOptions.filter(x => x.value === component.selectedCancerStagingSystem)[0];
                    const selectedCancerTypeOption = component.cancerTypeOptions.filter(x => x.value === component.selectedCancerType)[0];
                    component.$router.push({
                        name: 'AdminEditCancerStagePage',
                        params: {
                            cancerTypeId: selectedCancerTypeOption.value,
                            cancerTypeName: selectedCancerTypeOption.text,
                            cancerStagingSystemName: selectedCancerStagingSystemOption.text,
                            cancerStageId: rowData.id,
                        }
                    });
                });
            }
        },
        hideTable() {
            $('#cancer-stage-table-wrapper').hide();
        },
        showTable() {
            $('#cancer-stage-table-wrapper').show();
        },
        getCancerStagesTable() {
            const component = this;
            const loader = this.$loading.show();
            const cancerStagesUrl = this.populateCancerStagesUrl();
            const getcancerStagesUrl = `${cancerStagesUrl}?cancer_type_id=${this.selectedCancerType}`;

            axios.get(getcancerStagesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const cancerStages = resp.data.cancer_stages;
                    const table = $('#cancer-stage-table').DataTable({
                        destroy: true,
                        data: cancerStages,
                        columns: this.getColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#cancer_stage',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.stagingSystemOptions.filter(x => x.value === component.selectedCancerStagingSystem)[0].text}-cancer_stages`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#cancer_stage'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .finally(() => loader.hide());
        }
    }

};
</script>

<style>

</style>
