<template>
    <div>
        <h3>Add Cancer Type</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addCancerType">
                <b-form-group label="Cancer Staging System" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cancerStagingSystemsOptions" v-model="v$.form.selectedCancerStagingSystem.$model" />
                </b-form-group>
                <b-form-group label="Cancer Type" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.cancerTypeName.$model" :state="v$.form.cancerTypeName.$error ? false : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Add Cancer Type
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    maxLength,
    minLength
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {
    getUrl
} from '../../utils/directory';
import {
    createDropdownOptions,
    createCancerStagingSystemsDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            sampleCoordinatorCancerStagingSystemsUrl: getUrl('sampleCoordinatorCancerStagingSystemsUrl'),
            sampleCoordinatorCancerTypesUrl: getUrl('sampleCoordinatorCancerTypesUrl'),

            // options for form dropdowns
            cancerStagingSystemsOptions: [],

            // form data
            form: {
                // dropdowns
                selectedCancerStagingSystem: null,
                // inputs
                cancerTypeName: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // dropdowns
                selectedCancerStagingSystem: { required },
                cancerTypeName: {
                    minLength: minLength(3),
                    maxLength: maxLength(64)
                }
            }
        }
    },
    mounted() {
        this.initCancerTypeForm();
    },
    methods: {
        initCancerTypeForm() {
            const loader = this.$loading.show();
            const component = this;
            axios.get(component.sampleCoordinatorCancerStagingSystemsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cancerStagingSystems = createCancerStagingSystemsDict(resp.data.cancer_staging_systems);
                    component.cancerStagingSystemsOptions = createDropdownOptions(cancerStagingSystems);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        addCancerType() {
            const loader = this.$loading.show();
            const component = this;

            const newCancerType = {
                cancer_type_name: this.form.cancerTypeName,
                staging_system_id: this.form.selectedCancerStagingSystem
            };

            axios.post(this.sampleCoordinatorCancerTypesUrl, newCancerType, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
