<template>
    <div>
        <h3>Report All Samples Synchronization</h3>
        <hr>
        <b-container fluid>
            <b-tabs content-class="mt-3" fill>
                <!--the offscreen table's initial render is wonky unless both tabs are active-->
                <b-tab id="sync-results-biospecimen-table-tab" title="Biospecimens" active>
                    <table id="results-biospecimen-table" class="display compact stripe" />
                </b-tab>
                <b-tab id="sync-results-sample-table-tab" title="Samples" active>
                    <table id="results-sample-table" class="display compact stripe" />
                </b-tab>
            </b-tabs>
        </b-container>
    </div>
</template>

<script>
export default {
    props: {
        // sync results from the API
        reportData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            sampleData: this.reportData.matched,
            biospecimenData: this.reportData.unmatched,
            sampleExportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            sampleColumns: [
                { title: 'Collaborator', data: 'collaborator' },
                { title: 'Delivery Workspace', data: 'delivery_workspace' },
                { title: 'Workspace Sample Name', data: 'workspace_sample_name' },
                { title: 'Workspace Sample Sync Status', data: 'status' },
                { title: 'Reject/Overwrite Reason', data: 'reject_reason' },
                { title: 'Workspace Sample Alias', data: 'sample_alias' },
                { title: 'Matching Biospecimen Name', data: 'biospecimen_name' },
                { title: 'Matching Biospecimen Tumor/Normal Status', data: 'biospecimen_tumor/normal' },
                { title: 'Workspace Sample Type', data: 'workspace_sample_type' },
                { title: 'Workspace Sample Key Type', data: 'data_type' },
                { title: 'Workspace Experimental Strategy', data: 'experimental_strategy' },
                { title: 'ULPWGS Tumor Fraction', data: 'ulp_tumor_fraction' }
            ],
            biospecimenExportColumns: [0, 1, 2, 3],
            biospecimenColumns: [
                { title: 'Collaborator', data: 'collaborator' },
                { title: 'Cohort Name', data: 'cohort_name' },
                { title: 'Biospecimen Name', data: 'biospecimen_name' },
                { title: 'Note', data: 'note' }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    mounted() {
        this.getSampleTable();
        this.getBiospecimenTable();
    },
    methods: {
        updateExportColumnNames(headerRow, columns, exportColumns) {
            let header = headerRow;
            exportColumns.forEach((i) => {
                const col = columns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        getSampleTable() {
            const loader = this.$loading.show();
            const component = this;
            $('#results-sample-table').DataTable({
                destroy: true,
                data: component.sampleData,
                columns: component.sampleColumns,
                order: [],
                lengthMenu: component.tablePaginationOptions,
                dom: 'Blfrtip',
                fixedHeader: {
                    header: true
                },
                scrollX: true,
                scrollY: '80vh',
                scrollCollapse: true,
                buttons: component.getTableButtons(component.sampleColumns, component.sampleExportColumns)
            });
            loader.hide();
        },
        getBiospecimenTable() {
            const loader = this.$loading.show();
            const component = this;
            $('#results-biospecimen-table').DataTable({
                destroy: true,
                data: component.biospecimenData,
                columns: component.biospecimenColumns,
                order: [],
                lengthMenu: component.tablePaginationOptions,
                dom: 'Blfrtip',
                fixedHeader: {
                    header: true
                },
                scrollY: '80vh',
                scrollCollapse: true,
                buttons: component.getTableButtons(component.biospecimenColumns, component.biospecimenExportColumns)
            });
            loader.hide();
        },
        getTableButtons(columns, exportColumns) {
            const component = this;
            return [
                {
                    extend: 'copy',
                    exportOptions: { columns: exportColumns },
                    customize(exportData) {
                        const exportRows = exportData.split('\n');
                        exportRows.splice(1, 1); // removing extra space that isn't needed
                        const headerRow = component.updateExportColumnNames(exportRows[1], columns, exportColumns);
                        exportRows[1] = headerRow;
                        return exportRows.join('\n');
                    }
                },
                {
                    text: 'TSV',
                    extend: 'csv',
                    fieldSeparator: '\t',
                    fieldBoundary: '',
                    extension: '.txt',
                    exportOptions: { columns: exportColumns },
                    customize(exportData) {
                        let exportRows = exportData.split('\n');
                        const headerRow = component.updateExportColumnNames(exportRows[0], columns, exportColumns);
                        exportRows[0] = headerRow;
                        exportRows = ['#results'].concat(exportRows);
                        return exportRows.join('\n');
                    }
                },
                {
                    text: 'Template',
                    extend: 'csv',
                    fieldSeparator: '\t',
                    fieldBoundary: '',
                    extension: '.txt',
                    exportOptions: { columns: exportColumns },
                    customize(exportData) {
                        let exportRows = exportData.split('\n');
                        const headerRow = component.updateExportColumnNames(exportRows[0], columns, exportColumns);
                        exportRows[0] = headerRow;
                        exportRows = ['#results'].concat([exportRows[0]]);
                        return exportRows.join('\n');
                    }
                }
            ];
        }
    }
};
</script>
