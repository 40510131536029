<template>
    <div>
        <h3>Add a Production Analysis Workspace Attribute </h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addAttribute">
                <b-form-group label="Attribute Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.attributeName.$model" :state="$v.form.attributeName.$dirty ? !$v.form.attributeName.$error : null" />
                </b-form-group>
                <b-form-group label="Attribute Value:" label-class="font-weight-bold">
                    <b-form-textarea required size="sm" v-model="$v.form.attributeValue.$model" :state="$v.form.attributeValue.$dirty ? !$v.form.attributeValue.$error : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Add Workspace Attribute
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required, minLength, maxLength
} from '@vuelidate/validators';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';
import { LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST } from '../../utils/constants';

export default {
    props: {
        // For when attributes sets are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        // this prop is passed to indicate what type of user is viewing participants
        role: {
            type: String,
            required: true,
            validator(val) {
                return [LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // URLs used
            leadDataAnalystWorkspaceAttributesUrl: getUrl('leadDataAnalystWorkspaceAttributesUrl'),
            uberLeadDataAnalystWorkspaceAttributesUrl: getUrl('uberLeadDataAnalystWorkspaceAttributesUrl'),

            // form data
            form: {
                // inputs
                attributeName: null,
                attributeValue: null
            },
            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
    },
    validations: {
        form: {
            // dropdowns
            attributeName: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(64)
            },
            attributeValue: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(256)
            }
        }
    },
    mounted() {},
    methods: {
        populateAttributesUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.leadDataAnalystWorkspaceAttributesUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uberLeadDataAnalystWorkspaceAttributesUrl;
            default:
                console.error('Invalid role for attributes table.');
                return undefined;
            }
        },
        addAttribute() {
            const loader = this.$loading.show();
            const component = this;

            const newAttribute = {
                attribute_name: this.form.attributeName,
                attribute_value: this.form.attributeValue
            };

            const url = `${component.populateAttributesUrl()}`;

            axios.post(url, newAttribute, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
