<template>
    <div>
        <h3>View Samples</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>

                    <b-form-group label="Participant ID" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!cohortSelected" v-model="selectedParticipant" :options="participantOptions" />
                    </b-form-group>

                    <b-form-group label="Collaborator Sample ID" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!participantSelected" v-model="selectedCollabSampleId" :options="collabSampleIdOptions" />
                    </b-form-group>
                    <b-form-group v-if="canViewSamplesToCheck" v-show="selectedCollabSampleId" label="Analysis Samples to be checked" label-class="font-weight-bold">
                        <b-form-checkbox switch size="sm" v-model="samplesToCheckView" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="sample-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="sample-table" class="display compact stripe" style="width:100%" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import store from '../store';
import {
    createCohortsDict,
    createParticipantsDict,
    createBiospecimensDict,
    createDropdownOptions
} from '../utils/form-data-helpers';

export default {
    props: {
        // these props are passed in after a sample is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCohortId: { default: null, type: Number, required: false },
    },
    data() {
        const storeRoles = store.state.user.roles;
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',
            roles: storeRoles,

            // cohort specific role info
            readerCohorts: storeRoles.readerCohorts,
            dataEditorCohorts: storeRoles.dataEditorCohorts,
            dataAnalystCohorts: storeRoles.dataAnalystCohorts,
            projectLeadsCohorts: storeRoles.projectLeadCohorts,

            // URLs used

            projectsUrl: getUrl('projectsUrl'),
            participantsUrl: getUrl('participantsUrl'),
            biospecimensUrl: getUrl('biospecimensUrl'),
            samplesUrl: getUrl('samplesUrl'),
            daSamplesUrl: getUrl('dataAnalystSamplesUrl'),

            // form view controls
            cohortSelected: false,
            participantSelected: false,

            // cohort dropdown selections
            selectedCohort: null,
            cohortOptions: [],

            // participant dropdown selections
            selectedParticipant: null,
            participantOptions: [],

            // biospecimen selections
            selectedCollabSampleId: null,
            collabSampleIdOptions: [],

            sampleDict: null, // For saving the sample dictionary from GET request, used for DELETE request

            // table vars
            exportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
            tableColumns: [],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ],

            // user is an appropriate for toggling participants without main normal
            canViewSamplesToCheck: false,

            // expanded view type selection
            samplesToCheckView: false,
        };
    },
    beforeDestroy() {
        if ($.fn.dataTable.isDataTable('#sample-table')) {
            let table = $('#sample-table').DataTable();
            table.clear().draw();
            table.destroy();
        }
        this.hideTable()
    },
    watch: {
        samplesToCheckView() {
            const component = this;
            component.getSampleTable();
        },
        selectedCohort() {
            // update participants dropdown when new cohort is selected
            this.selectedParticipant = null;
            if (this.selectedCohort === null) {
                this.participantOptions = [];
                this.cohortSelected = false;
                this.hideTable();
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            const participantsUrl = this.populateParticipantUrl();
            axios.get(`${participantsUrl}?cohort_id=${this.selectedCohort}&primary_only=false`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    component.pDict = pDict;
                    const nullOption = {
                        value: null,
                        text: 'Please select an option'
                    };
                    const allOption = {
                        value: -1,
                        text: 'All'
                    };
                    const pOptions = createDropdownOptions(pDict, true, false);
                    component.participantOptions = [nullOption, allOption].concat(pOptions);
                    component.cohortSelected = true;
                })
                .finally(() => { loader.hide(); });
        },
        selectedParticipant() {
            // update biospecimens dropdown when new participant is selected
            this.selectedCollabSampleId = null;
            if (this.selectedParticipant === null) {
                this.collabSampleIdOptions = [];
                this.participantSelected = false;
                this.hideTable();
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            const biospecimensUrl = this.selectedParticipant === -1 ? `${this.populateBiospecimensUrl()}?cohort_id=${this.selectedCohort}` : `${this.populateBiospecimensUrl()}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}`;
            axios.get(biospecimensUrl, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((bResp) => {
                    const { biospecimens } = bResp.data;
                    const bDict = createBiospecimensDict(biospecimens);
                    component.bDict = bDict;
                    const nullOption = {
                        value: null,
                        text: 'Please select an option'
                    };
                    const allOption = {
                        value: -1,
                        text: 'All'
                    };
                    const bOptions = createDropdownOptions(bDict, true, false);
                    component.collabSampleIdOptions = [nullOption, allOption].concat(bOptions);
                    component.participantSelected = true;
                })
                .finally(() => { loader.hide(); });
        },
        selectedCollabSampleId() {
            if (this.selectedCollabSampleId === null) {
                this.hideTable();
                return;
            }
            // table must be reset if sample changes since number of columns may change based on permissions
            let table = $('#biospecimen-table');
            if ($.fn.DataTable.isDataTable(table)) {
                table.DataTable().clear().draw();
                table.DataTable().destroy();
                table.empty(); 
            }
            // needs to be in a function because of role based cusomization
            this.appropriateUser();
            this.populateTableColumns();
            this.getSampleTable();
        }
    },
    beforeMount() {
        this.getCohorts();
    },
    methods: {
        appropriateUser() {
            if (
                this.dataAnalystCohorts && this.dataAnalystCohorts.includes(this.selectedCohort) ||
                this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)
            ) {
                this.canViewSamplesToCheck = true;
            }
            else {
                this.canViewSamplesToCheck = false;
            }
        },
        updateSampleFlags(sampleId, flag, flag_type) {
            const component = this;
            const loader = component.$loading.show();
            const url = `${component.samplesUrl}?id=${sampleId}`;

            const sample_flag_info = flag_type=='analysis' ? {analysis_sample_flag: !flag} : {sample_check_flag: !flag};

            axios.put(url, sample_flag_info, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.alertMessage = resp.data;
                    component.alertVariant = 'warning';
                    component.getSampleTable();
                })
                .catch((err) => {
                    loader.hide();
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to update analysis sample. Please contact portal administrators.';
                    } else {
                        component.alertMessage = err.response.data;
                    }
                    component.alertVariant = 'danger';
                })
                .finally(() => {
                    component.showAlert = true;
                    window.scrollTo(0, 0);
                    loader.hide();
                });
        },
        addTableActions() {
            const component = this;
            const datatable = $('#sample-table').DataTable();

            $('#sample-table tbody .analysis-sample-label').click(function editAnalysisSampleClicked() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.updateSampleFlags(rowData.db_id, rowData.analysis_sample_flag, 'analysis');
            });
            $('#sample-table tbody .sample-check-label').click(function editAnalysisSampleClicked() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.updateSampleFlags(rowData.db_id, rowData.sample_check_flag, 'check');
            });
        },
        populateTableColumns(){
            const component = this;
            this.tableColumns = [
                { title: 'DBKey', data: 'db_id' },
                { title: 'GP Sample ID', data: 'gp_sample_id'},
                { title: 'Aliases', data: 'aliases' },
                { title: 'Collaborator Sample ID', data: 'collaborator_sample_id' },
                {
                    title: 'Analysis sample',
                    sortable: false,
                    render(data, type, row) {
                        const checkStatus = row.analysis_sample_flag;
                        const isDisabled = (
                            (component.dataAnalystCohorts && !component.dataAnalystCohorts.includes(component.selectedCohort)) ||
                            (component.dataEditorCohorts && !component.dataEditorCohorts.includes(component.selectedCohort))
                        );
                        return `<label class="analysis-sample-label">
                                            <input class="analysis-sample-checked" type="checkbox" ${checkStatus ? 'checked' : ''} ${isDisabled ? 'disabled' : ''}> Analysis Sample
                                        </label>`;
                    }
                },
                {
                    title: 'Check analysis sample',
                    sortable: false,
                    render(data, type, row) {
                        const checkStatus = !row.sample_check_flag;
                        const isDisabled = (
                            (component.dataAnalystCohorts && !component.dataAnalystCohorts.includes(component.selectedCohort)) ||
                            (component.dataEditorCohorts && !component.dataEditorCohorts.includes(component.selectedCohort))
                        );
                        return `<label class="sample-check-label">
                                            <input class="sample-checked" type="checkbox" ${checkStatus ? 'checked' : ''} ${isDisabled ? 'disabled' : ''}> Sample Checked
                                        </label>`;
                    }
                },
                { title: 'GP Product Order', data: 'gp_product_order' },
                { title: 'Tumor/Normal', data: 'tumor_normal' },
                { title: 'Analyte Type', data: 'analyte_type' },
                { title: 'Experimental Strategy', data: 'experimental_strategy' },
                {
                    title: 'Bait Set',
                    data: 'bait_set_name',
                    render(data) {
                        if (data !== null) {
                            if (data.includes('(Needs Abbreviation)')) {
                                return `<p style="color:red;">${data}</p>`;
                            }
                        }
                        return data;
                    }
                },
                { title: 'ULP Tumor Fraction', data: 'ulp_tumor_fraction' },
                { title: 'Status', data: 'status' },
                {
                    title: 'Delivery Workspace',
                    data: 'delivery_workspace_name',
                    render(data) {
                        if (((component.dataAnalystCohorts && component.dataAnalystCohorts.includes(component.selectedCohort)) || (component.dataEditorCohorts && component.dataEditorCohorts.includes(component.selectedCohort))) && data !== null) {
                            return `<a href="https://app.terra.bio/#workspaces/${data}">${data}</a>`;
                        }
                        return data;
                    }
                },
                { title: 'Delivery Date', data: 'delivery_date' },
                { title: 'Sequence Funded By', data: 'sequence_funded_by' },
                { title: 'Notes', data: 'notes' },
                { title: 'Primary Cohort', data: 'primary_cohort' },
                { title: 'Submitter', data: 'submitter' },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                },
            ]
        },
        populateCohortUrl() {
            return this.projectsUrl;
        },
        populateParticipantUrl() {
            return this.participantsUrl;
        },
        populateBiospecimensUrl() {
            return this.biospecimensUrl;
        },
        populateSamplesUrl() {
            if (this.samplesToCheckView) {
                return this.daSamplesUrl
            }else {
                return this.samplesUrl;
            }
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            const cohortsUrl = this.populateCohortUrl();
            axios.get(cohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        getColumns() {
            let { tableColumns } = this;
            return tableColumns;
        },
        hideTable() {
            $('#sample-table thead').hide();
            $('#sample-table-wrapper').hide();
        },
        showTable() {
            $('#sample-table thead').show();
            $('#sample-table-wrapper').show();
        },
        getSampleTable() {
            const component = this;
            const loader = this.$loading.show();
            const samplesUrl = this.populateSamplesUrl();

            let getSamplesUrl = null;
            if (this.selectedParticipant === -1 && this.selectedCollabSampleId === -1) {
                getSamplesUrl = `${samplesUrl}?cohort_id=${this.selectedCohort}`;
            } else if (this.selectedParticipant !== -1) {
                if (this.selectedCollabSampleId !== -1) {
                    getSamplesUrl = `${samplesUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}&biospecimen_id=${this.selectedCollabSampleId}`;
                } else {
                    getSamplesUrl = `${samplesUrl}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}`;
                }
            } else {
                getSamplesUrl = `${samplesUrl}?cohort_id=${this.selectedCohort}&biospecimen_id=${this.selectedCollabSampleId}`;
            }

            axios.get(getSamplesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const { samples } = resp.data;
                    $('#sample-table').DataTable({
                        destroy: true,
                        data: samples,
                        columns: this.getColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        fnInitComplete() {
                            component.addTableActions();
                        },
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#sample',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-samples`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#sample'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                })
                .finally(() => loader.hide());
        }
    }

};
</script>

<style>

</style>
