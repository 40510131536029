<template>
    <div>
        <h3>Add Production Analysis Workspace to Cohort</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="6">
            <b-form @submit.stop.prevent="addWorkspace">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cohortOptions" v-model="v$.form.selectedCohort.$model" :state="v$.form.selectedCohort.$dirty ? !v$.form.selectedCohort.$error : null" />
                </b-form-group>
                <b-form-group label="Production Analysis Workspace Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.pranName.$model" :state="v$.form.pranName.$dirty ? !v$.form.pranName.$error : null" />
                </b-form-group>
                <b-form-group label="Description [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.description" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <h6><strong> {{ description.notes }} </strong></h6>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Add Production Analysis Workspace
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    required,
    maxLength,
    minLength
} from '@vuelidate/validators';
import {
    getUrl, getDACohortsPromise, getLDACohortsPromise, getULDACohortsPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createCohortsDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';
import store from '../../store';

export default {
    props: {
        // these props are passed in after a workspace is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        const storeRoles = store.state.user.roles;
        return {
            roles: storeRoles,

            // cohort specific role info
            readerCohorts: storeRoles.readerCohorts,
            dataEditorCohorts: storeRoles.dataEditorCohorts,
            dataAnalystCohorts: storeRoles.dataAnalystCohorts,
            projectLeadCohorts: storeRoles.projectLeadCohorts,

            // URLs used
            daAddPrAnWorkspaceUrl: getUrl('prAnWorkspacesUrl'),
            // options for form dropdowns
            cohortOptions: [],

            // form info
            form: {
                // dropdowns
                selectedCohort: null,
                pranName: null,
                description: '',
                notes: ''
            },

            // form description
            description: {
                notes: 'NOTE: Initialization of production analysis workspaces for cohorts that contain a large amount of participant and sample data may take multiple minutes. As a result, the loading screen could show for a while after submitting this form.'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // dropdowns
                selectedCohort: { required },
                pranName: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(64)
                }
            }
        }
    },
    mounted() {
        this.populateAddWorkspaceForm();
    },
    methods: {
        populateAddWorkspaceForm() {
            const loader = this.$loading.show();
            const component = this;
            // if (this.roles.isDataAnalyst) {
            //     getDACohortsPromise()
            //         .then((cohortsResp) => {
            //             loader.hide();
            //             const cohorts = createCohortsDict(cohortsResp.data.cohorts);
            //             component.cohortOptions = createDropdownOptions(cohorts);
            //         });               
            // // if the user is a Lead Data Analyst
            // } else {
            //     getLDACohortsPromise()
            //         .then((cohortsResp) => {
            //             loader.hide();
            //             const cohorts = createCohortsDict(cohortsResp.data.cohorts);
            //             component.cohortOptions = createDropdownOptions(cohorts);
            //         });
            // }

            // only allow cohorts to be selected that user has data analyst role for
            getDACohortsPromise() // specifies access_type=analyze
            .then((cohortsResp) => {
                        loader.hide();
                        const cohorts = createCohortsDict(cohortsResp.data.cohorts);
                        component.cohortOptions = createDropdownOptions(cohorts);
                    });     
        },
        populateWorkspacesUrl() {
            if (this.dataAnalystCohorts && this.dataAnalystCohorts.includes(this.form.selectedCohort)) {
                return this.daAddPrAnWorkspaceUrl;
            }
            else {
                console.error('Invalid role for PrAn Workspace table.');
                return undefined;
            }
        },
        addWorkspace() {
            const loader = this.$loading.show();
            const component = this;
            const addPrAnWorkspaceUrl = `${component.populateWorkspacesUrl()}`;
            const newWorkspaceInfo = {
                cohort_id: this.form.selectedCohort,
                name: this.form.pranName,
                description: this.form.notes === '' ? null : this.form.description,
                notes: this.form.notes === '' ? null : this.form.notes
            };
            axios.post(addPrAnWorkspaceUrl, newWorkspaceInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                });
        }
    }
};
</script>

<style>

</style>
