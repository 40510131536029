<template>
    <div>
        <h3>View Biospecimens</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Project name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>

                    <b-form-group label="Participant ID" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!cohortSelected" v-model="selectedParticipant" :options="participantOptions" />
                    </b-form-group>
                    <button @click="updateStatus">Update Sample Statues</button>
                    <b-form-group v-show="selectedParticipant" label="Analysis biospecimens only (no skip flag)" label-class="font-weight-bold">
                        <b-form-checkbox switch size="sm" v-model="analysisBioView" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="biospecimen-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="biospecimen-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import {
    createCohortsDict,
    createParticipantsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';
import getUserToken from '../utils/auth';
import store from '../store';

export default {
    props: {
        // these props are passed in after a biospecimen is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCohortId: { default: null, type: Number, required: false },
        // this is the participant_id i.e. the submitted name
        editedParticipantName: { default: null, type: String, required: false },
    },
    data() {
        const storeRoles = store.state.user.roles;
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // user roles
            roles: storeRoles, 

            // cohort specific role info
            readerCohorts: storeRoles.readerCohorts,
            dataEditorCohorts: storeRoles.dataEditorCohorts,
            dataAnalystCohorts: storeRoles.dataAnalystCohorts,
            projectLeadsCohorts: storeRoles.projectLeadCohorts,

            // checking for an edit
            biospecimenEdited: !!this.editedCohortId,

            // URLs used
            projectsUrl: getUrl('projectsUrl'),
            participantsUrl: getUrl('participantsUrl'),
            biospecimensUrl: getUrl('biospecimensUrl'),
            pdoStatusesUrl: getUrl('pdoStatusesUrl'),
            daBiospecimensUrl: getUrl('dataAnalystBiospecimensUrl'),

            // form view controls
            cohortSelected: false,

            // cohort dropdown selections
            selectedCohort: null,
            cohortOptions: [],

            // participant dropdown selections
            selectedParticipant: null,
            participantOptions: [],

            // expanded view type selection
            analysisBioView: false,

            // user is an appropriate for toggling analysis biospecimens
            editAnalysisBiospecimens: false,

            // table vars
            exportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26], // will export these column numbers
            tableColumns: [],
            sColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span edit-biospecimen">Edit</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span delete-biospecimen">Delete</span>';
                        }
                        return '';
                    }
                }
            ],
            sampleStatusColumns: ['biospecimen_id', 'collaborator_sample_id', 'experimental_strategy', 'submitted_status'],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]

        };
    },
    watch: {
        analysisBioView() {
            const component = this;
            this.getBiospecimensTable(component.selectedCohort);
        },
        selectedCohort() {
            // update participants dropdown when new cohort is selected
            if (this.selectedCohort === null) {
                this.selectedParticipant = null;
                this.participantOptions = [];
                this.cohortSelected = false;
                this.hideTable();
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            const participantsUrl = component.populateParticipantsUrl();
            axios.get(`${participantsUrl}?cohort_id=${this.selectedCohort}&primary_only=false`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    const nullOption = {
                        value: null,
                        text: 'Please select an option'
                    };
                    const allOption = {
                        value: -1,
                        text: 'All'
                    };
                    const pOptions = createDropdownOptions(pDict, true, false);
                    component.participantOptions = [nullOption, allOption].concat(pOptions);

                    component.cohortSelected = true;
                    // checking if a biospecimen was edited, and automatically showing biospecimen recprds for that participant
                    if (component.biospecimenEdited) {
                        component.biospecimenEdited = false; // setting this to false, so this isn't triggerred if another cohort is selected
                        const editedParticipant = component.participantOptions.filter(x => x.text === component.editedParticipantName)[0];
                        component.selectedParticipant = editedParticipant.value;
                    } else {
                        component.selectedParticipant = null;
                    }
                })
                .finally(() => {
                    loader.hide();
                });
        },
        selectedParticipant() {
            if (this.selectedParticipant === null) {
                this.hideTable();
                return;
            }
            // table must be reset if participant changes since number of columns may change based on permissions
            let table = $('#biospecimen-table');
            if ($.fn.DataTable.isDataTable(table)) {
                table.DataTable().clear().draw();
                table.DataTable().destroy();
                table.empty(); 
            }
            this.appropriateUser();
            this.populateTableColumns();
            this.getBiospecimensTable();
        }
    },
    beforeMount() {
        this.getCohorts();
    },
    beforeDestroy() {
        if ($.fn.dataTable.isDataTable('#biospecimen-table')) {
            let table = $('#biospecimen-table').DataTable();
            table.clear().draw();
            table.destroy();
        }
        this.hideTable()
    },
    methods: {
        populateTableColumns () {
            const component = this;
            this.tableColumns = [
                { title: 'DBKey', data: 'db_id' },
                { title: 'Collaborator Sample ID', data: 'collaborator_sample_id' },
                { title: 'Aliases', data: 'aliases' },
                { title: 'Participant ID', data: 'participant_id' },
                { title: 'IRB protocols', data: 'irb_protocols' },
                { title: 'Collection date (dfd)', data: 'collection_date_dfd' },
                { title: 'Tissue site', data: 'tissue_site' },
                { title: 'Tissue site detail', data: 'tissue_site_detail' },
                { title: 'Original material type', data: 'original_material_type' },
                { title: 'Submitted material type', data: 'submitted_material_type' },
                { title: 'Preservation method', data: 'preservation_method' },
                { title: 'Primary Site', data: 'primary_site' },
                { title: 'Tumor/Normal', data: 'tumor_normal' },
                { title: 'Tumor state', data: 'tumor_state' },
                { title: 'Associated samples', data: 'associated_samples' },
                { title: 'Sample Statuses', data: 'sample_statuses' },
                { title: 'GP Sample IDs', data: 'sm_ids'},
                { title: 'GP Sample ID', data: 'sm_id' },
                { title: 'GP Submitted Date', data: 'gp_submitted_date' },
                { title: 'RQS', data: 'rqs' },
                { title: 'DV200', data: 'dv200' },
                { title: 'Quality Designation', data: 'quality_value' },
                { title: 'Quality Description', data: 'quality_description' },
                { title: 'Notes', data: 'notes' },
                { title: 'Submitter', data: 'submitter' },
                { title: 'Post-Allogeneic Stem Cell Transplant', data: 'post_allogeneic_transplant' },
                { title: 'Primary Cohort', data: 'primary_cohort' },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                },
                {
                    title: 'Skip Biospecimen',
                    sortable: false,
                    render(data, type, row) {
                        const checkStatus = row.skip_flag;
                        const isDisabled = (
                            (component.dataAnalystCohorts && !component.dataAnalystCohorts.includes(component.selectedCohort)) ||
                            (component.dataEditorCohorts && !component.dataEditorCohorts.includes(component.selectedCohort))
                        );
                        return `<label class="skip-biospecimen-label">
                                    <input class="skip-biospecimen-checked" type="checkbox" ${checkStatus ? 'checked' : ''} ${isDisabled ? 'disabled' : ''}> Biospecimen skipped
                                </label>`;
                    }
                },
            ]
        },
        appropriateUser() {
            if ((this.dataAnalystCohorts && this.dataAnalystCohorts.includes(this.selectedCohort)) || (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort))) {
                this.editAnalysisBiospecimens = true;
            }
            else {
                this.editAnalysisBiospecimens = false;
            }
        },
        populateCohortsUrl() {
            // a user should be able to select any cohort they have read access to
            return `${this.projectsUrl}?access_type=read`;
        },
        populateParticipantsUrl() {
            return this.participantsUrl;
        },
        populateBiospecimensUrl() {
            if (this.analysisBioView) {
                return `${this.daBiospecimensUrl}`;
            }
            return this.biospecimensUrl;
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            const cohortsUrl = component.populateCohortsUrl();
            axios.get(cohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);

                    // will be true if a biospecimen was edited
                    if (component.biospecimenEdited) {
                        component.selectedCohort = component.editedCohortId;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        updateSkipBiospecimen(biospecimenId, skipFlag) {
            const component = this;
            const loader = component.$loading.show();

            const bioUrl = `${component.daBiospecimensUrl}?id=${biospecimenId}`;
            const skip_flag_info = {
                skip_flag: !skipFlag
            };
            axios.put(bioUrl, skip_flag_info, { headers: { Authorization: `Bearer ${getUserToken()}` } })
            .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getBiospecimensTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete biospecimen. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        deleteBiospecimen(cohortId, biospecimenId) {
            const component = this;
            const loader = component.$loading.show();
            const deleteUrl = `${component.biospecimensUrl}?id=${biospecimenId}&cohort_id=${cohortId}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getBiospecimensTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete biospecimen. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        addTableActions(datatable) {
            const component = this;
            if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                $('#biospecimen-table tbody .edit-biospecimen').click(function editBiospecimen() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const selectedCohortOption = component.cohortOptions.filter(x => x.value === component.selectedCohort)[0];
                    component.$router.push({
                        name: 'DataEditorEditBiospecimenPage',
                        params: {
                            cohortId: Number(selectedCohortOption.value),
                            cohortName: selectedCohortOption.text,
                            biospecimenId: Number(rowData.db_id)
                        }
                    });
                });
                $('#biospecimen-table tbody .delete-biospecimen').click(function confirmBiospecimenDelete() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete biospecimen ${rowData.collaborator_sample_id} for participant ${rowData.participant_id}?`;
                    
                    if(confirm(confirmMessage)){
                        component.deleteBiospecimen(component.selectedCohort, rowData.db_id);
                    }
                });
            }
            if (this.dataAnalystCohorts && this.dataAnalystCohorts.includes(this.selectedCohort)) {
                $('#biospecimen-table tbody .skip-biospecimen-checked').click(function toggleSkipBiospecimen() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    component.updateSkipBiospecimen(rowData.db_id, rowData.skip_flag);
                });
            }
        },
        getTableColumns() {
            let { tableColumns } = this;
            if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                tableColumns = tableColumns.concat(this.sColumns);
            }
            return tableColumns;
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            header = `${header}${'\tdelete_biospecimen'}`;
            return header;
        },
        hideTable() {
            $('#biospecimen-table thead').hide();
            $('#biospecimen-table-wrapper').hide();
        },
        showTable() {
            $('#biospecimen-table thead').show();
            $('#biospecimen-table-wrapper').show();
        },
        getBiospecimensTable() {
            const component = this;
            const loader = this.$loading.show();
            const getBiospecimensUrl = this.selectedParticipant === -1 ? `${this.populateBiospecimensUrl()}?cohort_id=${this.selectedCohort}&name_id_only=false` : `${this.populateBiospecimensUrl()}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}&name_id_only=false`;

            axios.get(getBiospecimensUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const { biospecimens } = resp.data;
                    biospecimens.forEach((biospecimen) => {
                        let joinedSampleStatuses = [];
                        const currBiospecimen = biospecimen;
                        let statusDisplay = null;
                        if(Object.keys(currBiospecimen.sample_statuses).length == 1 && Object.keys(currBiospecimen.sample_statuses)[0] == "Done"){
                            const date = currBiospecimen.sample_statuses["Done"].exp_date ? currBiospecimen.sample_statuses["Done"].exp_date : 'No delivery date'
                            statusDisplay = `${currBiospecimen.sample_statuses["Done"].product}: ${currBiospecimen.sample_statuses["Done"].status}, ${date}\n`; 
                            joinedSampleStatuses = joinedSampleStatuses.concat(statusDisplay);
                        }
                        else{
                            Object.keys(currBiospecimen.sample_statuses).forEach((sm) => {
                                if(sm !== "Done"){
                                    const date = currBiospecimen.sample_statuses[sm].exp_date ? currBiospecimen.sample_statuses[sm].exp_date : 'No est. delivery date'
                                    statusDisplay = `${currBiospecimen.sample_statuses[sm].product}: ${currBiospecimen.sample_statuses[sm].status}, ${date}\n`; 
                                    joinedSampleStatuses = joinedSampleStatuses.concat(statusDisplay);
                                }
                            });
                        }
                        currBiospecimen.sample_statuses = joinedSampleStatuses.join('\n');
                    });

                    const table = $('#biospecimen-table').DataTable({
                        destroy: true,
                        data: biospecimens,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        createdRow(row, data) {
                            if (data.quality_value === 'low quality') {
                                $(row).addClass('low-quality-row');
                            }
                        },
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#biospecimen',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-biospecimens`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#biospecimen'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Biospecimen Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-biospecimens`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#biospecimen'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Sample Status Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-sample_statuses`,
                                extension: '.txt',
                                exportOptions: {},
                                customize() {
                                    const headerRow = component.sampleStatusColumns.join('\t');
                                    const exportRows = ['#sample_status'].concat(headerRow);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .finally(() => loader.hide());
        },
        updateStatus(){
            const component = this;
            axios.post(this.pdoStatusesUrl, {}, { headers: { Authorization: `Bearer ${getUserToken()}` } })
            .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
        },
    }
};
</script>

<style>
.low-quality-row {
    color: red;
}
</style>
