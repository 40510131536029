<template>
    <div>
        <h3>Update Drug</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateDrug">
                <b-form-group label="Drug DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.drugId" disabled />
                </b-form-group>
                <b-form-group label="Drug name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.drugName.$model" :state="v$.form.drugName.$dirty ? !v$.form.drugName.$error : null" />
                </b-form-group>
                <b-form-group label="Drug category [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="categoryOptions" v-model="form.selectedCategory" />
                </b-form-group>
                <b-form-group label="Drug target [optional] " label-class="font-weight-bold">
                    <b-form-select size="sm" :options="targetOptions" v-model="form.selectedTarget" />
                </b-form-group>
                <b-form-group label="Mechanism [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.mechanism" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-form-group label="URL [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.url" />
                </b-form-group>
                <b-form-group label="Aliases (comma-separated list) [optional]" :description="requirements.aliases" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.aliases.$model" :state="v$.form.aliases.$dirty ? !v$.form.aliases.$error : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Drug
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import {
    getUrl, getDrugCategoriesPromise, getDrugTargetsPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createDrugCategoriesDict, createDrugTargetsDict
} from '../../utils/form-data-helpers';
import { drugAliasCheck } from '../../utils/input-regex';
import getUserToken from '../../utils/auth';

export default {
    props: {
        drugId: {
            type: Number,
            required: true
        },
        role: {
            type: String, required: true
        }
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            updateDrugUrl: getUrl('drugsUrl'),

            // options for form dropdowns
            categoryOptions: [],
            targetOptions: [],

            // form data
            form: {
                // disabled
                drugId: this.drugId,

                // dropdowns
                selectedCategory: null,
                selectedTarget: null,

                // inputs
                drugName: null,
                mechanism: '',
                notes: '',
                url: '',
                aliases: ''
            },

            // form requirements
            requirements: {
                aliases: 'Please input as a comma-separated list. Aliases must only contain alphanumeric characters and embedded spaces.'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return {
            form: {
                drugName: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(128)
                },
                aliases: {
                    minLength: minLength(2),
                    maxLength: maxLength(64),
                    drugAliasCheck
                }
            }
        }
    },
    mounted() {
        this.populateDrugEditForm();
    },
    methods: {
        getDrugInfo() {
            const url = `${this.updateDrugUrl}?id=${this.drugId}`;
            return axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateDrugEditForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getDrugCategoriesPromise(),
                getDrugTargetsPromise(),
                this.getDrugInfo()
            ])
                .then(axios.spread((
                    categoriesResp,
                    targetsResp,
                    drugResp,
                ) => {
                    const drugCategories = createDrugCategoriesDict(categoriesResp.data.drug_categories);
                    const drugTargets = createDrugTargetsDict(targetsResp.data.drug_targets);
                    component.categoryOptions = createDropdownOptions(drugCategories);
                    component.targetOptions = createDropdownOptions(drugTargets);

                    const drugInfo = drugResp.data.drugs[0];
                    component.form.drugName = drugInfo.name;
                    component.form.selectedCategory = drugInfo.drug_category !== null ? component.categoryOptions.filter(x => x.text === drugInfo.drug_category)[0].value : drugInfo.drug_category;
                    component.form.selectedTarget = drugInfo.drug_target !== null ? component.targetOptions.filter(x => x.text === drugInfo.drug_target)[0].value : drugInfo.drug_target;
                    component.form.mechanism = drugInfo.mechanism;
                    component.form.notes = drugInfo.notes;
                    component.form.url = drugInfo.url;
                    component.form.aliases = drugInfo.aliases;
                }))
                .finally(() => { loader.hide(); });
        },
        updateDrug() {
            const loader = this.$loading.show();
            const url = `${this.updateDrugUrl}?id=${this.drugId}`;
            const drugInfo = {
                name: this.form.drugName,
                drug_category_id: this.form.selectedCategory,
                drug_target_id: this.form.selectedTarget,
                mechanism: this.form.mechanism === '' ? null : this.form.mechanism,
                notes: this.form.notes === '' ? null : this.form.notes,
                url: this.form.url === '' ? null : this.form.url,
                submitted_aliases: this.form.aliases === '' ? null : this.form.aliases
            };
            const component = this;
            axios.put(url, drugInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'ViewDrugsPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            role: this.role
                        }
                    });
                })
                .catch((err) => {
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .then(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
