<template>
    <div>
        <h3>Add Clinical Update Record</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addClinicalUpdateRecord">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cohortOptions" v-model="v$.form.selectedCohort.$model" />
                </b-form-group>
                <b-form-group label="Participant ID" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="participantOptions" v-model="v$.form.selectedParticipant.$model" />
                </b-form-group>
                <b-form-group label="Clinical update category" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="clinUpdateCategoryOptions" v-model="v$.form.selectedClinUpdateCategory.$model" />
                </b-form-group>
                <b-form-group label="Clinical update date (days from diagnosis)" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.updateDateDfd.$model" :state="v$.form.updateDateDfd.$error ? false : null" />
                </b-form-group>
                <b-form-group label="Enable Cancer Staging System" label-class="font-weight-bold">
                    <input type="checkbox" @change="toggleCancerStagingSelected()" :checked="toggleCancerStaging">
                </b-form-group>
                <b-form-group label="Cancer Staging System [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="cancerStagingSystemsOptions" v-model="form.selectedCancerStagingSystem" :disabled="!toggleCancerStaging" />
                </b-form-group>
                <b-form-group label="Cancer Type [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="cancerTypeOptions" v-model="form.selectedCancerType" :disabled="!toggleCancerStaging" />
                </b-form-group>
                <b-form-group label="Cancer Stage [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="cancerStageOptions" v-model="form.selectedCancerStage" :disabled="!toggleCancerStaging" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Add Clinical Update Record
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    required,
    between
} from '@vuelidate/validators';
import {
    getUrl, getClinicalUpdateCategoriesPromise, getWriteProjectsPromise, getCancerStagingSystemsPromise
} from '../../utils/directory';
import {
    createDropdownOptions,
    createClinicalUpdateCategoriesDict,
    createCohortsDict,
    createParticipantsDict,
    createCancerStagingSystemsDict,
    createCancerTypesDict,
    createCancerStagesDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            participantUrl: getUrl('participantsUrl'),
            clinicalUpdateUrl: getUrl('clinicalUpdatesUrl'),
            cancerTypesUrl: getUrl('cancerTypesUrl'),
            cancerStagesUrl: getUrl('cancerStagesUrl'),

            // local variables
            toggleCancerStaging: true,
            // options for form dropdowns
            cohortOptions: [],
            participantOptions: [],
            clinUpdateCategoryOptions: [],
            cancerStagingSystemsOptions: [],
            cancerTypeOptions: [],
            cancerStageOptions: [],

            // form data
            form: {
                // dropdowns
                selectedCohort: null,
                selectedParticipant: null,
                selectedClinUpdateCategory: null,
                selectedCancerStagingSystem: null,
                selectedCancerType: null,
                selectedCancerStage: null,
                // inputs
                updateDateDfd: null,
                notes: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
        'form.selectedCohort': function () {
            // updates the participants dropdown when a new cohort is selected
            if (this.form.selectedCohort === null) {
                this.form.selectedParticipant = null;
                this.participantOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${this.participantUrl}?cohort_id=${this.form.selectedCohort}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    component.participantOptions = createDropdownOptions(pDict);
                })
                .finally(() => {
                    loader.hide();
                    component.form.selectedParticipant = null;
                });
        },
        'form.selectedCancerStagingSystem': function () {
            // updates the cancer type dropdown when a new staging system is selected
            if (this.form.selectedCancerStagingSystem === null) {
                this.form.selectedCancerType = null;
                this.cancerTypeOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${this.cancerTypesUrl}?staging_system_id=${this.form.selectedCancerStagingSystem}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((cancerTypeResp) => {
                    const cancerTypes = createCancerTypesDict(cancerTypeResp.data.cancer_types);
                    component.cancerTypeOptions = createDropdownOptions(cancerTypes);
                })
                .finally(() => {
                    loader.hide();
                    component.form.selectedCancerType = null;
                });
        },
        'form.selectedCancerType': function () {
            // updates the cancer type dropdown when a new staging system is selected
            if (this.form.selectedCancerType === null) {
                this.form.selectedCancerStage = null;
                this.cancerStageOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${this.cancerStagesUrl}?cancer_type_id=${this.form.selectedCancerType}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((cancerStageResp) => {
                    const cancerStages = createCancerStagesDict(cancerStageResp.data.cancer_stages);
                    component.cancerStageOptions = createDropdownOptions(cancerStages);
                })
                .finally(() => {
                    loader.hide();
                    component.form.selectedCancerStage = null;
                });
        }
    },
    validations() {
        return { 
            form: {
                // dropdowns
                selectedCohort: { required },
                selectedParticipant: { required },
                selectedClinUpdateCategory: { required },
                updateDateDfd: {
                    required,
                    between: between(-36500, 36500)
                }
            }
        }
    },
    mounted() {
        this.initClinUpdateForm();
    },
    methods: {
        toggleCancerStagingSelected() {
            const component = this;
            component.toggleCancerStaging = !component.toggleCancerStaging;
        },
        initClinUpdateForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getWriteProjectsPromise(),
                getClinicalUpdateCategoriesPromise(),
                getCancerStagingSystemsPromise()
            ])
                .then(axios.spread((cohortResp, updateCategoryResp, stagingSystemResp) => {
                    const cohorts = createCohortsDict(cohortResp.data.cohorts);
                    const clinicalUpdateCategories = createClinicalUpdateCategoriesDict(updateCategoryResp.data.clinical_update_categories);
                    const cancerStagingSystems = createCancerStagingSystemsDict(stagingSystemResp.data.cancer_staging_systems);

                    component.cohortOptions = createDropdownOptions(cohorts);
                    component.clinUpdateCategoryOptions = createDropdownOptions(clinicalUpdateCategories);
                    component.cancerStagingSystemsOptions = createDropdownOptions(cancerStagingSystems);
                }))
                .finally(() => { loader.hide(); });
        },
        addClinicalUpdateRecord() {
            const loader = this.$loading.show();
            const component = this;

            const newClinUpdateRecord = {
                cohort_id: this.form.selectedCohort,
                participant_id: this.form.selectedParticipant,
                cancer_stage_id: this.form.selectedCancerStage,
                update_category_id: this.form.selectedClinUpdateCategory,
                update_date_dfd: this.form.updateDateDfd,
                notes: this.form.notes
            };

            axios.post(this.clinicalUpdateUrl, newClinUpdateRecord, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
