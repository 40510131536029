<template>
    <div>
        <h3>View Delivery Workspaces</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="workspaces-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="workspaces-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    props: {
        // For when collaborators are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // urls
            sampleCoordWorkspacesUrl: getUrl('sampleCoordinatorDeliveryWorkspacesUrl'),

            // table vars
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Workspace Project', data: 'namespace' },
                { title: 'Workspace Name', data: 'name' },
                { title: 'Collaborator', data: 'collaborator' },
                { title: 'Experimental Strategy', data: 'experimental_strategy' },
                { title: 'Last Edited By', data: 'submitter' },
                {
                    title: 'Last Modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        if (time !== null) {
                            return moment(time).format('M/D/YY, H:mm A');
                        }
                        return 'N/A';
                    }
                },
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-workspace">Edit</span>';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span delete-workspace">Delete</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    watch: {
    },
    mounted() {
        this.populateWorkspacesTable();
    },
    methods: {
        populateWorkspacesTable() {
            const loader = this.$loading.show(); // loader while waiting for categories GET request
            const component = this;

            axios.get(component.sampleCoordWorkspacesUrl, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((workspaceResp) => {
                    loader.hide();
                    const workspaceTableData = workspaceResp.data.delivery_workspaces;
                    const table = $('#workspaces-table').DataTable({
                        destroy: true,
                        data: workspaceTableData,
                        columns: component.tableColumns,
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '70vh',
                        scrollCollapse: true
                    });
                    component.addTableActions(table);
                });
        },
        deleteDeliveryWorkspace(id) {
            const component = this;
            const loader = this.$loading.show();
            const deleteUrl = `${this.sampleCoordWorkspacesUrl}?id=${id}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.populateWorkspacesTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete delivery workspace. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        addTableActions(datatable) {
            const component = this;
            $('#workspaces-table tbody .edit-workspace').click(function editWorkspace() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.$router.push({
                    name: 'AdminEditDeliveryWorkspacePage',
                    params: {
                        workspaceId: rowData.id
                    }
                });
            });
            $('#workspaces-table tbody .delete-workspace').click(function deleteWorkspace() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to delete delivery workspace ${rowData.namespace}/${rowData.name}?`;
                if(confirm(confirmMessage)){
                    component.deleteDeliveryWorkspace(rowData.id);
                }
            });
        }
    }
};
</script>

<style>
#collaborators-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
