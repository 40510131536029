<template>
    <div>
        <h3>View Participants</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-form-select v-model="selectedCohort" class="col-lg-4 col-md-6 col-xs-12" :options="cohortOptions" />

        <b-form-group v-if="viewParticipantsWithoutMainNormal" v-show="selectedCohort" label="Participants without main normals" label-class="font-weight-bold">
            <b-form-checkbox switch size="sm" v-model="noMainNormalView" />
        </b-form-group>

        <br><br>

        <b-container fluid>
            <b-row id="participant-table-wrapper">
                <table id="participant-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import {
    createCohortsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';
import getUserToken from '../utils/auth';
import store from '../store';

export default {
    props: {
        // these props are passed in after a participant is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCohortId: { default: null, type: Number, required: false },
    },
    data() {
        const storeRoles = store.state.user.roles;
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // user roles
            roles: storeRoles,

            // cohort specific role info
            readerCohorts: storeRoles.readerCohorts,
            dataEditorCohorts: storeRoles.dataEditorCohorts,
            dataAnalystCohorts: storeRoles.dataAnalystCohorts,
            projectLeadsCohorts: storeRoles.projectLeadCohorts,

            // checking if a participant was edited
            participantEdited: !!this.editedCohortId,

            // URLs used
            projectsUrl: getUrl('projectsUrl'),
            participantsUrl: getUrl('participantsUrl'),
            dataAnalystReportParticipantsWithoutMainNormalUrl: getUrl('dataAnalystReportParticipantsWithoutMainNormalUrl'),

            // cohort selection dropdown
            selectedCohort: null,
            cohortOptions: [],

            // expanded view type selection
            noMainNormalView: false,

            // user is an appropriate for toggling participants without main normal
            viewParticipantsWithoutMainNormal: false,

            // table vars
            exportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21],
            participantTableColumns: [
                { title: 'DB Key', data: 'db_id' },
                { title: 'Collaborator Participant ID', data: 'participant_id' },
                { title: 'Aliases', data: 'aliases' },
                { title: 'Primary Site', data: 'tumor_primary_site' },
                { title: 'Morphology', data: 'tumor_morphology' },
                { title: 'Molecular Subtype', data: 'tumor_molecular_subtype' },
                {
                    title: 'Biospecimen Type Count',
                    sortable: false,
                    data: 'biospecimen_type_count',
                    render(data, type, row) {
                        // first index refers to tumor samples, second index refers to normal samples, and third to NA samples
                        if (row.primary_cohort === true && row.main_normal === null && data[1] > 1) {
                            return `<p style="color:red;">Tumor: ${data[0]}<br>Normal: ${data[1]}<br>PBMC: ${data[2]}<br>NA: ${data[3]}</p>`;
                        }
                        return `<p>Tumor: ${data[0]}<br>Normal: ${data[1]}<br>PBMC: ${data[2]}<br>NA: ${data[3]}</p>`;
                    }
                },
                {
                    title: 'Main Normal',
                    data: 'main_normal',
                    render(data, type, row) {
                        // if there are no associated samples display this red
                        if (row.main_normal_sample_count === 0 && data !== null) {
                            return `<p style="color:red;">${data}</p>`;
                        }
                        return data;
                    }
                },
                { title: 'Gender', data: 'gender' },
                { title: 'Race', data: 'race' },
                { title: 'Age at Diagnosis', data: 'age_at_diagnosis' },
                { title: 'Year of Diagnosis', data: 'year_of_diagnosis' },
                { title: 'Cancer Stage', data: 'cancer_stage' },
                { title: 'Vital Status', data: 'vital_status' },
                { title: 'Death date (dfd)', data: 'death_date_dfd' },
                { title: 'Biospecimen Total Count', data: 'biospecimen_total_count' },
                { title: 'Treatment Count', data: 'treatment_count' },
                { title: 'Last Follow-Up Date (dfd)', data: 'follow_up_date' },
                { title: 'Notes', data: 'notes' },
                { title: 'Submitter', data: 'submitter' },
                { title: 'Primary Cohort', data: 'primary_cohort' },
                { title: 'All Cohort(s)', data: 'all_cohorts' },
                {
                    title: 'Last Modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            sTableColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span edit-participant">Edit</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span delete-participant">Delete</span>';
                        }
                        return '<span class="text-btn-span remove-participant">Remove from cohort</span>';
                    }
                },
            ],
            daTableColumns: [
                {
                    title: 'Edit Main Normal',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span edit-main-normal">Edit</span>';
                        }
                        return '';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    beforeMount() {
        this.getCohorts();
        // this.appropriateUser();
    },
    beforeDestroy() {
        if ($.fn.dataTable.isDataTable('#participant-table')) {
            let table = $('#participant-table').DataTable();
            table.clear().draw();
            table.destroy();
        }
        this.hideTable();
    },
    watch: {
        selectedCohort(newCohort) {
            // table must be reset if cohort changes since number of columns may change based on permissions
            let table = $('#participant-table');
            if ($.fn.DataTable.isDataTable(table)) {
                table.DataTable().clear().draw();
                table.DataTable().destroy();
                table.empty(); 
            }
            this.appropriateUser();
            this.getParticipantsTable(newCohort);
        },
        noMainNormalView() {
            const component = this;
            this.getParticipantsTable(component.selectedCohort);
        }
    },
    methods: {
        appropriateUser() {
            if (this.dataAnalystCohorts && this.dataAnalystCohorts.includes(this.selectedCohort)) {
                this.viewParticipantsWithoutMainNormal = true;
            }
            else {
                this.viewParticipantsWithoutMainNormal = false;
            }
        },
        populateCohortsUrl() {
            // console.log('Roles:', this.roles)
            // if (this.roles.isDataEditor) {
            //     return `${this.projectsUrl}?=write`;
            // }else if (this.roles.isDataAnalyst){
            //     console.log('Data Analyst');
            //     return `${this.projectsUrl}?access_type=analyze`;
            // }else if (this.roles.isUser) {
            //     console.log('User');
            //     return this.projectsUrl;
            // } else {
            //     console.error('Invalid role');
            //     return undefined;
            // }

            // a user should be able to select any cohort they have read access to
            return `${this.projectsUrl}?access_type=read`;
        },
        populateParticipantsUrl() {
            if (this.noMainNormalView) {
                return this.dataAnalystReportParticipantsWithoutMainNormalUrl;
            }else {
                return this.participantsUrl;
            }
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            axios.get(component.populateCohortsUrl(), { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    console.log(this.populateCohortsUrl())
                    console.log(resp.data.cohorts)
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    console.log(cohorts)
                    component.cohortOptions = createDropdownOptions(cohorts);

                    // will have a value if a cohort was edited
                    if (component.participantEdited) {
                        component.participantEdited = false;
                        component.selectedCohort = component.editedCohortId;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        getTableColumns() {
            console.log(this.participantTableColumns);
            console.log(this.selectedCohort);
            let { participantTableColumns } = this;
            if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                participantTableColumns = participantTableColumns.concat(this.sTableColumns);
            }
            else if (this.dataAnalystCohorts && this.dataAnalystCohorts.includes(this.selectedCohort)) {
                participantTableColumns = participantTableColumns.concat(this.daTableColumns);
            }
            console.log(participantTableColumns);
            return participantTableColumns;
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.participantTableColumns[i];
                header = header.replace(col.title, col.data);
            });
            header = `${header}${'\tcohorts_to_add'}${'\tdelete_participant'}`;
            return header;
        },
        hideTable() {
            $('#participant-table thead').hide();
            $('#participant-table-wrapper').hide();
        },
        showTable() {
            $('#participant-table thead').show();
            $('#participant-table-wrapper').show();
        },
        getParticipantsTable(cohortId) {
            const component = this;
            const loader = this.$loading.show();
            const getParticipantsUrl = `${component.populateParticipantsUrl()}?cohort_id=${cohortId}&include_secondary_data=true&primary_only=false&name_id_only=false`;
            // getting participants info and creating table
            axios.get(getParticipantsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const { participants } = resp.data;
                    const table = $('#participant-table').DataTable({
                        destroy: true,
                        data: participants,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#participant',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-participants`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#participant'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-participants`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#participant'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        deleteParticipant(cohortId, participantId) {
            const component = this;
            const loader = component.$loading.show();
            const deleteParticipantUrl = `${component.populateParticipantsUrl()}?id=${participantId}&cohort_id=${cohortId}`;
            axios.delete(deleteParticipantUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getParticipantsTable(cohortId);
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete participant. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        removeParticipant(cohortId, participantId) {
            const component = this;
            const loader = component.$loading.show();
            const removeParticipantUrl = `${component.populateParticipantsUrl()}?id=${participantId}&cohort_id=${cohortId}&remove=true`;
            axios.delete(removeParticipantUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getParticipantsTable(cohortId);
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to remove participant. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        addTableActions(datatable) {
            const component = this;

            $('#participant-table tbody .edit-participant').click(function goToEditParticipantPage() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const selectedCohortOption = component.cohortOptions.filter(x => x.value === component.selectedCohort)[0];
                component.$router.push({
                    name: 'DataEditorEditParticipantPage',
                    params: {
                        cohortName: selectedCohortOption.text,
                        cohortId: selectedCohortOption.value,
                        participantId: rowData.db_id,
                    }
                });
            });

            $('#participant-table tbody .delete-participant').click(function confirmParticipantDelete() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to delete participant ${rowData.participant_id}?`;
                if(confirm(confirmMessage)){
                    component.deleteParticipant(component.selectedCohort, rowData.db_id);
                }
            });

            $('#participant-table tbody .remove-participant').click(function confirmParticipantRemove() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to remove participant ${rowData.participant_id}?`;
                const msgBoxOptions = {
                    hideHeader: true,
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Remove Participant',
                    centered: true
                };
                component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                    .then((removeConfirmed) => {
                        if (removeConfirmed) {
                            component.removeParticipant(component.selectedCohort, rowData.db_id);
                        }
                    });
            });

            $('#participant-table tbody .edit-main-normal').click(function goToEditMainNormalPage() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const selectedCohortOption = component.cohortOptions.filter(x => x.value === component.selectedCohort)[0];
                component.$router.push({
                    name: 'DataAnalystEditParticipantPage',
                    params: {
                        cohortName: selectedCohortOption.text,
                        cohortId: selectedCohortOption.value,
                        participantId: rowData.db_id,
                    }
                });
            });
        }
    }
};
</script>

<style>
.dt-buttons {
    float: none !important;
    margin-bottom: 10px;
}

</style>
