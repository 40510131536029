<template>
    <div>
        <h3>Add Treatment Record</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addTreatmentRecord">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cohortOptions" v-model="v$.form.selectedCohort.$model" />
                </b-form-group>
                <b-form-group label="Participant ID" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="participantOptions" v-model="v$.form.selectedParticipant.$model" />
                </b-form-group>
                <!-- <b-form-group label="Treatment categories" label-class="font-weight-bold">
                    <b-form-select multiple required size="sm" :options="categoryOptions" v-model="v$.form.selectedCategories.$model" />
                </b-form-group> -->
                <b-form-group label="Drug(s) [optional]" label-class="font-weight-bold">
                    <b-form-select multiple size="sm" :options="drugOptions" v-model="form.selectedDrugs" />
                </b-form-group>
                <b-form-group label="Drug Combination(s) [optional]" label-class="font-weight-bold">
                    <b-form-select multiple size="sm" :options="drugCombinationOptions" v-model="form.selectedDrugCombinations" />
                </b-form-group>
                <b-form-group label="Line Of Treatment [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="lineOfTreatmentOptions" v-model="form.selectedLineOfTreatment" />
                </b-form-group>
                <b-form-group label="Treatment regimen [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.regimen" />
                </b-form-group>
                <b-form-group label="Treatment start date (days from diagnosis)" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.startDateDfd.$model" :state="v$.form.startDateDfd.$error ? false : null" />
                </b-form-group>
                <b-form-group label="Treatment stop date (days from diagnosis)" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.stopDateDfd.$model" :state="v$.form.stopDateDfd.$error ? false : null" />
                </b-form-group>
                <b-form-group label="Treatment Stop Reason" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="stopReasonOptions" v-model="v$.form.selectedStopReason.$model" />
                </b-form-group>
                <b-form-group label="Pre-status [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.pre_status" />
                </b-form-group>
                <b-form-group label="Post-status [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.post_status" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Add Treatment Record
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    required,
    between
} from '@vuelidate/validators';
import {
    getUrl,
    getWriteProjectsPromise,
    getTreatmentStopReasonPromise,
    // getTreatmentCategoriesPromise,
    getDrugsPromise,
    getDrugCombinationsPromise
} from '../../utils/directory';
import {
    createDropdownOptions,
    createCohortsDict,
    createParticipantsDict,
    // createTreatmentCategoriesDict,
    createStopReasonDict,
    createDrugsDict,
    createDrugCombinationsDict,
    createLineOfTreatmentDisplayDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            participantUrl: getUrl('participantsUrl'),
            treatmentUrl: getUrl('treatmentsUrl'),
            linesUrl: getUrl('linesOfTreatmentUrl'),

            // options for form dropdowns
            cohortOptions: [],
            participantOptions: [],
            // categoryOptions: [],
            stopReasonOptions: [],
            drugOptions: [],
            drugCombinationOptions: [],
            lineOfTreatmentOptions: [],

            // form data
            form: {
                // dropdowns
                selectedCohort: null,
                selectedParticipant: null,
                // selectedCategories: [],
                selectedStopReason: null,
                selectedDrugs: [],
                selectedDrugCombinations: [],
                selectedLineOfTreatment: null,
                // inputs
                startDateDfd: null,
                stopDateDfd: null,
                regimen: '',
                pre_status: '',
                post_status: '',
                notes: ''
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
        'form.selectedCohort'() {
            // updates the participants dropdown when a new cohort is selected
            if (this.form.selectedCohort === null) {
                this.form.selectedParticipant = null;
                this.form.selectedLineOfTreatment = null;
                this.participantOptions = [];
                this.lineOfTreatmentOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.all([
                this.getParticipantsPromise(this.form.selectedCohort),
                this.getLinesOfTreatmentPromise(this.form.selectedCohort)
            ])
                .then(axios.spread((pResp, lResp) => {
                    const { participants } = pResp.data;
                    const linesOfTreatment = lResp.data.lines_of_treatment;

                    const pDict = createParticipantsDict(participants);
                    component.participantOptions = createDropdownOptions(pDict);

                    const lDict = createLineOfTreatmentDisplayDict(linesOfTreatment);
                    component.lineOfTreatmentOptions = createDropdownOptions(lDict);
                }))
                .finally(() => {
                    loader.hide();
                    component.form.selectedParticipant = null;
                    component.form.selectedLineOfTreatment = null;
                });
        }
    },
    validations() {
        return { 
            form: {
                // dropdowns
                selectedCohort: { required },
                selectedParticipant: { required },
                // selectedCategories: { required },
                selectedStopReason: { required },
                startDateDfd: {
                    between: between(-36500, 36500)
                },
                stopDateDfd: {
                    between: between(-36500, 36500)
                }
            }
        }
    },
    mounted() {
        this.initTreatmentForm();
    },
    methods: {
        getParticipantsPromise(cohortId) {
            return axios.get(`${this.participantUrl}?cohort_id=${cohortId}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            });
        },
        getLinesOfTreatmentPromise(cohortId) {
            return axios.get(`${this.linesUrl}?cohort_id=${cohortId}`, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        initTreatmentForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getWriteProjectsPromise(),
                getTreatmentStopReasonPromise(),
                // getTreatmentCategoriesPromise(),
                getDrugsPromise(),
                getDrugCombinationsPromise()
            ])
                .then(axios.spread((cohortResp, stopReasonResp, drugsResp, drugCombinationsResp) => {
                    const cohorts = createCohortsDict(cohortResp.data.cohorts);
                    const stopReasons = createStopReasonDict(stopReasonResp.data.stop_reasons);
                    // const treatmentCategories = createTreatmentCategoriesDict(categoryResp.data.treatment_categories);
                    const drugs = createDrugsDict(drugsResp.data.drugs);
                    const drugCombinations = createDrugCombinationsDict(drugCombinationsResp.data.drug_combinations);

                    component.cohortOptions = createDropdownOptions(cohorts);
                    component.stopReasonOptions = createDropdownOptions(stopReasons);
                    // component.categoryOptions = createDropdownOptions(treatmentCategories, true, false);
                    component.drugOptions = createDropdownOptions(drugs, true, false);
                    component.drugCombinationOptions = createDropdownOptions(drugCombinations);
                }))
                .finally(() => { loader.hide(); });
        },
        addTreatmentRecord() {
            const loader = this.$loading.show();
            const component = this;

            const newTreatmentRecord = {
                cohort_id: this.form.selectedCohort,
                participant_id: this.form.selectedParticipant,
                // treatment_categories: this.form.selectedCategories,
                drugs: this.form.selectedDrugs,
                drug_combinations: this.form.selectedDrugCombinations,
                line_of_treatment: this.form.selectedLineOfTreatment,
                regimen: this.form.regimen === '' ? null : this.form.regimen,
                start_date: this.form.startDateDfd === '' ? null : this.form.startDateDfd,
                stop_date: this.form.stopDateDfd === '' ? null : this.form.stopDateDfd,
                stop_reason: this.form.selectedStopReason,
                pre_status: this.form.pre_status === '' ? null : this.form.pre_status,
                post_status: this.form.post_status === '' ? null : this.form.post_status,
                notes: this.form.notes === '' ? null : this.form.notes
            };

            axios.post(this.treatmentUrl, newTreatmentRecord, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>