// import Vue from 'vue';
// import Router from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './views/HomePage.vue';
import ErrorPage from './views/ErrorPage.vue';
import SwimmerPlotPage from './views/SwimmerPlotPage.vue';
import ViewProjectsPage from './views/ViewProjectsPage.vue';
import ViewDiagnosticTestCategoriesPage from './views/ViewDiagnosticTestCategoriesPage.vue';
import ViewClinicalUpdateCategoriesPage from './views/ViewClinicalUpdateCategoriesPage.vue';
import ViewDiagnosticTestsPage from './views/ViewDiagnosticTestsPage.vue';
import ViewClinicalUpdatesPage from './views/ViewClinicalUpdatesPage.vue';
import ViewSamplesPage from './views/ViewSamplesPage.vue';
import ViewBiospecimensPage from './views/ViewBiospecimensPage.vue';
import UnsequencedBiospecimensPage from './views/ReportUnsequencedBiospecimensPage.vue';
import ViewCohortSyncResults from './components/ViewCohortSyncResults.vue';
import ViewCollaboratorSyncResults from './components/ViewCollaboratorSyncResults.vue';
import ViewAllSampleSyncResults from './components/ViewAllSampleSyncResults.vue';
import ViewPrAnSampleStatusReport from './components/ViewPrAnSampleStatusReport.vue';
import ViewParticipantsPage from './views/ViewParticipantsPage.vue';
import ViewTreatmentsPage from './views/ViewTreatmentsPage.vue';
import ViewDrugsPage from './views/ViewDrugsPage.vue';
import ViewDrugCombinationsPage from './views/ViewDrugCombinationsPage.vue';
import ViewUsersPage from './views/ViewUsersPage.vue';
import ViewPrAnWorkspacesPage from './views/ViewPrAnWorkspacesPage.vue';
import ViewBaitSetsPage from './views/ViewBaitSetsPage.vue';
import ViewMethodConfigurationsPage from './views/ViewMethodConfigurationsPage.vue';
import ReportCollidingSamplesPage from './views/ReportCollidingSamplesPage.vue';
import ViewPrAnWorkspaceAttributesPage from './views/ViewPrAnWorkspaceAttributesPage.vue';
import ReportSampleTrackingPage from './views/ReportSampleTrackingPage.vue';
import ViewCancerStagingSystemsPage from './views/ViewCancerStagingSystemsPage.vue';
import ViewCancerTypesPage from './views/ViewCancerTypesPage.vue';
import ViewCancerStagesPage from './views/ViewCancerStagesPage.vue';
import ViewLinesOfTreatmentPage from './views/ViewLinesOfTreatmentPage.vue';
import ViewLatestEntityUploadTemplatePage from './views/ViewLatestEntityUploadTemplatePage.vue';
import ViewManifestsPage from './views/ViewManifestsPage.vue';
import UserHeaderComponent from './components/UserHeaderComponent.vue';
// administrator related components
import AdminHeaderComponent from './components/AdminHeaderComponent.vue';
import AdminCreateProjectPage from './views/admin_views/AdminCreateProjectPage.vue';
import AdminEditDrugPage from './views/admin_views/AdminEditDrugPage.vue';
import AdminAddDrugPage from './views/admin_views/AdminAddDrugPage.vue';
import AdminViewDrugCategoriesPage from './views/admin_views/AdminViewDrugCategoriesPage.vue';
import AdminAddDrugCategoryPage from './views/admin_views/AdminAddDrugCategoryPage.vue';
import AdminAddDrugCombinationPage from './views/admin_views/AdminAddDrugCombinationPage.vue';
import AdminEditDrugCategoryPage from './views/admin_views/AdminEditDrugCategoryPage.vue';
import AdminEditDrugCombinationPage from './views/admin_views/AdminEditDrugCombinationPage.vue';
import AdminViewDeliveryWorkspacesPage from './views/admin_views/AdminViewDeliveryWorkspacesPage.vue';
import AdminEditDeliveryWorkspacePage from './views/admin_views/AdminEditDeliveryWorkspacePage.vue';
import AdminAddDeliveryWorkspacePage from './views/admin_views/AdminAddDeliveryWorkspacePage.vue';
import AdminEditCancerStagingSystemPage from './views/admin_views/AdminEditCancerStagingSystemPage.vue';
import AdminAddCancerStagingSystemPage from './views/admin_views/AdminAddCancerStagingSystemPage.vue';
import AdminEditCancerTypePage from './views/admin_views/AdminEditCancerTypePage.vue';
import AdminAddCancerTypePage from './views/admin_views/AdminAddCancerTypePage.vue';
import AdminEditCancerStagePage from './views/admin_views/AdminEditCancerStagePage.vue';
import AdminAddCancerStagePage from './views/admin_views/AdminAddCancerStagePage.vue';
import AdminAddClinicalUpdateCategoryPage from './views/admin_views/AdminAddClinicalUpdateCategoryPage.vue';
import AdminEditClinicalUpdateCategoryPage from './views/admin_views/AdminEditClinicalUpdateCategoryPage.vue';
// project lead related components
import ProjectLeadHeaderComponent from './components/ProjectLeadHeaderComponent.vue';
import ProjectLeadEditProjectPage from './views/project_lead_views/ProjectLeadEditProjectPage.vue';
// sample coordinator related components
import SampleCoordinatorHeaderComponent from './components/SampleCoordinatorHeaderComponent.vue';
import SampleCoordinatorCreateCohortPage from './views/sample_coord_views/SampleCoordinatorCreateCohortPage.vue';
import SampleCoordinatorEditCohortPage from './views/sample_coord_views/SampleCoordinatorEditCohortPage.vue';
import SampleCoordinatorAddDiagnosticTestCategoryPage from './views/sample_coord_views/SampleCoordinatorAddDiagnosticTestCategoryPage.vue';
import SampleCoordinatorEditDiagnosticTestCategoryPage from './views/sample_coord_views/SampleCoordinatorEditDiagnosticTestCategoryPage.vue';
import SampleCoordinatorAddClinicalUpdateCategoryPage from './views/sample_coord_views/SampleCoordinatorAddClinicalUpdateCategoryPage.vue';
import SampleCoordinatorEditClinicalUpdateCategoryPage from './views/sample_coord_views/SampleCoordinatorEditClinicalUpdateCategoryPage.vue';
import SampleCoordinatorAddCollaboratorPage from './views/sample_coord_views/SampleCoordinatorAddCollaboratorPage.vue';
import SampleCoordinatorViewCollaboratorsPage from './views/sample_coord_views/SampleCoordinatorViewCollaboratorsPage.vue';
import SampleCoordinatorEditCollaboratorPage from './views/sample_coord_views/SampleCoordinatorEditCollaboratorPage.vue';
import SampleCoordinatorAddDeliveryWorkspacePage from './views/sample_coord_views/SampleCoordinatorAddDeliveryWorkspacePage.vue';
import SampleCoordinatorViewDeliveryWorkspacesPage from './views/sample_coord_views/SampleCoordinatorViewDeliveryWorkspacesPage.vue';
import SampleCoordinatorEditDeliveryWorkspacePage from './views/sample_coord_views/SampleCoordinatorEditDeliveryWorkspacePage.vue';
import SampleCoordinatorAddIRBProtocolPage from './views/sample_coord_views/SampleCoordinatorAddIRBProtocolPage.vue';
import SampleCoordinatorViewIRBProtocolsPage from './views/sample_coord_views/SampleCoordinatorViewIRBProtocolsPage.vue';
import SampleCoordinatorEditIRBProtocolPage from './views/sample_coord_views/SampleCoordinatorEditIRBProtocolPage.vue';
import SampleCoordinatorReportCohortSampleStatsPage from './views/sample_coord_views/SampleCoordinatorReportCohortSampleStatsPage.vue';
import SampleCoordinatorAddDrugCategoryPage from './views/sample_coord_views/SampleCoordinatorAddDrugCategoryPage.vue';
import SampleCoordinatorEditDrugCategoryPage from './views/sample_coord_views/SampleCoordinatorEditDrugCategoryPage.vue';
import SampleCoordinatorViewDrugTargetsPage from './views/sample_coord_views/SampleCoordinatorViewDrugTargetsPage.vue';
import SampleCoordinatorAddDrugTargetPage from './views/sample_coord_views/SampleCoordinatorAddDrugTargetPage.vue';
import SampleCoordinatorEditDrugTargetPage from './views/sample_coord_views/SampleCoordinatorEditDrugTargetPage.vue';
import SampleCoordinatorAddDrugCombinationPage from './views/sample_coord_views/SampleCoordinatorAddDrugCombinationPage.vue';
import SampleCoordinatorEditDrugCombinationPage from './views/sample_coord_views/SampleCoordinatorEditDrugCombinationPage.vue';
import SampleCoordinatorEditBaitSetPage from './views/sample_coord_views/SampleCoordinatorEditBaitSetPage.vue';
import SampleCoordinatorAddBaitSetPage from './views/sample_coord_views/SampleCoordinatorAddBaitSetPage.vue';
import SampleCoordinatorEditCancerStagingSystemPage from './views/sample_coord_views/SampleCoordinatorEditCancerStagingSystemPage.vue';
import SampleCoordinatorAddCancerStagingSystemPage from './views/sample_coord_views/SampleCoordinatorAddCancerStagingSystemPage.vue';
import SampleCoordinatorEditCancerTypePage from './views/sample_coord_views/SampleCoordinatorEditCancerTypePage.vue';
import SampleCoordinatorAddCancerTypePage from './views/sample_coord_views/SampleCoordinatorAddCancerTypePage.vue';
import SampleCoordinatorEditCancerStagePage from './views/sample_coord_views/SampleCoordinatorEditCancerStagePage.vue';
import SampleCoordinatorAddCancerStagePage from './views/sample_coord_views/SampleCoordinatorAddCancerStagePage.vue';
// data editor related components
import DataEditorHeaderComponent from './components/DataEditorHeaderComponent.vue';
import DataEditorBulkLoadPage from './views/data_editor_views/DataEditorBulkLoadPage.vue';
import DataEditorAddParticipantPage from './views/data_editor_views/DataEditorAddParticipantPage.vue';
import DataEditorEditParticipantPage from './views/data_editor_views/DataEditorEditParticipantPage.vue';
import DataEditorAddBiospecimenPage from './views/data_editor_views/DataEditorAddBiospecimenPage.vue';
import DataEditorEditBiospecimenPage from './views/data_editor_views/DataEditorEditBiospecimenPage.vue';
import DataEditorAddClinicalUpdatePage from './views/data_editor_views/DataEditorAddClinicalUpdatePage.vue';
import DataEditorEditClinicalUpdatePage from './views/data_editor_views/DataEditorEditClinicalUpdatePage.vue';
import SubmitterAddDiagnosticTestPage from './views/submitter_views/SubmitterAddDiagnosticTestPage.vue';
import SubmitterEditDiagnosticTestPage from './views/submitter_views/SubmitterEditDiagnosticTestPage.vue';
import DataEditorAddTreatmentPage from './views/data_editor_views/DataEditorAddTreatmentPage.vue';
import DataEditorEditTreatmentPage from './views/data_editor_views/DataEditorEditTreatmentPage.vue';
import DataEditorBSPUploadPage from './views/data_editor_views/DataEditorBSPUploadPage.vue';
import SubmitterAddLineOfTreatmentPage from './views/submitter_views/SubmitterAddLineOfTreatmentPage.vue';
import SubmitterEditLineOfTreatmentPage from './views/submitter_views/SubmitterEditLineOfTreatmentPage.vue';
import DataEditorAddManifestPage from './views/data_editor_views/DataEditorAddManifestPage.vue';
// data analyst related components
import DataAnalystHeaderComponent from './components/DataAnalystHeaderComponent.vue';
import DataAnalystAddPrAnWorkspacePage from './views/data_analyst_views/DataAnalystAddPrAnWorkspacePage.vue';
import DataAnalystEditParticipantPage from './views/data_analyst_views/DataAnalystEditParticipantPage.vue';
// import DataAnalystEditBiospecimenPage from './views/data_analyst_views/DataAnalystEditBiospecimenPage.vue';
// lead data analyst related components
import LeadDataAnalystHeaderComponent from './components/LeadDataAnalystHeaderComponent.vue';
import LeadDataAnalystAddPrAnWorkspacePage from './views/lead_data_analyst_views/LeadDataAnalystAddPrAnWorkspacePage.vue';
import LeadDataAnalystEditParticipantPage from './views/data_analyst_views/DataAnalystEditParticipantPage.vue';
import LeadDataAnalystAddMethodConfigurationPage from './views/lead_data_analyst_views/LeadDataAnalystAddMethodConfigurationPage.vue';
import LeadDataAnalystEditMethodConfigurationPage from './views/lead_data_analyst_views/LeadDataAnalystEditMethodConfigurationPage.vue';
import LeadDataAnalystEditWorkspaceAttributesPage from './views/lead_data_analyst_views/LeadDataAnalystEditPrAnWorkspaceAttributesPage.vue';
import LeadDataAnalystAddWorkspaceAttributesPage from './views/lead_data_analyst_views/LeadDataAnalystAddPrAnWorkspaceAttributesPage.vue';
import LeadDataAnalystWorkspaceAttributeUploadPage from './views/lead_data_analyst_views/LeadDataAnalystWorkspaceAttributeUploadPage.vue';
import LeadDataAnalystViewPrAnSnapshotsPage from './views/lead_data_analyst_views/LeadDataAnalystViewPrAnSnapshotsPage.vue';
import LeadDataAnalystReleasePrAnWorkspacePage from './views/lead_data_analyst_views/LeadDataAnalystReleasePrAnWorkspacePage.vue';
// lead data analyst related components
import UberLeadDataAnalystHeaderComponent from './components/UberLeadDataAnalystHeaderComponent.vue';

// Vue.use(Router);

// export default new Router({
//     mode: 'history',
//     base: process.env.BASE_URL,
const routes = [
        {
            path: '/',
            props: true,
            name: 'HomePage',
            component: HomePage
        },
        {
            path: '/swimmer_plot',
            name: 'SwimmerPlotPage',
            component: SwimmerPlotPage
        },
        {
            path: '/error',
            props: true,
            name: 'ErrorPage',
            component: ErrorPage
        },
        /* All Admin views */
        {
            path: '/admin',
            component: AdminHeaderComponent,
            children: [
                {
                    path: 'users',
                    props: true,
                    name: 'AdminViewUsersPage',
                    component: ViewUsersPage
                },
                {
                    path: 'drug/edit/:drugId',
                    props: true,
                    name: 'AdminEditDrugPage',
                    component: AdminEditDrugPage
                },
                // Drug related pages
                {
                    path: 'drug/view',
                    props: true,
                    name: 'ViewDrugsPage',
                    component: ViewDrugsPage
                },
                {
                    path: 'drug/add',
                    name: 'AdminAddDrugPage',
                    component: AdminAddDrugPage
                },
                // Drug combination related pages
                {
                    path: 'drug_combination/add',
                    name: 'AdminAddDrugCombinationPage',
                    component: AdminAddDrugCombinationPage
                },
                {
                    path: 'drug_combination/view',
                    props: true,
                    name: 'ViewDrugCombinationsPage',
                    component: ViewDrugCombinationsPage
                },
                {
                    path: 'drug_combination/edit/:drugCombinationId',
                    props: true,
                    name: 'AdminEditDrugCombinationPage',
                    component: AdminEditDrugCombinationPage
                },
                // Drug Categories related pages
                {
                    path: 'drug_category/add',
                    props: true,
                    name: 'AdminAddDrugCategoryPage',
                    component: AdminAddDrugCategoryPage
                },
                {
                    path: 'drug_category/view',
                    props: true,
                    name: 'AdminViewDrugCategoriesPage',
                    component: AdminViewDrugCategoriesPage
                },
                {
                    path: 'drug_category/edit/:categoryId',
                    props: true,
                    name: 'AdminEditDrugCategoryPage',
                    component: AdminEditDrugCategoryPage
                },
                // Delivery Workspaces
                {
                    path: 'delivery_workspace/view',
                    props: true,
                    name: 'AdminViewDeliveryWorkspacesPage',
                    component: AdminViewDeliveryWorkspacesPage
                },
                {
                    path: 'delivery_workspace/edit/:workspaceId',
                    props: true,
                    name: 'AdminEditDeliveryWorkspacePage',
                    component: AdminEditDeliveryWorkspacePage
                },
                {
                    path: 'delivery_workspace/add',
                    props: true,
                    name: 'AdminAddDeliveryWorkspacePage',
                    component: AdminAddDeliveryWorkspacePage
                },
                // Cancer Staging Systems
                {
                    path: 'cancer_staging_system/view',
                    props: true,
                    name: 'AdminViewCancerStagingSystemsPage',
                    component: ViewCancerStagingSystemsPage
                },
                {
                    path: 'cancer_staging_system/add',
                    props: true,
                    name: 'AdminAddCancerStagingSystemPage',
                    component: AdminAddCancerStagingSystemPage
                },
                {
                    path: 'cancer_staging_system/edit/:stagingSystemId',
                    props: true,
                    name: 'AdminEditCancerStagingSystemPage',
                    component: AdminEditCancerStagingSystemPage
                },
                // Cancer Types
                {
                    path: 'cancer_type/view',
                    props: true,
                    name: 'AdminViewCancerTypesPage',
                    component: ViewCancerTypesPage
                },
                {
                    path: 'cancer_type/add',
                    props: true,
                    name: 'AdminAddCancerTypePage',
                    component: AdminAddCancerTypePage
                },
                {
                    path: 'cancer_type/edit/:cancerStagingSystemName/:cancerStagingSystemId/:cancerTypeId',
                    props: true,
                    name: 'AdminEditCancerTypePage',
                    component: AdminEditCancerTypePage
                },
                // Cancer Stages
                {
                    path: 'cancer_stage/view',
                    props: true,
                    name: 'AdminViewCancerStagesPage',
                    component: ViewCancerStagesPage
                },
                {
                    path: 'cancer_stage/add',
                    props: true,
                    name: 'AdminAddCancerStagePage',
                    component: AdminAddCancerStagePage
                },
                {
                    path: 'cancer_stage/edit/:cancerTypeId/:cancerTypeName/:cancerStagingSystemName/:cancerStageId',
                    props: true,
                    name: 'AdminEditCancerStagePage',
                    component: AdminEditCancerStagePage
                },
                // Clinical update related pages
                {
                    path: 'clinical_update_category/add',
                    name: 'AdminAddClinicalUpdateCategoryPage',
                    component: AdminAddClinicalUpdateCategoryPage
                },
                {
                    path: 'clinical_update_categories/view',
                    props: true,
                    name: 'AdminViewClinicalUpdateCategoriesPage',
                    component: ViewClinicalUpdateCategoriesPage
                },
                {
                    path: 'clinical_update_categories/edit/:updateCategoryId',
                    props: true,
                    name: 'AdminEditClinicalUpdateCategoryPage',
                    component: AdminEditClinicalUpdateCategoryPage
                },
            ]
        },
        {
            path: '/admin/project/create',
            props: true,
            name: 'AdminCreateProjectPage',
            component: AdminCreateProjectPage
        },
        {
            /* Nesting all User views in this section */
            path: '/user',
            component: UserHeaderComponent,
            children: [
                {
                    path: 'projects',
                    props: true,
                    name: 'UserViewProjectsPage',
                    component: ViewProjectsPage
                },
                {
                    path: 'participants',
                    props: true,
                    name: 'UserViewParticipantsPage',
                    component: ViewParticipantsPage
                },
                {
                    path: 'biospecimens',
                    props: true,
                    name: 'UserViewBiospecimensPage',
                    component: ViewBiospecimensPage
                },
                {
                    path: 'treatments',
                    props: true,
                    name: 'UserViewTreatmentsPage',
                    component: ViewTreatmentsPage
                },
                {
                    path: 'samples',
                    props: true,
                    name: 'UserViewSamplesPage',
                    component: ViewSamplesPage
                },
                {
                    path: 'clinical_updates',
                    props: true, // cohortId is an expected prop in this component
                    name: 'UserViewClinicalUpdatesPage',
                    component: ViewClinicalUpdatesPage
                },
            ]
        },
        {
            /* Nesting all Sample Coordinator views in this section */
            path: '/sample_coordinator',
            component: SampleCoordinatorHeaderComponent,
            children: [
                {
                    path: 'users',
                    props: true,
                    name: 'SampleCoordinatorViewUsersPage',
                    component: ViewUsersPage
                },
                // Cohort related pages
                {
                    path: 'cohort/create',
                    props: true,
                    name: 'SampleCoordinatorCreateCohortPage',
                    component: SampleCoordinatorCreateCohortPage
                },
                {
                    path: 'cohort/view',
                    props: true,
                    name: 'SampleCoordinatorViewCohortPage',
                    component: ViewProjectsPage
                },
                {
                    path: 'cohort/edit/:cohortId',
                    props: true, // cohortId is an expected prop in this component
                    name: 'SampleCoordinatorEditCohortPage',
                    component: SampleCoordinatorEditCohortPage
                },
                {
                    path: 'cohort/pran_sample_status',
                    props: true,
                    name: 'SampleCoordinatorReportPrAnSampleStatusPage',
                    component: ViewPrAnSampleStatusReport
                },
                /* Clinical update related pages */
                {
                    path: 'clinical_update_category/add',
                    name: 'SampleCoordinatorAddClinicalUpdateCategoryPage',
                    component: SampleCoordinatorAddClinicalUpdateCategoryPage
                },
                {
                    path: 'clinical_update_categories/view',
                    props: true,
                    name: 'SampleCoordinatorViewClinicalUpdateCategoriesPage',
                    component: ViewClinicalUpdateCategoriesPage
                },
                {
                    path: 'clinical_update_categories/edit',
                    props: true,
                    name: 'SampleCoordinatorEditClinicalUpdateCategoryPage',
                    component: SampleCoordinatorEditClinicalUpdateCategoryPage
                },
                {
                    path: 'clinical_update/view',
                    props: true,
                    name: 'SampleCoordinatorViewClinicalUpdatesPage',
                    component: ViewClinicalUpdatesPage
                },
                // Diagnostic Test related pages
                {
                    path: 'diagnostic_test_category/add',
                    name: 'SampleCoordinatorAddDiagnosticTestCategoryPage',
                    component: SampleCoordinatorAddDiagnosticTestCategoryPage
                },
                {
                    path: 'diagnostic_test_categories/view',
                    props: true,
                    name: 'SampleCoordinatorViewDiagnosticTestCategoriesPage',
                    component: ViewDiagnosticTestCategoriesPage
                },
                {
                    path: 'diagnostic_test_categories/edit',
                    props: true,
                    name: 'SampleCoordinatorEditDiagnosticTestCategoryPage',
                    component: SampleCoordinatorEditDiagnosticTestCategoryPage
                },
                {
                    path: 'diagnostic_test/view',
                    props: true,
                    name: 'SampleCoordinatorViewDiagnosticTestsPage',
                    component: ViewDiagnosticTestsPage
                },
                // Collaborator related pages
                {
                    path: 'collaborator/add',
                    name: 'SampleCoordinatorAddCollaboratorPage',
                    component: SampleCoordinatorAddCollaboratorPage
                },
                {
                    path: 'collaborator/view',
                    props: true,
                    name: 'SampleCoordinatorViewCollaboratorsPage',
                    component: SampleCoordinatorViewCollaboratorsPage
                },
                {
                    path: 'collaborator/edit/:collabId',
                    props: true,
                    name: 'SampleCoordinatorEditCollaboratorPage',
                    component: SampleCoordinatorEditCollaboratorPage
                },
                // Delivery workspace related pages
                {
                    path: 'delivery_workspace/add',
                    name: 'SampleCoordinatorAddDeliveryWorkspacePage',
                    component: SampleCoordinatorAddDeliveryWorkspacePage
                },
                {
                    path: 'delivery_workspace/view',
                    props: true,
                    name: 'SampleCoordinatorViewDeliveryWorkspacesPage',
                    component: SampleCoordinatorViewDeliveryWorkspacesPage
                },
                {
                    path: 'collaborator/edit/:workspaceId',
                    props: true,
                    name: 'SampleCoordinatorEditDeliveryWorkspacePage',
                    component: SampleCoordinatorEditDeliveryWorkspacePage
                },
                // IRB Protocol related pages
                {
                    path: 'irb_protocol/add',
                    name: 'SampleCoordinatorAddIRBProtocolPage',
                    component: SampleCoordinatorAddIRBProtocolPage
                },
                {
                    path: 'irb_protocol/view',
                    props: true,
                    name: 'SampleCoordinatorViewIRBProtocolsPage',
                    component: SampleCoordinatorViewIRBProtocolsPage
                },
                {
                    path: 'irb_protocol/edit/:protocolId',
                    props: true,
                    name: 'SampleCoordinatorEditIRBProtocolPage',
                    component: SampleCoordinatorEditIRBProtocolPage
                },
                // Generate Reports pages
                {
                    path: 'report/cohort_sample_stats',
                    props: true,
                    name: 'SampleCoordinatorReportCohortSampleStatsPage',
                    component: SampleCoordinatorReportCohortSampleStatsPage
                },
                {
                    path: 'report/unsequenced_biospecimens',
                    props: true,
                    name: 'SampleCoordinatorUnsequencedBiospecimensPage',
                    component: UnsequencedBiospecimensPage
                },
                {
                    path: 'report/colliding_samples',
                    props: true,
                    name: 'SampleCoordinatorReportCollidingSamplePage',
                    component: ReportCollidingSamplesPage
                },
                {
                    path: 'report/sample_tracking',
                    props: true,
                    name: 'SampleCoordinatorReportSampleTrackingPage',
                    component: ReportSampleTrackingPage
                },
                // Sample related pages
                {
                    path: 'sample/view',
                    props: true,
                    name: 'SampleCoordinatorViewSamplesPage',
                    component: ViewSamplesPage
                },
                {
                    path: 'sample/sync/:cohortId',
                    props: true,
                    name: 'SyncCohortPage',
                    component: ViewCohortSyncResults
                },
                {
                    path: 'sample/sync/:collaboratorId',
                    props: true,
                    name: 'SampleCoordinatorSyncCollaboratorPage',
                    component: ViewCollaboratorSyncResults
                },
                {
                    path: 'report/sample_sync_report',
                    props: true,
                    name: 'AllSampleSyncResultsPage',
                    component: ViewAllSampleSyncResults
                },
                // Biospecimen related pages
                {
                    path: 'biospecimen/view',
                    props: true,
                    name: 'SampleCoordinatorViewBiospecimensPage',
                    component: ViewBiospecimensPage
                },
                // Participant related pages
                {
                    path: 'participant/view',
                    props: true,
                    name: 'SampleCoordinatorViewParticipantsPage',
                    component: ViewParticipantsPage
                },
                // Treatment related pages
                {
                    path: 'treatment/view',
                    props: true,
                    name: 'SampleCoordinatorViewTreatmentsPage',
                    component: ViewTreatmentsPage
                },
                // Drug Target related pages
                {
                    path: 'drug_target/add',
                    props: true,
                    name: 'SampleCoordinatorAddDrugTargetPage',
                    component: SampleCoordinatorAddDrugTargetPage
                },
                {
                    path: 'drug_target/view',
                    props: true,
                    name: 'SampleCoordinatorViewDrugTargetsPage',
                    component: SampleCoordinatorViewDrugTargetsPage
                },
                {
                    path: 'drug_target/edit',
                    props: true,
                    name: 'SampleCoordinatorEditDrugTargetPage',
                    component: SampleCoordinatorEditDrugTargetPage
                },
                // PrAn workspaces
                {
                    path: 'pran_workspaces/view',
                    props: true,
                    name: 'SampleCoordinatorViewPrAnWorkspacesPage',
                    component: ViewPrAnWorkspacesPage
                },
                // Bait Sets
                {
                    path: 'bait_set/view',
                    props: true,
                    name: 'SampleCoordinatorViewBaitSetsPage',
                    component: ViewBaitSetsPage
                },
                {
                    path: 'bait_set/edit',
                    props: true,
                    name: 'SampleCoordinatorEditBaitSetPage',
                    component: SampleCoordinatorEditBaitSetPage
                },
                {
                    path: 'bait_set/add',
                    props: true,
                    name: 'SampleCoordinatorAddBaitSetPage',
                    component: SampleCoordinatorAddBaitSetPage
                },
                // Cancer Staging Systems
                {
                    path: 'cancer_staging_system/view',
                    props: true,
                    name: 'SampleCoordinatorViewCancerStagingSystemsPage',
                    component: ViewCancerStagingSystemsPage
                },
                {
                    path: 'cancer_staging_system/add',
                    props: true,
                    name: 'SampleCoordinatorAddCancerStagingSystemPage',
                    component: SampleCoordinatorAddCancerStagingSystemPage
                },
                {
                    path: 'cancer_staging_system/edit',
                    props: true,
                    name: 'SampleCoordinatorEditCancerStagingSystemPage',
                    component: SampleCoordinatorEditCancerStagingSystemPage
                },
                // Cancer Types
                {
                    path: 'cancer_type/view',
                    props: true,
                    name: 'SampleCoordinatorViewCancerTypesPage',
                    component: ViewCancerTypesPage
                },
                {
                    path: 'cancer_type/add',
                    props: true,
                    name: 'SampleCoordinatorAddCancerTypePage',
                    component: SampleCoordinatorAddCancerTypePage
                },
                {
                    path: 'cancer_type/edit',
                    props: true,
                    name: 'SampleCoordinatorEditCancerTypePage',
                    component: SampleCoordinatorEditCancerTypePage
                },
                // Cancer Stages
                {
                    path: 'cancer_stage/view',
                    props: true,
                    name: 'SampleCoordinatorViewCancerStagesPage',
                    component: ViewCancerStagesPage
                },
                {
                    path: 'cancer_stage/add',
                    props: true,
                    name: 'SampleCoordinatorAddCancerStagePage',
                    component: SampleCoordinatorAddCancerStagePage
                },
                {
                    path: 'cancer_stage/edit',
                    props: true,
                    name: 'SampleCoordinatorEditCancerStagePage',
                    component: SampleCoordinatorEditCancerStagePage
                },
                // Line of Treatment
                {
                    path: 'line_of_treatment/view',
                    props: true,
                    name: 'SampleCoordinatorViewLinesOfTreatmentPage',
                    component: ViewLinesOfTreatmentPage
                }
            ]
        },
        {
            /* Nesting all Cohort Lead views in this section */
            path: '/project_lead',
            component: ProjectLeadHeaderComponent,
            children: [
                {
                    path: 'project/view',
                    props: true,
                    name: 'ProjectLeadViewProjectPage',
                    component: ViewProjectsPage
                },
                {
                    path: 'project/edit/:cohortId',
                    props: true,
                    name: 'ProjectLeadEditProjectPage',
                    component: ProjectLeadEditProjectPage
                },
                {
                    path: 'diagnostic_test/view',
                    props: true,
                    name: 'CohortLeadViewDiagnosticTestsPage',
                    component: ViewDiagnosticTestsPage
                },
                /* Clinical update related pages */
                {
                    path: 'clinical_update/view',
                    props: true,
                    name: 'CohortLeadViewClinicalUpdatesPage',
                    component: ViewClinicalUpdatesPage
                },
                // Sample related pages
                {
                    path: 'sample/view',
                    props: true,
                    name: 'CohortLeadViewSamplesPage',
                    component: ViewSamplesPage
                },
                // Generate Reports pages
                {
                    path: 'report/unsequenced_biospecimens',
                    props: true,
                    name: 'CohortLeadUnsequencedBiospecimensPage',
                    component: UnsequencedBiospecimensPage
                },
                // Biospecimen related pages
                {
                    path: 'biospecimen/view',
                    props: true,
                    name: 'CohortLeadViewBiospecimensPage',
                    component: ViewBiospecimensPage
                },
                // Participant related pages
                {
                    path: 'participant/view',
                    props: true,
                    name: 'CohortLeadViewParticipantsPage',
                    component: ViewParticipantsPage
                },
                // Treatment related pages
                {
                    path: 'treatment/view',
                    props: true,
                    name: 'CohortLeadViewTreatmentsPage',
                    component: ViewTreatmentsPage
                }
            ]
        },
        {
            /* Nesting all Submitter views in this section */
            path: '/data_editor',
            component: DataEditorHeaderComponent,
            children: [
                {
                    path: 'upload',
                    name: 'DataEditorBulkLoadPage',
                    component: DataEditorBulkLoadPage
                },
                {
                    path: 'upload_bsp',
                    name: 'DataEditorBSPUploadPage',
                    component: DataEditorBSPUploadPage
                },
                {
                    path: 'cohort/view',
                    props: true,
                    name: 'DataEditorViewCohortPage',
                    component: ViewProjectsPage
                },
                /* Participant related pages */
                {
                    path: 'participant/add',
                    name: 'DataEditorAddParticipantPage',
                    component: DataEditorAddParticipantPage
                },
                {
                    path: 'cohort/:cohortName/:cohortId/participant/edit/:participantId',
                    props: true,
                    name: 'DataEditorEditParticipantPage',
                    component: DataEditorEditParticipantPage
                },
                {
                    path: 'participant/view',
                    props: true,
                    name: 'DataEditorViewParticipantsPage',
                    component: ViewParticipantsPage
                },
                // Generate Reports pages
                {
                    path: 'report/unsequenced_biospecimens',
                    props: true,
                    name: 'SubmitterUnsequencedBiospecimensPage',
                    component: UnsequencedBiospecimensPage
                },
                /* Biospecimen related pages */
                {
                    path: 'biospecimen/view',
                    props: true,
                    name: 'DataEditorViewBiospecimensPage',
                    component: ViewBiospecimensPage
                },
                {
                    path: 'biospecimen/edit/:cohortName/:cohortId/:biospecimenId',
                    props: true,
                    name: 'DataEditorEditBiospecimenPage',
                    component: DataEditorEditBiospecimenPage
                },
                {
                    path: 'biospecimen/add',
                    name: 'DataEditorAddBiospecimenPage',
                    component: DataEditorAddBiospecimenPage
                },
                /* Clinical update related pages */
                {
                    path: 'clinical_update/add',
                    name: 'DataEditorAddClinicalUpdatePage',
                    component: DataEditorAddClinicalUpdatePage
                },
                {
                    path: 'clinical_update/edit/:cohortId/:cohortName/:recordId',
                    props: true,
                    name: 'DataEditorEditClinicalUpdatePage',
                    component: DataEditorEditClinicalUpdatePage
                },
                {
                    path: 'clinical_update/view',
                    props: true,
                    name: 'DataEditorViewClinicalUpdatesPage',
                    component: ViewClinicalUpdatesPage
                },
                {
                    path: 'clinical_update_category/view',
                    props: true,
                    name: 'SubmitterViewClinicalUpdateCategoriesPage',
                    component: ViewClinicalUpdateCategoriesPage
                },
                /* Diagnostic Test related pages */
                {
                    path: 'diagnostic_test/add',
                    name: 'SubmitterAddDiagnosticTest',
                    component: SubmitterAddDiagnosticTestPage
                },
                {
                    path: 'diagnostic_test/view',
                    props: true,
                    name: 'SubmitterViewDiagnosticTestsPage',
                    component: ViewDiagnosticTestsPage
                },
                {
                    path: 'diagnostic_test/edit/:recordId',
                    props: true,
                    name: 'SubmitterEditDiagnosticTestPage',
                    component: SubmitterEditDiagnosticTestPage
                },
                {
                    path: 'diagnostic_test_category/view',
                    props: true,
                    name: 'SubmitterViewDiagnosticTestCategoriesPage',
                    component: ViewDiagnosticTestCategoriesPage
                },
                // Sample related pages
                {
                    path: 'sample/view',
                    props: true,
                    name: 'DataEditorViewSamplesPage',
                    component: ViewSamplesPage
                },
                // Treatment related pages
                {
                    path: 'treatment/add',
                    name: 'DataEditorAddTreatmentPage',
                    component: DataEditorAddTreatmentPage
                },
                {
                    path: 'treatment/view',
                    props: true,
                    name: 'DataEditorViewTreatmentsPage',
                    component: ViewTreatmentsPage
                },
                {
                    path: 'treatment/edit/:cohortName/:cohortId/:treatmentId',
                    props: true,
                    name: 'DataEditorEditTreatmentPage',
                    component: DataEditorEditTreatmentPage
                },
                // Drug combination related pages
                {
                    path: 'drug_combination/view',
                    props: true,
                    name: 'SubmitterViewDrugCombinationsPage',
                    component: ViewDrugCombinationsPage
                },
                // Line of Treatment
                {
                    path: 'line_of_treatment/view',
                    props: true,
                    name: 'SubmitterViewLinesOfTreatmentPage',
                    component: ViewLinesOfTreatmentPage
                },
                {
                    path: 'line_of_treatment/add',
                    props: true,
                    name: 'SubmitterAddLineOfTreatmentPage',
                    component: SubmitterAddLineOfTreatmentPage
                },
                {
                    path: 'line_of_treatment/edit',
                    props: true,
                    name: 'SubmitterEditLineOfTreatmentPage',
                    component: SubmitterEditLineOfTreatmentPage
                },
                {
                    path: 'entity_upload_template/view',
                    props: true,
                    name: 'DataEditorViewLatestEntityUploadTemplatePage',
                    component: ViewLatestEntityUploadTemplatePage
                },
                /* Manifest related pages */
                {
                    path: 'manifest/view',
                    props: true,
                    name: 'DataEditorViewManifestsPage',
                    component: ViewManifestsPage
                },
                {
                    path: 'manifest/add',
                    name: 'DataEditorAddManifestPage',
                    component: DataEditorAddManifestPage
                },
                {
                    path: 'cohort/:cohortName/:cohortId/participant/edit/:participantId',
                    props: true,
                    name: 'DataAnalystEditParticipantPage',
                    component: DataAnalystEditParticipantPage
                },
                // {
                //     path: 'cohort/:cohortName/:cohortId/biospecimen/edit/:biospecimenId',
                //     props: true,
                //     name: 'DataAnalystEditBiospecimenPage',
                //     component: DataAnalystEditBiospecimenPage
                // },
            ]
        },
        {
            /* Nesting all Data Analyst views in this section */
            path: '/data_analyst',
            component: DataAnalystHeaderComponent,
            children: [
                // cohorts
                {
                    path: 'cohort/view',
                    props: true,
                    name: 'DataAnalystViewCohortPage',
                    component: ViewProjectsPage
                },
                // biospecimens
                {
                    path: 'biospecimens/view',
                    props: true,
                    name: 'DataAnalystViewBiospecimensPage',
                    component: ViewBiospecimensPage
                },
                // participants
                {
                    path: 'participants/view',
                    props: true,
                    name: 'DataAnalystViewParticipantsPage',
                    component: ViewParticipantsPage
                },
                // participants
                {
                    path: 'treatments/view',
                    props: true,
                    name: 'DataAnalystViewTreatmentsPage',
                    component: ViewTreatmentsPage
                },
                // diagnostic tests
                {
                    path: 'diagnostic_tests/view',
                    props: true,
                    name: 'DataAnalystViewDiagnosticTestsPage',
                    component: ViewDiagnosticTestsPage
                },
                // clinical updates
                {
                    path: 'clinical_updates/view',
                    props: true,
                    name: 'DataAnalystViewClinicalUpdatesPage',
                    component: ViewClinicalUpdatesPage
                },
                // samples
                {
                    path: 'samples/view',
                    props: true,
                    name: 'DataAnalystViewSamplesPage',
                    component: ViewSamplesPage
                },
                // drug combinations
                {
                    path: 'drug_combinations/view',
                    props: true,
                    name: 'DataAnalystViewDrugCombinationsPage',
                    component: ViewDrugCombinationsPage
                },
                // PrAn workspaces
                {
                    path: 'pran_workspaces/view',
                    props: true,
                    name: 'DataAnalystViewPrAnWorkspacesPage',
                    component: ViewPrAnWorkspacesPage
                },
                {
                    path: 'pran_workspaces/add',
                    props: true,
                    name: 'DataAnalystAddPrAnWorkspacePage',
                    component: DataAnalystAddPrAnWorkspacePage
                }
            ]
        },
        {
            /* Nesting all Lead Data Analyst views in this section */
            path: '/lead_data_analyst',
            component: LeadDataAnalystHeaderComponent,
            children: [
                // PrAn workspaces
                {
                    path: 'pran_workspaces/add',
                    props: true,
                    name: 'LeadDataAnalystAddPrAnWorkspacePage',
                    component: LeadDataAnalystAddPrAnWorkspacePage
                },
                {
                    path: 'pran_workspaces/view',
                    props: true,
                    name: 'LeadDataAnalystViewPrAnWorkspacesPage',
                    component: ViewPrAnWorkspacesPage
                },
                // Participant related pages
                {
                    path: 'participant/view',
                    props: true,
                    name: 'LeadDataAnalystViewParticipantsPage',
                    component: ViewParticipantsPage
                },
                {
                    path: 'cohort/:cohortId/participant/edit/:participantId',
                    props: true,
                    name: 'LeadDataAnalystEditParticipantPage',
                    component: LeadDataAnalystEditParticipantPage
                },
                // Reports
                {
                    path: 'report/colliding_samples',
                    props: true,
                    name: 'LeadDataAnalystReportCollidingSamplePage',
                    component: ReportCollidingSamplesPage
                },
                // Bait Sets
                {
                    path: 'bait_set/view',
                    props: true,
                    name: 'LeadDataAnalystViewBaitSetsPage',
                    component: ViewBaitSetsPage
                },
                // Method Configuration
                {
                    path: 'method_configurations/add',
                    props: true,
                    name: 'LeadDataAnalystAddMethodConfigurationPage',
                    component: LeadDataAnalystAddMethodConfigurationPage
                },
                {
                    path: 'method_configurations/view',
                    props: true,
                    name: 'LeadDataAnalystViewMethodConfigurationPage',
                    component: ViewMethodConfigurationsPage
                },
                {
                    path: 'method_configurations/edit',
                    props: true,
                    name: 'LeadDataAnalystEditMethodConfigurationPage',
                    component: LeadDataAnalystEditMethodConfigurationPage
                },
                // PrAn Workspace Attributes
                {
                    path: 'workspace_attribute/view',
                    props: true,
                    name: 'LeadDataAnalystViewWorkspaceAttributesPage',
                    component: ViewPrAnWorkspaceAttributesPage
                },
                {
                    path: 'workspace_attribute/edit/:attributeId',
                    props: true,
                    name: 'LeadDataAnalystEditWorkspaceAttributesPage',
                    component: LeadDataAnalystEditWorkspaceAttributesPage
                },
                {
                    path: 'workspace_attribute/add',
                    props: true,
                    name: 'LeadDataAnalystAddWorkspaceAttributesPage',
                    component: LeadDataAnalystAddWorkspaceAttributesPage
                },
                {
                    path: 'upload_workspace_attributes',
                    props: true,
                    name: 'LeadDataAnalystWorkspaceAttributeUploadPage',
                    component: LeadDataAnalystWorkspaceAttributeUploadPage
                },
                {
                    path: 'pran_snapshot_workspaces/:workspaceId/:workspaceName/:cohortId',
                    props: true,
                    name: 'LeadDataAnalystViewPrAnSnapshotsPage',
                    component: LeadDataAnalystViewPrAnSnapshotsPage
                },
                {
                    path: 'release_pran_workspaces/:workspaceId/:workspaceName/:cohortId',
                    props: true,
                    name: 'LeadDataAnalystReleasePrAnWorkspacePage',
                    component: LeadDataAnalystReleasePrAnWorkspacePage
                }
            ]
        },
        {
            /* Nesting all Uber Lead Data Analyst views in this section */
            path: '/uber_lead_data_analyst',
            component: UberLeadDataAnalystHeaderComponent,
            children: [
                // PrAn workspaces
                {
                    path: 'pran_workspaces/add',
                    props: true,
                    name: 'UberLeadDataAnalystAddPrAnWorkspacePage',
                    component: LeadDataAnalystAddPrAnWorkspacePage
                },
                {
                    path: 'pran_workspaces/view',
                    props: true,
                    name: 'UberLeadDataAnalystViewPrAnWorkspacesPage',
                    component: ViewPrAnWorkspacesPage
                },
                // Participant related pages
                {
                    path: 'participant/view',
                    props: true,
                    name: 'UberLeadDataAnalystViewParticipantsPage',
                    component: ViewParticipantsPage
                },
                {
                    path: 'cohort/:cohortId/participant/edit/:participantId',
                    props: true,
                    name: 'UberLeadDataAnalystEditParticipantPage',
                    component: LeadDataAnalystEditParticipantPage
                },
                // Reports
                {
                    path: 'report/colliding_samples',
                    props: true,
                    name: 'UberLeadDataAnalystReportCollidingSamplePage',
                    component: ReportCollidingSamplesPage
                },
                // Bait Sets
                {
                    path: 'bait_set/view',
                    props: true,
                    name: 'UberLeadDataAnalystViewBaitSetsPage',
                    component: ViewBaitSetsPage
                },
                // Method Configuration
                {
                    path: 'method_configurations/add',
                    props: true,
                    name: 'UberLeadDataAnalystAddMethodConfigurationPage',
                    component: LeadDataAnalystAddMethodConfigurationPage
                },
                {
                    path: 'method_configurations/view',
                    props: true,
                    name: 'UberLeadDataAnalystViewMethodConfigurationPage',
                    component: ViewMethodConfigurationsPage
                },
                {
                    path: 'method_configurations/edit',
                    props: true,
                    name: 'UberLeadDataAnalystEditMethodConfigurationPage',
                    component: LeadDataAnalystEditMethodConfigurationPage
                },
                // PrAn Workspace Attributes
                {
                    path: 'workspace_attribute/view',
                    props: true,
                    name: 'UberLeadDataAnalystViewWorkspaceAttributesPage',
                    component: ViewPrAnWorkspaceAttributesPage
                },
                {
                    path: 'workspace_attribute/edit/:attributeId',
                    props: true,
                    name: 'UberLeadDataAnalystEditWorkspaceAttributesPage',
                    component: LeadDataAnalystEditWorkspaceAttributesPage
                },
                {
                    path: 'workspace_attribute/add',
                    props: true,
                    name: 'UberLeadDataAnalystAddWorkspaceAttributesPage',
                    component: LeadDataAnalystAddWorkspaceAttributesPage
                },
                {
                    path: 'upload_workspace_attributes',
                    props: true,
                    name: 'UberLeadDataAnalystWorkspaceAttributeUploadPage',
                    component: LeadDataAnalystWorkspaceAttributeUploadPage
                },
                {
                    path: 'pran_snapshot_workspaces',
                    props: true,
                    name: 'UberLeadDataAnalystViewPrAnSnapshotsPage',
                    component: LeadDataAnalystViewPrAnSnapshotsPage
                },
                {
                    path: 'release_pran_workspaces',
                    props: true,
                    name: 'UberLeadDataAnalystReleasePrAnWorkspacePage',
                    component: LeadDataAnalystReleasePrAnWorkspacePage
                }
            ]
        }
    ]
// });

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
