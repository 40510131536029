<template>
    <div>
        <h3>View Drugs</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="drugs-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="drugs-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import {
    ADMINISTRATOR,
    DATA_EDITOR,
    DATA_ANALYST
} from '../utils/constants';
import getUserToken from '../utils/auth';
import store from '../store';

export default {
    props: {
        role: {
            type: String,
            required: true,
            validator(val) {
                return [ADMINISTRATOR, DATA_EDITOR, DATA_ANALYST].indexOf(val) !== -1;
            }
        },
        // For when drugs are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',
            roles: store.state.user.roles, 

            // urls
            drugUrl: getUrl('drugsUrl'),

            // table vars
            exportColumns: [0, 1, 2, 3, 4, 5, 6],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Name', data: 'name' },
                { title: 'Aliases', data: 'aliases' },
                { title: 'Drug Category', data: 'drug_category' },
                { title: 'Drug Target', data: 'drug_target' },
                { title: 'Mechanism', data: 'mechanism' },
                { title: 'Notes', data: 'notes' },
                {
                    title: 'URL',
                    data(row) {
                        const { url } = row;
                        return `<a href="${url}">${url}</a>`;
                    }
                },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ],
            // Sample Coordinator Action Columns
            sCColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-drug">Edit</span>';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span delete-drug">Delete</span>';
                    }
                }
            ]
        };
    },
    watch: {
    },
    mounted() {
        this.populateDrugTable();
    },
    methods: {
        populateDrugUrl() {
            return this.drugUrl;
        },
        getTableColumns() {
            let { tableColumns } = this;
            if (this.roles.isAdmin) {
                tableColumns = tableColumns.concat(this.sCColumns);
            }
            return tableColumns;
        },
        populateDrugTable() {
            const loader = this.$loading.show(); // loader while waiting for categories GET request
            const component = this;
            const drugUrl = this.populateDrugUrl();

            axios.get(drugUrl, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((drugResp) => {
                    loader.hide();
                    const drugData = drugResp.data.drugs;

                    const table = $('#drugs-table').DataTable({
                        destroy: true,
                        data: drugData,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '80vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#drug',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'drugs',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#drug'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'drugs',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#drug'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ],
                        fnInitComplete() {
                        }
                    });
                    component.addSCTableActions(table);
                });
        },
        addSCTableActions(datatable) {
            const component = this;
            if (this.roles.isAdmin) {
                $('#drugs-table tbody .edit-drug').click(function editRow() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    component.$router.push({
                        name: 'AdminEditDrugPage',
                        params: {
                            drugId: rowData.id,
                        }
                    });
                });

                $('#drugs-table tbody .delete-drug').click(function deleteRow() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete category ${rowData.name}?`;
                    if(confirm(confirmMessage)){
                        component.deleteDrug(rowData.id);
                    }
                });
            }
        },
        deleteDrug(id) {
            const component = this;
            const loader = this.$loading.show();
            const deleteUrl = `${this.drugUrl}?id=${id}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    console.log(resp);
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.populateDrugTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete drug. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data.error;
                    window.scrollTo(0, 0);
                });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        }
    }
};
</script>

<style>
#drugs-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
