/*
 * A simple store to track application-level data.
 * Recommended method for simple applications.
 * Decision made based off documentation found at:
 * https://vuejs.org/v2/guide/state-management.html#Simple-State-Management-from-Scratch
 * https://medium.com/fullstackio/managing-state-in-vue-js-23a0352b1c87
 */
import axios from 'axios';
import router from './router';
import getUserToken, { decodeJwt } from './utils/auth';
import { getUrl } from './utils/directory';
import {
    USER, ADMINISTRATOR, PROJECT_LEAD, DATA_EDITOR,
    DATA_ANALYST, ROLES_CAN_VIEW_SWIMMER_PLOT
} from './utils/constants';

export default {
    state: {
        user: {
            roles: {
                isUser: false,
                isAdmin: false,
                isProjectLead: false,
                isDataEditor: false,
                isDataAnalyst: false,
                readerCohorts: [],
                dataEditorCohorts: [],
                dataAnalystCohorts: [],
                projectLeadCohorts: [],
            },
            isSignedIn: false,
            email: undefined,
            token: undefined,
            canViewSwimmerPlot: false,
            loadingRoles: null // initialize as null, but will be set to true when the API roles requestis sent and set to false when the API responds
        },
        syncResp: {}
    },
    setSyncResp(resp) {
        this.state.syncResp = resp;
    },
    setUserRoles() {
        this.state.user.loadingRoles = true;
        const rolesUrl = getUrl('rolesUrl');
        return axios.get(rolesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
            .then((response) => {
                const allRoles = response.data.roles;
                const userRoles = allRoles.user_roles;
                const readerCohorts = allRoles.reader_cohorts;
                const dataEditorCohorts = allRoles.data_editor_cohorts;
                const dataAnalystCohorts = allRoles.data_analyst_cohorts;
                const projectLeadCohorts = allRoles.project_lead_cohorts;
                console.log(readerCohorts);
                this.state.user.roles.isUser = userRoles.includes(USER);
                this.state.user.roles.isAdmin = userRoles.includes(ADMINISTRATOR);
                this.state.user.roles.isProjectLead = projectLeadCohorts.length > 0;
                this.state.user.roles.isDataEditor = dataEditorCohorts.length > 0;
                this.state.user.roles.isDataAnalyst = dataAnalystCohorts.length > 0;
                this.state.user.roles.readerCohorts = readerCohorts;
                this.state.user.roles.dataEditorCohorts = dataEditorCohorts;
                this.state.user.roles.dataAnalystCohorts = dataAnalystCohorts;
                this.state.user.roles.projectLeadCohorts = projectLeadCohorts;
                this.state.user.loadingRoles = false;
                this.checkUserViewSwimmerPlot(userRoles);
            }).catch((error) => {
                console.error(error);
            });
    },
    clearUserRoles() {
        this.state.user.roles.isUser = false;
        this.state.user.roles.isAdmin = false;
        this.state.user.roles.isProjectLead = false;
        this.state.user.roles.isDataEditor = false;
        this.state.user.roles.isDataAnalyst = false;
        this.state.user.canViewSwimmerPlot = false;
        this.state.user.roles.readerCohorts = [];
        this.state.user.roles.dataEditorCohorts = [];
        this.state.user.roles.dataAnalystCohorts = [];
        this.state.user.roles.projectLeadCohorts = [];
    },
    checkUserRoles(role) {
        // for validation of the state' user access privileges
        const checkRoleMap = {
            swimmer_plot: this.state.user.canViewSwimmerPlot,
            [USER]: this.state.user.roles.isUser,
            [ADMINISTRATOR]: this.state.user.roles.isAdmin, // Use ES6 convention of [CONSTANT] to set the key to the computed property name, i.e. ADMINISTRATOR -> Administrator
            [PROJECT_LEAD]: this.state.user.roles.isCohortLead,
            [DATA_EDITOR]: this.state.user.roles.isSubmitter,
            [DATA_ANALYST]: this.state.user.roles.isDataAnalyst,
        };
        if (!(role in checkRoleMap)) {
            return false;
        }
        return checkRoleMap[role];
    },
    async userLogIn(token) { 
        const userData = await decodeJwt(token);
        // if the Google User cannot be determined, the user in the store state will not be signed in
        // in this case, do not continue further with logging in actions
        if (userData.verified !== true) {
            return;
        }
        this.state.user.isSignedIn = true;
        this.state.user.email = userData.email;
        this.state.user.token = token;
        this.setUserRoles();
    },
    userLogOut() {
        this.state.user.isSignedIn = false;
        this.state.user.email = undefined;
        this.clearUserRoles();
        router.push({ name: 'HomePage' });
    },
    checkUserViewSwimmerPlot(roles) {
        const filteredRoles = roles.filter(x => ROLES_CAN_VIEW_SWIMMER_PLOT.includes(x));
        this.state.user.canViewSwimmerPlot = !!filteredRoles.length;
    }
};
