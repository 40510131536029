<template>
    <div>
        <h3>View Production Analysis Workspaces</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-form-select v-model="selectedCohort" class="col-lg-4 col-md-6 col-xs-12" :options="cohortOptions" />
        <br><br>

        <b-container fluid>
            <b-row id="workspace-table-wrapper">
                <table id="workspace-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../utils/directory';
import {
    createCohortsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';
import getUserToken from '../utils/auth';
import store from '../store'

export default {
    props: {
        // these props are passed in after a workspace is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCohortId: { default: null, type: Number, required: false },
    },
    data() {
        const storeRoles = store.state.user.roles;
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',
            roles: storeRoles, 

           // cohort specific role info
            readerCohorts: storeRoles.readerCohorts,
            dataEditorCohorts: storeRoles.dataEditorCohorts,
            dataAnalystCohorts: storeRoles.dataAnalystCohorts,
            projectLeadCohorts: storeRoles.projectLeadCohorts,

            // checking if a workspace was edited
            workspaceEdited: !!this.editedWorkspaceId,

            // URLs used
            projectsUrl: getUrl('projectsUrl'),
            dataAnalystPrAnWorkspacesUrl: getUrl('prAnWorkspacesUrl'),

            // cohort selection dropdown
            selectedCohort: null,
            cohortOptions: [],

            // table vars
            workspaceTableColumns: [],
            ldaTableColumns: [
                {
                    title: 'Sync',
                    sortable: false,
                    render(data, type, row) {
                        if (row.state === 'in_development') {
                            return '<span class="text-btn-span sync-workspace">Sync</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Release',
                    sortable: false,
                    render(data, type, row) {
                        if (row.state === 'in_development') {
                            return '<span class="text-btn-span release-workspace">Release</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Checkout',
                    sortable: false,
                    render(data, type, row) {
                        if (row.state === 'released') {
                            return '<span class="text-btn-span checkout-workspace">Checkout</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Publish',
                    sortable: false,
                    render(data, type, row) {
                        if (row.state === 'released') {
                            return '<span class="text-btn-span publish-workspace">Publish</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Redact',
                    sortable: false,
                    render(data, type, row) {
                        if (row.state === 'published') {
                            return '<span class="text-btn-span redact-workspace">Redact</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render(data, type, row) {
                        if (row.state === 'in_development') {
                            return '<span class="text-btn-span delete-workspace">Delete</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Revert',
                    sortable: false,
                    render(data, type, row) {
                        if (row.state === 'in_development') {
                            return '<span class="text-btn-span revert-workspace">Revert</span>';
                        }
                        return '';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    beforeMount() {
        this.getCohorts();
    },
    watch: {
        selectedCohort(newCohort) {
            // table must be reset if cohort changes since number of columns may change based on permissions
            let table = $('#workspace-table');
            if ($.fn.DataTable.isDataTable(table)) {
                table.DataTable().clear().draw();
                table.DataTable().destroy();
                table.empty(); 
            }
            this.populateWorkspaceTableColumns();
            this.getWorkspacesTable(newCohort);
        }
    },
    methods: {
        populateWorkspaceTableColumns() {
            const component = this;
            this.workspaceTableColumns = [
                { title: 'DB Key', data: 'id' },
                { title: 'Workspace Name', data: 'name' },
                { title: 'Workspace Description', data: 'description' },
                { title: 'Project Namespace', data: 'namespace' },
                { title: 'Workspace State', data: 'state' },
                { title: 'Current Snapshot Id', data: 'latest_snapshot_id' },
                {
                    title: 'URL',
                    render(data, type, row) {
                        const { url } = row;
                        if ((row.state === 'published' || row.state === 'redacted' || row.state === 'released') && ((component.projectLeadCohorts && component.projectLeadCohorts.includes(component.selectedCohort)) || (component.dataAnalystCohorts && component.dataAnalystCohorts.includes(component.selectedCohort)))) {
                            return `<a href="${url}">${url}</a>`;
                        }
                        return url;
                    }
                },
                { title: 'Date Initialized (UTC)', data: 'date_initialized' },
                { title: 'Notes', data: 'notes' }
            ]
        },
        populateCohortsUrl() {
            // if (this.roles.isDataAnalyst) {
            //     return `${this.projectsUrl}?access_type=analyze`;
            // } else {
            //     console.error('Invalid role for PrAn Workspace table.');
            //     return undefined;
            // }
            return `${this.projectsUrl}?access_type=analyze`;
        },
        populateWorkspacesUrl() {
            if (this.dataAnalystCohorts && this.dataAnalystCohorts.includes(this.selectedCohort)) {
                return this.dataAnalystPrAnWorkspacesUrl;
            } else {
                console.error('Invalid role for PrAn Workspace table.');
                return undefined;
            }
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            axios.get(component.populateCohortsUrl(), { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);

                    // will have a value if a cohort was edited
                    if (component.workspaceEdited) {
                        component.workspaceEdited = false;
                        component.selectedCohort = component.editedCohortId;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        getTableColumns() {
            let { workspaceTableColumns } = this;
            if (this.dataAnalystCohorts && this.dataAnalystCohorts.includes(this.selectedCohort)) {
                workspaceTableColumns = workspaceTableColumns.concat(this.ldaTableColumns);
            }
            return workspaceTableColumns;
        },
        getWorkspacesTable(cohortId) {
            const component = this;
            const loader = this.$loading.show();
            const getWorkspacesUrl = `${component.populateWorkspacesUrl()}?cohort_id=${cohortId}`;

            // getting workspaces info and creating table
            axios.get(getWorkspacesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const pranWorkspaces = resp.data.pran_workspaces;
                    const table = $('#workspace-table').DataTable({
                        destroy: true,
                        data: pranWorkspaces,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true
                    });
                    component.addTableActions(table);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        deleteWorkspace(cohortId, workspaceId) {
            const component = this;
            const loader = component.$loading.show();
            const deleteWorkspaceUrl = `${component.populateWorkspacesUrl()}?id=${workspaceId}&cohort_id=${cohortId}`;
            axios.delete(deleteWorkspaceUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getWorkspacesTable(cohortId);
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete production analysis workspace. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        releasePublishRedactWorkspace(cohortId, workspaceId, verb) {
            const validActions = ['publish', 'redact', 'release', 'checkout', 'sync'];
            if (validActions.includes(verb)) {
                const component = this;
                const loader = component.$loading.show();
                const updateWorkspaceUrl = `${component.populateWorkspacesUrl()}?id=${workspaceId}&cohort_id=${cohortId}&${verb}=true`;
                axios.put(updateWorkspaceUrl, {}, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                    .then((resp) => {
                        loader.hide();
                        component.showAlert = true;
                        component.alertVariant = 'warning';
                        component.alertMessage = resp.data.message;
                        component.getWorkspacesTable(cohortId);
                        window.scrollTo(0, 0);
                    })
                    .catch((err) => {
                        loader.hide();
                        component.showAlert = true;
                        component.alertVariant = 'danger';
                        if (err.response === undefined) {
                            component.alertMessage = `Failed to ${verb} production analysis workspace. Please contact portal administrators.`;
                        }
                        component.alertMessage = err.response.data;
                        window.scrollTo(0, 0);
                    });
            }
        },
        addTableActions(datatable) {
            const component = this;

            $('#workspace-table tbody .delete-workspace').click(function confirmWorkspaceDelete() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to delete workspace ${rowData.name}?`;
                if(confirm(confirmMessage)){
                    component.deleteWorkspace(component.selectedCohort, rowData.id);
                }
            });

            $('#workspace-table tbody .publish-workspace').click(function confirmWorkspacePublish() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to publish workspace ${rowData.name}? Once a workspace is published it is frozen and cannot be changed.`;
                if(confirm(confirmMessage)){
                    component.releasePublishRedactWorkspace(component.selectedCohort, rowData.id, 'publish');
                }
            });

            $('#workspace-table tbody .redact-workspace').click(function confirmWorkspaceRedact() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to redact workspace ${rowData.name}? Once a published workspace is redacted, it can not be published again.`;
                if(confirm(confirmMessage)){
                    component.releasePublishRedactWorkspace(component.selectedCohort, rowData.id, 'redact');
                }
            });

            $('#workspace-table tbody .sync-workspace').click(function confirmWorkspaceRelease() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to sync this workspace ${rowData.name}?`;
                if(confirm(confirmMessage)){
                    component.releasePublishRedactWorkspace(component.selectedCohort, rowData.id, 'sync');
                }
            });

            
            $('#workspace-table tbody .release-workspace').click(function confirmWorkspaceRelease() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.$router.push({
                    name: 'LeadDataAnalystReleasePrAnWorkspacePage',
                    params: {
                        workspaceId: rowData.id,
                        workspaceName: rowData.name,
                        cohortId: component.selectedCohort,
                    }
                });
            });

            $('#workspace-table tbody .checkout-workspace').click(function confirmWorkspaceCheckout() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to checkout this workspace ${rowData.name}?`;
                if(confirm(confirmMessage)){
                    component.releasePublishRedactWorkspace(component.selectedCohort, rowData.id, 'checkout');
                }
            });

            $('#workspace-table tbody .revert-workspace').click(function confirmWorkspaceRevert() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.$router.push({
                    name: 'LeadDataAnalystViewPrAnSnapshotsPage',
                    params: {
                        workspaceId: rowData.id,
                        workspaceName: rowData.name,
                        cohortId: component.selectedCohort,
                    }
                });
            });
        }
    }
};
</script>

<style>
.dt-buttons {
    float: none !important;
    margin-bottom: 10px;
}

</style>
