<template>
    <div>
        <h1> Cohort Sample Stats </h1>
        <h2> For Samples From IBM Delivery Workspaces In Their Primary Cohort </h2>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Year" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedYear" :options="yearOptions" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="reports-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="reports-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import {
    createDropdownOptions
} from '../../utils/form-data-helpers';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    props: {
        // For when reports are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // urls
            sampleCoordinatorReportCohortSampleStatsUrl: getUrl('sampleCoordinatorReportCohortSampleStatsUrl'),

            // year dropdown selections
            selectedYear: null,
            yearOptions: [],

            // table vars
            exportColumns: [], // determined once year is selected
            mainTableColumns: [
                { title: 'Id', data: 'id' },
                { title: 'Name', data: 'name' },
                { title: 'Collaborator', data: 'collaborator' },
                { title: 'Participant Count', data: 'num_participants' },
                { title: 'Biospecimen Count', data: 'num_biospecimens' },
                { title: 'Sample Count', data: 'num_samples' }
            ],
            wesTableColumns: [
                { title: 'WES Samples', data: 'num_wes_samples' },
                { title: 'WES Tumor Samples', data: 'num_wes_tumor_samples' },
                { title: 'WES Normal Samples', data: 'num_wes_normal_samples' }
            ],
            ulpwgsTableColumns: [
                { title: 'ULP-WGS Samples', data: 'num_ulpwgs_samples' },
                { title: 'ULP-WGS Tumor Samples', data: 'num_ulpwgs_tumor_samples' },
                { title: 'ULP-WGS Normal Samples', data: 'num_ulpwgs_normal_samples' }
            ],
            rnaseqTableColumns: [
                { title: 'RNASeq Samples', data: 'num_rnaseq_samples' },
                { title: 'RNASeq Tumor Samples', data: 'num_rnaseq_tumor_samples' },
                { title: 'RNASeq Normal Samples', data: 'num_rnaseq_normal_samples' }
            ],
            wgsTableColumns: [
                { title: 'WGS Samples', data: 'num_wgs_samples' },
                { title: 'WGS Tumor Samples', data: 'num_wgs_tumor_samples' },
                { title: 'WGS Normal Samples', data: 'num_wgs_normal_samples' }
            ],
            scrnaseqTableColumns: [
                { title: 'scRNASeq Samples', data: 'num_scrnaseq_samples' },
                { title: 'scRNASeq Tumor Samples', data: 'num_scrnaseq_tumor_samples' },
                { title: 'scRNASeq Normal Samples', data: 'num_scrnaseq_normal_samples' }
            ],
            panelTableColumns: [
                { title: 'Panel Samples', data: 'num_panel_samples' },
                { title: 'Panel Tumor Samples', data: 'num_panel_tumor_samples' },
                { title: 'Panel Normal Samples', data: 'num_panel_normal_samples' }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    watch: {
        selectedYear() {
            if (this.selectedYear === null) {
                this.hideTable();
                return;
            }
            let actualWESColumns = this.wesTableColumns;
            let actualULPWGSColumns = this.ulpwgsTableColumns;
            let actualRNASEQColumns = this.rnaseqTableColumns;
            let actualWGSColumns = this.wgsTableColumns;
            let actualSCRNASEQColumns = this.scrnaseqTableColumns;
            let actualPanelColumns = this.panelTableColumns;

            // append onto each of these experimental strategy list of columns if a year is selected
            if (this.selectedYear !== 'All') {
                actualWESColumns = actualWESColumns.concat([
                    { title: `${this.selectedYear} WES Tumor Samples`, data: `num_wes_${this.selectedYear}_tumor_samples` },
                    { title: `${this.selectedYear} WES Normal Samples`, data: `num_wes_${this.selectedYear}_normal_samples` }
                ]);
                actualULPWGSColumns = actualULPWGSColumns.concat([
                    { title: `${this.selectedYear} ULP-WGS Tumor Samples`, data: `num_ulpwgs_${this.selectedYear}_tumor_samples` },
                    { title: `${this.selectedYear} ULP-WGS Normal Samples`, data: `num_ulpwgs_${this.selectedYear}_normal_samples` }
                ]);
                actualRNASEQColumns = actualRNASEQColumns.concat([
                    { title: `${this.selectedYear} RNASeq Tumor Samples`, data: `num_rnaseq_${this.selectedYear}_tumor_samples` },
                    { title: `${this.selectedYear} RNASeq Normal Samples`, data: `num_rnaseq_${this.selectedYear}_normal_samples` }
                ]);
                actualWGSColumns = actualWGSColumns.concat([
                    { title: `${this.selectedYear} WGS Tumor Samples`, data: `num_wgs_${this.selectedYear}_tumor_samples` },
                    { title: `${this.selectedYear} WGS Normal Samples`, data: `num_wgs_${this.selectedYear}_normal_samples` }
                ]);
                actualSCRNASEQColumns = actualSCRNASEQColumns.concat([
                    { title: `${this.selectedYear} scRNASeq Tumor Samples`, data: `num_scrnaseq_${this.selectedYear}_tumor_samples` },
                    { title: `${this.selectedYear} scRNASeq Normal Samples`, data: `num_scrnaseq_${this.selectedYear}_normal_samples` }
                ]);
                actualPanelColumns = actualPanelColumns.concat([
                    { title: `${this.selectedYear} Panel Tumor Samples`, data: `num_panel_${this.selectedYear}_tumor_samples` },
                    { title: `${this.selectedYear} Panel Normal Samples`, data: `num_panel_${this.selectedYear}_normal_samples` }
                ]);
            }
            const actualTableColumns = [].concat(
                this.mainTableColumns,
                actualWESColumns,
                actualULPWGSColumns,
                actualRNASEQColumns,
                actualWGSColumns,
                actualSCRNASEQColumns,
                actualPanelColumns
            );
            // make array of column numbers from 0 up to but not including the length of actualTableColumns
            this.exportColumns = Array.from(Array(actualTableColumns.length).keys());
            this.populateReportTable(actualTableColumns);
        }
    },
    beforeMount() {
        this.populateYearOptions();
    },
    methods: {
        updateExportColumnNames(headerRow, tableColumns) {
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        populateYearOptions() {
            const yearsObj = {
                'All Years': 'All',
                2017: '2017',
                2018: '2018',
                2019: '2019',
                2020: '2020'
            };
            this.yearOptions = createDropdownOptions(yearsObj);
        },
        hideTable() {
            $('#reports-table-wrapper').hide();
        },
        showTable() {
            $('#reports-table-wrapper').show();
        },
        populateReportTable(tableColumns) {
            const loader = this.$loading.show(); // loader while waiting for categories GET request
            const component = this;

            axios.get(`${component.sampleCoordinatorReportCohortSampleStatsUrl}?year=${component.selectedYear}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((reportResp) => {
                    loader.hide();
                    const reportTableData = reportResp.data.generated_report;

                    // destroy any existing previous table and clear all columns
                    if ($.fn.DataTable.isDataTable('#reports-table')) {
                        $('#reports-table').DataTable().destroy();
                    }
                    $('#reports-table tbody').empty();
                    $('#reports-table thead').empty();
                    $('#reports-table').DataTable({
                        destroy: true,
                        data: reportTableData,
                        columns: tableColumns,
                        order: [],
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '70vh',
                        scrollCollapse: true,
                        dom: 'Blfrtip',
                        buttons: [
                            {
                                extend: 'copy',
                                title: `#${component.selectedYear}_cohort_sample_stats_report`,
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1], tableColumns);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.selectedYear}_cohort_sample_stats_report`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0], tableColumns);
                                    exportRows[0] = headerRow;
                                    exportRows = [`#${component.selectedYear}_cohort_sample_stats_report`].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.showTable();
                });
        }
    }
};
</script>

<style>
#reports-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
