<template>
    <div>
        <h3>Edit Cohort Metadata</h3>
        <hr>

        <b-alert :show="updateError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4" sm="6">
            <b-form>
                <b-form-group label="Cohort DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="localCohortId" disabled />
                </b-form-group>

                <b-form-group label="Cohort Name" label-class="font-weight-bold" :description="requirements.cohortName">
                    <b-form-input required size="sm" v-model="$v.form.cohortName.$model" :state="$v.form.cohortName.$dirty ? !$v.form.cohortName.$error : null" />
                </b-form-group>

                <b-form-group label="Collaborator Name" label-class="font-weight-bold">
                    <b-form-select size="sm" v-model="form.selectedCollaborator" :options="collaboratorOptions" />
                </b-form-group>

                <b-form-group label="Cohort Description (optional)" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.cohortDescription" />
                </b-form-group>

                <b-form-group label="Cohort Leads (optional)" label-class="font-weight-bold" description="Press and hold the CMD key to select multiple leads.">
                    <b-form-select multiple size="sm" v-model="form.selectedCohortLeads" :options="cohortLeadOptions" />
                </b-form-group>

                <b-form-group label="Lead Data Analysts (optional)" label-class="font-weight-bold" :description="form.leadDataAnalystDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedLeadAnalysts" :options="leadAnalystOptions" />
                </b-form-group>
            </b-form>
            <b-btn type="outline-secondary" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid" @click="updateCohort">
                Update Cohort
            </b-btn>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    minLength, required
} from '@vuelidate/validators';
import { cohortNameCheck } from '../../utils/input-regex';
import getUserToken from '../../utils/auth';
import { getUrl, getCollaboratorsInfoPromise } from '../../utils/directory';
import { createDropdownOptions, createCollaboratorDict, createUsersDict } from '../../utils/form-data-helpers';

export default {
    name: 'SampleCoordinatorEditCohortPage',
    props: {
        role: {
            type: String,
            required: true
        },
        cohortId: { type: Number, required: true }
    },
    data() {
        return {
            // prop local vars 
            localCohortId: this.cohortId, 

            // URLs used
            scCohortsUrl: `${getUrl('sampleCoordinatorCohortsUrl')}?id=${this.cohortId}&name_id_only=false`,
            scUsersUrl: getUrl('sampleCoordinatorUsersUrl'),

            // options for dropdown
            collaboratorOptions: [],
            cohortLeadOptions: [],
            leadAnalystOptions: [],

            // form requirements
            requirements: {
                cohortName: 'Cohort name must be >= 2 characters and contain only alphanumeric characters, periods (.), dashes (-), and underscores (_).'
            },

            // selected cohort metadata -- will initially come from the DB, but can be updated
            form: {
                cohortName: '',
                cohortDescription: null,
                selectedCollaborator: undefined,
                selectedCohortLeads: [],
                selectedLeadAnalysts: [],
                leadDataAnalystDescription: 'Press and hold the CMD key to select multiple lead data analysts. All users who are assigned to be lead data analysts will also, by default, be assigned as data analysts if they have not been already.'
            },

            // error modal
            updateError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            cohortName: {
                required,
                minLength: minLength(2),
                cohortNameCheck
            }
        }
    },
    mounted() {
        this.populateCohortEditForm();
    },
    methods: {
        getUsersInfo() {
            return axios.get(this.scUsersUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getCohortInfo() {
            return axios.get(this.scCohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateCohortEditForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getCollaboratorsInfoPromise(),
                this.getUsersInfo(),
                this.getCohortInfo()
            ])
                .then(axios.spread((collabResp, uResp, cohortResp) => {
                    loader.hide();

                    const collaborators = createCollaboratorDict(collabResp.data.collaborators);
                    const users = createUsersDict(uResp.data.users);
                    const cohortData = cohortResp.data.cohorts[0];

                    // current cohort metadata
                    component.form.cohortName = cohortData.name;
                    component.form.cohortDescription = cohortData.description;
                    component.form.selectedCohortLeads = cohortData.leads.map(x => users[x]);
                    component.form.selectedLeadAnalysts = cohortData.lead_analysts.map(x => users[x]);
                    component.form.selectedCollaborator = collaborators[cohortData.collaborator];

                    // using DB IDs as the value
                    component.collaboratorOptions = createDropdownOptions(collaborators, true, false);
                    const userOptions = createDropdownOptions(users, true, false);
                    component.cohortLeadOptions = userOptions;
                    component.leadAnalystOptions = userOptions;
                }));
        },
        updateCohort() {
            const loader = this.$loading.show();
            const component = this;
            const updatedInfo = {
                name: this.form.cohortName,
                description: this.form.cohortDescription === '' ? null : this.form.cohortDescription,
                collaborator: this.form.selectedCollaborator,
                cohort_leads: this.form.selectedCohortLeads,
                lead_analysts: this.form.selectedLeadAnalysts
            };

            axios.put(this.scCohortsUrl, updatedInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.$router.push({
                        name: 'SampleCoordinatorViewCohortPage',
                        params: {
                            role: this.role,
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.updateError = true;
                    if (err.response === undefined) {
                        component.errorResponse = 'Failed to update cohort. Please contact portal administrators.';
                    } else {
                        component.errorResponse = err.response;
                    }
                });
        }
    }
};
</script>

<style>

</style>
