<template>
    <div>
        <h3>Update Line Of Treatment</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateLineOfTreatment">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="form.cohortName" disabled />
                </b-form-group>
                <b-form-group label="Line Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.lineName.$model" :state="$v.form.lineName.$dirty ? !$v.form.lineName.$error : null" />
                </b-form-group>
                <b-form-group label="Backbone Drug" label-class="font-weight-bold" :description="form.description">
                    <b-form-select size="sm" :options="drugOptions" v-model="form.selectedDrug" />
                </b-form-group>
                <b-form-group label="Backbone Drug Combination" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="drugCombinationOptions" v-model="form.selectedDrugCombination" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Update Line Of Treatment
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    minLength,
    maxLength
} from '@vuelidate/validators';
import {
    getUrl,
    getSubmitterCohortsPromise,
    getDrugsPromise,
    getDrugCombinationsPromise
} from '../../utils/directory';
import {
    createDropdownOptions,
    createCohortsDict,
    createDrugsDict,
    createDrugCombinationsDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    props: {
        cohortName: { type: String, required: true },
        cohortId: { type: Number, required: true },
        lineId: { type: Number, required: true },
        role: { type: String, required: true }
    },
    data() {
        return {
            // URLs used
            sLineOfTreatmentUrl: getUrl('submitterLinesOfTreatmentUrl'),

            // options for form dropdowns
            drugOptions: [],
            drugCombinationOptions: [],

            // form data
            form: {
                // dropdowns
                cohortName: this.cohortName,
                selectedDrugCombination: null,
                selectedDrug: null,
                // inputs
                lineName: null,

                // description for backbone drug selection
                description: 'Please select either a backbone drug or a backbone drug combination. Do not select both. To clear out an existing selection, select the first option in the dropdown.'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            // dropdowns
            lineName: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(64)
            }
        }
    },
    mounted() {
        this.initLineOfTreatmentForm();
    },
    methods: {
        getLineOfTreatmentInfo() {
            const url = `${this.sLineOfTreatmentUrl}?cohort_id=${this.cohortId}&id=${this.lineId}`;
            return axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        initLineOfTreatmentForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                this.getLineOfTreatmentInfo(),
                getSubmitterCohortsPromise(),
                getDrugsPromise(),
                getDrugCombinationsPromise()
            ])
                .then(axios.spread((lineResp, cohortResp, drugsResp, drugCombinationsResp) => {
                    const cohorts = createCohortsDict(cohortResp.data.cohorts);
                    const drugs = createDrugsDict(drugsResp.data.drugs);
                    const drugCombinations = createDrugCombinationsDict(drugCombinationsResp.data.drug_combinations);

                    component.cohortOptions = createDropdownOptions(cohorts);
                    component.drugOptions = createDropdownOptions(drugs);
                    component.drugCombinationOptions = createDropdownOptions(drugCombinations);

                    const currLineData = lineResp.data.lines_of_treatment[0];

                    /* filter through the drug options and drug combination options to see which one the current line of treatment's backbone drug
                    matches. If either has no match with the backbone drug, then assign the selected option to null */
                    const selectedDrugCombination = component.drugCombinationOptions.filter(x => x.text === currLineData.backbone_drug);
                    if (selectedDrugCombination.length) {
                        component.form.selectedDrugCombination = selectedDrugCombination[0].value;
                    } else {
                        component.form.selectedDrugCombination = null;
                    }
                    const selectedDrug = component.drugOptions.filter(x => x.text === currLineData.backbone_drug);
                    if (selectedDrug.length) {
                        component.form.selectedDrug = selectedDrug[0].value;
                    } else {
                        component.form.selectedDrug = null;
                    }
                    component.form.lineName = currLineData.line_name;
                }))
                .finally(() => { loader.hide(); });
        },
        updateLineOfTreatment() {
            const loader = this.$loading.show();
            const component = this;

            const updatedLineOfTreatment = {
                line_name: this.form.lineName,
                backbone_drug_id: this.form.selectedDrug,
                backbone_drug_combination_id: this.form.selectedDrugCombination
            };

            axios.put(`${this.sLineOfTreatmentUrl}?cohort_id=${this.cohortId}&id=${this.lineId}`, updatedLineOfTreatment, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'SubmitterViewLinesOfTreatmentPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            editedCohortId: component.cohortId,
                            role: component.role
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
