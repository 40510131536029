<template>
    <div>
        <h3>Update Delivery Workspaces</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateDeliveryWorkspace">
                <b-form-group label="Workspace DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.id" disabled />
                </b-form-group>
                <b-form-group label="Workspace Project" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.namespace.$model" :state="v$.form.namespace.$dirty ? !v$.form.namespace.$error : null" />
                </b-form-group>
                <b-form-group label="Workspace Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.name.$model" :state="v$.form.name.$dirty ? !v$.form.name.$error : null" />
                </b-form-group>
                <b-form-group label="Collaborator Name" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="collaboratorOptions" v-model="v$.form.selectedCollaborator.$model" />
                </b-form-group>
                <b-form-group label="Experimental Strategy" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="strategyOptions" v-model="v$.form.selectedStrategy.$model" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Delivery Workspace
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    required,
    minLength,
    maxLength
} from '@vuelidate/validators';
import {
    getUrl, getCollaboratorsInfoPromise, getExperimentalStrategyEnumPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createCollaboratorDict, createExperimentalStrategyDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    props: {
        workspaceId: {
            type: Number,
            required: true
        },
        // For when collaborators are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false }
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // options for form dropdowns
            collaboratorOptions: [],
            strategyOptions: [],

            // urls
            sampleCoordWorkspacesUrl: getUrl('sampleCoordinatorDeliveryWorkspacesUrl'),

            // form data
            form: {
                // dropdowns
                selectedCollaborator: null,
                selectedStrategy: null,
                // inputs
                id: this.workspaceId,
                namespace: null,
                name: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // dropdowns
                selectedCollaborator: { required },
                selectedStrategy: { required },
                namespace: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(64)
                },
                name: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(64)
                }
            }
        }
    },
    watch: {
    },
    mounted() {
        this.populateWorkspaceForm();
    },
    methods: {
        populateWorkspaceForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getCollaboratorsInfoPromise(),
                getExperimentalStrategyEnumPromise(),
                component.getWorkspaceInfo()
            ])
                .then(axios.spread((collaboratorResp, strategyResp, workspaceResp) => {
                    const collaborators = createCollaboratorDict(collaboratorResp.data.collaborators);
                    const experimentalStrategies = createExperimentalStrategyDict(strategyResp.data.experimental_strategy);
                    const workspaceData = workspaceResp.data.delivery_workspaces[0];

                    // populate form with current workspace data from db
                    component.form.namespace = workspaceData.namespace;
                    component.form.name = workspaceData.name;
                    component.form.selectedCollaborator = collaborators[workspaceData.collaborator];
                    component.form.selectedStrategy = experimentalStrategies[workspaceData.experimental_strategy];

                    // populate dropdown options
                    component.collaboratorOptions = createDropdownOptions(collaborators, true, false);
                    component.strategyOptions = createDropdownOptions(experimentalStrategies, true, false);
                }))
                .finally(() => { loader.hide(); });
        },
        getWorkspaceInfo() {
            const getWorkspaceUrl = `${this.sampleCoordWorkspacesUrl}?id=${this.workspaceId}`;
            return axios.get(getWorkspaceUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        updateDeliveryWorkspace() {
            const loader = this.$loading.show();
            const component = this;
            const updateUrl = `${this.sampleCoordWorkspacesUrl}?id=${this.workspaceId}`;

            const updatedWorkspace = {
                namespace: this.form.namespace,
                name: this.form.name,
                collaborator_id: this.form.selectedCollaborator,
                experimental_strategy: this.form.selectedStrategy
            };

            axios.put(updateUrl, updatedWorkspace, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'AdminViewDeliveryWorkspacesPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }
};
</script>

<style>
#collaborators-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
