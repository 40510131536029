<template>
    <div class="center">
        <h2 v-if="pathExists === true">
            404 Error! The page at {{ path }} either does not exist, cannot be accessed directly, or you do not have the appropriate role privileges to view it.
        </h2>
        <h2 v-else-if="pathExists === false">
            404 Error! This page either does not exist, cannot be accessed directly, or you do not have the appropriate role privileges to view it.
        </h2>
    </div>
</template>

<script>
export default {
    props: {
        // these props are passed in after a participant is updated
        pathName: { default: '', type: String, required: false }
    },
    data() {
        return {
            path: this.pathName,
            pathExists: null
        };
    },
    mounted() {
        this.pathExists = this.path.length > 0;
    }
};
</script>

<style>
.center {
    text-align: center;
}
</style>
