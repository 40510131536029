<template>
    <div>
        <h3>Add Diagnostic Test Record</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addDiagnosticTestRecord">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="cohortOptions" v-model="$v.form.selectedCohort.$model" :state="$v.form.selectedCohort.$dirty ? !$v.form.selectedCohort.$error : null" />
                </b-form-group>
                <b-form-group label="Participant ID" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="participantOptions" v-model="$v.form.selectedParticipant.$model" :state="$v.form.selectedParticipant.$dirty ? !$v.form.selectedParticipant.$error : null" />
                </b-form-group>
                <b-form-group label="Diagnostic test category" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="diagTestCategoryOptions" v-model="$v.form.selectedDiagTestCategory.$model" :state="$v.form.selectedDiagTestCategory.$dirty ? !$v.form.selectedDiagTestCategory.$error : null" />
                </b-form-group>
                <b-form-group label="Diagnostic test date (days from diagnosis)" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.testDateDfd.$model" :state="$v.form.testDateDfd.$dirty ? !$v.form.testDateDfd.$error : null" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Add Diagnostic Test Record
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    between
} from '@vuelidate/validators';
import {
    getUrl, getDiagnosticTestCategoriesPromise, getSubmitterCohortsPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createDiagnosticTestCategoriesDict, createCohortsDict, createParticipantsDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    data() {
        return {
            // URLs used
            submitterParticipantUrl: getUrl('submitterParticipantsUrl'),
            submitterAddDiagnosticTestUrl: getUrl('submitterDiagnosticTestsUrl'),

            // options for form dropdowns
            cohortOptions: [],
            participantOptions: [],
            diagTestCategoryOptions: [],

            // form data
            form: {
                // dropdowns
                selectedCohort: null,
                selectedParticipant: null,
                selectedDiagTestCategory: null,
                // inputs
                testDateDfd: null,
                notes: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
        'form.selectedCohort': function () {
            // updates the participants dropdown when a new cohort is selected
            if (this.form.selectedCohort === null) {
                this.form.selectedParticipant = null;
                this.participantOptions = [];
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${this.submitterParticipantUrl}?cohort_id=${this.form.selectedCohort}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    component.participantOptions = createDropdownOptions(pDict);
                })
                .finally(() => {
                    loader.hide();
                    component.form.selectedParticipant = null;
                });
        }
    },
    validations: {
        form: {
            // dropdowns
            selectedCohort: { required },
            selectedParticipant: { required },
            selectedDiagTestCategory: { required },
            testDateDfd: {
                required,
                between: between(-36500, 36500)
            }
        }
    },
    mounted() {
        this.initDiagTestForm();
    },
    methods: {
        initDiagTestForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getSubmitterCohortsPromise(),
                getDiagnosticTestCategoriesPromise()
            ])
                .then(axios.spread((cohortResp, testCategoryResp) => {
                    const cohorts = createCohortsDict(cohortResp.data.cohorts);
                    const diagnosticTestCategories = createDiagnosticTestCategoriesDict(testCategoryResp.data.diagnostic_test_categories);

                    component.cohortOptions = createDropdownOptions(cohorts);
                    component.diagTestCategoryOptions = createDropdownOptions(diagnosticTestCategories);
                }))
                .finally(() => { loader.hide(); });
        },
        addDiagnosticTestRecord() {
            const loader = this.$loading.show();
            const component = this;

            const newDiagTestRecord = {
                cohort_id: this.form.selectedCohort,
                participant_id: this.form.selectedParticipant,
                test_category_id: this.form.selectedDiagTestCategory,
                test_date_dfd: this.form.testDateDfd,
                notes: this.form.notes === '' ? null : this.form.notes
            };

            axios.post(this.submitterAddDiagnosticTestUrl, newDiagTestRecord, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
