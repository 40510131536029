<template>
    <div>
        <h3>View Cohort Sync Results for {{ cohortName }}</h3>
        <hr>

        <h4>{{ accepted_sample_count }} samples accepted into cohort. Unmatched biospecimens in cohort were: </h4>
        <p> {{ unmatched_biospecimen_names }} </p>
        <!-- <br> -->

        <b-container fluid>
            <b-row id="sync-results-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="sync-results-table" class="display compact stripe" style="width:100%" />
            </b-row>
            <!-- Show only when the cohort name has been fetched and the sync results has been shown -->
            <b-button size="sm" style="font-size:0.85rem;" @click="backtoCohortsView" v-show="cohortName !== null">
                Go back to cohorts
            </b-button>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import { DATA_EDITOR } from '../utils/constants';
import store from '../store';

export default {
    props: {
        cohortId: {
            type: Number,
            required: true
        }
    },
    data() {
        const syncResp = store.state.syncResp;
        return {
            // urls
            projectsUrl: getUrl('projectsUrl'),

            // syncResp from store (instead of prop because it's a complex object)
            syncResp: syncResp,

            // inline vars
            cohortName: null,
            accepted_sample_count: syncResp.data.sync_cohort_data.accepted_sample_count,
            unmatched_biospecimen_array: syncResp.data.sync_cohort_data.unmatched_biospecimen_names,
            // table vars
            exportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            tableColumns: [
                { title: 'Delivery workspace', data: 'delivery_workspace' },
                { title: 'Workspace sample name', data: 'workspace_sample_name' },
                { title: 'Workspace sample sync status', data: 'status' },
                { title: 'Reject/Overwrite reason', data: 'reject_reason' },
                { title: 'Workspace sample alias', data: 'sample_alias' },
                { title: 'Matching biospecimen name', data: 'biospecimen_name' },
                { title: 'Matching biospecimen tumor/normal status', data: 'biospecimen_tumor/normal' },
                { title: 'Workspace sample type', data: 'workspace_sample_type' },
                { title: 'Workspace sample data type', data: 'data_type' },
                { title: 'Workspace experimental strategy', data: 'experimental_strategy' },
                { title: 'ULPWGS tumor fraction', data: 'ulp_tumor_fraction' }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]

        };
    },
    computed: {
        unmatched_biospecimen_names() {
            return Array.isArray(this.unmatched_biospecimen_array) && this.unmatched_biospecimen_array.length > 0 ? this.unmatched_biospecimen_array.join(', ') : 'No unmatched biospecimens';
        }
    },
    mounted() {
        this.populateCohortNameAndTable(this.cohortId, this.syncResp);
    },
    methods: {
        populateCohortNameAndTable(cohortId, syncResp) {
            const loader = this.$loading.show();
            const component = this;
            axios.get(`${component.projectsUrl}?id=${cohortId}`, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const { cohorts } = resp.data;
                    component.cohortName = cohorts[0].name;
                })
                .then(() => {
                    loader.hide();
                    this.populateSyncResultsTable(syncResp);
                });
        },
        populateSyncResultsTable(syncResp) {
            const component = this;
            component.showTable();
            const { sync_cohort_data } = syncResp.data;
            $('#sync-results-table').DataTable({
                destroy: true,
                data: sync_cohort_data.sync_results,
                columns: component.tableColumns,
                order: [], // no sorting, initially
                lengthMenu: component.tablePaginationOptions,
                dom: 'Blfrtip',
                scrollY: '45vh',
                scrollCollapse: true,
                buttons: [
                    {
                        extend: 'copy',
                        title: '#sample',
                        exportOptions: { columns: component.exportColumns },
                        customize(exportData) {
                            const exportRows = exportData.split('\n');
                            exportRows.splice(1, 1); // removing extra space that isn't needed
                            const headerRow = component.updateExportColumnNames(exportRows[1]);
                            exportRows[1] = headerRow;
                            return exportRows.join('\n');
                        }
                    },
                    {
                        text: 'TSV',
                        extend: 'csv',
                        fieldSeparator: '\t',
                        fieldBoundary: '',
                        title: 'sample',
                        extension: '.txt',
                        exportOptions: { columns: component.exportColumns },
                        customize(exportData) {
                            let exportRows = exportData.split('\n');
                            const headerRow = component.updateExportColumnNames(exportRows[0]);
                            exportRows[0] = headerRow;
                            exportRows = ['#sample'].concat(exportRows);
                            return exportRows.join('\n');
                        }
                    }
                ]
            });
        },
        backtoCohortsView() {
            this.$router.push({
                name: 'UserViewProjectsPage',
            });
        },
        hideTable() {
            $('#sync-results-table-wrapper').hide();
        },
        showTable() {
            $('#sync-results-table-wrapper').show();
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        }
    }

};
</script>

<style>
#sync-results-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
