<template>
    <div>
        <h3>View Treatments</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>

                    <b-form-group label="Participant ID" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!cohortSelected" v-model="selectedParticipant" :options="participantOptions" />
                    </b-form-group>

                    <b-form-group label="Expanded View" label-class="font-weight-bold">
                        <b-form-checkbox switch size="sm" :disabled="!participantSelected" v-model="expandedView" />
                    </b-form-group>
                </b-form>
            </b-col>
            <!-- <b-col>
                <b-table small caption-top striped hover :items="lineTable">
                    <template #table-caption>
                        Line Of Treatments
                    </template>
                </b-table>
            </b-col> -->
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="treatment-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="treatment-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import {
    createCohortsDict,
    createParticipantsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';
import getUserToken from '../utils/auth';
import store from '../store';

export default {
    props: {
        // these props are passed in after a treatment is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCohortId: { default: null, type: Number, required: false },
        // this is the participant_id i.e. the submitted name
        editedParticipantName: { default: null, type: String, required: false },
    },
    data() {
        const storeRoles = store.state.user.roles;
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',
            roles: storeRoles,

            // cohort specific role info
            readerCohorts: storeRoles.readerCohorts,
            dataEditorCohorts: storeRoles.dataEditorCohorts,
            dataAnalystCohorts: storeRoles.dataAnalystCohorts,
            projectLeadsCohorts: storeRoles.projectLeadCohorts,

            // checking for an edit
            treatmentEdited: !!this.editedCohortId,

            // URLs used
            projectsUrl: getUrl('projectsUrl'),
            participantsUrl: getUrl('participantsUrl'),
            treatmentsUrl: getUrl('treatmentsUrl'),
            linesUrl: getUrl('linesOfTreatmentUrl'),
            submitterBulkDeleteUrl: getUrl('submitterBulkDeleteUrl'),

            // form view controls
            cohortSelected: false,
            participantSelected: false,

            // cohort dropdown selections
            selectedCohort: null,
            cohortOptions: [],

            // participant dropdown selections
            selectedParticipant: null,
            participantOptions: [],

            // expanded view type selection
            expandedView: false,

            // selection checkboxes for bulk delete
            selectedRows: new Set(),

            // table vars
            tableColumns: null,
            selectionColumn: {
                title: 'Select for deletion',
                sortable: false,
                render(data, type, row) {
                    const checkboxId = `cb_${row.db_id}`;
                    const isDisabled = row.primary_cohort === true ? '' : 'disabled'; // disable checkboxes for treatments not belonging to this cohort
                    const checkboxHtml = `<input type="checkbox" class="select-row" ${isDisabled} id="${checkboxId}"/>`;
                    return checkboxHtml;
                }
            },
            condensedExportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], // will export these column numbers (or increment by 1 if selection column is there)
            condensedTableColumns: [
                { title: 'DBKey', data: 'db_id' },
                { title: 'ParticipantID', data: 'participant_id' },
                { title: 'Associated drug categories', data: 'categories'}, // replaces treatment categories of yore
                { title: 'Drug Treatment', data: 'drug_treatment' },
                { title: 'Drugs', data: 'drugs' },
                { title: 'Drug Combinations', data: 'drug_combinations' },
                { title: 'Line of Treatment', data: 'line_of_treatment' },
                { title: 'Treatment regimen', data: 'treatment_regimen' },
                { title: 'Start date (dfd)', data: 'start_date_dfd' },
                { title: 'Stop date (dfd)', data: 'stop_date_dfd' },
                { title: 'Stop reason', data: 'stop_reason' },
                { title: 'Pre-status', data: 'pre_status' },
                { title: 'Post-status', data: 'post_status' },
                { title: 'Notes', data: 'notes' },
                { title: 'Primary Cohort', data: 'primary_cohort' },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            expandedExportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], // will export these column numbers
            expandedTableColumns: [
                { title: 'DBKey', data: 'treatment_id' },
                { title: 'ParticipantID', data: 'participant_id' },
                { title: 'Associated drug categories', data: 'drug_categories' },
                { title: 'Drug Treatment', data: 'drug_treatment' },
                { title: 'Expanded drug name', data: 'drug' },
                { title: 'Drug combination names', data: 'drug_combination' },
                { title: 'Line of Treatment', data: 'line_of_treatment' },
                { title: 'Start date (dfd)', data: 'start_date_dfd' },
                { title: 'Stop date (dfd)', data: 'stop_date_dfd' },
                { title: 'Stop reason', data: 'stop_reason' },
                { title: 'Pre-status', data: 'pre_status' },
                { title: 'Post-status', data: 'post_status' },
                { title: 'Notes', data: 'notes' },
                { title: 'Primary Cohort', data: 'primary_cohort' }
            ],
            sColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span edit-treatment"">Edit</span>';
                        }
                        return '';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render(data, type, row) {
                        if (row.primary_cohort === true) {
                            return '<span class="text-btn-span delete-treatment">Delete</span>';
                        }
                        return '';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ],
            // this will be the items object that the line of treatments legend table will default back to
            // defaultlineTable: [
            //     {
            //         cohort_name: null,
            //         line_name: null,
            //         backbone_drug: null
            //     }
            // ],
            // initialize the items for the lines of treatments legend table to the values from the default
            // lineTable: [
            //     {
            //         cohort_name: null,
            //         line_name: null,
            //         backbone_drug: null
            //     }
            // ]
        };
    },
    watch: {
        selectedCohort() {
            // this.lineTable = [...this.defaultlineTable]; // reset line of treatment legend table by setting it to a copy of the default
            // update participants dropdown when new cohort is selected
            if (this.selectedCohort === null) {
                this.selectedParticipant = null;
                this.participantOptions = [];
                this.cohortSelected = false;
                this.hideTable();
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            const participantsUrl = component.populateParticipantsUrl();
            axios.get(`${participantsUrl}?cohort_id=${this.selectedCohort}&primary_only=false`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    const nullOption = {
                        value: null,
                        text: 'Please select an option'
                    };
                    const allOption = {
                        value: -1,
                        text: 'All'
                    };
                    const pOptions = createDropdownOptions(pDict, true, false);
                    component.participantOptions = [nullOption, allOption].concat(pOptions);

                    component.cohortSelected = true;
                    // checking if a treatment was edited, and automatically showing treatment records for that participant
                    if (component.treatmentEdited) {
                        component.treatmentEdited = false; // setting this to false, so this isn't triggerred if another cohort is selected
                        const editedParticipant = component.participantOptions.filter(x => x.text === component.editedParticipantName)[0];
                        component.selectedParticipant = editedParticipant.value;
                    } else {
                        component.selectedParticipant = null;
                    }
                })
                .finally(() => {
                    loader.hide();
                });
        },
        selectedParticipant() {
            // this.lineTable = [...this.defaultlineTable]; // reset line of treatment legend table by setting it to a copy of the default
            if (this.selectedParticipant === null) {
                this.participantSelected = false;
                this.hideTable();
                return;
            }
            // table must be reset if participant changes since number of columns may change based on permissions
            let table = $('#manifest-table');
                if ($.fn.DataTable.isDataTable(table)) {
                    table.DataTable().clear().draw();
                    table.DataTable().destroy();
                    table.empty(); 
                }
            this.participantSelected = true;
            this.getTreatmentsTable();
        },
        expandedView() {
            this.getTreatmentsTable();
        }
    },
    beforeMount() {
        this.getCohorts();
    },
    beforeDestroy() {
        if ($.fn.dataTable.isDataTable('#treatment-table')) {
            let table = $('#treatment-table').DataTable();
            table.clear().draw();
            table.destroy();
        }
        this.hideTable()
    },
    methods: {
        populateCohortsUrl() {
            return this.projectsUrl;
        },
        populateParticipantsUrl() {
            return this.participantsUrl;
        },
        populateTreatmentsUrl() {
            return this.treatmentsUrl;
        },
        bulkDeleteUrl() {
            if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                return this.submitterBulkDeleteUrl;
            }
            else {
                console.error('Invalid role for treatment bulk deletion.');
                return undefined;
            }
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            const cohortsUrl = component.populateCohortsUrl();
            axios.get(cohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);

                    // will be true if a treatment was edited
                    if (component.treatmentEdited) {
                        component.selectedCohort = component.editedCohortId;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        deleteTreatment(cohortId, treatmentId) {
            const component = this;
            const loader = component.$loading.show();
            const deleteUrl = `${component.populateTreatmentsUrl()}?id=${treatmentId}&cohort_id=${cohortId}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getTreatmentsTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete treatment. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        confirmBulkDeleteTreatments() {
            const component = this;
            const { selectedRows } = component; // object destructuring, equivalent to const selectedRows = component.selectedRows
            const numSelected = selectedRows.size;
            const selectedList = [...selectedRows].sort();
            const maxListNum = 100;
            if (numSelected < 1) {
                return;
            }
            let confirmMessage = `Are you sure you want to delete these ${numSelected} selected treatments? `;
            confirmMessage = confirmMessage.concat(selectedList.slice(0, maxListNum).join(', '));
            if (numSelected > maxListNum) {
                confirmMessage = confirmMessage.concat('...');
            }
            const msgBoxOptions = {
                hideHeader: true,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Bulk delete treatments',
                centered: true
            };
            component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                .then((deleteConfirmed) => {
                    if (deleteConfirmed) {
                        component.bulkDeleteTreatments(component.selectedCohort, [...component.selectedRows]);
                    }
                });
        },
        bulkDeleteTreatments(cohortId, participantIds) {
            const component = this;
            const loader = component.$loading.show();
            const bulkDeleteUrl = `${component.bulkDeleteUrl()}`;
            const bulkDeleteData = {
                entity_type: 'treatment',
                bulk_delete_ids: participantIds,
                cohort_id: cohortId
            };
            axios.post(bulkDeleteUrl, bulkDeleteData, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = `Successfully deleted ${resp.data.deletions.length} treatments.`;
                    component.getTreatmentsTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to bulk delete treatments. Please contact portal administrators.';
                    }
                    component.alertMessage = `Failed to bulk delete treatments. ${err.response.data.error}`;
                    window.scrollTo(0, 0);
                });
        },
        addTableActions(datatable) {
            const component = this;
            if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                $('#treatment-table tbody .edit-treatment').click(function editTreatment() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const selectedCohortOption = component.cohortOptions.filter(x => x.value === component.selectedCohort)[0];
                    component.$router.push({
                        name: 'DataEditorEditTreatmentPage',
                        params: {
                            role: component.role,
                            cohortId: selectedCohortOption.value,
                            cohortName: selectedCohortOption.text,
                            treatmentId: rowData.db_id
                        }
                    });
                });

                $('#treatment-table tbody .select-row').click(function updateRowSelection() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const dbId = rowData.db_id;
                    const checkboxId = `#cb_${dbId}`;
                    const selected = $(checkboxId).prop('checked');
                    if (selected) {
                        component.selectedRows.add(dbId);
                    } else {
                        component.selectedRows.delete(dbId);
                    }
                });

                $('#treatment-table tbody .delete-treatment').click(function confirmTreatmentDelete() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete treatment ${rowData.db_id} for participant ${rowData.participant_id}?`;
                    if(confirm(confirmMessage)){
                        component.deleteTreatment(component.selectedCohort, rowData.db_id);
                    }
                });
                // if (this.expandedView === false) {
                //     component.addSelectDeleteButtons(datatable);
                // }
            }
        },
        getTableColumns() {
            let { tableColumns } = this;
            /* condensed */
            if (this.expandedView === false) {
                tableColumns = this.condensedTableColumns;
                if (this.dataEditorCohorts && this.dataEditorCohorts.includes(this.selectedCohort)) {
                    tableColumns = [this.selectionColumn, ...tableColumns]; // selection column comes first
                    tableColumns = tableColumns.concat(this.sColumns);
                }
            /* expanded */
            } else {
                tableColumns = this.expandedTableColumns;
            }
            return tableColumns;
        },
        getExportColumns() {
            const component = this;
            if (component.expandedView) {
                return component.expandedExportColumns;
            }
            if (this.roles.isDataEditor) { // submitters have a selection column first, incrementing the others
                return Array.from(component.condensedExportColumns, col => col + 1);
            }
            return component.condensedExportColumns; // condensed view, not a submitter
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            const tableColumns = component.getTableColumns();
            const exportColumns = component.getExportColumns();
            exportColumns.forEach((i) => {
                const col = tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        hideTable() {
            $('#treatment-table thead').hide();
            $('#treatment-table-wrapper').hide();
        },
        showTable() {
            $('#treatment-table thead').show();
            $('#treatment-table-wrapper').show();
        },
        // getLinesOfTreatmentPromise() {
        //     // return this.linesUrl;
        //     return axios.get(this.linesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        // },
        addSelectDeleteButtons(datatable) {
            const component = this;
            new $.fn.dataTable.Buttons(datatable, { // eslint-disable-line no-new
                name: 'selectDeleteButtons',
                buttons: [
                    {
                        text: 'Select All',
                        className: 'bulk-delete-btn',
                        action: function selectAllRows() {
                            this.rows().every((rowIdx) => {
                                const rowData = datatable.row(rowIdx).data();
                                if (rowData.primary_cohort === true) {
                                    const dbId = rowData.db_id;
                                    const checkboxId = `#cb_${dbId}`;
                                    $(checkboxId).prop('checked', true);
                                    component.selectedRows.add(dbId);
                                    return true;
                                }
                                return false;
                            });
                        }
                    },
                    {
                        text: 'Deselect All',
                        action: function deselectAllRows() {
                            this.rows().every((rowIdx) => {
                                const rowData = datatable.row(rowIdx).data();
                                const dbId = rowData.db_id;
                                const checkboxId = `#cb_${dbId}`;
                                $(checkboxId).prop('checked', false);
                                component.selectedRows.delete(dbId);
                                return false;
                            });
                        }
                    },
                    {
                        text: 'Delete Selected',
                        action: component.confirmBulkDeleteTreatments
                    }
                ]
            });

            datatable.buttons('selectDeleteButtons', null).containers().addClass('bulk-delete-btns').insertAfter(datatable.buttons(0, null).container());
        },
        getTreatmentsTable() {
            const component = this;
            const loader = this.$loading.show();
            let getTreatmentsUrl = this.selectedParticipant === -1 ? `${this.populateTreatmentsUrl()}?cohort_id=${this.selectedCohort}` : `${this.populateTreatmentsUrl()}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}`;
            getTreatmentsUrl = this.expandedView === false ? `${getTreatmentsUrl}&expanded=false` : `${getTreatmentsUrl}&expanded=true`;

            axios.get(getTreatmentsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    console.log(resp)
                    component.showTable();
                    const { treatments } = resp.data;
                    const cohortIds = resp.data.cohort_ids;

                    // // generate lines of treatment legend
                    // const linesOfTreatmentPromiseArray = [];
                    // component.lineTable = [...component.defaultlineTable]; // reset line of treatment legend table by setting it to a copy of the default

                    // // iterate through the cohort Ids and create promise functions to get lines of treatment for those cohorts
                    // cohortIds.forEach((cohortId) => {
                    //     linesOfTreatmentPromiseArray.push(component.getLinesOfTreatmentPromise());
                    // });
                    // console.log(linesOfTreatmentPromiseArray)
                    // axios.all(linesOfTreatmentPromiseArray)
                    //     .then((responses) => {
                    //         console.log(responses)
                    //         // iterate through the line of treatment response for each requested cohort and
                    //         // collect the line of treatment data into an array for the line of treatment legend table
                    //         responses.forEach((lineResponse) => {
                    //             console.log(lineResponse)
                    //             const currCohortLines = lineResponse.data.lines_of_treatment;
                    //             currCohortLines.forEach((currLine) => {
                    //                 const lineItem = {
                    //                     cohort_name: currLine.cohort_name,
                    //                     line_name: currLine.line_name,
                    //                     backbone_drug: currLine.backbone_drug.length === 0 ? 'Needs information from collaborator' : currLine.backbone_drug
                    //                 };
                    //                 component.lineTable.push(lineItem);
                    //             });
                    //         });
                    //     });

                    // generate treatments table
                    if ($.fn.DataTable.isDataTable('#treatment-table')) {
                        $('#treatment-table').DataTable().destroy();
                    }
                    $('#treatment-table tbody').empty();
                    $('#treatment-table thead').empty();
                    const table = $('#treatment-table').DataTable({
                        data: treatments,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '50vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: component.expandedView === false ? '#treatment' : '#drug_event',
                                exportOptions: { columns: component.getExportColumns() },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: component.expandedView === false
                                    ? `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-treatments`
                                    : `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-drug_events`,
                                extension: '.txt',
                                exportOptions: { columns: component.getExportColumns() },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = [component.expandedView === false ? '#treatment' : '#drug_event'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: component.expandedView === false
                                    ? `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-treatments`
                                    : `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-drug_events`,
                                extension: '.txt',
                                exportOptions: { columns: component.getExportColumns() },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = [component.expandedView === false ? '#treatment' : '#drug_event'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .finally(() => loader.hide());
        }
    }
};
</script>

<style>
.bulk-delete-btns {
    border: 1px solid black;
    padding: 4px 2px 2px 4px;
    width: fit-content;
}
</style>