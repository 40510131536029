<template>
    <div>
        <h3>View Production Analysis Workspace Attributes</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="attributes-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="attributes-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import { LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST } from '../utils/constants';

export default {
    props: {
        // For when attributes sets are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        // this prop is passed to indicate what type of user is viewing participants
        role: {
            type: String,
            required: true,
            validator(val) {
                return [LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // urls
            leadDataAnalystWorkspaceAttributesUrl: getUrl('leadDataAnalystWorkspaceAttributesUrl'),
            uberLeadDataAnalystWorkspaceAttributesUrl: getUrl('uberLeadDataAnalystWorkspaceAttributesUrl'),

            // table vars
            exportColumns: [0, 1, 2],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Attribute Name', data: 'attribute_name' },
                { title: 'Attribute Value', data: 'attribute_value' },
                { title: 'Last Edited By', data: 'submitter' },
                {
                    title: 'Last Modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            ldaTableColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-attribute">Edit</span>';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span delete-attribute">Delete</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    watch: {
    },
    mounted() {
        this.populateAttributesTable();
    },
    methods: {
        populateEditPageRoute() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return 'LeadDataAnalystEditWorkspaceAttributesPage';
            case UBER_LEAD_DATA_ANALYST:
                return 'UberLeadDataAnalystEditWorkspaceAttributesPage';
            default:
                console.error('Invalid role');
                return undefined;
            }
        },
        populateAttributesUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.leadDataAnalystWorkspaceAttributesUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uberLeadDataAnalystWorkspaceAttributesUrl;
            default:
                console.error('Invalid role for attributes table.');
                return undefined;
            }
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        getTableColumns() {
            let { tableColumns } = this;
            if (this.role === LEAD_DATA_ANALYST || this.role === UBER_LEAD_DATA_ANALYST) {
                tableColumns = tableColumns.concat(this.ldaTableColumns);
            }
            return tableColumns;
        },
        populateAttributesTable() {
            const loader = this.$loading.show(); // loader while waiting for attributes GET request
            const component = this;

            axios.get(component.populateAttributesUrl(), {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((attributeResp) => {
                    loader.hide();
                    const attributeTableData = attributeResp.data.workspace_attributes;
                    const table = $('#attributes-table').DataTable({
                        destroy: true,
                        data: attributeTableData,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '70vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#workspace_attribute',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'PrAn_workspace_attributes',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#workspace-attribute'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'PrAn_workspace_attributes',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#workspace-attribute'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .finally(() => loader.hide());
        },
        deleteAttribute(attributeId) {
            const component = this;
            const loader = component.$loading.show();
            const deleteUrl = `${this.populateAttributesUrl()}?id=${attributeId}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.populateAttributesTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete workspace attribute. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        addTableActions(datatable) {
            const component = this;
            $('#attributes-table tbody .edit-attribute').click(function editAttribute() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.$router.push({
                    name: component.populateEditPageRoute(),
                    params: {
                        attributeId: rowData.id,
                        role: component.role
                    }
                });
            });
            $('#attributes-table tbody .delete-attribute').click(function confirmAttributeDelete() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const confirmMessage = `Are you sure you want to delete attribute ${rowData.attribute_name}?`;
                const msgBoxOptions = {
                    hideHeader: true,
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Delete attribute',
                    centered: true
                };

                component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                    .then((deleteConfirmed) => {
                        if (deleteConfirmed) {
                            component.deleteAttribute(rowData.id);
                        }
                    });
            });
        }
    }
};
</script>

<style>
#collaborators-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
