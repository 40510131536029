<template>
    <div>
        <h3>PrAn Sample Status</h3>
        <hr>
        <b-container fluid>
            <b-tabs content-class="mt-3" fill>
                <!--the offscreen table"s initial render is wonky unless both tabs are active-->
                <b-tab id="sync-results-problem-table-tab" title="Problems" active>
                    <table id="results-problem-table" class="display compact stripe" />
                </b-tab>
                <b-tab id="sync-results-sample-table-tab" title="Samples" active>
                    <b-alert :show="true" :variant="warning" dismissible>
                        When data appears in the "Succesful Workflows" column for a sample, it means that one or more of the listed workflows
                        has been run for some sample associated with the biospecimen of the same row.
                        The sample on a given row containing Successful Workflow data is not necessarily the sample on which the workflows were run.
                    </b-alert>
                    <table id="results-sample-table" class="display compact stripe" />
                </b-tab>
            </b-tabs>
        </b-container>
    </div>
</template>

<script>
export default {
    props: {
        // report data from the API
        reportData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            sampleData: this.reportData.found_samples,
            problemData: this.reportData.problem_samples,
            sampleExportColumns: [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
            ],
            sampleColumns: [
                { title: 'DB Key' },
                { title: 'GP Sample ID' },
                { title: 'Cohort Name' },
                { title: 'Is Primary Cohort?' },
                { title: 'PrAn Name', defaultContent: 'No PrAn found' },
                { title: 'PrAn Status', defaultContent: 'N/A' },
                { title: 'PrAn Creation Time', defaultContent: 'N/A' },
                { title: 'PrAn Last Modified Time', defaultContent: 'N/A' },
                { title: 'Biospecimen ID' },
                { title: 'Collaborator Sample ID' },
                { title: 'Participant ID' },
                { title: 'Tumor/Normal Status' },
                { title: 'Analyte Type' },
                { title: 'Experimental Strategy' },
                { title: 'Bait Set Name' },
                { title: 'Delivery Workspace Name' },
                { title: 'Delivery Date' },
                { title: 'Successful RNA Workflows', defaultContent: 'None' },
                { title: 'Successful WES Matched Workflows', defaultContent: 'None' },
                { title: 'Successful WES Unmatched Workflows', defaultContent: 'None' },
                { title: 'Successful WGS Workflows', defaultContent: 'None' },
                { title: 'Analyzed ?', defaultContent: 'None' },
            ],
            problemExportColumns: [0, 1, 2],
            problemColumns: [
                { title: 'Sample ID', defaultContent: 'N/A' },
                { title: 'Cohort ID', defaultContent: 'N/A' },
                { title: 'Problem Description', defaultContent: 'N/A' },
                { title: 'Bam File Path', defaultContent: 'N/A' },
                { title: 'PrAn', defaultContent: 'N/A' },
                { title: 'Bam Attribute', defaultContent: 'N/A' }
            ],
            tablePaginationOptions: [
                [10, 25, 50, 100, -1], // pagination values; -1 displays all
                [10, 25, 50, 100, 'All'] // pagination display text
            ]
        };
    },
    mounted() {
        this.getSampleTable();
        this.getProblemTable();
    },
    methods: {
        getSampleTable() {
            const loader = this.$loading.show();
            const component = this;
            $('#results-sample-table').DataTable({
                destroy: true,
                data: component.sampleData,
                columns: component.sampleColumns,
                order: [],
                lengthMenu: component.tablePaginationOptions,
                dom: 'Blfrtip',
                fixedHeader: {
                    header: true
                },
                scrollX: true,
                scrollY: '80vh',
                scrollCollapse: true,
                buttons: component.getTableButtons(
                    component.sampleColumns,
                    component.sampleExportColumns
                )
            });
            loader.hide();
        },
        getProblemTable() {
            const loader = this.$loading.show();
            const component = this;
            $('#results-problem-table').DataTable({
                destroy: true,
                data: component.problemData,
                columns: component.problemColumns,
                order: [],
                lengthMenu: component.tablePaginationOptions,
                dom: 'Blfrtip',
                fixedHeader: {
                    header: true
                },
                scrollY: '80vh',
                scrollCollapse: true,
                buttons: component.getTableButtons(
                    component.problemColumns,
                    component.problemExportColumns
                )
            });
            loader.hide();
        },
        getTableButtons(columns, exportColumns) {
            return [
                {
                    extend: 'copy',
                    exportOptions: { columns: exportColumns },
                    customize(exportData) {
                        const exportRows = exportData.split('\n');
                        exportRows.splice(1, 1); // removing extra space that isn't needed
                        return exportRows.join('\n');
                    }
                },
                {
                    text: 'TSV',
                    title: 'PrAn_sample_status_report',
                    extend: 'csv',
                    fieldSeparator: '\t',
                    fieldBoundary: '',
                    extension: '.tsv',
                    exportOptions: { columns: exportColumns },
                    customize(exportData) {
                        let exportRows = exportData.split('\n');
                        exportRows = ['#results'].concat(exportRows);
                        return exportRows.join('\n');
                    }
                }
            ];
        }
    }
};
</script>
