<template>
    <div>
        <h3>View Cancer Types</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-form-select v-model="selectedCancerStagingSystem" class="col-lg-4 col-md-6 col-xs-12" :options="stagingSystemOptions" />
        <br><br>

        <b-container fluid>
            <b-row id="cancer-type-table-wrapper">
                <table id="cancer-type-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import {
    createCancerStagingSystemsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';
import getUserToken from '../utils/auth';
import store from '../store';

export default {
    props: {
        // these props are passed in after a cancer type is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCancerStagingSystemId: { default: null, type: Number, required: false },
    },
    data() {
        const storeRoles = store.state.user.roles;
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // user roles
            roles: storeRoles, 

            // checking if a cancer type was edited
            cancerTypeEdited: !!this.editedCancerStagingSystemId,

            // URLs used
            cancerStagingSystemsUrl: getUrl('cancerStagingSystemsUrl'),
            sampleCoordinatorCancerTypesUrl: getUrl('sampleCoordinatorCancerTypesUrl'),

            // cancer staging selection dropdown
            selectedCancerStagingSystem: null,
            stagingSystemOptions: [],

            // table vars
            exportColumns: [0, 1],
            cancerTypeTableColumns: [
                { title: 'DB Key', data: 'id' },
                { title: 'Cancer Type', data: 'cancer_type_name' },
                {
                    title: 'Last Modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            scTableColumns: [
                {
                    title: 'Edit Cancer Type',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-cancer-type">Edit</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    beforeMount() {
        this.getCancerStagingSystems();
    },
    watch: {
        selectedCancerStagingSystem(newCancerStagingSystem) {
            this.getCancerTypesTable(newCancerStagingSystem);
        }
    },
    methods: {
        populateCancerStagingSystemsUrl() {
            if (this.roles.isAdmin) {
                return this.cancerStagingSystemsUrl;
            }
            console.error('Invalid role for cancer types table.');
            return undefined;
        },
        populateCancerTypesUrl() {
            if (this.roles.isAdmin) {
                return this.sampleCoordinatorCancerTypesUrl;
            }
            console.error('Invalid role for cancer types table.');
            return undefined;
        },
        getCancerStagingSystems() {
            const loader = this.$loading.show();
            const component = this;
            axios.get(component.populateCancerStagingSystemsUrl(), { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cancerStagingSystems = createCancerStagingSystemsDict(resp.data.cancer_staging_systems);
                    component.stagingSystemOptions = createDropdownOptions(cancerStagingSystems);

                    // will have a value if a cancer type was edited
                    if (component.cancerTypeEdited) {
                        component.cancerTypeEdited = false;
                        component.selectedCancerType = component.editedCancerStagingSystemId;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        getTableColumns() {
            let { cancerTypeTableColumns } = this;
            if (this.roles.isAdmin) {
                cancerTypeTableColumns = cancerTypeTableColumns.concat(this.scTableColumns);
            }
            return cancerTypeTableColumns;
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.cancerTypeTableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        getCancerTypesTable(cancerStagingSystemId) {
            const component = this;
            const loader = this.$loading.show();
            const getCancerTypesUrl = `${component.populateCancerTypesUrl()}?staging_system_id=${cancerStagingSystemId}`;
            // getting cancer types info and creating table
            axios.get(getCancerTypesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cancerTypes = resp.data.cancer_types;
                    const table = $('#cancer-type-table').DataTable({
                        destroy: true,
                        data: cancerTypes,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#cancer_type',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.stagingSystemOptions.filter(x => x.value === component.selectedCancerStagingSystem)[0].text}-cancer-types`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#cancer_type'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        addTableActions(datatable) {
            const component = this;

            $('#cancer-type-table tbody .edit-cancer-type').click(function goToEditCancerTypePage() {
                const rowData = datatable.row($(this).parents('tr')).data();
                const selectedCancerStagingSystemOption = component.stagingSystemOptions.filter(x => x.value === component.selectedCancerStagingSystem)[0];
                component.$router.push({
                    name: 'AdminEditCancerTypePage',
                    params: {
                        cancerStagingSystemName: selectedCancerStagingSystemOption.text,
                        cancerStagingSystemId: selectedCancerStagingSystemOption.value,
                        cancerTypeId: rowData.id,
                    }
                });
            });
        }
    }
};
</script>

<style>
.dt-buttons {
    float: none !important;
    margin-bottom: 10px;
}

</style>
