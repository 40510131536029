<template>
    <div>
        <h3>Create a New Cohort</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form>
                <b-form-group label="Cohort Name" label-class="font-weight-bold" :description="requirements.cohortName">
                    <b-form-input required size="sm" v-model="$v.form.cohortName.$model" :state="$v.form.cohortName.$dirty ? !$v.form.cohortName.$error : null" />
                </b-form-group>

                <b-form-group label="Collaborator Name" label-class="font-weight-bold">
                    <b-form-select size="sm" v-model="form.selectedCollaborator" :options="collaboratorOptions" />
                </b-form-group>

                <b-form-group label="Cohort Description (optional)" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.cohortDescription" />
                </b-form-group>

                <b-form-group label="Cohort Leads (optional)" label-class="font-weight-bold" description="Press and hold the CMD key to select multiple cohort leads.">
                    <b-form-select multiple size="sm" v-model="form.selectedCohortLeads" :options="cohortLeadOptions" />
                </b-form-group>

                <b-form-group label="Lead Data Analysts (optional)" label-class="font-weight-bold" :description="form.leadDataAnalystDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedLeadAnalysts" :options="leadAnalystOptions" />
                </b-form-group>
            </b-form>
            <b-btn type="outline-secondary" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid" @click="createCohort">
                Create Cohort
            </b-btn>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    minLength, required
} from '@vuelidate/validators';
import { cohortNameCheck } from '../../utils/input-regex';
import getUserToken from '../../utils/auth';
import { getUrl, getCollaboratorsInfoPromise } from '../../utils/directory';
import { createDropdownOptions, createCollaboratorDict, createUsersDict } from '../../utils/form-data-helpers';
import { SAMPLE_COORDINATOR } from '../../utils/constants';

export default {
    data() {
        return {
            // URLs used
            scCohortsUrl: getUrl('sampleCoordinatorCohortsUrl'),
            scUsersUrl: getUrl('sampleCoordinatorUsersUrl'),

            // options for form dropdown
            collaboratorOptions: [],
            cohortLeadOptions: [],
            leadAnalystOptions: [],

            // form requirements
            requirements: {
                cohortName: 'Cohort name must be >= 2 characters and contain only alphanumeric characters, periods (.), dashes (-), and underscores (_).'
            },

            // selected cohort information
            form: {
                cohortName: '',
                cohortDescription: null,
                selectedCollaborator: undefined,
                selectedCohortLeads: [],
                selectedLeadAnalysts: [],
                leadDataAnalystDescription: 'Press and hold the CMD key to select multiple lead data analysts. All users who are assigned to be lead data analysts will also, by default, be assigned as data analysts if they have not been already.'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            cohortName: {
                required,
                minLength: minLength(2),
                cohortNameCheck
            }
        }
    },
    mounted() {
        this.populateCohortAddForm();
    },
    methods: {
        getUsersInfo() {
            return axios.get(this.scUsersUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateCohortAddForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getCollaboratorsInfoPromise(),
                this.getUsersInfo()
            ])
                .then(axios.spread((collabResp, uResp) => {
                    loader.hide();

                    const collaborators = createCollaboratorDict(collabResp.data.collaborators);
                    const users = createUsersDict(uResp.data.users);

                    // using DB IDs as the value
                    component.collaboratorOptions = createDropdownOptions(collaborators, true, false);
                    const userOptions = createDropdownOptions(users, true, false);
                    component.cohortLeadOptions = userOptions;
                    component.leadAnalystOptions = userOptions;

                    // selecting first collaborator as default
                    component.form.selectedCollaborator = component.collaboratorOptions[0].value;
                }));
        },
        createCohort() {
            this.addError = false; // hiding error alert message in case it was showing
            const component = this;
            const loader = this.$loading.show();
            const newCohortInfo = {
                name: this.form.cohortName,
                description: this.form.cohortDescription === '' ? null : this.form.cohortDescription,
                collaborator: this.form.selectedCollaborator,
                cohort_leads: this.form.selectedCohortLeads,
                lead_analysts: this.form.selectedLeadAnalysts
            };
            axios.post(this.scCohortsUrl, newCohortInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.$router.push({
                        name: 'SampleCoordinatorViewCohortPage',
                        params: {
                            role: SAMPLE_COORDINATOR,
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                });
        }
    }

};
</script>

<style>

</style>
