import { helpers } from '@vuelidate/validators';

const biospecimenIdCheck = helpers.regex(/^([-+\w._()\\/]+)$/);
const biospecimenAliasCheck = helpers.regex(/^([-+\w ._()\\/]+)(, *[-+\w ._()\\/]+)*$/);
const participantIdCheck = helpers.regex(/^([-\w._]+)$/);
const participantAliasCheck = helpers.regex(/^([-\w._]+)(, *[-\w._]+)*$/);
const cohortNameCheck = helpers.regex(/^([-\w._]+)$/);
const authGroupCheck = helpers.regex(/^([-\w._]+)$/);
const drugAliasCheck = helpers.regex(/^([-\w._]+( [-\w._]+)*)(, *[-\w._]+( [-\w._]+)*)*$/);
const drugCombinationAliasCheck = helpers.regex(/^(\w+( \w+)*)(, *\w+( \w+)*)*$/);
const pranWorkspaceNameCheck = helpers.regex(/(.*)(-pran-)(\d+)/);
const smSampleIdCheck = helpers.regex(/^([S,s][M,m][-][A-Z,a-z,0-9]{5})$/);

export {
    biospecimenIdCheck,
    biospecimenAliasCheck,
    participantAliasCheck,
    participantIdCheck,
    cohortNameCheck,
    drugAliasCheck,
    drugCombinationAliasCheck,
    pranWorkspaceNameCheck,
    authGroupCheck,
    smSampleIdCheck,
};
