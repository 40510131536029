<template>
  <div>
    <h3>View Projects</h3>
    <div id="sample-status-report">
      <button v-on:click="reportAllSampleStatus">
        Report Sample Status for All Projects
      </button>
    </div>
    <hr />

    <b-alert :show="showAlert" :variant="alertVariant" dismissible>
      {{
        alertMessage.message === undefined ? alertMessage : alertMessage.message
      }}
    </b-alert>

    <b-form-group
      label="View Cohort Sharing"
      label-class="font-weight-bold"
    >
      <b-form-checkbox switch size="sm" v-model="sharedCohortsViewSelected" />
    </b-form-group>

    <p class="h6">
      Note: Any cohort with its name in bold indicates that it is a master
      cohort.
    </p>

    <b-container fluid>
      <b-row id="cohort-table-wrapper">
        <!-- showing/hiding table is controlled by jQuery and DataTables -->
        <table
          id="cohort-table"
          class="display compact stripe"
          style="width: 100%"
        />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import getUserToken from "../utils/auth";
import store from '../store';
import {
  PROJECT_LEAD,
  DATA_EDITOR,
  DATA_ANALYST,
  USER,
  ADMINISTRATOR
} from "../utils/constants";
import { getUrl } from "../utils/directory";

export default {
  props: {
    // these props are passed in after a cohort is modified or added
    needAlert: { default: false, type: Boolean, required: false },
    ajaxResponse: {
      default() {
        return { data: { message: "" } };
      },
      type: Object,
      required: false,
    },
  },
  data() {
    const storeRoles = store.state.user.roles;
    return {
      // error alert vars
      showAlert: this.needAlert,
      alertMessage: this.ajaxResponse.data,
      alertVariant: "warning",
      
      // user roles
      roles: storeRoles,

      // cohort specific role info
      readerCohorts: storeRoles.readerCohorts,
      dataEditorCohorts: storeRoles.dataEditorCohorts,
      dataAnalystCohorts: storeRoles.dataAnalystCohorts,
      projectLeadCohorts: storeRoles.projectLeadCohorts,

      // form view controls
      sharedCohortsViewSelected: false,
      deRole: DATA_EDITOR, // assigning the imported constant so the Vue hooks can access it
      plRole: PROJECT_LEAD,

      // url
      samplesUrl: getUrl("samplesUrl"),
      sampleStatusUrl: getUrl("sampleStatusReportUrl"),
      biospecimensUrl: getUrl("biospecimensUrl"),
      treatmentsUrl: getUrl("treatmentsUrl"),
      projectsUrl: getUrl("projectsUrl"),

      sharedCohortsUrl: getUrl("sharedCohortsUrl"),

      // to save off cohort data from regular SC Cohorts GET call
      cohortData: null,

      // these are columns shared by all tables
      columns: [
        { title: "Id", data: "id" },
        {
          title: "Name",
          data: "name",
          render(data, type, row) {
            if (row.num_cohorts_share_from === 0) {
              return `<b> ${data} <b>`;
            }
            return data;
          },
        },
        { title: "Description", data: "description" },
        { title: "Collaborator", data: "collaborator" },
        { title: "Getz Lab Project?", data: "lab_project"},
        { title: "Project Leads", data: "project_leads" },
        { title: "Data Editors", data: "data_editors" },
        { title: "Data Analysts", data: "data_analysts" },
        { title: "Participant Count", data: "num_participants" },
        { title: "Biospecimen Count", data: "num_biospecimens" },
        { title: "Sample Count", data: "num_samples" },
        { title: "Treatment Count", data: "num_treatments" },
        { title: "Diagnostic Test Count", data: "num_diagnostic_tests" },
        { title: "Clinical Update Count", data: "num_clinical_updates" },
      ],
      paginationOptions: [
        [-1, 10, 25, 50, 100], // pagination values; -1 displays all
        ["All", 10, 25, 50, 100], // pagination display text
      ],
      // additional columns displayed in table for sample coordinator role
      plColumns: [],
      // additional columns in table for submitter role
      sColumns: [],
    };
  },
  beforeMount() {
    this.setExtraColumns();
  },
  watch: {
    sharedCohortsViewSelected() {
      if (this.cohortData !== null && this.role === this.scRole) {
        if (this.sharedCohortsViewSelected === true) {
          this.getCohortsSharedColumns();
        } else if (this.sharedCohortsViewSelected === false) {
          const loader = this.$loading.show();
          this.hideTable();
          const tableColumns = this.getTableColumns();
          this.generateTable(this.cohortData, tableColumns, this);
          loader.hide();
        }
      }
    },
  },
  methods: {
    setExtraColumns() {
      const component = this;
      this.plColumns = [
        {
          title: "Edit Project",
          sortable: false,
          render(data, type, row) {
            if (component.projectLeadCohorts && component.projectLeadCohorts.includes(row.id)){
              return `<span class="text-btn-span edit-cohort">Edit</span>
                          <br/>
                          <span class="text-btn-span purge-cohort">Purge</span>
                          <br/>
                          <span class="text-btn-span delete-cohort">Delete</span>`;
            } else {
              return `<span class="text-span edit-cohort" style="color: grey;">Edit</span>
                          <br/>
                          <span class="text-span purge-cohort" style="color: grey;">Purge</span>
                          <br/>
                          <span class="text-span delete-cohort" style="color: grey;">Delete</span>`;
              }
          },
        },
        {
          title: "Report",
          sortable: false,
          render(data, type, row) {
            if (component.projectLeadCohorts && component.projectLeadCohorts.includes(row.id)){
              return `<span class="text-btn-span report-sample-status">PrAn Sample Status</span>`;
            } else {
              return `<span class="text-span report-sample-status" style="color: grey;">PrAn Sample Status</span>`;
            }
          }
        }]
        this.sColumns = [
          {
            title: "Clear",
            sortable: false,
            render(data, type, row) {
              if (component.dataEditorCohorts && component.dataEditorCohorts.includes(row.id)){
                console.log(row.id);
                return `<span class="text-btn-span clear-biospecimens">Clear \nBiospecimens</span>
                            <br/>
                            <span class="text-btn-span clear-treatments">Clear Treatments</span>`;
              } else {
                return `<span class="text-span clear-biospecimens" style="color: grey;">Clear \nBiospecimens</span>
                            <br/>
                            <span class="text-span clear-treatments" style="color: grey;">Clear Treatments</span>`;
              }
            },
          },
          {
            title: "Edit Samples",
            sortable: false,
            render(data, type, row) {
              if (component.dataEditorCohorts && component.dataEditorCohorts.includes(row.id)){
                return `<span class="text-btn-span sync-samples">Sync</span>
                            <br/>
                            <span class="text-btn-span clear-samples">Clear Samples</button>
                            `;
              } else {
                return `<span class="text-span sync-samples" style="color: grey;">Sync</span>
                            <br/>
                            <span class="text-span clear-samples" style="color: grey;">Clear Samples</button>
                            `;
              }
            }
          },
        ]
    },
    /**
     * Based off the role passed into this component, it determines the proper cohort URL to query.
     */
    populateCohortUrl() {
      return this.projectsUrl;
    },
    /**
     * Sample Coordinator table action that is called when the "Delete" button is clicked
     */
    deleteCohort(rowData) {
        const component = this;
        const confirmMessage = `Are you sure you want to delete cohort ${rowData.name}?`;
        const deleteCohortUrl = `${component.populateCohortUrl()}?id=${
        rowData.id
        }&data_only=false`;
        if (confirm(confirmMessage)) {
        const loader = component.$loading.show();
        axios.delete(deleteCohortUrl, {
            headers: { Authorization: `Bearer ${getUserToken()}` },
            })
            .then((resp) => {
                loader.hide();
                component.showAlert = true;
                component.alertVariant = "warning";
                component.alertMessage = resp.data.message;
                component.mountCohortsTable();
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                loader.hide();
                component.showAlert = true;
                component.alertVariant = "danger";
                if (err.response === undefined) {
                    component.alertMessage =
                    "Failed to delete cohort. Please contact portal administrators.";
                }
                else {
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                }
            });
        }
    },
    /**
     * Sample Coordinator table action that is called when the "Purge" button is clicked
     */
    purgeCohort(rowData) {
        const component = this;
        const confirmMessage = `Are you sure you want to remove all data in cohort ${rowData.name}?`;
            const deleteCohortUrl = `${component.populateCohortUrl()}?id=${
            rowData.id
            }&data_only=true`;
            if (confirm(confirmMessage)) {
                const loader = component.$loading.show();
            axios.delete(deleteCohortUrl, {
                headers: { Authorization: `Bearer ${getUserToken()}` },
            }).then((resp) => {
                loader.hide();
                component.showAlert = true;
                component.alertVariant = "warning";
                component.alertMessage = resp.data.message;
                component.mountCohortsTable();
                window.scrollTo(0, 0);
            }).catch((err) => {
                loader.hide();
                component.showAlert = true;
                component.alertVariant = "danger";
                if (err.response === undefined) {
                    component.alertMessage =
                    "Failed to delete cohort. Please contact portal administrators.";
                }
                component.alertMessage = err.response.data;
                window.scrollTo(0, 0);
            });
        }
    },
    // Sample coordinator action for when the "sync" button under "Edit Samples" is clicked
    syncCohort(rowData) {
      console.log(rowData);
      const component = this;
      const url = `${component.samplesUrl}?cohort_id=${rowData.id}`;
      const loader = component.$loading.show();
      axios
        .post(
          url,
          {},
          { headers: { Authorization: `Bearer ${getUserToken()}` } }
        )
        .then((resp) => {
          store.setSyncResp(resp);
          component.$router.push({
            name: "SyncCohortPage",
            params: {
              cohortId: rowData.id,
            },
          });
        })
        .catch((err) => {
          component.showAlert = true;
          component.alertVariant = "danger";
          if (err.response === undefined) {
            component.alertMessage =
              "Failed to sync cohort. Please contact portal administrators.";
          }
          component.alertMessage = err.response.data;
          window.scrollTo(0, 0);
        })
        .then(() => {
          loader.hide();
        });
    },
    reportAllSampleStatus: function () {
        const component = this;
        component.reportSampleStatusForCohort(-1);
    },
    // Asynchronously generate a PrAn Sample Status report
    reportSampleStatusForCohort: function (cohortId) {
      const component = this;
      const url = `${component.sampleStatusUrl}?cohortId=${cohortId}`;
      const loader = component.$loading.show();
      axios
        .post(
          url,
          {},
          { headers: { Authorization: `Bearer ${getUserToken()}` } }
        )
        .then((resp) => {
          component.alertMessage = resp.data.message;
          component.showAlert = true;
        })
        .finally(() => loader.hide());
    },
    // Sample coordinator action for when the "clear" button under "Edit Samples" is clicked
    clearSamples(rowData) {
        const component = this;
        const confirmMessage = `Are you sure you want to clear samples from cohort ${rowData.name}?`;
        const url = `${component.samplesUrl}?cohort_id=${rowData.id}`;
        if (confirm(confirmMessage)) {
            const loader = component.$loading.show();
            axios.delete(url, {
                headers: { Authorization: `Bearer ${getUserToken()}` },
            }).then((resp) => {
                loader.hide();
                component.showAlert = true;
                component.alertVariant = "warning";
                component.alertMessage = resp.data.message;
                component.mountCohortsTable();
                window.scrollTo(0, 0);
              })
              .catch((err) => {
                loader.hide();
                component.showAlert = true;
                component.alertVariant = "danger";
                component.alertMessage = err.response.data;
                if (err.response === undefined) {
                    component.alertMessage =
                    "Failed to clear samples from cohort. Please contact portal administrators.";
                }
                window.scrollTo(0, 0);
              })
          }
    },
    /**
     * Table actions for project lead table
     */
    addPLActions() {
      // if (!this.roles.isProjectLead) {
      //   return;
      // }
      const component = this;
      const datatable = $("#cohort-table").DataTable();

      // eslint-disable-next-line func-names
      $("#cohort-table tbody .edit-cohort").click(function editCohort() {
        const rowData = datatable.row($(this).parents("tr")).data();
        component.$router.push({
          name: "ProjectLeadEditProjectPage",
          params: { role: component.role, cohortId: rowData.id },
        });
      });

      // eslint-disable-next-line func-names
      $("#cohort-table tbody .purge-cohort").click(function purgeCohort() {
        const rowData = datatable.row($(this).parents("tr")).data();
        component.purgeCohort(rowData);
      });

      // eslint-disable-next-line func-names
      $("#cohort-table tbody .delete-cohort").click(function deleteCohort() {
        const rowData = datatable.row($(this).parents("tr")).data();
        component.deleteCohort(rowData);
      });

      $("#cohort-table tbody .report-sample-status").click(function reportSampleStatus() {
        const rowData = datatable.row($(this).parents("tr")).data();
        component.reportSampleStatusForCohort(rowData.id);
      });
    },
    /**
     * Submitter action for when the "Clear Biospecimens" button is clicked
     */
    clearBiospecimens(rowData) {
      const component = this;
      const confirmMessage = `Are you sure you want to clear biospecimens from the ${rowData.name} cohort?`;
        const url = `${component.biospecimensUrl}?cohort_id=${rowData.id}`;
        if (confirm(confirmMessage)) {
            const loader = component.$loading.show();
            axios.delete(url, {
                headers: { Authorization: `Bearer ${getUserToken()}` },
            }).then((resp) => {
                loader.hide();
                component.showAlert = true;
                component.alertVariant = "warning";
                component.alertMessage = resp.data.message;
                component.mountCohortsTable();
                window.scrollTo(0, 0);
            }).catch((err) => {
                loader.hide();
                component.alertVariant = "danger";
                if (err.response === undefined) {
                    component.alertMessage =
                    "Failed to clear biospecimens from cohort. Please contact portal administrators.";
                }
                component.alertMessage = err.response.data;
                window.scrollTo(0, 0);
            });
        }
    },
    /**
     * Submitter action for when the "Clear Treatments" button is clicked
     */
    clearTreatments(rowData) {
        const component = this;
        const confirmMessage = `Are you sure you want to clear treatments from the ${rowData.name} cohort?`;
        const url = `${component.treatmentsUrl}?cohort_id=${rowData.id}`;
        if (confirm(confirmMessage)) {
            const loader = component.$loading.show();
            axios.delete(url, {
                headers: { Authorization: `Bearer ${getUserToken()}` },
            })
            .then((resp) => {
                loader.hide();
                component.showAlert = true;
                component.alertVariant = "warning";
                component.alertMessage = resp.data.message;
                component.mountCohortsTable();
                window.scrollTo(0, 0);
            }).catch((err) => {
                loader.hide();
                component.alertVariant = "danger";
                if (err.response === undefined) {
                    component.alertMessage =
                    "Failed to clear treatments from cohort. Please contact portal administrators.";
                }
                component.alertMessage = err.response.data;
                window.scrollTo(0, 0);
            });
        };
    },
    /**
     * Table actions for submitter role
     */
    addSActions() {
      // if (!this.roles.isDataEditor) {
      //   return;
      // }

      const component = this;
      const datatable = $("#cohort-table").DataTable();

      $("#cohort-table tbody .clear-biospecimens").click(function clearBio() {
        const rowData = datatable.row($(this).parents("tr")).data();
        component.clearBiospecimens(rowData);
      });
      $("#cohort-table tbody .clear-treatments").click(function clearTreat() {
        const rowData = datatable.row($(this).parents("tr")).data();
        component.clearTreatments(rowData);
      });

      $("#cohort-table tbody .clear-samples").click(function clearSamples() {
        const rowData = datatable.row($(this).parents("tr")).data();
        component.clearSamples(rowData);
      });

      $("#cohort-table tbody .sync-samples").click(function syncCohort() {
        const rowData = datatable.row($(this).parents("tr")).data();
        component.syncCohort(rowData);
      });
    },
    /**
     * Function to add actions to buttons in the datatable
     * Buttons available are dependent on the user's cohort specific roles
     */
    addTableActions() {
        if (this.roles.isProjectLead) {
          this.addPLActions();
        }
        if (this.roles.isDataEditor) {
          this.addSActions();
        }
    },
    /**
     * Determines columns to be displayed for a particular user.
     * Columns will be dependent on the user's overall role
     */
    getTableColumns() {
      // overall projectLead and dataEditor columns given if a user has at least one cohort with such a role
      // don't show if a user has no cohorts with such a role, but show if a user has at least one cohort with such a role
      let { columns } = this;
      if (this.roles.isProjectLead) {
          columns = columns.concat(this.plColumns);
      }
      if (this.roles.isDataEditor) {
          columns = columns.concat(this.sColumns);
      }
      return columns;
    },
    /**
     * Populates the table
     */
    generateTable(cohortData, tableColumns, component) {
      // Destroy and clear the previous table and initialize the new datatable with the updated data
      if ($.fn.DataTable.isDataTable("#cohort-table")) {
        $("#cohort-table").DataTable().destroy();
      }
      $("#cohort-table tbody").empty();
      $("#cohort-table thead").empty();

      $("#cohort-table").DataTable({
        destroy: true,
        data: cohortData,
        columns: tableColumns,
        order: [], // no sorting, initially
        lengthMenu: component.paginationOptions,
        fixedHeader: {
          header: true,
        },
        scrollY: "70vh",
        scrollCollapse: true,
        fnInitComplete() {
          component.addTableActions();
        },
      });
      component.showTable();
    },
    mountCohortsTable() {
      const loader = this.$loading.show();
      const component = this;
      axios
        .get(`${component.populateCohortUrl()}?name_id_only=false`, {
          headers: { Authorization: `Bearer ${getUserToken()}` },
        })
        .then((resp) => {
          loader.hide();
          const { cohorts } = resp.data;
          cohorts.forEach((cohort) => {
            const x = cohort;
            x.project_leads = x.project_leads.join(", ");
            x.data_editors = x.data_editors.join(", ");
            x.data_analysts = x.data_analysts.join(", ");
          });
          const tableColumns = component.getTableColumns();
          component.generateTable(cohorts, tableColumns, component);
          component.cohortData = cohorts;
        });
    },
    getCohortsSharedColumns() {
      this.hideTable();
      const loader = this.$loading.show();
      const component = this;
      const updatedCohortData = [];
      axios
        .get(component.sharedCohortsUrl, {
          headers: { Authorization: `Bearer ${getUserToken()}` },
        })
        .then((resp) => {
          const sharedCohortColumns = resp.data.shared_cohorts;
          const sharedCohortMap = {};

          /*  Iterate through the response data from the cohort sharing GET URL and
                    map the id to the data object for quick assignment in the next block */
          sharedCohortColumns.forEach((sharedCohort) => {
            const x = sharedCohort;
            const { id } = x;
            x.cohorts_share_from = x.cohorts_share_from.join(", ");
            x.cohorts_share_to = x.cohorts_share_to.join(", ");
            delete x.id;
            sharedCohortMap[id] = x;
          });

          /*  Iterate through the cohort data appearing in the table from the regular cohorts GET call
                    and merge that data with the corresponding cohort sharing data from the map created before */
          component.cohortData.forEach((cohort) => {
            const updatedCohort = Object.assign(
              cohort,
              sharedCohortMap[cohort.id]
            );
            updatedCohortData.push(updatedCohort);
          });

          /*  Insert the cohort sharing columns into the array of columns (right after description column)
                    used previously in the table and also change the name column to be rendered as bold if the cohort of that row
                    has no cohorts it shares participants from */
          const updatedColumns = component.getTableColumns();
          let columnIdx = 0;
          const cohortsShareToColumn = {
            title: "Sharing To",
            data: "cohorts_share_to",
          };
          const cohortsShareFromColumn = {
            title: "Sharing From",
            data: "cohorts_share_from",
          };
          updatedColumns.every((column) => {
            if (column.title === "Description") {
              updatedColumns.splice(
                columnIdx,
                0,
                cohortsShareToColumn,
                cohortsShareFromColumn
              );
              return false; // break iterator
            }
            columnIdx += 1;
            return true; // continue iterator
          });
          component.generateTable(updatedCohortData, updatedColumns, component);
          loader.hide();
        });
    },
    hideTable() {
      $("#cohort-table-wrapper").hide();
    },
    showTable() {
      $("#cohort-table-wrapper").show();
      $("#cohort-table").DataTable().columns.adjust().draw(); // align column width based on data
    },
  },
  mounted() {
    this.mountCohortsTable();
  },
};
</script>

<style>
#cohort-table-wrapper {
  font-size: 0.85rem;
  overflow-x: auto;
}
</style>
