<template>
    <div>
        <h3>Add a New Cancer Staging System</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addCancerStagingSystem">
                <b-form-group label="Cancer Staging System Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.updateStagingSystemName.$model" :state="$v.form.updateStagingSystemName.$dirty ? !$v.form.updateStagingSystemName.$error : null" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Add Cancer Staging System
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { required, minLength, maxLength } from '@vuelidate/validators';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    data() {
        return {
            // URLs used
            sampleCoordinatorCancerStagingSystemsUrl: getUrl('sampleCoordinatorCancerStagingSystemsUrl'),

            // form data
            form: {
                // inputs
                updateStagingSystemName: null
            },
            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
    },
    validations: {
        form: {
            // dropdowns
            updateStagingSystemName: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(256)
            }
        }
    },
    mounted() {},
    methods: {
        addCancerStagingSystem() {
            const loader = this.$loading.show();
            const component = this;

            const newCancerStagingSystem = {
                staging_system_name: this.form.updateStagingSystemName
            };

            axios.post(this.sampleCoordinatorCancerStagingSystemsUrl, newCancerStagingSystem, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
