<template>
    <div>
        <h3>Report of Unsequenced Biospecimens</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>

                    <b-form-group label="Participant ID" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" :disabled="!cohortSelected" v-model="selectedParticipant" :options="participantOptions" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="biospecimen-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="biospecimen-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import {
    createCohortsDict,
    createParticipantsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';
import getUserToken from '../utils/auth';
import {
    SAMPLE_COORDINATOR,
    COHORT_LEAD,
    SUBMITTER
} from '../utils/constants';

export default {
    props: {
        // these props are passed in after a biospecimen is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        editedCohortId: { default: null, type: Number, required: false },
        // this is the participant_id i.e. the submitted name
        editedParticipantName: { default: null, type: String, required: false },
        // this prop is passed to indicate what type of user is viewing the biospecimens
        role: {
            type: String,
            required: true,
            validator(val) {
                return [SAMPLE_COORDINATOR, COHORT_LEAD, SUBMITTER].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // checking for an edit
            biospecimenEdited: !!this.editedCohortId,

            // URLs used
            submitterReportUnsequencedBiospecimensUrl: getUrl('submitterReportUnsequencedBiospecimensUrl'),
            submitterCohortsUrl: getUrl('submitterCohortsUrl'),
            submitterParticipantsUrl: getUrl('submitterParticipantsUrl'),
            submitterBiospecimensUrl: getUrl('submitterBiospecimensUrl'),
            sampleCoordinatorReportUnsequencedBiospecimensUrl: getUrl('sampleCoordinatorReportUnsequencedBiospecimensUrl'),
            sampleCoordinatorCohortsUrl: getUrl('sampleCoordinatorCohortsUrl'),
            sampleCoordinatorParticipantsUrl: getUrl('sampleCoordinatorParticipantsUrl'),
            sampleCoordinatorBiospecimensUrl: getUrl('sampleCoordinatorBiospecimensUrl'),
            cohortLeadReportUnsequencedBiospecimensUrl: getUrl('cohortLeadReportUnsequencedBiospecimensUrl'),
            cohortLeadCohortsUrl: getUrl('cohortLeadCohortsUrl'),
            cohortLeadParticipantsUrl: getUrl('cohortLeadParticipantsUrl'),
            cohortLeadBiospecimensUrl: getUrl('cohortLeadBiospecimensUrl'),


            // form view controls
            cohortSelected: false,

            // cohort dropdown selections
            selectedCohort: null,
            cohortOptions: [],

            // participant dropdown selections
            selectedParticipant: null,
            participantOptions: [],

            // table vars
            exportColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17], // will export these column numbers
            tableColumns: [
                { title: 'DBKey', data: 'db_id' },
                { title: 'Collaborator Sample ID', data: 'collaborator_sample_id' },
                { title: 'Aliases', data: 'aliases' },
                { title: 'Participant ID', data: 'participant_id' },
                { title: 'IRB protocols', data: 'irb_protocols' },
                { title: 'Collection date (dfd)', data: 'collection_date_dfd' },
                { title: 'Tissue site', data: 'tissue_site' },
                { title: 'Tissue site detail', data: 'tissue_site_detail' },
                { title: 'Original material type', data: 'original_material_type' },
                { title: 'Submitted material type', data: 'submitted_material_type' },
                { title: 'Preservation method', data: 'preservation_method' },
                { title: 'Primary Site', data: 'primary_site' },
                { title: 'Tumor/Normal', data: 'tumor_normal' },
                { title: 'Tumor state', data: 'tumor_state' },
                { title: 'Associated samples', data: 'associated_samples' },
                { title: 'Notes', data: 'notes' },
                { title: 'Submitter', data: 'submitter' },
                { title: 'Post-Allogeneic Stem Cell Transplant', data: 'post_allogeneic_transplant' },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            sColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-biospecimen">Edit</span>';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span delete-biospecimen">Delete</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]

        };
    },
    watch: {
        selectedCohort() {
            // update participants dropdown when new cohort is selected
            if (this.selectedCohort === null) {
                this.selectedParticipant = null;
                this.participantOptions = [];
                this.cohortSelected = false;
                this.hideTable();
                return;
            }
            const loader = this.$loading.show();
            const component = this;
            const participantsUrl = component.populateParticipantsUrl();
            axios.get(`${participantsUrl}?cohort_id=${this.selectedCohort}`, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((pResp) => {
                    const { participants } = pResp.data;
                    const pDict = createParticipantsDict(participants);
                    const nullOption = {
                        value: null,
                        text: 'Please select an option'
                    };
                    const allOption = {
                        value: -1,
                        text: 'All'
                    };
                    const pOptions = createDropdownOptions(pDict, true, false);
                    component.participantOptions = [nullOption, allOption].concat(pOptions);

                    component.cohortSelected = true;
                    // checking if a biospecimen was edited, and automatically showing biospecimen recprds for that participant
                    if (component.biospecimenEdited) {
                        component.biospecimenEdited = false; // setting this to false, so this isn't triggerred if another cohort is selected
                        const editedParticipant = component.participantOptions.filter(x => x.text === component.editedParticipantName)[0];
                        component.selectedParticipant = editedParticipant.value;
                    } else {
                        component.selectedParticipant = null;
                    }
                })
                .finally(() => {
                    loader.hide();
                });
        },
        selectedParticipant() {
            if (this.selectedParticipant === null) {
                this.hideTable();
                return;
            }
            this.getBiospecimensTable();
        }
    },
    beforeMount() {
        this.getCohorts();
    },
    methods: {
        populateCohortsUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.sampleCoordinatorCohortsUrl;
            case COHORT_LEAD:
                return this.cohortLeadCohortsUrl;
            case SUBMITTER:
                return this.submitterCohortsUrl;
            default:
                console.error('Invalid role for sample table.');
                return undefined;
            }
        },
        populateParticipantsUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.sampleCoordinatorParticipantsUrl;
            case COHORT_LEAD:
                return this.cohortLeadParticipantsUrl;
            case SUBMITTER:
                return this.submitterParticipantsUrl;
            default:
                console.error('Invalid role for sample table.');
                return undefined;
            }
        },
        populateBiospecimensUrl() {
            switch (this.role) {
            case SAMPLE_COORDINATOR:
                return this.sampleCoordinatorReportUnsequencedBiospecimensUrl;
            case COHORT_LEAD:
                return this.cohortLeadReportUnsequencedBiospecimensUrl;
            case SUBMITTER:
                return this.submitterReportUnsequencedBiospecimensUrl;
            default:
                console.error('Invalid role for sample table.');
                return undefined;
            }
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            const cohortsUrl = component.populateCohortsUrl();
            axios.get(cohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);

                    // will be true if a biospecimen was edited
                    if (component.biospecimenEdited) {
                        component.selectedCohort = component.editedCohortId;
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        deleteBiospecimen(cohortId, biospecimenId) {
            const component = this;
            const loader = component.$loading.show();
            const deleteUrl = `${component.submitterBiospecimensUrl}?id=${biospecimenId}&cohort_id=${cohortId}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getBiospecimensTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete biospecimen. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        addTableActions(datatable) {
            const component = this;
            if (this.role === SUBMITTER) {
                $('#biospecimen-table tbody .edit-biospecimen').click(function editBiospecimen() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const selectedCohortOption = component.cohortOptions.filter(x => x.value === component.selectedCohort)[0];
                    component.$router.push({
                        name: 'SubmitterEditBiospecimenPage',
                        params: {
                            role: component.role,
                            cohortId: selectedCohortOption.value,
                            cohortName: selectedCohortOption.text,
                            biospecimenId: rowData.db_id
                        }
                    });
                });
                $('#biospecimen-table tbody .delete-biospecimen').click(function confirmBiospecimenDelete() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete biospecimen ${rowData.collaborator_sample_id} for participant ${rowData.participant_id}?`;
                    const msgBoxOptions = {
                        hideHeader: true,
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Delete biospecimen',
                        centered: true
                    };

                    component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                        .then((deleteConfirmed) => {
                            if (deleteConfirmed) {
                                component.deleteBiospecimen(component.selectedCohort, rowData.db_id);
                            }
                        });
                });
            }
        },
        getTableColumns() {
            let { tableColumns } = this;
            if (this.role === SUBMITTER) {
                tableColumns = tableColumns.concat(this.sColumns);
            }
            return tableColumns;
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        hideTable() {
            $('#biospecimen-table-wrapper').hide();
        },
        showTable() {
            $('#biospecimen-table-wrapper').show();
        },
        getBiospecimensTable() {
            const component = this;
            const loader = this.$loading.show();
            const getBiospecimensUrl = this.selectedParticipant === -1 ? `${this.populateBiospecimensUrl()}?cohort_id=${this.selectedCohort}` : `${this.populateBiospecimensUrl()}?cohort_id=${this.selectedCohort}&participant_id=${this.selectedParticipant}`;

            axios.get(getBiospecimensUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const { biospecimens } = resp.data;
                    const table = $('#biospecimen-table').DataTable({
                        destroy: true,
                        data: biospecimens,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#biospecimen',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'biospecimen',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#biospecimen'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                })
                .finally(() => loader.hide());
        }
    }
};
</script>

<style>

</style>
