<template>
    <div>
        <h3>Release Production Analysis Workspace</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="6">
            <b-form @submit.stop.prevent="releaseWorkspace">
                <b-form-group label="Workspace ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.pranWorkspaceId" disabled />
                </b-form-group>
                <b-form-group label="Workspace Description" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.pranWorkspaceName" disabled />
                </b-form-group>
                <b-form-group label="PrAn Snapshot Description" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.pranSnapshotDescription" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;">
                    Release Workspace
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    getUrl
} from '../../utils/directory';
import getUserToken from '../../utils/auth';
import { LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST } from '../../utils/constants';

export default {
    name: 'LeadDataAnalystReleasePrAnWorkspacePage',
    props: {
        workspaceId: { type: Number, required: true },
        workspaceName: { type: String, required: true },
        cohortId: { type: Number, required: true },
        role: { type: String, required: true }
    },
    data() {
        return {
            // URLs used
            uberLeadDataAnalystPrAnWorkspacesUrl: `${getUrl('uberLeadDataAnalystPrAnWorkspacesUrl')}?id=${this.workspaceId}&cohort_id=${this.cohortId}&release=true`,
            leadDataAnalystPrAnWorkspacesUrl: `${getUrl('leadDataAnalystPrAnWorkspacesUrl')}?id=${this.workspaceId}&cohort_id=${this.cohortId}&release=true`,

            // form info
            form: {
                // unchangeable items
                pranWorkspaceId: this.workspaceId,
                pranWorkspaceName: this.workspaceName,
                pranWorkspaceCohortId: this.cohortId,

                // input
                pranSnapshotDescription: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    mounted() {},
    methods: {
        populateViewPrAnWorkspacesRoute() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return 'LeadDataAnalystViewPrAnWorkspacesPage';
            case UBER_LEAD_DATA_ANALYST:
                return 'UberLeadDataAnalystViewPrAnWorkspacesPage';
            default:
                console.error('Invalid role');
                return undefined;
            }
        },
        populateWorkspacesUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.leadDataAnalystPrAnWorkspacesUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uberLeadDataAnalystPrAnWorkspacesUrl;
            default:
                console.error('Invalid role for PrAn Workspace table.');
                return undefined;
            }
        },
        releaseWorkspace() {
            const loader = this.$loading.show();
            const component = this;

            const snapshotDescription = {
                snapshot_description: this.form.pranSnapshotDescription
            };

            axios.put(component.populateWorkspacesUrl(), snapshotDescription, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: component.populateViewPrAnWorkspacesRoute(),
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            role: this.role
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }
};
</script>

<style>

</style>
