<template>
    <div>
        <h3>View IRB Protocols</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="protocols-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="protocols-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    props: {
        // For when protocols are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // urls
            sampleCoordinatorIRBProtocolsUrl: getUrl('sampleCoordinatorIRBProtocolsUrl'),

            // table vars
            exportColumns: [0, 1, 2, 3],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Name', data: 'name' },
                { title: 'Institution', data: 'institution' },
                { title: 'Notes', data: 'notes' },
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-protocols">Edit</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    watch: {
    },
    mounted() {
        this.populateIRBProtocolsTable();
    },
    methods: {
        populateIRBProtocolsTable() {
            const loader = this.$loading.show(); // loader while waiting for categories GET request
            const component = this;

            axios.get(component.sampleCoordinatorIRBProtocolsUrl, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((protocolsResp) => {
                    loader.hide();
                    const protocolsTableData = protocolsResp.data.irb_protocols;
                    const table = $('#protocols-table').DataTable({
                        destroy: true,
                        data: protocolsTableData,
                        columns: component.tableColumns,
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '70vh',
                        scrollCollapse: true,
                        dom: 'Blfrtip',
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#irb_protocol',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'irb_protocols',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#irb_protocol'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'irb_protocols',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#irb_protocol'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                    component.addTableActions(table);
                });
        },
        addTableActions(datatable) {
            const component = this;
            $('#protocols-table tbody .edit-protocols').click(function editProtocol() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.$router.push({
                    name: 'SampleCoordinatorEditIRBProtocolPage',
                    params: {
                        protocolId: rowData.id
                    }
                });
            });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        }
    }
};
</script>

<style>
#protocols-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
