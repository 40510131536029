<template>
    <div id="portal-navbar">
        <b-navbar toggleable="false" type="dark" variant="dark">
            <ul class="navbar-nav">
                <b-navbar-brand to="/">
                    <!-- Getz Lab Project Portal<sup class="text-danger">v1.00.1</sup> -->
                    Getz Lab Project Portal<sup class="text-danger">BETA</sup>
                </b-navbar-brand>

                <b-navbar-toggle target="nav-collapse" />

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-text v-if="storeState.user.loadingRoles">
                            <font-awesome-icon icon="spinner" spin />
                        </b-nav-text>
                        <b-nav-item-dropdown v-if="storeState.user.roles.isAdmin" text="Manage Portal">
                            <b-dropdown-item :to="adminViewUsersRoute">
                                List registered users
                            </b-dropdown-item>
                            <b-dropdown-item :to="adminCreateProjectRoute">
                                Create project
                            </b-dropdown-item>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drugs</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="viewDrugsRoute">
                                        View and edit drugs
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="adminAddDrugRoute">
                                        Add drug
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drug Categories</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="adminViewDrugCategoriesRoute">
                                        View drug categories
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/admin/drug_category/add">
                                        Add drug category
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drug Combinations</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="viewDrugCombinationsRoute">
                                        View drug combinations
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/admin/drug_combination/add">
                                        Add drug combination
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Delivery Workspaces</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item to="/admin/delivery_workspace/view">
                                        View delivery workspaces
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/admin/delivery_workspace/add">
                                        Add delivery workspace
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Cancer Staging</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="adminViewCancerStagingSystemsRoute">
                                        View cancer staging systems
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="adminAddCancerStagingSystemsRoute">
                                        Add cancer staging system
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="adminViewCancerTypesRoute">
                                        View cancer types
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="adminAddCancerTypeRoute">
                                        Add cancer type
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="adminViewCancerStagesRoute">
                                        View cancer stages
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="adminAddCancerStageRoute">
                                        Add cancer stage
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Clinical Update Categories</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="adminViewClinUpdateCategoriesRoute">
                                        View clinical update categories
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/admin/clinical_update_category/add">
                                        Add clinical update category
                                    </b-dropdown-item>
                                </ul>
                            </li>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown v-if="storeState.user.roles.isUser" class="nav-item dropdown" text="Explore Data">
                            <b-dropdown-item to="/swimmer_plot">
                                View Swimmer Plot
                            </b-dropdown-item>
                            <b-dropdown-item :to="userViewProjectsRoute">
                                View Projects
                            </b-dropdown-item>
                            <b-dropdown-item :to="userViewParticipantsRoute">
                                View Participants
                            </b-dropdown-item>
                            <b-dropdown-item :to="userViewBiospecimensRoute">
                                View Biospecimens
                            </b-dropdown-item>
                            <b-dropdown-item :to="userViewTreatmentsRoute">
                                View Treatments
                            </b-dropdown-item>
                            <b-dropdown-item :to="userViewClinicalUpdatesRoute">
                                View Clinical Updates
                            </b-dropdown-item>
                            <b-dropdown-item :to="userViewSamplesRoute">
                                View Samples
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <!-- to add once CRUD functionality is ported over from other app -->
                        <b-nav-item-dropdown v-if="storeState.user.roles.isSampleCoord" class="nav-item dropdown" text="Sample Coordinator">
                            <b-dropdown-item :to="sampleCoordViewUsersRoute">
                                List registered users
                            </b-dropdown-item>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Cohorts</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewCohortsRoute">
                                        View cohorts
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/sample_coordinator/cohort/create">
                                        Create cohort
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Collaborator</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item to="/sample_coordinator/collaborator/view">
                                        View collaborators
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/sample_coordinator/collaborator/add">
                                        Add collaborator
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Participants</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewParticipantsRoute">
                                        View participants
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Treatments</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewTreatmentsRoute">
                                        View treatments
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Lines of Treatment</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewLinesOfTreatmentRoute">
                                        View Lines Of Treatment
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Diagnostic Tests</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewDiagTestRecordsRoute">
                                        View diagnostic tests
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Diagnostic Test Categories</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewDiagTestCategoriesRoute">
                                        View diagnostic test categories
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/sample_coordinator/diagnostic_test_category/add">
                                        Add diagnostic test category
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Cancer Staging</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewCancerStagingSystemsRoute">
                                        View cancer staging systems
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="sampleCoordAddCancerStagingSystemsRoute">
                                        Add cancer staging system
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="sampleCoordViewCancerTypesRoute">
                                        View cancer types
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="sampleCoordAddCancerTypeRoute">
                                        Add cancer type
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="sampleCoordViewCancerStagesRoute">
                                        View cancer stages
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="sampleCoordAddCancerStageRoute">
                                        Add cancer stage
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Clinical Updates</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewClinUpdateRecordsRoute">
                                        View clinical updates
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Clinical Update Categories</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewClinUpdateCategoriesRoute">
                                        View clinical update categories
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/sample_coordinator/clinical_update_category/add">
                                        Add clinical update category
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Biospecimens</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewBiospecimensRoute">
                                        View biospecimens
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Samples</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewSamplesRoute">
                                        View samples
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Bait Sets</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordinatorViewBaitSetsRoute">
                                        View bait sets
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="sampleCoordinatorAddBaitSetsRoute">
                                        Add Bait Set
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drug Targets</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewDrugTargetsRoute">
                                        View drug targets
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/sample_coordinator/drug_target/add">
                                        Add drug target
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drugs</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewDrugsRoute">
                                        View drugs
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/sample_coordinator/drug/add">
                                        Add drug
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drug Combinations</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordViewDrugCombinationsRoute">
                                        View drug combinations
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/sample_coordinator/drug_combination/add">
                                        Add drug combination
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">IRB Protocols</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item to="/sample_coordinator/irb_protocol/view">
                                        View IRB protocols
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/sample_coordinator/irb_protocol/add">
                                        Add IRB protocol
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Production Analysis Workspaces</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordinatorViewPrAnWorkspacesRoute">
                                        View production analysis workspaces
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Reports</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="sampleCoordReportCohortSampleStatsRoute">
                                        Cohort Sample Stats
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="sampleCoordUnsequencedBiospecimensRoute">
                                        Unsequenced Biospecimens
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="sampleCoordReportCollidingSamplesRoute">
                                        Colliding Samples
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="sampleCoordReportSampleTrackingRoute">
                                        Sample Tracking
                                    </b-dropdown-item>
                                </ul>
                            </li>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown v-if="storeState.user.roles.isProjectLead" class="nav-item dropdown" text="Manage Projects">
                            <b-dropdown-item :to="projectLeadViewProjectsRoute">
                                View projects
                            </b-dropdown-item>
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Participants</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="cohortLeadViewParticipantsRoute">
                                        View participants
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Treatments</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="cohortLeadViewTreatmentsRoute">
                                        View treatments
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Diagnostic Tests</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="cohortLeadViewDiagTestRecordsRoute">
                                        View diagnostic tests
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Clinical Updates</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="cohortLeadViewClinUpdateRecordsRoute">
                                        View clinical updates
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Biospecimens</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="cohortLeadViewBiospecimensRoute">
                                        View biospecimens
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Samples</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="cohortLeadViewSamplesRoute">
                                        View samples
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Reports</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="cohortLeadUnsequencedBiospecimensRoute">
                                        Unsequenced Biospecimens
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown v-if="storeState.user.roles.isDataEditor" class="nav-item dropdown" text="Modify Data">
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Projects</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataEditorViewCohortsRoute">
                                        View and sync projects
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Participants</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataEditorViewParticipantsRoute">
                                        View participants
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/data_editor/participant/add">
                                        Add participant to project
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Treatments</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataEditorViewTreatmentsRoute">
                                        View treatments
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/data_editor/treatment/add">
                                        Add treatment to project
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Lines Of Treatment</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item to="/submitter/line_of_treatment/add">
                                        Add Line Of Treatment
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="submitterViewLinesOfTreatmentRoute">
                                        View lines of treatment
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Diagnostic Tests</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="submitterViewDiagTestRecordsRoute">
                                        View diagnostic tests
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/submitter/diagnostic_test/add">
                                        Add diagnostic test to cohort
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Clinical Updates</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataEditorViewClinUpdateRecordsRoute">
                                        View clinical updates
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/data_editor/clinical_update/add">
                                        Add clinical update to project
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Biospecimens</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataEditorViewBiospecimensRoute">
                                        View biospecimens
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/data_editor/biospecimen/add">
                                        Add biospecimen to project
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Manifests</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataEditorViewManifestsRoute">
                                        View manifests
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/data_editor/manifest/add">
                                        Add manifest to project
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Samples</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataEditorViewSamplesRoute">
                                        View samples
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drugs</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="submitterViewDrugsRoute">
                                        View drugs
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drug Combinations</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="submitterViewDrugCombinationsRoute">
                                        View drug combinations
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Reports</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="submitterUnsequencedBiospecimensRoute">
                                        Unsequenced Biospecimens
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Upload</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item to="/data_editor/upload">
                                        Validate and bulk upload TSV
                                    </b-dropdown-item>
                                    <b-dropdown-item to="/data_editor/upload_bsp">
                                        Validate and upload BSP
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="DataEditorViewLatestEntityUploadTemplateRoute">
                                        View Latest Entity Upload Templates
                                    </b-dropdown-item>
                                </ul>
                            </li>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown v-if="storeState.user.roles.isDataAnalyst" class="nav-item dropdown" text="Analyze Data">
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Cohorts</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewCohortsRoute">
                                        View cohorts
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Participants</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewParticipantsRoute">
                                        View participants
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Treatments</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewTreatmentsRoute">
                                        View treatments
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Diagnostic Tests</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewDiagnosticTestsRoute">
                                        View diagnostic tests
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Clinical Updates</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewClinicalUpdatesRoute">
                                        View clinical updates
                                    </b-dropdown-item>
                                </ul>
                            </li>  -->
                            <!-- <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Biospecimens</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewBiospecimensRoute">
                                        View biospecimens
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Samples</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewSamplesRoute">
                                        View samples
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drugs</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewDrugsRoute">
                                        View drugs
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Drug Combinations</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewDrugCombinationsRoute">
                                        View drug combinations
                                    </b-dropdown-item>
                                </ul>
                            </li> -->
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Production Analysis Workspaces</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="dataAnalystViewPrAnWorkspacesRoute">
                                        View production analysis workspaces
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="dataAnalystAddPrAnWorkspacesRoute">
                                        Add production analysis workspace to project
                                    </b-dropdown-item>
                                </ul>
                            </li>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown v-if="storeState.user.roles.isLeadDataAnalyst && !storeState.user.roles.isUberLeadDataAnalyst" class="nav-item dropdown" text="Lead Data Analyst">
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Participants</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="leadDataAnalystViewParticipantsRoute">
                                        View participants
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Production Analysis Workspaces</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="leadDataAnalystViewPrAnWorkspacesRoute">
                                        View production analysis workspaces
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="leadDataAnalystAddPrAnWorkspacesRoute">
                                        Add production analysis workspace to cohort
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Bait Sets</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="leadDataAnalystViewBaitSetsRoute">
                                        View bait sets
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Workspace Attributes</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="leadDataAnalystViewWorkspaceAttributesRoute">
                                        View workspace attributes
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="leadDataAnalystAddWorkspaceAttributesRoute">
                                        Add workspace attribute
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="leadDataAnalystWorkspaceAttributeUploadRoute">
                                        Validate and upload workspace Attributes
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Method Configurations</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="leadDataAnalystAddMethodConfigurationRoute">
                                        Add method configuration
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="leadDataAnalystViewMethodConfigurationsRoute">
                                        View method configurations
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Reports</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="leadDataAnalystReportCollidingSamplesRoute">
                                        Colliding Samples
                                    </b-dropdown-item>
                                </ul>
                            </li>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown v-if="storeState.user.roles.isUberLeadDataAnalyst" class="nav-item dropdown" text="Uber Lead Data Analyst">
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Participants</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="uberLeadDataAnalystViewParticipantsRoute">
                                        View participants
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Production Analysis Workspaces</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="uberLeadDataAnalystViewPrAnWorkspacesRoute">
                                        View production analysis workspaces
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="uberLeadDataAnalystAddPrAnWorkspacesRoute">
                                        Add production analysis workspace to cohort
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Bait Sets</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="uberLeadDataAnalystViewBaitSetsRoute">
                                        View bait sets
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Workspace Attributes</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="uberLeadDataAnalystViewWorkspaceAttributesRoute">
                                        View workspace attributes
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="uberLeadDataAnalystAddWorkspaceAttributesRoute">
                                        Add workspace attribute
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="uberLeadDataAnalystWorkspaceAttributeUploadRoute">
                                        Validate and upload workspace Attributes
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Method Configurations</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="uberLeadDataAnalystAddMethodConfigurationsRoute">
                                        Add method configuration
                                    </b-dropdown-item>
                                    <b-dropdown-item :to="uberLeadDataAnalystViewMethodConfigurationsRoute">
                                        View method configurations
                                    </b-dropdown-item>
                                </ul>
                            </li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle">Reports</a>
                                <ul class="dropdown-menu">
                                    <b-dropdown-item :to="uberLeadDataAnalystReportCollidingSamplesRoute">
                                        Colliding Samples
                                    </b-dropdown-item>
                                </ul>
                            </li>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                    <b-navbar-nav class="ml-auto">
                        <div id="g_id_onload"
                            data-client_id="156602588770-qsfhqe333vncebfr3ij987iujbal8jqj.apps.googleusercontent.com"
                            data-callback="handleCredentialResponse"
                        />
                        <b-nav-text v-show="!storeState.user.isSignedIn" class="g_id_signin" data-type="standard" />
                        <!-- Using v-show instead of v-if since Google's API needs an
                            HTML tag in which to render the Sign In button at load time -->
                        <b-nav-item-dropdown v-show="storeState.user.isSignedIn" :text="storeState.user.email" right>
                            <b-dropdown-item id="sign-out-btn" @click="signOut">
                                Sign Out
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </ul>
        </b-navbar>
    </div>
</template>

<script>
import store from '../store';
import {
    SAMPLE_COORDINATOR,
    SUBMITTER,
    COHORT_LEAD,
    DATA_EDITOR,
    PROJECT_LEAD,
    ADMINISTRATOR,
    DATA_ANALYST,
    LEAD_DATA_ANALYST,
    UBER_LEAD_DATA_ANALYST,
    USER
} from '../utils/constants';

// Define global credential response function to be accessible by google sign-in button
window.handleCredentialResponse = (response) => {
    store.userLogIn(response.credential);
};

export default {
    name: 'NavigationBarComponent',
    data() {
        return {
            storeState: store.state,
            // Administrator route objects
            adminViewUsersRoute: {
                name: 'AdminViewUsersPage',
                params: { role: ADMINISTRATOR }
            },
            adminCreateProjectRoute: {
                name: 'AdminCreateProjectPage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            adminAddDrugRoute: {
                name: 'AdminAddDrugPage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            adminViewDrugCategoriesRoute: {
                name: 'AdminViewDrugCategoriesPage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            viewDrugCategoriesRoute: {
                name: 'AdminViewDrugCategoriesPage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            adminViewCancerStagingSystemsRoute: {
                name: 'AdminViewCancerStagingSystemsPage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            adminAddCancerStagingSystemsRoute: {
                name: 'AdminAddCancerStagingSystemPage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            adminViewCancerTypesRoute: {
                name: 'AdminViewCancerTypesPage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            adminAddCancerTypeRoute: {
                name: 'AdminAddCancerTypePage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            adminViewCancerStagesRoute: {
                name: 'AdminViewCancerStagesPage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            adminAddCancerStageRoute: {
                name: 'AdminAddCancerStagePage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            adminViewClinUpdateCategoriesRoute: {
                name: 'AdminViewClinicalUpdateCategoriesPage',
                params: {
                    role: ADMINISTRATOR
                }
            },
            // User route objects
            userViewProjectsRoute: {
                name: 'UserViewProjectsPage',
            },
            userViewParticipantsRoute: {
                name: 'UserViewParticipantsPage',
            },
            userViewBiospecimensRoute: {
                name: 'UserViewBiospecimensPage',
            },
            userViewTreatmentsRoute: {
                name: 'UserViewTreatmentsPage',
            },
            userViewClinicalUpdatesRoute: {
                name: 'UserViewClinicalUpdatesPage',
            },
            userViewSamplesRoute: {
                name: 'UserViewSamplesPage',
            },
            viewDrugsRoute: {
                name: 'ViewDrugsPage',
            },
            viewDrugCombinationsRoute: {
                name: 'ViewDrugCombinationsPage',
            },
            // Sample Coord route objects
            sampleCoordViewCohortsRoute: {
                name: 'SampleCoordinatorViewCohortPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewUsersRoute: {
                name: 'SampleCoordinatorViewUsersPage',
                params: { role: SAMPLE_COORDINATOR }
            },
            sampleCoordViewDiagTestCategoriesRoute: {
                name: 'SampleCoordinatorViewDiagnosticTestCategoriesPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewDiagTestRecordsRoute: {
                name: 'SampleCoordinatorViewDiagnosticTestsPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewClinUpdateCategoriesRoute: {
                name: 'SampleCoordinatorViewClinicalUpdateCategoriesPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewClinUpdateRecordsRoute: {
                name: 'SampleCoordinatorViewClinicalUpdatesPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewSamplesRoute: {
                name: 'SampleCoordinatorViewSamplesPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordUnsequencedBiospecimensRoute: {
                name: 'SampleCoordinatorUnsequencedBiospecimensPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewBiospecimensRoute: {
                name: 'SampleCoordinatorViewBiospecimensPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewParticipantsRoute: {
                name: 'SampleCoordinatorViewParticipantsPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordReportCohortSampleStatsRoute: {
                name: 'SampleCoordinatorReportCohortSampleStatsPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewTreatmentsRoute: {
                name: 'SampleCoordinatorViewTreatmentsPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewDrugCategoriesRoute: {
                name: 'SampleCoordinatorViewDrugCategoriesPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewDrugTargetsRoute: {
                name: 'SampleCoordinatorViewDrugTargetsPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewDrugCombinationsRoute: {
                name: 'SampleCoordinatorViewDrugCombinationsPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordinatorViewPrAnWorkspacesRoute: {
                name: 'SampleCoordinatorViewPrAnWorkspacesPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordinatorViewBaitSetsRoute: {
                name: 'SampleCoordinatorViewBaitSetsPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordinatorAddBaitSetsRoute: {
                name: 'SampleCoordinatorAddBaitSetPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordReportCollidingSamplesRoute: {
                name: 'SampleCoordinatorReportCollidingSamplePage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordReportSampleTrackingRoute: {
                name: 'SampleCoordinatorReportSampleTrackingPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewCancerStagingSystemsRoute: {
                name: 'SampleCoordinatorViewCancerStagingSystemsPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordAddCancerStagingSystemsRoute: {
                name: 'SampleCoordinatorAddCancerStagingSystemPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewCancerTypesRoute: {
                name: 'SampleCoordinatorViewCancerTypesPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordAddCancerTypeRoute: {
                name: 'SampleCoordinatorAddCancerTypePage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewCancerStagesRoute: {
                name: 'SampleCoordinatorViewCancerStagesPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordAddCancerStageRoute: {
                name: 'SampleCoordinatorAddCancerStagePage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            sampleCoordViewLinesOfTreatmentRoute: {
                name: 'SampleCoordinatorViewLinesOfTreatmentPage',
                params: {
                    role: SAMPLE_COORDINATOR
                }
            },
            // Data Editor route objects
            dataEditorViewCohortsRoute: {
                name: 'DataEditorViewCohortPage',
                params: {
                    role: DATA_EDITOR
                }
            },
            submitterViewDiagTestCategoriesRoute: {
                name: 'SubmitterViewDiagnosticTestCategoriesPage',
                params: {
                    role: SUBMITTER
                }
            },
            submitterViewDiagTestRecordsRoute: {
                name: 'SubmitterViewDiagnosticTestsPage',
                params: {
                    role: SUBMITTER
                }
            },
            submitterViewClinUpdateCategoriesRoute: {
                name: 'SubmitterViewClinicalUpdateCategoriesPage',
                params: {
                    role: SUBMITTER
                }
            },
            dataEditorViewClinUpdateRecordsRoute: {
                name: 'DataEditorViewClinicalUpdatesPage',
            },
            dataEditorViewSamplesRoute: {
                name: 'DataEditorViewSamplesPage',
            },
            dataEditorViewBiospecimensRoute: {
                name: 'DataEditorViewBiospecimensPage',
            },
            submitterUnsequencedBiospecimensRoute: {
                name: 'SubmitterUnsequencedBiospecimensPage',
                params: {
                    role: SUBMITTER
                }
            },
            dataEditorViewParticipantsRoute: {
                name: 'DataEditorViewParticipantsPage',
            },
            dataEditorViewTreatmentsRoute: {
                name: 'DataEditorViewTreatmentsPage',
            },
            submitterViewDrugsRoute: {
                name: 'SubmitterViewDrugsPage',
            },
            submitterViewDrugCombinationsRoute: {
                name: 'SubmitterViewDrugCombinationsPage',
            },
            submitterViewLinesOfTreatmentRoute: {
                name: 'SubmitterViewLinesOfTreatmentPage',
            },
            DataEditorViewLatestEntityUploadTemplateRoute: {
                name: 'DataEditorViewLatestEntityUploadTemplatePage',
            },
            dataEditorViewManifestsRoute: {
                name: 'DataEditorViewManifestsPage',
            },
            
            // Project Lead route objects
            projectLeadViewProjectsRoute: {
                name: 'ProjectLeadViewProjectPage',
                params: {
                    role: PROJECT_LEAD
                }
            },
            cohortLeadViewDiagTestRecordsRoute: {
                name: 'CohortLeadViewDiagnosticTestsPage',
                params: {
                    role: COHORT_LEAD
                }
            },
            cohortLeadViewClinUpdateRecordsRoute: {
                name: 'CohortLeadViewClinicalUpdatesPage',
                params: {
                    role: COHORT_LEAD
                }
            },
            cohortLeadViewSamplesRoute: {
                name: 'CohortLeadViewSamplesPage',
                params: {
                    role: COHORT_LEAD
                }
            },
            cohortLeadViewBiospecimensRoute: {
                name: 'CohortLeadViewBiospecimensPage',
                params: {
                    role: COHORT_LEAD
                }
            },
            cohortLeadUnsequencedBiospecimensRoute: {
                name: 'CohortLeadUnsequencedBiospecimensPage',
                params: {
                    role: COHORT_LEAD
                }
            },
            cohortLeadViewParticipantsRoute: {
                name: 'CohortLeadViewParticipantsPage',
                params: {
                    role: COHORT_LEAD
                }
            },
            cohortLeadViewTreatmentsRoute: {
                name: 'CohortLeadViewTreatmentsPage',
                params: {
                    role: COHORT_LEAD
                }
            },
            // Data Analyst Route objects
            dataAnalystViewCohortsRoute: {
                name: 'DataAnalystViewCohortPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystViewBiospecimensRoute: {
                name: 'DataAnalystViewBiospecimensPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystViewParticipantsRoute: {
                name: 'DataAnalystViewParticipantsPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystViewTreatmentsRoute: {
                name: 'DataAnalystViewTreatmentsPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystViewDiagnosticTestsRoute: {
                name: 'DataAnalystViewDiagnosticTestsPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystViewClinicalUpdatesRoute: {
                name: 'DataAnalystViewClinicalUpdatesPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystViewSamplesRoute: {
                name: 'DataAnalystViewSamplesPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystViewDrugsRoute: {
                name: 'DataAnalystViewDrugsPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystViewDrugCombinationsRoute: {
                name: 'DataAnalystViewDrugCombinationsPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystViewPrAnWorkspacesRoute: {
                name: 'DataAnalystViewPrAnWorkspacesPage',
                params: {
                    role: DATA_ANALYST
                }
            },
            dataAnalystAddPrAnWorkspacesRoute: {
                name: 'DataAnalystAddPrAnWorkspacePage',
                params: {
                    role: DATA_ANALYST
                }
            },
            // Lead Data Analyst Route objects
            leadDataAnalystViewPrAnWorkspacesRoute: {
                name: 'LeadDataAnalystViewPrAnWorkspacesPage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            leadDataAnalystAddPrAnWorkspacesRoute: {
                name: 'LeadDataAnalystAddPrAnWorkspacePage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            leadDataAnalystViewParticipantsRoute: {
                name: 'LeadDataAnalystViewParticipantsPage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            leadDataAnalystReportCollidingSamplesRoute: {
                name: 'LeadDataAnalystReportCollidingSamplePage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            leadDataAnalystViewBaitSetsRoute: {
                name: 'LeadDataAnalystViewBaitSetsPage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            leadDataAnalystViewMethodConfigurationsRoute: {
                name: 'LeadDataAnalystViewMethodConfigurationPage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            leadDataAnalystAddMethodConfigurationRoute: {
                name: 'LeadDataAnalystAddMethodConfigurationPage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            leadDataAnalystViewWorkspaceAttributesRoute: {
                name: 'LeadDataAnalystViewWorkspaceAttributesPage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            leadDataAnalystAddWorkspaceAttributesRoute: {
                name: 'LeadDataAnalystAddWorkspaceAttributesPage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            leadDataAnalystWorkspaceAttributeUploadRoute: {
                name: 'LeadDataAnalystWorkspaceAttributeUploadPage',
                params: {
                    role: LEAD_DATA_ANALYST
                }
            },
            // Uber Lead Data Analyst Route objects
            uberLeadDataAnalystViewPrAnWorkspacesRoute: {
                name: 'UberLeadDataAnalystViewPrAnWorkspacesPage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            },
            uberLeadDataAnalystAddPrAnWorkspacesRoute: {
                name: 'UberLeadDataAnalystAddPrAnWorkspacePage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            },
            uberLeadDataAnalystViewParticipantsRoute: {
                name: 'UberLeadDataAnalystViewParticipantsPage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            },
            uberLeadDataAnalystReportCollidingSamplesRoute: {
                name: 'UberLeadDataAnalystReportCollidingSamplePage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            },
            uberLeadDataAnalystViewBaitSetsRoute: {
                name: 'UberLeadDataAnalystViewBaitSetsPage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            },
            uberLeadDataAnalystViewMethodConfigurationsRoute: {
                name: 'UberLeadDataAnalystViewMethodConfigurationPage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            },
            uberLeadDataAnalystAddMethodConfigurationsRoute: {
                name: 'UberLeadDataAnalystAddMethodConfigurationPage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            },
            uberLeadDataAnalystViewWorkspaceAttributesRoute: {
                name: 'UberLeadDataAnalystViewWorkspaceAttributesPage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            },
            uberLeadDataAnalystAddWorkspaceAttributesRoute: {
                name: 'UberLeadDataAnalystAddWorkspaceAttributesPage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            },
            uberLeadDataAnalystWorkspaceAttributeUploadRoute: {
                name: 'UberLeadDataAnalystWorkspaceAttributeUploadPage',
                params: {
                    role: UBER_LEAD_DATA_ANALYST
                }
            }
        };
    },
    methods: {
        signOut() {
            store.userLogOut();
        }
    }
};
</script>

<style scoped>
#sign-out-btn {
    cursor: pointer;
}

#portal-navbar .navbar-brand {
    font-size: 1.05rem;
}

#portal-navbar .nav-item {
    font-size: 0.95rem;
}
</style>

<style>
#portal-navbar .router-link-exact-active {
    color: #65b1c4 !important;
    font-weight: 500;
}

#portal-navbar .dropdown-item {
    font-size: 0.95rem;
}

.navbar-nav li:hover > ul.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top:-6px;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
}
</style>
