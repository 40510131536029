<template>
    <div>
        <h3>Edit Diagnostic Test Record</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4" sm="6">
            <b-form @submit.stop.prevent="updateRecord">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="localCohortName" disabled />
                </b-form-group>

                <b-form-group label="Participant ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.participantName" disabled />
                </b-form-group>

                <b-form-group label="Diagnostic test DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.updateRecordId" disabled />
                </b-form-group>

                <b-form-group label="Diagnostic test category" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="diagTestCategoryOptions" v-model="$v.form.selectedDiagTestCategory.$model" :state="$v.form.selectedDiagTestCategory.$error ? false : null" />
                </b-form-group>

                <b-form-group label="Diagnostic test date (days from diagnosis)" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.testDateDfd.$model" :state="$v.form.testDateDfd.$error ? false : null" />
                </b-form-group>

                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>

                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Update Record
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    between
} from '@vuelidate/validators';
import {
    getUrl, getDiagnosticTestCategoriesPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createDiagnosticTestCategoriesDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    props: {
        cohortId: { type: Number, required: true },
        cohortName: { type: String, required: true },
        recordId: { type: Number, required: true },
        role: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // local prop vars
            localCohortName: this.cohortName,

            // URLs used
            sDiagnosticTestUrl: getUrl('submitterDiagnosticTestsUrl'),

            // options for form dropdowns
            participantOptions: [],
            diagTestCategoryOptions: [],

            // form data
            form: {
                // uneditable items that we call the API to figure out
                cohortName: null,
                participantName: null,
                updateRecordId: null,

                // dropdowns
                selectedDiagTestCategory: null,
                // inputs
                testDateDfd: null,
                notes: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            // dropdowns
            selectedDiagTestCategory: { required },
            testDateDfd: {
                required,
                between: between(-36500, 36500)
            }
        }
    },
    mounted() {
        this.populateDiagTestForm();
    },
    methods: {
        getDiagnosticTestRecordInfoPromise() {
            return axios.get(`${this.sDiagnosticTestUrl}?cohort_id=${this.cohortId}&id=${this.recordId}`, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateDiagTestForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([this.getDiagnosticTestRecordInfoPromise(), getDiagnosticTestCategoriesPromise()])
                .then(axios.spread((currInfoResp, testCategoryResp) => {
                    const diagnosticTestInfo = currInfoResp.data.diagnostic_tests[0];
                    const diagnosticTestCategories = createDiagnosticTestCategoriesDict(testCategoryResp.data.diagnostic_test_categories);
                    component.diagTestCategoryOptions = createDropdownOptions(diagnosticTestCategories);


                    // current diagnostic test info -- unchangeable form fiekds
                    component.form.cohortName = component.cohortName;
                    component.form.participantName = diagnosticTestInfo.participant_id;
                    component.form.updateRecordId = diagnosticTestInfo.db_id;

                    // current diagnostic test info -- updateable form fields
                    component.form.selectedDiagTestCategory = component.diagTestCategoryOptions.filter(x => x.text === diagnosticTestInfo.test_category)[0].value;
                    component.form.testDateDfd = diagnosticTestInfo.test_date_dfd;
                    component.form.notes = diagnosticTestInfo.notes;
                }))
                .finally(() => { loader.hide(); });
        },
        updateRecord() {
            const loader = this.$loading.show();
            const component = this;

            const url = `${this.sDiagnosticTestUrl}?cohort_id=${this.cohortId}&id=${this.form.updateRecordId}`;
            const newDiagnosticTestInfo = {
                test_category_id: this.form.selectedDiagTestCategory,
                test_date_dfd: this.form.testDateDfd,
                notes: this.form.notes === '' ? null : this.form.notes
            };
            axios.put(url, newDiagnosticTestInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'SubmitterViewDiagnosticTestsPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            editedCohortId: component.cohortId,
                            role: this.role
                        }
                    });
                }).catch((err) => {
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                }).finally(() => loader.hide());
        }
    }
};
</script>

<style>

</style>
