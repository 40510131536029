<template>
    <div>
        <h3>Add Drug Category</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="addDrugCategory">
                <b-form-group label="Drug Category Name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.name.$model" :state="$v.form.name.$dirty ? !$v.form.name.$error : null" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Add Drug Category
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    minLength,
    maxLength
} from '@vuelidate/validators';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    data() {
        return {
            // URLs used
            sampleCoordAddDrugCategoryUrl: getUrl('sampleCoordinatorDrugCategoriesUrl'),

            // form data
            form: {
                // inputs
                name: null,
                notes: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(256)
            }
        }
    },
    mounted() {},
    methods: {
        addDrugCategory() {
            const loader = this.$loading.show();
            const component = this;

            const newCategory = {
                name: this.form.name,
                notes: this.form.notes === '' ? null : this.form.notes
            };
            axios.post(this.sampleCoordAddDrugCategoryUrl, newCategory, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'HomePage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
