import axios from 'axios';
import getUserToken from './auth';

const BASE_URL = process.env.VUE_APP_ENV === 'production' ? 'https://rest-api-dot-broad-getzlab-project-portal.uc.r.appspot.com/api' : 'https://test-rest-api-dot-broad-getzlab-project-portal.uc.r.appspot.com/api';
// const BASE_URL = 'http://localhost:5000/api';
// const LOCAL_BASE_URL = 'http://localhost:5000/api';

const urls = {
    biospecimensUrl: `${BASE_URL}/v1/biospecimens`,
    collaboratorsUrl: `${BASE_URL}/v1/collaborators`,
    clinicalUpdatesUrl: `${BASE_URL}/v1/clinical_updates`,
    cancerTypesUrl: `${BASE_URL}/v1/cancer_types`,
    cancerStagesUrl: `${BASE_URL}/v1/cancer_stages`,
    clinicalUpdateCategoriesUrl: `${BASE_URL}/v1/clinical_update_categories`,
    diagnosticTestCategoriesUrl: `${BASE_URL}/v1/diagnostic_test_categories`,
    sharedCohortsUrl: `${BASE_URL}/v1/get_shared_cohorts`,
    treatmentCategoriesUrl: `${BASE_URL}/v1/treatment_categories`,
    projectsUrl: `${BASE_URL}/v1/projects`,
    cohortsUrl: `${BASE_URL}/v1/cohorts`,
    icdo3TopoCodesUrl: `${BASE_URL}/v1/icdo3_topo_codes`,
    irbProtocolsUrl: `${BASE_URL}/v1/irb_protocols`,
    participantsUrl: `${BASE_URL}/v1/participants`,
    rolesUrl: `${BASE_URL}/v1/roles`,
    samplesUrl: `${BASE_URL}/v1/samples`,
    treatmentsUrl: `${BASE_URL}/v1/treatments`,
    linesOfTreatmentUrl: `${BASE_URL}/v1/lines_of_treatment`,
    uberonAnatomySitesUrl: `${BASE_URL}/v1/uberon_anatomy_sites`,
    usersUrl: `${BASE_URL}/v1/users`,
    drugsUrl: `${BASE_URL}/v1/drugs`,
    drugCombinationsUrl: `${BASE_URL}/v1/drug_combinations`,
    drugCategoriesUrl: `${BASE_URL}/v1/drug_categories`,
    drugTargetsUrl: `${BASE_URL}/v1/drug_targets`,
    cancerStagingSystemsUrl: `${BASE_URL}/v1/cancer_staging_systems`,
    manifestsUrl: `${BASE_URL}/v1/manifests`,
    prAnWorkspacesUrl: `${BASE_URL}/v1/pran_workspaces`,
    // reports
    sampleStatusReportUrl: `${BASE_URL}/v1/pran_sample_status`,
    reportUnsequencedBiospecimensUrl: `${BASE_URL}/v1/report_unsequenced_biospecimens`,
    manifestsUrl: `${BASE_URL}/v1/manifests`,
    pdoStatusesUrl: `${BASE_URL}/v1/pdo_statuses`,
    // enums
    biospecimenTumorNormalUrl: `${BASE_URL}/v1/enum/biospecimen_tumor_normal`,
    genderUrl: `${BASE_URL}/v1/enum/gender`,
    raceUrl: `${BASE_URL}/v1/enum/race`,
    originalMaterialTypeUrl: `${BASE_URL}/v1/enum/original_material_type`,
    preservationMethodUrl: `${BASE_URL}/v1/enum/preservation_method`,
    submittedMaterialTypeUrl: `${BASE_URL}/v1/enum/submitted_material_type`,
    tumorStateUrl: `${BASE_URL}/v1/enum/tumor_state`,
    vitalStatusUrl: `${BASE_URL}/v1/enum/vital_status`,
    experimentalStrategyUrl: `${BASE_URL}/v1/enum/experimental_strategy`,
    analyteTypeUrl: `${BASE_URL}/v1/enum/analyte_type`,
    treatmentStopReasonUrl: `${BASE_URL}/v1/enum/treatment_stop_reason`,
    platformUrl: `${BASE_URL}/v1/enum/platform`,
    // admin specific URLS
    adminUsersUrl: `${BASE_URL}/v1/admin/users`,
    // project lead specific URLs
    projectLeadProjectsUrl: `${BASE_URL}/v1/pl/projects`,
    projectLeadBiospecimensUrl: `${BASE_URL}/v1/pl/biospecimens`,
    projectLeadDiagnosticTestsUrl: `${BASE_URL}/v1/pl/diagnostic_tests`,
    projectLeadClinicalUpdatesUrl: `${BASE_URL}/v1/pl/clinical_updates`,
    projectLeadParticipantsUrl: `${BASE_URL}/v1/pl/participants`,
    projectLeadSamplesUrl: `${BASE_URL}/v1/pl/samples`,
    projectLeadTreatmentsUrl: `${BASE_URL}/v1/pl/treatments`,
    projectLeadUsersUrl: `${BASE_URL}/v1/pl/users`,
    projectLeadReportUnsequencedBiospecimensUrl: `${BASE_URL}/v1/pl/report_unsequenced_biospecimens`,
    projectLeadLinesOfTreatmentUrl: `${BASE_URL}/v1/pl/lines_of_treatment`,
    cohortLeadManifestsUrl: `${BASE_URL}/v1/cl/manifests`, //TODO project lead or role agnostic?
    // sample coordinator specific URLs
    sampleCoordinatorCohortsUrl: `${BASE_URL}/v1/sc/cohorts`,
    sampleCoordinatorDiagnosticTestsUrl: `${BASE_URL}/v1/sc/diagnostic_tests`,
    sampleCoordinatorDiagnosticTestCategoriesUrl: `${BASE_URL}/v1/sc/diagnostic_test_categories`,
    sampleCoordinatorBiospecimensUrl: `${BASE_URL}/v1/sc/biospecimens`,
    sampleCoordinatorClinicalUpdatesUrl: `${BASE_URL}/v1/sc/clinical_updates`,
    sampleCoordinatorClinicalUpdateCategoriesUrl: `${BASE_URL}/v1/sc/clinical_update_categories`,
    sampleCoordinatorParticipantsUrl: `${BASE_URL}/v1/sc/participants`,
    sampleCoordinatorCollaboratorsUrl: `${BASE_URL}/v1/sc/collaborators`,
    sampleCoordinatorDeliveryWorkspacesUrl: `${BASE_URL}/v1/sc/delivery_workspaces`,
    sampleCoordinatorIRBProtocolsUrl: `${BASE_URL}/v1/sc/irb_protocols`,
    sampleCoordinatorSamplesUrl: `${BASE_URL}/v1/sc/samples`,
    sampleCoordinatorTreatmentsUrl: `${BASE_URL}/v1/sc/treatments`,
    sampleCoordinatorReportCohortSampleStatsUrl: `${BASE_URL}/v1/sc/report_cohort_sample_stats`,
    sampleCoordinatorReportUnsequencedBiospecimensUrl: `${BASE_URL}/v1/sc/report_unsequenced_biospecimens`,
    sampleCoordinatorDrugCategoriesUrl: `${BASE_URL}/v1/sc/drug_categories`,
    sampleCoordinatorDrugTargetsUrl: `${BASE_URL}/v1/sc/drug_targets`,
    sampleCoordinatorDrugCombinationsUrl: `${BASE_URL}/v1/sc/drug_combinations`,
    sampleCoordinatorUsersUrl: `${BASE_URL}/v1/sc/users`,
    sampleCoordinatorPrAnWorkspacesUrl: `${BASE_URL}/v1/sc/pran_workspaces`,
    sampleCoordinatorBaitSetsUrl: `${BASE_URL}/v1/sc/bait_sets`,
    sampleCoordinatorReportCollidingSamplesUrl: `${BASE_URL}/v1/sc/report_colliding_samples`,
    sampleCoordinatorReportSampleTrackingUrl: `${BASE_URL}/v1/sc/report_sample_tracking`,
    sampleCoordinatorCancerStagingSystemsUrl: `${BASE_URL}/v1/sc/cancer_staging_systems`,
    sampleCoordinatorCancerTypesUrl: `${BASE_URL}/v1/sc/cancer_types`,
    sampleCoordinatorCancerStagesUrl: `${BASE_URL}/v1/sc/cancer_stages`,
    sampleCoordinatorLinesOfTreatmentUrl: `${BASE_URL}/v1/sc/lines_of_treatment`,
    sampleCoordinatorReportParticipantsWithoutMainNormalUrl: `${BASE_URL}/v1/sc/report_participants_without_main_normal`,
    sampleCoordinatorSyncAllUrl: `${BASE_URL}/v1/sc/all_samples_sync`,
    // data editor role specific URLs
    normalBiospecimensUrl: `${BASE_URL}/v1/biospecimens`,
    uploadFileUrl: `${BASE_URL}/v1/upload_file`,
    validateFileUrl: `${BASE_URL}/v1/validate_file`,
    uploadBSPUrl: `${BASE_URL}/v1/upload_bsp`,
    validateBSPUrl: `${BASE_URL}/v1/validate_bsp`,
    sampleCoordinatorManifestsUrl: `${BASE_URL}/v1/sc/manifests`, //TODO role agnostic??
    // submitter role specific URLs
    submitterCohortsUrl: `${BASE_URL}/v1/s/cohorts`,
    submitterDiagnosticTestsUrl: `${BASE_URL}/v1/s/diagnostic_tests`,
    submitterDiagnosticTestCategoriesUrl: `${BASE_URL}/v1/s/diagnostic_test_categories`,
    submitterBiospecimensUrl: `${BASE_URL}/v1/s/biospecimens`,
    submitterClinicalUpdatesUrl: `${BASE_URL}/v1/s/clinical_updates`,
    submitterClinicalUpdateCategoriesUrl: `${BASE_URL}/v1/s/clinical_update_categories`,
    submitterParticipantsUrl: `${BASE_URL}/v1/s/participants`,
    submitterSamplesUrl: `${BASE_URL}/v1/s/samples`,
    submitterTreatmentsUrl: `${BASE_URL}/v1/s/treatments`,
    submitterReportUnsequencedBiospecimensUrl: `${BASE_URL}/v1/s/report_unsequenced_biospecimens`,
    submitterDrugCombinationsUrl: `${BASE_URL}/v1/s/drug_combinations`,
    submitterCancerStagingSystemsUrl: `${BASE_URL}/v1/s/cancer_staging_systems`,
    submitterCancerTypesUrl: `${BASE_URL}/v1/s/cancer_types`,
    submitterCancerStagesUrl: `${BASE_URL}/v1/s/cancer_stages`,
    submitterLinesOfTreatmentUrl: `${BASE_URL}/v1/s/lines_of_treatment`,
    submitterLatestEntityUploadTemplateUrl: `${BASE_URL}/v1/s/latest_entity_upload_template`,
    submitterBulkDeleteUrl: `${BASE_URL}/v1/s/bulk_delete`,
    submitterManifestsUrl: `${BASE_URL}/v1/s/manifests`,
    // data analyst role specific URLs
    dataAnalystCohortsUrl: `${BASE_URL}/v1/da/cohorts`,
    dataAnalystParticipantsUrl: `${BASE_URL}/v1/da/participants`,
    dataAnalystTreatmentsUrl: `${BASE_URL}/v1/da/treatments`,
    dataAnalystBiospecimensUrl: `${BASE_URL}/v1/da/biospecimens`,
    dataAnalystDiagnosticTestsUrl: `${BASE_URL}/v1/da/diagnostic_tests`,
    dataAnalystClinicalUpdatesUrl: `${BASE_URL}/v1/da/clinical_updates`,
    dataAnalystSamplesUrl: `${BASE_URL}/v1/da/samples`,
    dataAnalystDrugCombinationsUrl: `${BASE_URL}/v1/da/drug_combinations`,
    dataAnalystPrAnWorkspacesUrl: `${BASE_URL}/v1/da/pran_workspaces`,
    dataAnalystLinesOfTreatmentUrl: `${BASE_URL}/v1/da/lines_of_treatment`,
    dataAnalystManifestsUrl: `${BASE_URL}/v1/da/manifests`,
    dataAnalystReportParticipantsWithoutMainNormalUrl: `${BASE_URL}/v1/da/report_participants_without_main_normal`,
    // lead data analyst role specific URLs
    leadDataAnalystCohortsUrl: `${BASE_URL}/v1/lda/cohorts`,
    leadDataAnalystParticipantsUrl: `${BASE_URL}/v1/lda/participants`,
    leadDataAnalystBiospecimensUrl: `${BASE_URL}/v1/lda/biospecimens`,
    leadDataAnalystPrAnWorkspacesUrl: `${BASE_URL}/v1/lda/pran_workspaces`,
    leadDataAnalystReportCollidingSamplesUrl: `${BASE_URL}/v1/lda/report_colliding_samples`,
    leadDataAnalystBaitSetsUrl: `${BASE_URL}/v1/lda/bait_sets`,
    leadDataAnalystMethodConfigurationsUrl: `${BASE_URL}/v1/lda/method_configurations`,
    leadDataAnalystWorkspaceAttributesUrl: `${BASE_URL}/v1/lda/workspace_attributes`,
    leadDataAnalystUploadWorkspaceAttributesUrl: `${BASE_URL}/v1/lda/upload_workspace_attributes`,
    leadDataAnalystValidateWorkspaceAttributesUrl: `${BASE_URL}/v1/lda/validate_workspace_attributes`,
    leadDataAnalystReportParticipantsWithoutMainNormalUrl: `${BASE_URL}/v1/lda/report_participants_without_main_normal`,
    leadDataAnalystPrAnSnapshotsUrl: `${BASE_URL}/v1/lda/pran_snapshots`,
    leadDataAnalystManifestsUrl: `${BASE_URL}/v1/lda/manifests`,
    // lead data analyst role specific URLs
    uberLeadDataAnalystCohortsUrl: `${BASE_URL}/v1/ulda/cohorts`,
    uberLeadDataAnalystReportCollidingSamplesUrl: `${BASE_URL}/v1/ulda/report_colliding_samples`,
    uberLeadDataAnalystParticipantsUrl: `${BASE_URL}/v1/ulda/participants`,
    uberLeadDataAnalystBiospecimensUrl: `${BASE_URL}/v1/ulda/biospecimens`,
    uberLeadDataAnalystPrAnWorkspacesUrl: `${BASE_URL}/v1/ulda/pran_workspaces`,
    uberLeadDataAnalystBaitSetsUrl: `${BASE_URL}/v1/ulda/bait_sets`,
    uberLeadDataAnalystWorkspaceAttributesUrl: `${BASE_URL}/v1/ulda/workspace_attributes`,
    uberLeadDataAnalystUploadWorkspaceAttributesUrl: `${BASE_URL}/v1/ulda/upload_workspace_attributes`,
    uberLeadDataAnalystValidateWorkspaceAttributesUrl: `${BASE_URL}/v1/ulda/validate_workspace_attributes`,
    uberLeadDataAnalystMethodConfigurationsUrl: `${BASE_URL}/v1/ulda/method_configurations`,
    uberLeadDataAnalystReportParticipantsWithoutMainNormalUrl: `${BASE_URL}/v1/ulda/report_participants_without_main_normal`,
    uberLeadDataAnalystPrAnSnapshotsUrl: `${BASE_URL}/v1/ulda/pran_snapshots`,
    uberLeadDataAnalystManifestsUrl: `${BASE_URL}/v1/ulda/manifests`,
};

function getUrl(urlKey) {
    const url = urls[urlKey];
    if (url === undefined) {
        throw new Error(`getUrl Error: ${urlKey} is not a valid URL key.`);
    } else {
        return url;
    }
}

function getBiospecimenTumorNormalEnumPromise() {
    return axios.get(getUrl('biospecimenTumorNormalUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getCollaboratorsInfoPromise() {
    const collaboratorsUrl = getUrl('collaboratorsUrl');
    return axios.get(collaboratorsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getClinicalUpdateCategoriesPromise() {
    return axios.get(getUrl('clinicalUpdateCategoriesUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getDiagnosticTestCategoriesPromise() {
    return axios.get(getUrl('diagnosticTestCategoriesUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getTreatmentCategoriesPromise() {
    return axios.get(getUrl('treatmentCategoriesUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getGenderEnumPromise() {
    return axios.get(getUrl('genderUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getRaceEnumPromise() {
    return axios.get(getUrl('raceUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getIcdo3TopoCodesPromise() {
    return axios.get(getUrl('icdo3TopoCodesUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getIrbProtocolsPromise() {
    return axios.get(getUrl('irbProtocolsUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getOriginalMaterialTypeEnumPromise() {
    return axios.get(getUrl('originalMaterialTypeUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getPreservationMethodEnumPromise() {
    return axios.get(getUrl('preservationMethodUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getSubmittedMaterialTypeEnumPromise() {
    return axios.get(getUrl('submittedMaterialTypeUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getReadProjectsPromise() {
    const projectUrl = `${getUrl('projectsUrl')}?access_type=read`;
    return axios.get(projectUrl, {headers: {Authorization: `Bearer ${getUserToken()}` } });
}

function getWriteProjectsPromise() {
    const projectUrl = `${getUrl('projectsUrl')}?access_type=write`;
    return axios.get(projectUrl, {headers: {Authorization: `Bearer ${getUserToken()}` } });
}

function getAnalyzeProjectsPromise() {
    const projectUrl = `${getUrl('projectsUrl')}?access_type=analyze`;
    return axios.get(projectUrl, {headers: {Authorization: `Bearer ${getUserToken()}` } });
}

function getLeadProjectsPromise() {
    const projectUrl = `${getUrl('projectsUrl')}?access_type=lead`;
    return axios.get(projectUrl, {headers: {Authorization: `Bearer ${getUserToken()}` } });
}

function getSubmitterCohortsPromise() {
    const submittersCohortUrl = `${getUrl('submitterCohortsUrl')}`;
    return axios.get(submittersCohortUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getDACohortsPromise() {
    const dataAnalystCohortUrl = `${getUrl('cohortsUrl')}?access_type=analyze`;
    return axios.get(dataAnalystCohortUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getLDACohortsPromise() {
    const leadDataAnalystCohortUrl = `${getUrl('leadDataAnalystCohortsUrl')}`;
    return axios.get(leadDataAnalystCohortUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getULDACohortsPromise() {
    const uberLeadDataAnalystCohortUrl = `${getUrl('uberLeadDataAnalystCohortsUrl')}`;
    return axios.get(uberLeadDataAnalystCohortUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getSampleCoordinatorCohortsPromise() {
    const sampleCoordinatorCohortsUrl = `${getUrl('sampleCoordinatorCohortsUrl')}`;
    return axios.get(sampleCoordinatorCohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getTumorStateEnumPromise() {
    return axios.get(getUrl('tumorStateUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getUberonAnatomySitesPromise() {
    return axios.get(getUrl('uberonAnatomySitesUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getUsersInfoPromise() {
    const usersUrl = `${getUrl('usersUrl')}`;
    return axios.get(usersUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getVitalStatusEnumPromise() {
    return axios.get(getUrl('vitalStatusUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getExperimentalStrategyEnumPromise() {
    return axios.get(getUrl('experimentalStrategyUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getAnalyteTypeEnumPromise() {
    return axios.get(getUrl('analyteTypeUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getTreatmentStopReasonPromise() {
    return axios.get(getUrl('treatmentStopReasonUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getPlatformPromise() {
    return axios.get(getUrl('platformUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getDrugCategoriesPromise() {
    return axios.get(getUrl('drugCategoriesUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getDrugTargetsPromise() {
    return axios.get(getUrl('drugTargetsUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getDrugsPromise() {
    return axios.get(getUrl('drugsUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getDrugCombinationsPromise() {
    return axios.get(getUrl('drugCombinationsUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

function getCancerStagingSystemsPromise() {
    return axios.get(getUrl('cancerStagingSystemsUrl'), { headers: { Authorization: `Bearer ${getUserToken()}` } });
}

export {
    getUrl,
    getBiospecimenTumorNormalEnumPromise,
    getClinicalUpdateCategoriesPromise,
    getDiagnosticTestCategoriesPromise,
    getTreatmentCategoriesPromise,
    getCollaboratorsInfoPromise,
    getGenderEnumPromise,
    getRaceEnumPromise,
    getIcdo3TopoCodesPromise,
    getIrbProtocolsPromise,
    getOriginalMaterialTypeEnumPromise,
    getPreservationMethodEnumPromise,
    getAnalyteTypeEnumPromise,
    getSubmittedMaterialTypeEnumPromise,
    getReadProjectsPromise,
    getWriteProjectsPromise,
    getAnalyzeProjectsPromise,
    getLeadProjectsPromise,
    getSubmitterCohortsPromise,
    getDACohortsPromise,
    getLDACohortsPromise,
    getULDACohortsPromise,
    getSampleCoordinatorCohortsPromise,
    getTumorStateEnumPromise,
    getUberonAnatomySitesPromise,
    getUsersInfoPromise,
    getVitalStatusEnumPromise,
    getExperimentalStrategyEnumPromise,
    getTreatmentStopReasonPromise,
    getPlatformPromise,
    getDrugCategoriesPromise,
    getDrugTargetsPromise,
    getDrugsPromise,
    getDrugCombinationsPromise,
    getCancerStagingSystemsPromise
};
