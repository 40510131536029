<template>
    <div>
        <h3>View Drug Combinations</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="drug-combinations-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="drug-combinations-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import store from '../store';

export default {
    props: {
        // For when drugs are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',
            roles: store.state.user.roles,

            // urls
            drugCombinationsUrl: getUrl('drugCombinationsUrl'),

            // table vars
            exportColumns: [0, 1, 2, 3, 4],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Drug combination name', data: 'name' },
                { title: 'Aliases', data: 'aliases' },
                { title: 'Notes', data: 'notes' },
                { title: 'Drugs', data: 'drugs' },
                {
                    title: 'URL',
                    data(row) {
                        const { url } = row;
                        return `<a href="${url}">${url}</a>`;
                    }
                },
                {
                    title: 'Last modified (UTC)',
                    data(row) {
                        const time = row.last_modified;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ],
            // Sample Coordinator Action Columns
            sCColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-drug-combination">Edit</span>';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span delete-drug-combination">Delete</span>';
                    }
                }
            ]
        };
    },
    watch: {
    },
    mounted() {
        this.populateDrugCombinationsTable();
    },
    methods: {
        getTableColumns() {
            let { tableColumns } = this;
            if (this.roles.isAdmin) {
                tableColumns = tableColumns.concat(this.sCColumns);
            }
            return tableColumns;
        },
        populateDrugCombinationsTable() {
            const loader = this.$loading.show(); // loader while waiting for categories GET request
            const component = this;

            axios.get(component.drugCombinationsUrl, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((drugCombinationResp) => {
                    loader.hide();
                    const drugCombinationData = drugCombinationResp.data.drug_combinations;
                    const table = $('#drug-combinations-table').DataTable({
                        destroy: true,
                        data: drugCombinationData,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '80vh',
                        scrollCollapse: true,
                        dom: 'Blfrtip',
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#drug_combination',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'drug_combinations',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#drug_combination'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'drug_combinations',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#drug_combination'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ],
                        fnInitComplete() {
                        }
                    });
                    component.addSCTableActions(table);
                });
        },
        addSCTableActions(datatable) {
            const component = this;
            if (this.roles.isAdmin) {
                $('#drug-combinations-table tbody .edit-drug-combination').click(function editRow() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    component.$router.push({
                        name: 'AdminEditDrugCombinationPage',
                        params: {
                            drugCombinationId: rowData.id,
                            role: component.role
                        }
                    });
                });

                $('#drug-combinations-table tbody .delete-drug-combination').click(function deleteRow() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete category ${rowData.name}?`;
                    if(confirm(confirmMessage)){
                        component.deleteDrugCombination(rowData.id);
                    }
                });
            }
        },
        deleteDrugCombination(id) {
            const component = this;
            const loader = this.$loading.show();
            const deleteUrl = `${this.drugCombinationsUrl}?id=${id}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.populateDrugCombinationsTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete drug combination. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        }
    }
};
</script>

<style>
#drug-combinations-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
