<template>
  <div>
    <h3>View Collaborators</h3>
    <div id="sync-all-samples">
      <button v-on:click="syncAll">Synchronize All Samples</button>
    </div>
    <hr />

    <b-alert :show="showAlert" :variant="alertVariant" dismissible>
      {{
        alertMessage.message === undefined ? alertMessage : alertMessage.message
      }}
    </b-alert>

    <b-container fluid>
      <b-row id="collaborators-table-wrapper">
        <!-- showing/hiding table is controlled by jQuery and DataTables -->
        <table id="collaborators-table" class="display compact stripe" />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { getUrl } from "../../utils/directory";
import { SAMPLE_COORDINATOR } from "../../utils/constants";
import getUserToken from "../../utils/auth";

export default {
  props: {
    // For when collaborators are updated
    needAlert: { default: false, type: Boolean, required: false },
    ajaxResponse: {
      default() {
        return { data: { message: "" } };
      },
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      // alert box vars
      showAlert: this.needAlert,
      alertMessage: this.ajaxResponse.data,
      alertVariant: "warning",

      // urls
      sampleCoordinatorCollaboratorsUrl: getUrl(
        "sampleCoordinatorCollaboratorsUrl"
      ),
      scSamplesUrl: getUrl("sampleCoordinatorSamplesUrl"),
      scSyncAllUrl: getUrl("sampleCoordinatorSyncAllUrl"),

      // prop vars
      role: SAMPLE_COORDINATOR,

      // table vars
      exportColumns: [0, 1, 2],
      tableColumns: [
        { title: "DBKey", data: "id" },
        { title: "Name", data: "name" },
        { title: "Institution", data: "institution" },
        { title: "Email", data: "email" },
        { title: "Cohorts", data: "cohorts" },
        { title: "Delivery Workspaces", data: "delivery_workspaces" },
        { title: "Notes", data: "notes" },
        {
          title: "Edit",
          sortable: false,
          render() {
            return '<span class="text-btn-span edit-collaborator">Edit</span>';
          },
        },
        {
          title: "Sync",
          sortable: false,
          render() {
            return '<span class="text-btn-span sync-collaborator">Sync</span>';
          },
        },
      ],
      tablePaginationOptions: [
        [-1, 10, 25, 50, 100], // pagination values; -1 displays all
        ["All", 10, 25, 50, 100], // pagination display text
      ],
    };
  },
  watch: {},
  mounted() {
    this.populateCollaboratorsTable();
  },
  methods: {
    populateCollaboratorsTable() {
      const loader = this.$loading.show(); // loader while waiting for categories GET request
      const component = this;

      axios
        .get(component.sampleCoordinatorCollaboratorsUrl, {
          headers: { Authorization: `Bearer ${getUserToken()}` },
        })
        .then((collaboratorResp) => {
          loader.hide();
          const collaboratorTableData = collaboratorResp.data.collaborators;
          const table = $("#collaborators-table").DataTable({
            destroy: true,
            data: collaboratorTableData,
            columns: component.tableColumns,
            order: [], // no sorting, initially
            lengthMenu: component.tablePaginationOptions,
            fixedHeader: {
              header: true,
            },
            scrollY: "70vh",
            scrollCollapse: true,
          });
          component.addTableActions(table);
        });
    },
    addTableActions(datatable) {
      const component = this;
      $("#collaborators-table tbody .edit-collaborator").click(
        function editCollaborator() {
          const rowData = datatable.row($(this).parents("tr")).data();
          component.$router.push({
            name: "SampleCoordinatorEditCollaboratorPage",
            params: {
              collabId: rowData.id,
            },
          });
        }
      );
      $("#collaborators-table tbody .sync-collaborator").click(
        function syncCollaborator() {
          const rowData = datatable.row($(this).parents("tr")).data();
          component.syncCollaborator(rowData);
        }
      );
    },
    // Sync all cohort make sure test flag is removed
    syncAll: function () {
      const component = this;
      const url = `${component.scSyncAllUrl}?testFlag=false`;
      const loader = component.$loading.show();
      axios
        .post(
          url,
          {},
          { headers: { Authorization: `Bearer ${getUserToken()}` } }
        )
        .then((resp) => {
          component.alertMessage = resp.data.message;
          component.showAlert = true;
        })
        .finally(() => loader.hide());
    },
    // Sample coordinator action for when the "sync" button under the sync column is clicked
    syncCollaborator(rowData) {
      const component = this;
      const url = `${component.scSamplesUrl}?collaborator_id=${rowData.id}`;
      const loader = component.$loading.show();
      axios
        .post(
          url,
          {},
          { headers: { Authorization: `Bearer ${getUserToken()}` } }
        )
        .then((resp) => {
          component.$router.push({
            name: "SampleCoordinatorSyncCollaboratorPage",
            params: {
              role: component.role,
              syncResp: resp,
              collaboratorId: rowData.id,
            },
          });
        })
        .catch((err) => {
          component.showAlert = true;
          component.alertVariant = "danger";
          component.alertMessage = err.response.data;
          if (err.response === undefined) {
            component.alertMessage =
              "Failed to sync collaborator. Please contact portal administrators.";
          }
          window.scrollTo(0, 0);
        })
        .then(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style>
#collaborators-table-wrapper {
  font-size: 0.85rem;
  overflow-x: auto;
}
</style>
