<template>
    <div>
        <h3>Create a New Project</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form>
                <b-form-group label="Project Name" label-class="font-weight-bold" :description="requirements.projectName">
                    <b-form-input required size="sm" v-model="v$.form.projectName.$model" :state="v$.form.projectName.$dirty ? !v$.form.projectName.$error : null" />
                </b-form-group>

                <b-form-group label="Collaborator Name" label-class="font-weight-bold">
                    <b-form-select size="sm" v-model="form.selectedCollaborator" :options="collaboratorOptions" />
                </b-form-group>

                <b-form-group label="Internal Getz Lab project?" label-class="font-weight-bold">
                    <b-form-checkbox size="sm" v-model="form.labProject" value="true" unchecked-value="false" />
                </b-form-group>

                <b-form-group label="Project Description (optional)" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.projectDescription" />
                </b-form-group>

                <b-form-group label="Project Leads (optional)" label-class="font-weight-bold" :description="form.userSelectDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedProjectLeads" :options="projectLeadOptions" />
                </b-form-group>

                <b-form-group label="Data Analysts (optional)" label-class="font-weight-bold" :description="form.userSelectDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedDataAnalysts" :options="dataAnalystOptions" />
                </b-form-group>

                <b-form-group label="Data Editors (optional)" label-class="font-weight-bold" :description="form.userSelectDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedDataEditors" :options="dataEditorOptions" />
                </b-form-group>

                <b-form-group label="Project Readers (optional)" label-class="font-weight-bold" :description="form.userSelectDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedReaders" :options="readerOptions" />
                </b-form-group>
            </b-form>
            <b-btn type="outline-secondary" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid" @click="createCohort">
                Create Project
            </b-btn>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    minLength, required
} from '@vuelidate/validators';
import { cohortNameCheck } from '../../utils/input-regex';
// import { cohortNameCheck } from '../../utils/input-regex';
import getUserToken from '../../utils/auth';
import { getUrl, getCollaboratorsInfoPromise } from '../../utils/directory';
import { createDropdownOptions, createCollaboratorDict, createUsersDict } from '../../utils/form-data-helpers';
import { PROJECT_LEAD } from '../../utils/constants';
import store from '../../store';

export default {
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            plProjectsUrl: getUrl('projectLeadProjectsUrl'),
            plUsersUrl: getUrl('projectLeadUsersUrl'),

            // options for form dropdown
            collaboratorOptions: [],
            projectLeadOptions: [],
            dataAnalystOptions: [],
            dataEditorOptions: [],
            readerOptions: [],

            // form requirements
            requirements: {
                projectName: 'Project name must be >= 2 characters and contain only alphanumeric characters, periods (.), dashes (-), and underscores (_).',
            },

            // selected cohort information
            form: {
                projectName: '',
                projectDescription: null,
                selectedCollaborator: undefined,
                labProject: false,
                selectedProjectLeads: [],
                selectedDataAnalysts: [],
                selectedDataEditors: [],
                selectedReaders: [],
                userSelectDescription: 'Press and hold the CMD key to select multiple users. All users who are assigned to be project leads also become data editors and data analysts. All leads, editors, and analysts automatically become readers.',
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                projectName: {
                    required,
                    minLength: minLength(2),
                    cohortNameCheck
                },
            }
        }
    },
    mounted() {
        this.populateCohortAddForm();
    },
    methods: {
        getUsersInfo() {
            return axios.get(this.plUsersUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateCohortAddForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getCollaboratorsInfoPromise(),
                this.getUsersInfo()
            ])
                .then(axios.spread((collabResp, uResp) => {
                    loader.hide();

                    const collaborators = createCollaboratorDict(collabResp.data.collaborators);
                    const users = createUsersDict(uResp.data.users);

                    // using DB IDs as the value
                    component.collaboratorOptions = createDropdownOptions(collaborators, true, false);
                    const userOptions = createDropdownOptions(users, true, false);
                    component.projectLeadOptions = userOptions;
                    component.dataAnalystOptions = userOptions;
                    component.dataEditorOptions = userOptions;
                    component.readerOptions = userOptions;

                    // selecting first collaborator as default
                    component.form.selectedCollaborator = component.collaboratorOptions[0].value;
                }));
        },
        createCohort() {
            this.addError = false; // hiding error alert message in case it was showing
            const component = this;
            const loader = this.$loading.show();
            const newCohortInfo = {
                name: this.form.projectName,
                description: this.form.projectDescription === '' ? null : this.form.projectDescription,
                collaborator: this.form.selectedCollaborator,
                lab_project: this.form.labProject,
                project_leads: this.form.selectedProjectLeads,
                data_analysts: this.form.selectedDataAnalysts,
                data_editors: this.form.selectedDataEditors,
                readers: this.form.selectedReaders,
            };
            axios.post(this.plProjectsUrl, newCohortInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then(async (resp) => {
                    await store.setUserRoles();
                    loader.hide();
                    console.log(resp)
                    component.$router.push({
                        name: 'UserViewProjectsPage',
                        params: {
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    console.log(err)
                    component.addError = true;
                    component.errorResponse = err.response;
                });
        }
    }

};
</script>

<style>

</style>
