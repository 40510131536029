<template>
    <div>
        <h3>Edit Project</h3>
        <hr>

        <b-alert :show="updateError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4" sm="6">
            <b-form>
                <b-form-group label="Project DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="localCohortId" disabled />
                </b-form-group>

                <b-form-group label="Project Name" label-class="font-weight-bold" :description="requirements.projectName">
                    <b-form-input required size="sm" v-model="v$.form.projectName.$model" :state="v$.form.projectName.$dirty ? !v$.form.projectName.$error : null" />
                </b-form-group>

                <b-form-group label="Collaborator Name" label-class="font-weight-bold">
                    <b-form-select size="sm" v-model="form.selectedCollaborator" :options="collaboratorOptions" />
                </b-form-group>

                <b-form-group label="Internal Getz Lab project?" label-class="font-weight-bold">
                    <b-form-checkbox size="sm" v-model="form.labProject" value="true" unchecked-value="false" />
                </b-form-group>

                <b-form-group label="Project Description (optional)" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.projectDescription" />
                </b-form-group>

                <b-form-group label="Project Leads (optional)" label-class="font-weight-bold" :description="form.userSelectDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedProjectLeads" :options="projectLeadOptions" />
                </b-form-group>

                <b-form-group label="Data Analysts (optional)" label-class="font-weight-bold" :description="form.userSelectDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedDataAnalysts" :options="dataAnalystOptions" />
                </b-form-group>

                <b-form-group label="Data Editors (optional)" label-class="font-weight-bold" :description="form.userSelectDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedDataEditors" :options="dataEditorOptions" />
                </b-form-group>

                <b-form-group label="Project Readers (optional)" label-class="font-weight-bold" :description="form.userSelectDescription">
                    <b-form-select multiple size="sm" v-model="form.selectedReaders" :options="readerOptions" />
                </b-form-group>
            </b-form>
            <b-btn type="outline-secondary" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid" @click="updateCohort">
                Update Project
            </b-btn>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    minLength, required
} from '@vuelidate/validators';
import { cohortNameCheck } from '../../utils/input-regex';
import getUserToken from '../../utils/auth';
import { getUrl, getCollaboratorsInfoPromise } from '../../utils/directory';
import { createDropdownOptions, createCollaboratorDict, createUsersDict } from '../../utils/form-data-helpers';
import store from '../../store';

export default {
    name: 'ProjectLeadEditProjectPage',
    props: {
        cohortId: { type: Number, required: true }
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // prop local vars
            localCohortId: this.cohortId,

            // URLs used
            plProjectsUrl:`${getUrl('projectLeadProjectsUrl')}?id=${this.cohortId}&name_id_only=false`,
            plUsersUrl: getUrl('projectLeadUsersUrl'),

            // options for dropdown
            collaboratorOptions: [],
            projectLeadOptions: [],
            dataEditorOptions: [],
            dataAnalystOptions: [],
            readerOptions: [],

            // form requirements
            requirements: {
                projectName: 'Project name must be >= 2 characters and contain only alphanumeric characters, periods (.), dashes (-), and underscores (_).'
            },

            // selected cohort metadata -- will initially come from the DB, but can be updated
            form: {
                projectName: '',
                projectDescription: null,
                selectedCollaborator: undefined,
                labProject: false,
                selectedProjectLeads: [],
                selectedDataEditors: [],
                selectedDataAnalysts: [],
                selectedReaders: [],
                userSelectDescription: 'Press and hold the CMD key to select multiple users. All users who are assigned to be project leads will also, by default, be assigned as data analysts and data editors if they have not been already. All leads, editors, and analysts automatically become readers.'
            },

            // error modal
            updateError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                projectName: {
                    required,
                    minLength: minLength(2),
                    cohortNameCheck
                }
            }
        }
    },
    mounted() {
        this.populateCohortEditForm();
    },
    methods: {
        getUsersInfo() {
            return axios.get(this.plUsersUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getCohortInfo() {
            return axios.get(this.plProjectsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateCohortEditForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getCollaboratorsInfoPromise(),
                this.getUsersInfo(),
                this.getCohortInfo()
            ])
                .then(axios.spread((collabResp, uResp, cohortResp) => {
                    loader.hide();

                    const collaborators = createCollaboratorDict(collabResp.data.collaborators);
                    const users = createUsersDict(uResp.data.users);
                    const cohortData = cohortResp.data.cohorts[0];

                    // current cohort metadata
                    component.form.projectName = cohortData.name;
                    component.form.cohortDescription = cohortData.description;
                    component.form.labProject = cohortData.lab_project;
                    component.form.selectedProjectLeads = cohortData.project_leads.map(x => users[x]);
                    component.form.selectedDataEditors = cohortData.data_editors.map(x => users[x]);
                    component.form.selectedDataAnalysts = cohortData.data_analysts.map(x => users[x]);
                    component.form.selectedReaders = cohortData.readers.map(x => users[x]);
                    component.form.selectedCollaborator = collaborators[cohortData.collaborator];

                    // using DB IDs as the value
                    component.collaboratorOptions = createDropdownOptions(collaborators, true, false);
                    const userOptions = createDropdownOptions(users, true, false);
                    component.projectLeadOptions = userOptions;
                    component.dataEditorOptions = userOptions;
                    component.dataAnalystOptions = userOptions;
                    component.readerOptions = userOptions;
                }));
        },
        updateCohort() {
            const loader = this.$loading.show();
            const component = this;
            const updatedInfo = {
                name: this.form.projectName,
                description: this.form.projectDescription === '' ? null : this.form.projectDescription,
                collaborator: this.form.selectedCollaborator,
                lab_project: this.form.labProject,
                project_leads: this.form.selectedProjectLeads,
                data_editors: this.form.selectedDataEditors,
                data_analysts: this.form.selectedDataAnalysts,
                readers: this.form.selectedReaders
            };

            axios.put(this.plProjectsUrl, updatedInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then(async (resp) => {
                    await store.setUserRoles();
                    loader.hide();
                    component.$router.push({
                        name: 'ProjectLeadViewProjectPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.updateError = true;
                    if (err.response === undefined) {
                        component.errorResponse = 'Failed to update project. Please contact portal administrators.';
                    } else {
                        component.errorResponse = err.response;
                    }
                });
        }
    }
};
</script>

<style>

</style>
