<template>
    <div>
        <h3>View Diagnostic Test Categories</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="diag-test-categories-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="diag-test-categories-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../utils/directory';
import { SAMPLE_COORDINATOR, SUBMITTER } from '../utils/constants';
import getUserToken from '../utils/auth';

export default {
    props: {
        role: {
            type: String,
            required: true,
            validator(val) {
                return [SAMPLE_COORDINATOR, SUBMITTER].indexOf(val) !== -1;
            }
        },
        // For when diagnostic test categories are updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // urls
            submitterDiagnosticTestCategoriesUrl: getUrl('submitterDiagnosticTestCategoriesUrl'),
            sampleCoordinatorDiagnosticTestCategoriesUrl: getUrl('sampleCoordinatorDiagnosticTestCategoriesUrl'),
            testCategoriesUrl: null,

            // table vars
            exportColumns: [0, 1, 2],
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Diagnostic Test Category', data: 'name' }, // name refers to category name in request data
                { title: 'Notes', data: 'notes' }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ],
            // Sample Coordinator Action Columns
            sCColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-diag-test-categories">Edit</span>';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span delete-diag-test-categories">Delete</span>';
                    }
                }
            ]
        };
    },
    watch: {
    },
    mounted() {
        this.populateCategoriesTable();
    },
    methods: {
        getTableColumns() {
            let { tableColumns } = this;
            if (this.role === SAMPLE_COORDINATOR) {
                tableColumns = tableColumns.concat(this.sCColumns);
            }
            return tableColumns;
        },
        populateCategoriesTable() {
            const loader = this.$loading.show(); // loader while waiting for categories GET request
            const component = this; // Making calls to the Vue component more explicit
            this.testCategoriesUrl = (component.role === SAMPLE_COORDINATOR
            ) ? component.sampleCoordinatorDiagnosticTestCategoriesUrl : component.submitterDiagnosticTestCategoriesUrl;

            axios.get(component.testCategoriesUrl, {
                headers: { Authorization: `Bearer ${getUserToken()}` }
            })
                .then((testCategoryResp) => {
                    loader.hide();
                    const categoriesTableData = testCategoryResp.data.diagnostic_test_categories;
                    const table = $('#diag-test-categories-table').DataTable({
                        destroy: true,
                        data: categoriesTableData,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '80vh',
                        scrollCollapse: true,
                        dom: 'Blfrtip',
                        buttons: [
                            {
                                extend: 'copy',
                                title: '#diagnostic_test_category',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'diagnostic_test_categories',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#diagnostic_test_category'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'Template',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: 'diagnostic_test_categories',
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#diagnostic_test_category'].concat([exportRows[0]]);
                                    return exportRows.join('\n');
                                }
                            }
                        ],
                        fnInitComplete() {
                        }
                    });
                    component.addSCTableActions(table);
                });
        },
        addSCTableActions(datatable) {
            const component = this;
            if (this.role === SAMPLE_COORDINATOR) {
                $('#diag-test-categories-table tbody .edit-diag-test-categories').click(function updateCategory() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    component.$router.push({
                        name: 'SampleCoordinatorEditDiagnosticTestCategoryPage',
                        params: {
                            testCategoryId: rowData.id,
                            role: component.role
                        }
                    });
                });

                $('#diag-test-categories-table tbody .delete-diag-test-categories').click(function deleteCategory() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete category ${rowData.name}?`;
                    const msgBoxOptions = {
                        hideHeader: true,
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Delete Category',
                        centered: true
                    };

                    component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                        .then((deleteConfirmed) => {
                            if (deleteConfirmed) {
                                component.deleteDiagTestCategory(rowData.id);
                            }
                        });
                });
            }
        },
        deleteDiagTestCategory(id) {
            const component = this;
            const loader = this.$loading.show();
            const deleteUrl = `${this.testCategoriesUrl}?id=${id}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.populateCategoriesTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete category. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        }
    }
};
</script>

<style>
#diag-test-categories-table-wrapper {
    font-size: 0.85rem;
    overflow-x: auto;
}
</style>
