<template>
    <div>
        <h3>Edit Cancer Stage</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="6">
            <b-form @submit.stop.prevent="updateCancerStage">
                <b-form-group label="Cancer Stage DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.cancerStageId" disabled />
                </b-form-group>
                <b-form-group label="Cancer Staging System" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.cancerStagingSystemName" disabled />
                </b-form-group>
                <b-form-group label="Cancer Type" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.cancerTypeName" disabled />
                </b-form-group>
                <b-form-group label="Cancer Stage" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.cancerStageName.$model" :state="v$.form.cancerStageName.$dirty ? !v$.form.cancerStageName.$error : null" />
                </b-form-group>
                <b-form-group label="Cancer Stage Information" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.cancerStageInformation" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Cancer Stage
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    maxLength,
    minLength,
    required
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {
    getUrl
} from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    name: 'AdminEditCancerTypePage',
    props: {
        cancerTypeId: { type: Number, required: true },
        cancerTypeName: { type: String, required: true },
        cancerStagingSystemName: { type: String, required: true },
        cancerStageId: { type: Number, required: true },
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            sampleCoordinatorCancerStagesUrl: `${getUrl('sampleCoordinatorCancerStagesUrl')}?cancer_type_id=${this.cancerTypeId}&id=${this.cancerStageId}`,

            // form info
            form: {
                // unchangeable items
                cancerTypeId: null,
                cancerStagingSystemName: this.cancerStagingSystemName,
                cancerTypeName: this.cancerTypeName,

                // input
                cancerStageName: null,
                cancerStageInformation: null
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // input
                cancerStageName: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(16)
                }
            }
        }
    },
    mounted() {
        this.populateCancerStageEditForm();
    },
    methods: {
        getCancerStageInfo() {
            return axios.get(this.sampleCoordinatorCancerStagesUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateCancerStageEditForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                this.getCancerStageInfo()
            ])
                .then(axios.spread((cancerStageResp) => {
                    loader.hide();
                    const currCancerStageData = cancerStageResp.data.cancer_stages[0];

                    component.form.cancerStageId = currCancerStageData.id;
                    component.form.cancerStageName = currCancerStageData.stage_number;
                    component.form.cancerStageInformation = currCancerStageData.stage_information;
                }));
        },
        updateCancerStage() {
            const loader = this.$loading.show();
            const component = this;

            const cancerStageInfo = {
                stage_number: component.form.cancerStageName,
                stage_information: component.form.cancerStageInformation
            };
            axios.put(this.sampleCoordinatorCancerStagesUrl, cancerStageInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.$router.push({
                        name: 'AdminViewCancerStagesPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                });
        }
    }
};
</script>

<style>

</style>
