<template>
    <div>
        <h3>Edit Participant Main Normal</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="6">
            <b-form @submit.stop.prevent="updateParticipant">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.cohortName" disabled />
                </b-form-group>
                <b-form-group label="Participant DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.participantDbId" disabled />
                </b-form-group>
                <b-form-group label="Collaborator Participant ID" :description="requirements.submittedName" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.submittedName.$model" :state="v$.form.submittedName.$dirty ? !v$.form.submittedName.$error : null" disabled />
                </b-form-group>
                <b-form-group label="Assigned Main Normal Biospecimen" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="mainNormalOptions" v-model="form.selectedMainNormal" />
                </b-form-group>
                <b-form-group label="Participant aliases [optional]" :description="requirements.aliases" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.aliases.$model" :state="v$.form.aliases.$dirty ? !v$.form.aliases.$error : null" disabled />
                </b-form-group>
                <b-form-group label="Shared cohorts [optional]" label-class="font-weight-bold" description="Press and hold the CMD key to select multiple cohorts.">
                    <b-form-input size="sm" v-model="form.selectedSecCohorts" disabled />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Participant
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    between,
    maxLength,
    minLength,
    required
} from '@vuelidate/validators';
import {
    getUrl,
    getGenderEnumPromise,
    getRaceEnumPromise,
    getIcdo3TopoCodesPromise,
    getVitalStatusEnumPromise
} from '../../utils/directory';
import {
    createDropdownOptions,
    createGenderDict,
    createRaceDict,
    createIcdo3TopoCodesDict,
    createVitalStatusDict,
    createCohortsDict,
    createBiospecimensDict
} from '../../utils/form-data-helpers';
import { participantAliasCheck, participantIdCheck } from '../../utils/input-regex';
import getUserToken from '../../utils/auth';

export default {
    name: 'DataAnalystEditParticipantPage',
    props: {
        cohortName: { type: String, required: true },
        cohortId: { type: Number, required: true },
        participantId: { type: Number, required: true },
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            daParticipantUrl: `${getUrl('dataAnalystParticipantsUrl')}?cohort_id=${this.cohortId}&id=${this.participantId}&include_secondary_data=true&name_id_only=false`,
            daNormalBiospecimensUrl: `${getUrl('biospecimensUrl')}?cohort_id=${this.cohortId}&participant_id=${this.participantId}`,
            daCohortsUrl: `${getUrl('cohortsUrl')}`,

            // options for form dropdowns
            cohortOptions: [],
            secCohortOptions: [],
            genderOptions: [],
            raceOptions: [],
            vitalStatusOptions: [],
            tumorMorphologyOptions: [],
            mainNormalOptions: [],

            // form requirements
            requirements: {
                submittedName: 'Names must be between 4 and 64 characters and can only contain alphanumeric characters, periods (.), dashes (-) and underscores (_).',
                aliases: 'Please input as a comma-separated list. Aliases must only contain alphanumeric characters, periods (.), dashes (-) and underscores (_).',
                tumorMorphology: 'Must be between 6 and 256 characters.'
            },

            // form info
            form: {
                // unchangeable items
                cohortName: this.cohortName,
                participantDbId: null,
                // dropdowns
                selectedCohort: null,
                selectedGender: null,
                selectedRace: null,
                selectedVitalStatus: null,
                selectedTumorPrimary: null,
                selectedMainNormal: null,
                selectedSecCohorts: null,
                // input
                submittedName: '',
                aliases: null,
                tumorMorphology: null,
                tumorMolecularSubtype: '',
                ageOfDiagnosis: null,
                yearOfDiagnosis: null,
                dateOfDeathDfd: null,
                notes: ''
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // dropdowns
                selectedGender: { required },
                selectedVitalStatus: { required },
                selectedTumorPrimary: { required },
                // input
                submittedName: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(64),
                    participantIdCheck
                },
                aliases: { participantAliasCheck },
                tumorMorphology: {
                    minLength: minLength(6),
                    maxLength: maxLength(256)
                },
                ageOfDiagnosis: { between: between(0, 120) },
                yearOfDiagnosis: { between: between(1900, new Date().getFullYear()) },
                dateOfDeathDfd: { between: between(0, 36500) }
            }
        }
    },
    mounted() {
        this.populateParticipantEditForm();
    },
    methods: {
        getParticipantInfo() {
            return axios.get(this.daParticipantUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getLDANormalBiospecimenInfo() {
            return axios.get(this.daNormalBiospecimensUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getCohortsInfo() {
            return axios.get(this.daCohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateParticipantEditForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getGenderEnumPromise(),
                getRaceEnumPromise(),
                getVitalStatusEnumPromise(),
                getIcdo3TopoCodesPromise(),
                this.getCohortsInfo(),
                this.getParticipantInfo(),
                this.getLDANormalBiospecimenInfo()
            ])
                .then(axios.spread((genderResp, raceResp, vitalStatusResp, icdo3Resp, cohortsResp, participantResp, mainNormalResp) => {
                    loader.hide();
                    const cohorts = createCohortsDict(cohortsResp.data.cohorts);
                    const genders = createGenderDict(genderResp.data.gender);
                    const races = createRaceDict(raceResp.data.race);
                    const icdo3Codes = createIcdo3TopoCodesDict(icdo3Resp.data.icdo3_topo_codes);
                    const vitalStatuses = createVitalStatusDict(vitalStatusResp.data.vital_status);
                    const normalBiospecimens = createBiospecimensDict(mainNormalResp.data.biospecimens);
                    const currParticipantData = participantResp.data.participants[0];
                    // using DB IDs as the value
                    component.secCohortOptions = createDropdownOptions(cohorts, true, false);
                    component.genderOptions = createDropdownOptions(genders);
                    component.raceOptions = createDropdownOptions(races);
                    component.vitalStatusOptions = createDropdownOptions(vitalStatuses);
                    component.tumorMorphologyOptions = createDropdownOptions(icdo3Codes);
                    component.mainNormalOptions = createDropdownOptions(normalBiospecimens);
                    // current participant metadata -- unchangeable form fields
                    component.form.participantDbId = currParticipantData.db_id;
                    // current participant metadata -- input
                    component.form.submittedName = currParticipantData.participant_id;
                    component.form.aliases = currParticipantData.aliases;
                    component.form.tumorMorphology = currParticipantData.tumor_morphology;
                    component.form.tumorMolecularSubtype = currParticipantData.tumor_molecular_subtype;
                    component.form.ageOfDiagnosis = currParticipantData.age_at_diagnosis;
                    component.form.yearOfDiagnosis = currParticipantData.year_of_diagnosis;
                    component.form.dateOfDeathDfd = currParticipantData.death_date_dfd;
                    component.form.notes = currParticipantData.notes;
                    component.form.selectedSecCohorts = currParticipantData.secondary_cohorts;
                    // current participant metadata -- dropdown selections
                    component.form.selectedGender = component.genderOptions.filter(x => x.text === currParticipantData.gender)[0].value;
                    const participantRace = component.raceOptions.filter(x => x.text === currParticipantData.race);
                    if (participantRace.length) { // race could be unset for the participant, which would result in an empty array here
                        component.form.selectedRace = participantRace[0].value;
                    }
                    component.form.selectedVitalStatus = component.vitalStatusOptions.filter(x => x.text === currParticipantData.vital_status)[0].value;
                    component.form.selectedTumorPrimary = component.tumorMorphologyOptions.filter(x => x.text === currParticipantData.tumor_primary_site)[0].value;
                    if (currParticipantData.main_normal != null) {
                        component.form.selectedMainNormal = component.mainNormalOptions.filter(x => x.text === currParticipantData.main_normal)[0].value;
                    }
                }));
        },
        updateParticipant() {
            const loader = this.$loading.show();
            const component = this;

            const participantInfo = {
                main_normal: this.form.selectedMainNormal
            };
                axios.put(this.daParticipantUrl, participantInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.$router.push({
                        name: 'DataAnalystViewParticipantsPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            editedCohortId: component.cohortId,
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                });
        }
    }
};
</script>

<style>

</style>
