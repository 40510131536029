<template>
    <div>
        <h2>Edit Biospecimen</h2>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateBiospecimen">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="form.cohortName" disabled />
                </b-form-group>
                <b-form-group label="Participant ID" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="form.participantName" disabled />
                </b-form-group>
                <b-form-group label="Biospecimen DB ID" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="form.biospecimenDbId" disabled />
                </b-form-group>
                <b-form-group label="Collaborator Sample ID" :description="requirements.submittedName" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.submittedName.$model" :state="v$.form.submittedName.$dirty ? !v$.form.submittedName.$error : null" />
                </b-form-group>
                <b-form-group label="Aliases [optional]" :description="requirements.aliases" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.aliases.$model" :state="v$.form.aliases.$dirty ? !v$.form.aliases.$error : null" />
                </b-form-group>
                <b-form-group label="IRB Protocol IDs" label-class="font-weight-bold">
                    <b-form-select multiple required size="sm" :options="irbProtocolOptions" v-model="v$.form.protocolsCollectedUnder.$model" />
                </b-form-group>
                <b-form-group label="Collection date (days from diagnosis) [optional]" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.collectionDateDfd.$model" :state="v$.form.collectionDateDfd.$dirty ? !v$.form.collectionDateDfd.$error : null" />
                </b-form-group>
                <b-form-group label="Tissue site (Uberon anatomy ontology) [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="tissueSiteOptions" v-model="form.tissueSite" />
                </b-form-group>
                <b-form-group label="Tissue site details [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.tissueSiteDetail" />
                </b-form-group>
                <b-form-group label="Original material type" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="origMatTypeOptions" v-model="v$.form.originalMaterialType.$model" :state="v$.form.originalMaterialType.$dirty ? !v$.form.originalMaterialType.$error : null" />
                </b-form-group>
                <b-form-group label="Submitted material type" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="submitMatTypeOptions" v-model="v$.form.submittedMaterialType.$model" :state="v$.form.submittedMaterialType.$dirty ? !v$.form.submittedMaterialType.$error : null" />
                </b-form-group>
                <b-form-group label="Preservation method" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="preserveMethodOptions" v-model="v$.form.preservationMethod.$model" :state="v$.form.preservationMethod.$dirty ? !v$.form.preservationMethod.$error : null" />
                </b-form-group>
                <b-form-group label="Tumor/Normal" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="tumorNormalOptions" v-model="v$.form.tumorNormal.$model" :state="v$.form.tumorNormal.$dirty ? !v$.form.tumorNormal.$error : null" />
                </b-form-group>
                <b-form-group label="Tumor state" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="tumorStateOptions" v-model="v$.form.tumorState.$model" :state="v$.form.tumorState.$dirty ? !v$.form.tumorState.$error : null" />
                </b-form-group>
                <b-form-group label="RQS [optional]" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.rqs.$model" :state="v$.form.rqs.$dirty ? !v$.form.rqs.$error : null" />
                </b-form-group>
                <b-form-group label="DV200 [optional]" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.dv200.$model" :state="v$.form.dv200.$dirty ? !v$.form.dv200.$error : null" />
                </b-form-group>
                <b-form-group label="WES Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.wes" />
                </b-form-group>
                <b-form-group label="WGS Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.wgs" />
                </b-form-group>
                <b-form-group label="RNASeq Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.rnaseq" />
                </b-form-group>
                <b-form-group label="ULPWGS Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.ulpwgs" />
                </b-form-group>
                <b-form-group label="SCRNASeq Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.scrnaseq" />
                </b-form-group>
                <b-form-group label="Panel Sample Sample Status Submitted Date" :description="form.sampleStatusDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.sampleStatuses.panel" />
                </b-form-group>
                <b-form-group label="GP Sample ID [optional]" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.gpSampleId" />
                </b-form-group>
                <b-form-group label="GP Submitted Date [optional]" :description="form.gpDateDescription" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="form.gpSubmittedDate" />
                </b-form-group>
                <b-form-group label="Low-quality sample?" label-class="font-weight-bold">
                    <b-form-checkbox size="sm" v-model="form.is_low_quality" value="true" unchecked-value="false" />
                </b-form-group>
                <b-form-group label="Quality Description [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.quality_description" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-form-group label="Post-Allogeneic Stem Cell Transplant" label-class="font-weight-bold">
                    <b-form-checkbox size="sm" v-model="form.post_allogeneic_transplant" value="true" unchecked-value="false" />
                </b-form-group>
                <!-- <b-form-group label="Assigned Main Sample" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="mainSampleOptions" v-model="form.selectedMainSample" />
                </b-form-group> -->

                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Biospecimen
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    between, maxLength, minLength, required
} from '@vuelidate/validators';
import { biospecimenIdCheck, biospecimenAliasCheck } from '../../utils/input-regex';
import {
    getUrl,
    getBiospecimenTumorNormalEnumPromise,
    getIrbProtocolsPromise,
    getOriginalMaterialTypeEnumPromise,
    getPreservationMethodEnumPromise,
    getSubmittedMaterialTypeEnumPromise,
    getTumorStateEnumPromise,
    getUberonAnatomySitesPromise
} from '../../utils/directory';
import {
    createDropdownOptions, createEnumDict, createSamplesDict, createIrbProtocolsDict, createUberonAnatomySitesDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    name: 'DataEditorEditBiospecimenPage',
    props: {
        cohortName: { type: String, required: true },
        cohortId: { type: Number, required: true },
        biospecimenId: { type: Number, required: true },
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // urls used
            sBiospecimenUrl: `${getUrl('biospecimensUrl')}`,
            editBiospecimenUrl: `${getUrl('biospecimensUrl')}?cohort_id=${this.cohortId}&id=${this.biospecimenId}&name_id_only=false`,
            sampelsUrl: `${getUrl('samplesUrl')}?biospecimen_id=${this.biospecimenId}`,

            // options for form dropdowns
            cohortOptions: [],
            participantOptions: [],
            irbProtocolOptions: [],
            tissueSiteOptions: [],
            origMatTypeOptions: [],
            submitMatTypeOptions: [],
            preserveMethodOptions: [],
            tumorNormalOptions: [],
            tumorStateOptions: [],
            // mainSampleOptions: [],

            // form requirements
            requirements: {
                submittedName: 'Collaborator Sample IDs can only contain alphanumeric characters, periods (.), dashes (-), pluses (+), parentheses, forwardslashes (/), backslashes (\\) and underscores (_).',
                aliases: 'Please input as a comma-separated list. Aliases must only contain alphanumeric characters, spaces, periods (.), dashes (-), pluses (+), parentheses, forwardslashes (/), backslashes (\\) and underscores (_).'
            },

            form: {
                // uneditable fields
                cohortName: this.cohortName,
                cohortId: this.cohortId,
                participantName: null,
                biospecimenDbId: null,

                // dropdowns and selections
                protocolsCollectedUnder: [],
                tissueSite: null,
                originalMaterialType: null,
                submittedMaterialType: null,
                preservationMethod: null,
                tumorNormal: null,
                tumorState: null,
                // selectedMainSample: null,

                // inputs
                submittedName: null,
                aliases: null,
                collectionDateDfd: null,
                tissueSiteDetail: null,
                rqs: null,
                dv200: null,
                sm_id: null,
                gp_submitted_date: null,
                notes: '',
                post_allogeneic_transplant: false,
                is_low_quality: false,
                quality_description: '',

                sampleStatuses: {
                    wes: '',
                    wgs: '',
                    rnaseq: '',
                    ulpwgs: '',
                    scrnaseq: '',
                    panel: ''
                },

                // description for form
                sampleStatusDescription: 'Accepted entries: blank entry, delete, or a date in the format MM/DD/YYYY',
                gpDateDescription: 'Accepted entries: blank entry, a date in the format MM/DD/YYYY, or a date in the format MM/DD/YY'
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // dropdowns
                protocolsCollectedUnder: { required },
                // inputs
                submittedName: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(64),
                    biospecimenIdCheck
                },
                aliases: {
                    minLength: minLength(3),
                    maxLength: maxLength(1024),
                    biospecimenAliasCheck
                },
                collectionDateDfd: { between: between(-36500, 36500) },
                rqs: { between: between(0, 36) },
                dv200: { between: between(0, 1) },
                originalMaterialType: { required },
                submittedMaterialType: { required },
                preservationMethod: { required },
                tumorNormal: { required },
                tumorState: { required }
            }
        }
    },
    mounted() {
        this.populateBiospecimenEditForm();
    },
    methods: {
        getBiospecimenInfo() {
            // const url = `${this.sBiospecimenUrl}?cohort_id=${this.cohortId}&id=${this.biospecimenId}&name_id_only=false`;
            return axios.get(this.editBiospecimenUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getSamplesInfo() {
            const url = `${getUrl('samplesUrl')}?cohort_id=${Number(this.cohortId)}&biospecimen_id=${this.biospecimenId}`
            return axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateBiospecimenEditForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getIrbProtocolsPromise(),
                getUberonAnatomySitesPromise(),
                getOriginalMaterialTypeEnumPromise(),
                getSubmittedMaterialTypeEnumPromise(),
                getPreservationMethodEnumPromise(),
                getBiospecimenTumorNormalEnumPromise(),
                getTumorStateEnumPromise(),
                this.getBiospecimenInfo(),
                this.getSamplesInfo()
            ])
                .then(axios.spread((
                    protocolsResp,
                    uberonResp,
                    origMaterialResp,
                    submittedMaterialResp,
                    preservationResp,
                    biospecimenTumorNormalResp,
                    tumorStateResp,
                    biospecimenResp,
                    sampleResp
                ) => {
                    loader.hide();

                    const irbProtocols = createIrbProtocolsDict(protocolsResp.data.irb_protocols);
                    const uberonSites = createUberonAnatomySitesDict(uberonResp.data.uberon_anatomy_sites);
                    const originalMaterialTypes = createEnumDict(origMaterialResp.data.original_material_type);
                    const submittedMaterialTypes = createEnumDict(submittedMaterialResp.data.submitted_material_type);
                    const preservationMethods = createEnumDict(preservationResp.data.preservation_method);
                    const tumorNormal = createEnumDict(biospecimenTumorNormalResp.data.biospecimen_tumor_normal);
                    const tumorStates = createEnumDict(tumorStateResp.data.tumor_state);
                    const samples = createSamplesDict(sampleResp.data.samples);
                    component.irbProtocolOptions = createDropdownOptions(irbProtocols, true, false);
                    component.tissueSiteOptions = createDropdownOptions(uberonSites);
                    component.origMatTypeOptions = createDropdownOptions(originalMaterialTypes);
                    component.submitMatTypeOptions = createDropdownOptions(submittedMaterialTypes);
                    component.preserveMethodOptions = createDropdownOptions(preservationMethods);
                    component.tumorNormalOptions = createDropdownOptions(tumorNormal);
                    component.tumorStateOptions = createDropdownOptions(tumorStates);
                    // component.mainSampleOptions = createDropdownOptions(samples);

                    // assumes that there is an element in the array
                    const currBiospecimenData = biospecimenResp.data.biospecimens[0];

                    // current biospecimen metadata -- unchangeable form fields
                    component.form.participantName = currBiospecimenData.participant_id;
                    component.form.biospecimenDbId = currBiospecimenData.db_id;
                    // current biospecimen metadata -- input
                    component.form.submittedName = currBiospecimenData.collaborator_sample_id;
                    component.form.aliases = currBiospecimenData.aliases;
                    component.form.collectionDateDfd = currBiospecimenData.collection_date_dfd;
                    component.form.tissueSiteDetail = currBiospecimenData.tissue_site_detail;
                    component.form.rqs = currBiospecimenData.rqs;
                    component.form.dv200 = currBiospecimenData.dv200;
                    component.form.gpSampleId = currBiospecimenData.sm_id;
                    component.form.gpSubmittedDate = currBiospecimenData.gp_submitted_date;
                    // current biospecimen metadata -- dropdown selections
                    const biospecimenProtocolsList = currBiospecimenData.irb_protocols.split(', ');
                    component.form.protocolsCollectedUnder = component.irbProtocolOptions.filter(x => biospecimenProtocolsList.includes(x.text)).map(x => x.value);
                    component.form.tissueSite = currBiospecimenData.tissue_site ? component.tissueSiteOptions.filter(x => x.text === currBiospecimenData.tissue_site)[0].value : null;
                    component.form.originalMaterialType = component.origMatTypeOptions.filter(x => x.text === currBiospecimenData.original_material_type)[0].value;
                    component.form.submittedMaterialType = component.submitMatTypeOptions.filter(x => x.text === currBiospecimenData.submitted_material_type)[0].value;
                    component.form.preservationMethod = component.preserveMethodOptions.filter(x => x.text === currBiospecimenData.preservation_method)[0].value;
                    component.form.tumorNormal = component.tumorNormalOptions.filter(x => x.text === currBiospecimenData.tumor_normal)[0].value;
                    component.form.tumorState = component.tumorStateOptions.filter(x => x.text === currBiospecimenData.tumor_state)[0].value;
                    component.form.notes = currBiospecimenData.notes;
                    component.form.post_allogeneic_transplant = currBiospecimenData.post_allogeneic_transplant;
                    component.form.is_low_quality = (currBiospecimenData.quality_value === 'low quality');
                    component.form.quality_description = currBiospecimenData.quality_description;

                    Object.keys(currBiospecimenData.sample_statuses).forEach((expStrategy) => {
                        component.form.sampleStatuses[expStrategy.toLowerCase()] = currBiospecimenData.sample_statuses[expStrategy].submitted_date;
                    });
                    // if (currBiospecimenData.main_sample != null) {
                    //     component.form.selectedMainSample = component.mainSampleOptions.filter(x => x.text === currBiospecimenData.main_sample)[0].value;
                    // }
                }));
        },
        updateBiospecimen() {
            const loader = this.$loading.show();
            const component = this;

            const biospecimenInfo = {
                submitted_name: this.form.submittedName,
                submitted_aliases: this.form.aliases === '' ? null : this.form.aliases,
                protocols_collected_under: this.form.protocolsCollectedUnder,
                collection_date_dfd: this.form.collectionDateDfd === '' ? null : this.form.collectionDateDfd,
                tissue_site: this.form.tissueSite,
                tissue_site_detail: this.form.tissueSiteDetail,
                original_material_type: this.form.originalMaterialType,
                submitted_material_type: this.form.submittedMaterialType,
                preservation_method: this.form.preservationMethod,
                tumor_normal: this.form.tumorNormal,
                tumor_state: this.form.tumorState,
                rqs: this.form.rqs === '' ? null : this.form.rqs,
                dv200: this.form.dv200 === '' ? null : this.form.dv200,
                sm_id: this.form.gpSampleId === '' ? null : this.form.gpSampleId,
                gp_submitted_date: this.form.gpSubmittedDate === '' ? null : this.form.gpSubmittedDate,
                notes: this.form.notes,
                post_allogeneic_transplant: this.form.post_allogeneic_transplant,
                sample_statuses: this.form.sampleStatuses,
                is_low_quality: this.form.is_low_quality,
                quality_description: this.form.quality_description,
                // main_sample: this.form.selectedMainSample
            };
            const url = `${this.sBiospecimenUrl}?id=${this.biospecimenId}&cohort_id=${this.cohortId}`;
            axios.put(url, biospecimenInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'DataEditorViewBiospecimensPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            editedCohortId: component.cohortId,
                            editedParticipantName: component.form.participantName,
                        }
                    });
                })
                .catch((err) => {
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .then(() => { loader.hide(); });
        }
    }
};
</script>

<style>

</style>
