<template>
    <div>
        <h3>View Registered Users</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <div id="user-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="user-table" class="display compact stripe dataTable" style="width:100%;" />
            </div>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import store from '../store';

export default {
    data() {
        return {
            currUserRoles: store.state.user.roles,
            // alert vars
            showAlert: false,
            alertMessage: { message: undefined },
            alertVariant: 'warning',
            // end alert vars
            usersUrl: getUrl('usersUrl'),
            tableColumns: [
                { title: 'Id', data: 'id' },
                { title: 'Email', data: 'email' },
                { title: 'Username', data: 'username' },
                { title: 'Roles', data: 'roles[, ]' },
                {
                    title: 'Enabled users',
                    sortable: false,
                    render(data, type, row) {
                        const userStatus = row.user_enabled;
                        return `<label class="user-enable-label">
                                    <input class="enable-user" type="checkbox" ${userStatus ? 'checked' : ''}> User Enabled
                                </label>`;
                    }
                }
            ],
            adminColumns: [
                {
                    title: 'Update roles',
                    sortable: false,
                    render(data, type, row) {
                        const leadStatus = row.roles.includes('ProjectLead');
                        const adminStatus = row.roles.includes('Administrator');
                        const userStatus = row.user_enabled;
                        if (!userStatus) {
                            return `<label class="edit-role-label">
                                    <input class="edit-admin-role" disabled="true" type="checkbox" ${adminStatus ? 'checked' : ''}> Administrator
                                </label><br>`;
                        }
                        return `<label class="edit-role-label">
                                    <input class="edit-admin-role" type="checkbox" ${adminStatus ? 'checked' : ''}> Administrator
                                </label><br>`;
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    mounted() {
        this.getUserTable();
    },
    methods: {
        getTableColumns() {
            let { tableColumns } = this;
            if (this.currUserRoles.isAdmin) {
                tableColumns = tableColumns.concat(this.adminColumns);
            }
            return tableColumns;
        },
        updateUserRole(userId, role) {
            const component = this;
            const loader = component.$loading.show();
            const url = `${component.usersUrl}?id=${userId}&role=${role}`;

            const enableUserInfo = {
                enable_user: false
            };

            axios.put(url, enableUserInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.alertMessage = resp.data;
                    component.alertVariant = 'warning';
                    component.getUserTable();
                })
                .catch((err) => {
                    loader.hide();
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to update user roles. Please contact portal administrators.';
                    } else {
                        component.alertMessage = err.response.data;
                    }
                    component.alertVariant = 'danger';
                })
                .finally(() => {
                    component.showAlert = true;
                    window.scrollTo(0, 0);
                    loader.hide();
                });
        },
        enableUser(userId) {
            const component = this;
            const loader = component.$loading.show();
            const url = `${component.usersUrl}?id=${userId}`;
            const enableUserInfo = {
                enable_user: true
            };

            axios.put(url, enableUserInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.alertMessage = resp.data;
                    component.alertVariant = 'warning';
                    component.getUserTable();
                })
                .catch((err) => {
                    loader.hide();
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to change the status of the user. Please contact portal administrators.';
                    } else {
                        component.alertMessage = err.response.data;
                    }
                    component.alertVariant = 'danger';
                })
                .finally(() => {
                    component.showAlert = true;
                    window.scrollTo(0, 0);
                    loader.hide();
                });
        },
        addTableActions() {
            const component = this;
            const datatable = $('#user-table').DataTable();

            $('#user-table tbody .edit-lead-role').click(function editLeadRoleClicked() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.updateUserRole(rowData.id, 'ProjectLead');
            });

            $('#user-table tbody .edit-admin-role').click(function editAdminRoleClicked() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.updateUserRole(rowData.id, 'Administrator');
            });

            $('#user-table tbody .enable-user').click(function enableUserClicked() {
                const rowData = datatable.row($(this).parents('tr')).data();
                component.enableUser(rowData.id, rowData.roles);
            });
        },
        getUserTable() {
            const loader = this.$loading.show();
            const component = this;
            axios.get(this.usersUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const { users } = resp.data;
                    $('#user-table').DataTable({
                        destroy: true,
                        data: users,
                        columns: component.getTableColumns(),
                        order: [],
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '80vh',
                        scrollCollapse: true,
                        fnInitComplete() {
                            component.addTableActions();
                        }
                    });
                })
                .finally(() => {
                    loader.hide();
                });
        },
        hideTable() {
            $('#user-table-wrapper').hide();
        },
        showTable() {
            $('#user-table-wrapper').show();
        }
    }
};
</script>

<style>
.edit-role-label {
    margin-top: .5rem;
    margin-bottom: .5rem;
    cursor: pointer;
}
</style>
