<template>
    <div>
        <h3>View Method Configurations</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-container fluid>
            <b-row id="configuration-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="configuration-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { getUrl } from '../utils/directory';
import getUserToken from '../utils/auth';
import {
    LEAD_DATA_ANALYST,
    UBER_LEAD_DATA_ANALYST
} from '../utils/constants';

export default {
    props: {
        // these props are passed in after a method configuration is updated
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        role: {
            type: String,
            required: true,
            validator(val) {
                return [LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // URLs used
            ldaMethodConfigurationsUrl: getUrl('leadDataAnalystMethodConfigurationsUrl'),
            uldaMethodConfigurationsUrl: getUrl('uberLeadDataAnalystMethodConfigurationsUrl'),

            // table vars
            tableColumns: [
                { title: 'DBKey', data: 'id' },
                { title: 'Method Configuration Name', data: 'name' },
                {
                    title: 'Method Configuration URL',
                    data(row) {
                        const url = row.method_configuration_url;
                        return `<a href="${url}">${url}</a>`;
                    }
                },
                { title: 'Analyte Type', data: 'analyte_type' },
                { title: 'Experimental Strategy', data: 'experimental_strategy' },
                { title: 'Preservation Method', data: 'preservation_method' },
                { title: 'Platform', data: 'platform' },
                { title: 'Bait Set', data: 'bait_set' },
                {
                    title: 'Last modified Date (UTC)',
                    data(row) {
                        const time = row.last_modified_date;
                        return moment(time).format('M/D/YY, H:mm A');
                    }
                },
                { title: 'Last Modified By', data: 'last_modified_user' }
            ],
            ldaColumns: [
                {
                    title: 'Edit',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span edit-method-config">Edit</span>';
                    }
                },
                {
                    title: 'Delete',
                    sortable: false,
                    render() {
                        return '<span class="text-btn-span delete-method-config">Delete</span>';
                    }
                }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]

        };
    },
    mounted() {
        this.getMethodConfigurationTable();
    },
    methods: {
        populateEditPageRoute() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return 'LeadDataAnalystEditMethodConfigurationPage';
            case UBER_LEAD_DATA_ANALYST:
                return 'UberLeadDataAnalystEditMethodConfigurationPage';
            default:
                console.error('Invalid role');
                return undefined;
            }
        },
        populateMethodConfigUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.ldaMethodConfigurationsUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uldaMethodConfigurationsUrl;
            default:
                console.error('Invalid role for method configuration table.');
                return undefined;
            }
        },
        getMethodConfigurationTable() {
            const component = this;
            const loader = this.$loading.show();

            axios.get(component.populateMethodConfigUrl(), { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const methodConfigs = resp.data.method_configurations;
                    const table = $('#configuration-table').DataTable({
                        destroy: true,
                        data: methodConfigs,
                        columns: component.getTableColumns(),
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true
                    });
                    component.addTableActions(table);
                })
                .finally(() => loader.hide());
        },
        deleteMethodConfig(configId) {
            const component = this;
            const loader = component.$loading.show();
            const deleteUrl = `${component.populateMethodConfigUrl()}?id=${configId}`;
            axios.delete(deleteUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'warning';
                    component.alertMessage = resp.data.message;
                    component.getMethodConfigurationTable();
                    window.scrollTo(0, 0);
                })
                .catch((err) => {
                    loader.hide();
                    component.showAlert = true;
                    component.alertVariant = 'danger';
                    if (err.response === undefined) {
                        component.alertMessage = 'Failed to delete method configuration. Please contact portal administrators.';
                    }
                    component.alertMessage = err.response.data;
                    window.scrollTo(0, 0);
                });
        },
        addTableActions(datatable) {
            const component = this;
            if (this.role === LEAD_DATA_ANALYST || this.role === UBER_LEAD_DATA_ANALYST) {
                $('#configuration-table tbody .edit-method-config').click(function editMethodConfig() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    component.$router.push({
                        name: component.populateEditPageRoute(),
                        params: {
                            role: component.role,
                            methodConfigId: rowData.id
                        }
                    });
                });
                $('#configuration-table tbody .delete-method-config').click(function confirmDelete() {
                    const rowData = datatable.row($(this).parents('tr')).data();
                    const confirmMessage = `Are you sure you want to delete method configuration \n${rowData.name}?`;
                    const msgBoxOptions = {
                        hideHeader: true,
                        size: 'xs',
                        buttonSize: 'md',
                        okVariant: 'danger',
                        okTitle: 'Delete method configuration',
                        centered: true
                    };

                    component.$bvModal.msgBoxConfirm(confirmMessage, msgBoxOptions)
                        .then((deleteConfirmed) => {
                            if (deleteConfirmed) {
                                component.deleteMethodConfig(rowData.id);
                            }
                        });
                });
            }
        },
        getTableColumns() {
            let { tableColumns } = this;
            if (this.role === LEAD_DATA_ANALYST || this.role === UBER_LEAD_DATA_ANALYST) {
                tableColumns = tableColumns.concat(this.ldaColumns);
            }
            return tableColumns;
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        hideTable() {
            $('#configuration-table-wrapper').hide();
        },
        showTable() {
            $('#configuration-table-wrapper').show();
        }
    }
};
</script>

<style>

</style>
