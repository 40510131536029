<template>
    <div>
        <h3>Validate and Upload a BSP file to a Cohort</h3>
        <hr>

        <b-alert :show="ajaxComplete" :variant="alertVariant" dismissible>
            {{ ajaxResponse.data.message }}
        </b-alert>

        <b-row>
            <b-col md="4">
                <b-form>
                    <b-form-group label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" :options="cohortOptions" v-model="$v.form.selectedCohort.$model" @change="requireFormValidate" />
                    </b-form-group>
                    <b-form-group>
                        <b-form-file required size="sm" placeholder="Please choose a file" browse-text="Browse files" v-model="$v.form.selectedFile.$model" @change="previewFiles" />
                    </b-form-group>

                    <b-button type="validate" size="sm" style="font-size:0.85rem; margin-right:15px;" :disabled="$v.form.$invalid" @click.stop.prevent="validateFile">
                        Validate File
                    </b-button>

                    <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="!validationPassed" @click.stop.prevent="uploadFile">
                        Upload
                    </b-button>
                </b-form>
            </b-col>
        </b-row>

        <b-container fluid v-show="showErrorTable">
            <br>
            <br>
            <b-row>
                <b-col>
                    <table id="error-table" class="display stripe" style="width:100%" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import XLSX from 'xlsx';
import { required } from '@vuelidate/validators';
import getUserToken from '../../utils/auth';
import { getWriteProjectsPromise, getUrl } from '../../utils/directory';
import { createCohortsDict, createDropdownOptions } from '../../utils/form-data-helpers';

export default {
    data() {
        return {
            // error modal
            alertVariant: 'warning',
            ajaxComplete: false,
            ajaxResponse: {
                data: { message: '' }
            },

            // upload submit button
            validationPassed: false,

            fileUploadUrl: getUrl('uploadBSPUrl'),
            fileValidateUrl: getUrl('validateBSPUrl'),

            sheetsJSON: null,

            cohortOptions: [],
            form: {
                selectedCohort: null,
                selectedFile: null
            },

            // table vars
            showErrorTable: false,
            tableColumns: [
                { title: 'Row', data: 'row' },
                { title: 'Type', data: 'type' },
                { title: 'Error', data: 'error' },
                { title: 'Value', data: 'value' }
            ],
            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    validations: {
        form: {
            // dropdowns
            selectedCohort: { required },
            selectedFile: { required }
        }
    },
    mounted() {
        this.populateForm();
    },
    methods: {
        populateForm() {
            const loader = this.$loading.show();
            const component = this;
            getWriteProjectsPromise()
                .then((resp) => {
                    const { cohorts } = resp.data;
                    const cohortsDict = createCohortsDict(cohorts);
                    component.cohortOptions = createDropdownOptions(cohortsDict);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        requireFormValidate() {
            if (this.validationPassed) {
                this.validationPassed = false;
            }
        },
        previewFiles(e) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                /* Parse data */
                const bstr = event.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                /* Get first worksheet */
                const sheets = wb.SheetNames;
                const data = {};
                /* Convert array of arrays */
                sheets.forEach((sheet) => {
                    const ws = wb.Sheets[sheet];
                    const sheetData = XLSX.utils.sheet_to_json(ws, { raw: true });
                    /* If first sheet there fix certain keys that are named incorrectly */
                    if (sheet === 'Sample Info') {
                        console.log('true');
                        /* Get headers to fix the wrongly named keys */
                        const header = sheetData.shift();
                        sheetData.forEach((element) => {
                            const updatedData = element;
                            if ('Alias' in element) {
                                updatedData[header.Alias] = element.Alias;
                                delete updatedData.Alias;
                            }
                            if ('Alias_1' in element) {
                                updatedData[header.Alias_1] = element.Alias_1;
                                delete updatedData.Alias_1;
                            }
                            if ('Alias_2' in element) {
                                updatedData[header.Alias_2] = element.Alias_2;
                                delete updatedData.Alias_2;
                            }
                            sheetData[element] = updatedData;
                        });
                    }
                    data[sheet] = sheetData;
                });
                this.sheetsJSON = data;
            };
            reader.readAsBinaryString(file);
            if (this.validationPassed) {
                this.validationPassed = false;
            }
        },
        validateFile() {
            const fileName = this.form.selectedFile.name;
            const regex = new RegExp('[^.]+$');
            const fileExtension = fileName.match(regex)[0];
            const validExtensions = ['xls', 'xlsx'];
            console.log(this.sheetsJSON);
            // Check that file extension is .xls or .xlxs
            if (!validExtensions.includes(fileExtension)) {
                const wrongExtensionResp = {
                    data: {
                        message: 'Uploaded BSP file is not an .xls or .xlsx file'
                    }
                };
                this.alertVariant = 'danger';
                this.ajaxComplete = true;
                this.validationPassed = false;
                this.ajaxResponse = wrongExtensionResp;
                this.showErrorTable = false;
                this.form.selectedFile = null;
                return;
            }

            // Check that file has sampleID and collaborator participant ID
            const sampleInfoSheet = this.sheetsJSON['Sample Info'];
            let missing = false;
            const rowNumber = [];
            // Find which rows in particular are missing values
            sampleInfoSheet.forEach((element, i) => {
                if (!('Collaborator Participant ID' in element && 'Collaborator Sample ID' in element)) {
                    missing = true;
                    // This will be the row number, adding 3 because there are header rows that need to  be accounted for
                    rowNumber.push(i + 3);
                }
            });
            if (missing) {
                const missingVitalValue = {
                    data: {
                        message: `Uploaded BSP file is missing Collaborator Sample ID or Collaborator Participant ID in rows: ${rowNumber}`
                    }
                };
                this.alertVariant = 'danger';
                this.ajaxComplete = true;
                this.validationPassed = false;
                this.ajaxResponse = missingVitalValue;
                this.showErrorTable = false;
                this.form.selectedFile = null;
                return;
            }


            this.ajaxComplete = false;
            this.showErrorTable = false; // resetting and hiding the table
            const loader = this.$loading.show();
            const component = this;
            const url = `${this.fileValidateUrl}?cohort_id=${this.form.selectedCohort}`;
            axios.post(url, component.sheetsJSON, {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((resp) => {
                    if (resp.status === 200) {
                        component.ajaxComplete = true;
                        component.alertVariant = 'warning';
                        component.ajaxResponse = resp;
                        component.validationPassed = true;
                    }
                })
                .catch((err) => {
                    const resp = err.response;
                    if (resp.status === 422) {
                        // validation errors were found
                        if (resp.data.message === undefined) {
                            resp.data.message = resp.data.error;
                        }
                        component.form.selectedFile = null;
                        component.alertVariant = 'danger';
                        component.ajaxComplete = true;
                        component.validationPassed = false;
                        component.ajaxResponse = resp;

                        if (typeof resp.data.error === 'object') {
                            $('#error-table').DataTable({
                                destroy: true,
                                data: resp.data.error,
                                columns: component.tableColumns,
                                order: [], // no sorting, initially
                                fixedHeader: {
                                    header: true
                                },
                                scrollY: '60vh',
                                lengthMenu: component.tablePaginationOptions,
                                dom: 'Blfrtip',
                                buttons: [
                                    {
                                        extend: 'copy'
                                    },
                                    {
                                        text: 'TSV',
                                        extend: 'csv',
                                        fieldSeparator: '\t',
                                        fieldBoundary: '',
                                        title: 'candr_bulk_upload_validator',
                                        extension: '.txt'
                                    }
                                ]
                            });
                            component.showErrorTable = true;
                        }
                    }
                })
                .finally(() => { loader.hide(); });
        },
        uploadFile() {
            const loader = this.$loading.show();
            const component = this;
            component.ajaxComplete = false;
            const url = `${this.fileUploadUrl}?cohort_id=${this.form.selectedCohort}`;
            axios.post(url, component.sheetsJSON, {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((resp) => {
                    if (resp.status === 200) {
                        component.form.selectedFile = null;
                        component.ajaxResponse = resp;
                        component.alertVariant = 'warning';
                        component.validationPassed = false;
                        component.ajaxComplete = true;
                    }
                })
                .catch((err) => {
                    const resp = err.response;
                    if (resp.data.message === undefined) {
                        resp.data.message = resp.data.error;
                    }
                    component.ajaxResponse = resp;
                    component.alertVariant = 'danger';
                    component.validationPassed = false;
                    component.ajaxComplete = true;
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
