<template>
    <div>
        <h3>Report of Colliding Samples With The Same BAM Path</h3>
        <hr>

        <b-alert :show="showAlert" :variant="alertVariant" dismissible>
            {{ alertMessage.message === undefined ? alertMessage : alertMessage.message }}
        </b-alert>

        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label="Cohort name" label-class="font-weight-bold">
                        <b-form-select required size="sm" class="col-lg-4 col-md-6 col-xs-12" v-model="selectedCohort" :options="cohortOptions" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <br><br>

        <b-container fluid>
            <b-row id="samples-table-wrapper">
                <!-- showing/hiding table is controlled by jQuery and DataTables -->
                <table id="samples-table" class="display compact stripe" />
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { getUrl } from '../utils/directory';
import {
    createCohortsDict,
    createDropdownOptions
} from '../utils/form-data-helpers';
import getUserToken from '../utils/auth';
import {
    LEAD_DATA_ANALYST,
    SAMPLE_COORDINATOR,
    UBER_LEAD_DATA_ANALYST
} from '../utils/constants';

export default {
    props: {
        needAlert: { default: false, type: Boolean, required: false },
        ajaxResponse: { default() { return { data: { message: '' } }; }, type: Object, required: false },
        role: {
            type: String,
            required: true,
            validator(val) {
                return [LEAD_DATA_ANALYST, SAMPLE_COORDINATOR, UBER_LEAD_DATA_ANALYST].indexOf(val) !== -1;
            }
        }
    },
    data() {
        return {
            // alert box vars
            showAlert: this.needAlert,
            alertMessage: this.ajaxResponse.data,
            alertVariant: 'warning',

            // checking for an edit
            biospecimenEdited: !!this.editedCohortId,

            // URLs used
            ldaReportSamplesUrl: getUrl('leadDataAnalystReportCollidingSamplesUrl'),
            ldaCohortsUrl: getUrl('leadDataAnalystCohortsUrl'),
            scReportSamplesUrl: getUrl('sampleCoordinatorReportCollidingSamplesUrl'),
            scCohortsUrl: getUrl('sampleCoordinatorCohortsUrl'),
            uldaReportSamplesUrl: getUrl('uberLeadDataAnalystReportCollidingSamplesUrl'),
            uldaCohortsUrl: getUrl('uberLeadDataAnalystCohortsUrl'),

            // cohort dropdown selections
            selectedCohort: null,
            cohortOptions: [],

            // table vars
            exportColumns: [0, 1, 2, 3, 4, 5], // will export these column numbers
            tableColumns: [
                { title: 'DBKey', data: 'db_id' },
                { title: 'Sample Name', data: 'sample_name' },
                { title: 'Associated Biospecimen', data: 'associated_biospecimen' },
                { title: 'Associated Participant', data: 'associated_participant' },
                { title: 'BAM path', data: 'bam_path' },
                { title: 'Colliding Samples', data: 'other_samples' }
            ],

            tablePaginationOptions: [
                [-1, 10, 25, 50, 100], // pagination values; -1 displays all
                ['All', 10, 25, 50, 100] // pagination display text
            ]
        };
    },
    watch: {
        selectedCohort() {
            // update participants dropdown when new cohort is selected
            if (this.selectedCohort === null) {
                this.hideTable();
                return;
            }
            this.getSamplesTable();
        }
    },
    beforeMount() {
        this.getCohorts();
    },
    methods: {
        populateCohortsUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.ldaCohortsUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uldaCohortsUrl;
            case SAMPLE_COORDINATOR:
                return this.scCohortsUrl;
            default:
                console.error('Invalid role for sample table.');
                return undefined;
            }
        },
        populateReportsUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.ldaReportSamplesUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uldaReportSamplesUrl;
            case SAMPLE_COORDINATOR:
                return this.scReportSamplesUrl;
            default:
                console.error('Invalid role for sample table.');
                return undefined;
            }
        },
        getCohorts() {
            const loader = this.$loading.show();
            const component = this;
            const cohortsUrl = component.populateCohortsUrl();
            axios.get(cohortsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    const cohorts = createCohortsDict(resp.data.cohorts);
                    component.cohortOptions = createDropdownOptions(cohorts);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        updateExportColumnNames(headerRow) {
            const component = this;
            let header = headerRow;
            this.exportColumns.forEach((i) => {
                const col = component.tableColumns[i];
                header = header.replace(col.title, col.data);
            });
            return header;
        },
        hideTable() {
            $('#samples-table-wrapper').hide();
        },
        showTable() {
            $('#samples-table-wrapper').show();
        },
        getSamplesTable() {
            const component = this;
            const loader = this.$loading.show();
            const getSampleReportsUrl = `${this.populateReportsUrl()}?cohort_id=${this.selectedCohort}`;

            axios.get(getSampleReportsUrl, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.showTable();
                    const collidingSamples = resp.data.colliding_samples;
                    $('#samples-table').DataTable({
                        destroy: true,
                        data: collidingSamples,
                        columns: component.tableColumns,
                        order: [], // no sorting, initially
                        lengthMenu: component.tablePaginationOptions,
                        dom: 'Blfrtip',
                        fixedHeader: {
                            header: true
                        },
                        scrollY: '60vh',
                        scrollCollapse: true,
                        buttons: [
                            {
                                extend: 'copy',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-colliding-samples-report`,
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    const exportRows = exportData.split('\n');
                                    exportRows.splice(1, 1); // removing extra space that isn't needed
                                    const headerRow = component.updateExportColumnNames(exportRows[1]);
                                    exportRows[1] = headerRow;
                                    return exportRows.join('\n');
                                }
                            },
                            {
                                text: 'TSV',
                                extend: 'csv',
                                fieldSeparator: '\t',
                                fieldBoundary: '',
                                title: `${component.cohortOptions.filter(x => x.value === component.selectedCohort)[0].text}-colliding-samples-report`,
                                extension: '.txt',
                                exportOptions: { columns: component.exportColumns },
                                customize(exportData) {
                                    let exportRows = exportData.split('\n');
                                    const headerRow = component.updateExportColumnNames(exportRows[0]);
                                    exportRows[0] = headerRow;
                                    exportRows = ['#colliding-samples-report'].concat(exportRows);
                                    return exportRows.join('\n');
                                }
                            }
                        ]
                    });
                })
                .finally(() => loader.hide());
        }
    }
};
</script>

<style>

</style>
