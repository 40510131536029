<template>
    <div>
        <h3>Edit Method Configuration</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="6">
            <b-form @submit.stop.prevent="addMethodConfiguration">
                <b-form-group label="Method Configuration DB ID" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="localMethodConfigId" disabled />
                </b-form-group>
                <b-form-group label="Method Configuration name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.methodConfigName.$model" :state="$v.form.methodConfigName.$dirty ? !$v.form.methodConfigName.$error : null" />
                </b-form-group>
                <b-form-group label="Method Configuration URL" label-class="font-weight-bold" :description="form.description.urlNote">
                    <b-form-input required size="sm" v-model="$v.form.methodConfigUrl.$model" :state="$v.form.methodConfigUrl.$dirty ? !$v.form.methodConfigUrl.$error : null" />
                </b-form-group>
                <b-form-group label="Analyte Type" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="form.analyteTypeOptions" v-model="$v.form.selectedAnalyteType.$model" :state="$v.form.selectedAnalyteType.$dirty ? !$v.form.selectedAnalyteType.$error : null" />
                </b-form-group>
                <b-form-group label="Experimental Strategy" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="form.experimentalStrategyOptions" v-model="$v.form.selectedExperimentalStrategy.$model" :state="$v.form.selectedExperimentalStrategy.$dirty ? !$v.form.selectedExperimentalStrategy.$error : null" />
                </b-form-group>
                <b-form-group label="Preservation Method" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="form.preservationMethodOptions" v-model="$v.form.selectedPreservationMethod.$model" :state="$v.form.selectedPreservationMethod.$dirty ? !$v.form.selectedPreservationMethod.$error : null" />
                </b-form-group>
                <b-form-group label="Platform" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="form.platformOptions" v-model="$v.form.selectedPlatform.$model" :state="$v.form.selectedPlatform.$dirty ? !$v.form.selectedPlatform.$error : null" />
                </b-form-group>
                <b-form-group label="Bait Set [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="form.baitSetOptions" v-model="form.selectedBaitSet" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Edit Method Configuration
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import {
    required,
    minLength,
    maxLength,
    url
} from '@vuelidate/validators';
import {
    getUrl,
    getExperimentalStrategyEnumPromise,
    getPreservationMethodEnumPromise,
    getAnalyteTypeEnumPromise,
    getPlatformPromise
} from '../../utils/directory';
import {
    createDropdownOptions,
    createBaitSetDict,
    createEnumDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';
import { LEAD_DATA_ANALYST, UBER_LEAD_DATA_ANALYST } from '../../utils/constants';

export default {
    props: {
        methodConfigId: { type: Number, required: true },
        role: { type: String, required: true }
    },
    data() {
        return {
            // local prop vars
            localMethodConfigId: this.methodConfigId,

            // URLs used
            ldaMethodConfigUrl: getUrl('leadDataAnalystMethodConfigurationsUrl'),
            ldaBaitSetUrl: getUrl('leadDataAnalystBaitSetsUrl'),
            uldaMethodConfigUrl: getUrl('uberLeadDataAnalystMethodConfigurationsUrl'),
            uldaBaitSetUrl: getUrl('uberLeadDataAnalystBaitSetsUrl'),

            // form info
            form: {
                // text entry
                methodConfigName: null,
                methodConfigUrl: null,

                // dropdowns
                selectedAnalyteType: null,
                selectedExperimentalStrategy: null,
                selectedPreservationMethod: null,
                selectedPlatform: null,
                selectedBaitSet: null,

                // dropdown options
                analyteTypeOptions: [],
                experimentalStrategyOptions: [],
                preservationMethodOptions: [],
                platformOptions: [],
                baitSetOptions: [],

                // form description
                description: {
                    urlNote: 'Example URL: https://app.terra.bio/#workspaces/broad-getzlab-project-portal-t/Example_Cohort-pran-1/workflows/broad-getzlab-project-portal-t/Example_Method_Configuration'
                }
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations: {
        form: {
            // text entry
            methodConfigName: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(256)
            },
            methodConfigUrl: {
                required,
                url
            },
            // dropdown
            selectedAnalyteType: { required },
            selectedExperimentalStrategy: { required },
            selectedPreservationMethod: { required },
            selectedPlatform: { required }
        }
    },
    mounted() {
        this.populateEditConfigMethodForm();
    },
    methods: {
        populateViewConfigTableRoutes() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return 'LeadDataAnalystViewMethodConfigurationPage';
            case UBER_LEAD_DATA_ANALYST:
                return 'UberLeadDataAnalystViewMethodConfigurationPage';
            default:
                console.error('Invalid role');
                return undefined;
            }
        },
        populateBaitSetsUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.ldaBaitSetUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uldaBaitSetUrl;
            default:
                console.error('Invalid role for adding method config.');
                return undefined;
            }
        },
        populateMethodConfigUrl() {
            switch (this.role) {
            case LEAD_DATA_ANALYST:
                return this.ldaMethodConfigUrl;
            case UBER_LEAD_DATA_ANALYST:
                return this.uldaMethodConfigUrl;
            default:
                console.error('Invalid role for adding method config.');
                return undefined;
            }
        },
        populateEditConfigMethodForm() {
            const loader = this.$loading.show();
            const component = this;
            const baitSetPromise = axios.get(component.populateBaitSetsUrl(), { headers: { Authorization: `Bearer ${getUserToken()}` } });
            const methodConfigPromise = axios.get(`${component.populateMethodConfigUrl()}?id=${component.methodConfigId}`, { headers: { Authorization: `Bearer ${getUserToken()}` } });

            axios.all([
                getAnalyteTypeEnumPromise(),
                getExperimentalStrategyEnumPromise(),
                getPreservationMethodEnumPromise(),
                getPlatformPromise(),
                baitSetPromise,
                methodConfigPromise
            ])
                .then(axios.spread((
                    analyteTypeResp,
                    experimentalStrategyResp,
                    preservationMethodResp,
                    platformResp,
                    baitSetResp,
                    methodConfigResp
                ) => {
                    const analyteTypes = createEnumDict(analyteTypeResp.data.analyte_type);
                    const experimentalStrategies = createEnumDict(experimentalStrategyResp.data.experimental_strategy);
                    const preservationMethods = createEnumDict(preservationMethodResp.data.preservation_method);
                    const platforms = createEnumDict(platformResp.data.platform);
                    const baitSets = createBaitSetDict(baitSetResp.data.bait_sets);

                    component.form.analyteTypeOptions = createDropdownOptions(analyteTypes);
                    component.form.experimentalStrategyOptions = createDropdownOptions(experimentalStrategies);
                    component.form.preservationMethodOptions = createDropdownOptions(preservationMethods);
                    component.form.platformOptions = createDropdownOptions(platforms);
                    component.form.baitSetOptions = createDropdownOptions(baitSets);

                    const currMethodConfigData = methodConfigResp.data.method_configurations[0];
                    component.form.methodConfigName = currMethodConfigData.name;
                    component.form.methodConfigUrl = currMethodConfigData.method_configuration_url;
                    component.form.selectedAnalyteType = component.form.analyteTypeOptions.filter(x => x.text === currMethodConfigData.analyte_type)[0].value;
                    component.form.selectedExperimentalStrategy = component.form.experimentalStrategyOptions.filter(x => x.text === currMethodConfigData.experimental_strategy)[0].value;
                    component.form.selectedPreservationMethod = component.form.preservationMethodOptions.filter(x => x.text === currMethodConfigData.preservation_method)[0].value;
                    component.form.selectedPlatform = component.form.platformOptions.filter(x => x.text === currMethodConfigData.platform)[0].value;
                    component.form.selectedBaitSet = currMethodConfigData.bait_set ? component.form.baitSetOptions.filter(x => x.text === currMethodConfigData.bait_set)[0].value : null;
                }))
                .finally(() => { loader.hide(); });
        },
        addMethodConfiguration() {
            const loader = this.$loading.show();
            const component = this;

            const currMethodConfigInfo = {
                name: this.form.methodConfigName,
                analyte_type: this.form.selectedAnalyteType,
                experimental_strategy: this.form.selectedExperimentalStrategy,
                preservation_method: this.form.selectedPreservationMethod,
                platform: this.form.selectedPlatform,
                bait_set_id: this.form.selectedBaitSet,
                method_configuration_url: this.form.methodConfigUrl
            };
            axios.put(`${component.populateMethodConfigUrl()}?id=${this.methodConfigId}`, currMethodConfigInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    loader.hide();
                    component.$router.push({
                        name: this.populateViewConfigTableRoutes(),
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            role: component.role
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                });
        }
    }
};
</script>

<style>

</style>
