<template>
    <div>
        <h3>Edit Treatment Record</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateTreatmentRecord">
                <b-form-group label="Cohort name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="form.cohortName" disabled />
                </b-form-group>
                <b-form-group label="Participant ID" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="form.participantName" disabled />
                </b-form-group>
                <b-form-group label="Treatment DB ID" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="form.treatmentDbId" disabled />
                </b-form-group>
                <!-- <b-form-group label="Treatment categories" label-class="font-weight-bold">
                    <b-form-select multiple required size="sm" :options="categoryOptions" v-model="v$.form.selectedCategories.$model" />
                </b-form-group> -->
                <b-form-group label="Drug(s) [optional]" label-class="font-weight-bold">
                    <b-form-select multiple size="sm" :options="drugOptions" v-model="form.selectedDrugs" />
                </b-form-group>
                <b-form-group label="Drug Combination(s) [optional]" label-class="font-weight-bold">
                    <b-form-select multiple size="sm" :options="drugCombinationOptions" v-model="form.selectedDrugCombinations" />
                </b-form-group>
                <b-form-group label="Line Of Treatment [optional]" label-class="font-weight-bold">
                    <b-form-select size="sm" :options="lineOfTreatmentOptions" v-model="form.selectedLineOfTreatment" />
                </b-form-group>
                <b-form-group label="Treatment regimen [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.regimen" />
                </b-form-group>
                <b-form-group label="Treatment start date (days from diagnosis)" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.startDateDfd.$model" :state="v$.form.startDateDfd.$error ? false : null" />
                </b-form-group>
                <b-form-group label="Treatment stop date (days from diagnosis)" label-class="font-weight-bold">
                    <b-form-input size="sm" v-model="v$.form.stopDateDfd.$model" :state="v$.form.stopDateDfd.$error ? false : null" />
                </b-form-group>
                <b-form-group label="Treatment Stop Reason" label-class="font-weight-bold">
                    <b-form-select required size="sm" :options="stopReasonOptions" v-model="v$.form.selectedStopReason.$model" />
                </b-form-group>
                <b-form-group label="Pre-status [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.pre_status" />
                </b-form-group>
                <b-form-group label="Post-status [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.post_status" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Treatment Record
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import {
    required,
    between
} from '@vuelidate/validators';
import {
    getUrl,
    getTreatmentStopReasonPromise,
    // getTreatmentCategoriesPromise,
    getDrugsPromise,
    getDrugCombinationsPromise
} from '../../utils/directory';
import {
    createDropdownOptions,
    // createTreatmentCategoriesDict,
    createStopReasonDict,
    createDrugsDict,
    createDrugCombinationsDict,
    createLineOfTreatmentDisplayDict,
    createLineOfTreatmentNameDict
} from '../../utils/form-data-helpers';
import getUserToken from '../../utils/auth';

export default {
    props: {
        cohortName: { type: String, required: true },
        cohortId: { type: Number, required: true },
        treatmentId: { type: Number, required: true },
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            treatmentUrl: getUrl('treatmentsUrl'),
            linesUrl: getUrl('linesOfTreatmentUrl'),

            // options for form dropdowns
            categoryOptions: [],
            stopReasonOptions: [],
            drugOptions: [],
            drugCombinationOptions: [],
            lineOfTreatmentOptions: [],

            // form data
            form: {
                // disabled
                cohortName: this.cohortName,
                cohortId: this.cohortId,
                participantName: null,
                treatmentDbId: null,
                // selected option(s) for dropdowns
                // selectedCategories: [],
                selectedStopReason: null,
                selectedDrugs: [],
                selectedDrugCombinations: [],
                selectedLineOfTreatment: null,
                // inputs
                startDateDfd: null,
                stopDateDfd: null,
                regimen: '',
                pre_status: '',
                post_status: '',
                notes: ''
            },

            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    validations() {
        return { 
            form: {
                // dropdowns
                // selectedCategories: { required },
                selectedStopReason: { required },
                startDateDfd: {
                    between: between(-36500, 36500)
                },
                stopDateDfd: {
                    between: between(-36500, 36500)
                }
            }
        }
    },
    mounted() {
        this.populateEditTreatmentForm();
    },
    methods: {
        getLinesOfTreatmentPromise(cohortId) {
            return axios.get(`${this.linesUrl}?cohort_id=${cohortId}`, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        getTreatmentInfo() {
            const url = `${this.treatmentUrl}?cohort_id=${this.cohortId}&id=${this.treatmentId}`;
            return axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } });
        },
        populateEditTreatmentForm() {
            const loader = this.$loading.show();
            const component = this;

            axios.all([
                getTreatmentStopReasonPromise(),
                // getTreatmentCategoriesPromise(),
                getDrugsPromise(),
                getDrugCombinationsPromise(),
                this.getLinesOfTreatmentPromise(this.form.cohortId),
                this.getTreatmentInfo()
            ])
                .then(axios.spread((stopReasonResp, drugsResp, drugCombinationsResp, lineResp, treatmentResp) => {
                    const stopReasons = createStopReasonDict(stopReasonResp.data.stop_reasons);
                    // const treatmentCategories = createTreatmentCategoriesDict(categoryResp.data.treatment_categories);
                    const drugs = createDrugsDict(drugsResp.data.drugs);
                    const drugCombinations = createDrugCombinationsDict(drugCombinationsResp.data.drug_combinations);
                    const linesDisplayDict = createLineOfTreatmentDisplayDict(lineResp.data.lines_of_treatment);
                    const linesNameDict = createLineOfTreatmentNameDict(lineResp.data.lines_of_treatment);


                    component.stopReasonOptions = createDropdownOptions(stopReasons);
                    // component.categoryOptions = createDropdownOptions(treatmentCategories, true, false);
                    component.drugOptions = createDropdownOptions(drugs, true, false);
                    component.drugCombinationOptions = createDropdownOptions(drugCombinations);
                    component.lineOfTreatmentOptions = createDropdownOptions(linesDisplayDict);
                    const lineofTreatmentNameOptions = createDropdownOptions(linesNameDict);

                    const currTreatmentData = treatmentResp.data.treatments[0];

                    // current treatment metadata -- unchangeable form fields
                    component.form.participantName = currTreatmentData.participant_id;
                    component.form.treatmentDbId = currTreatmentData.db_id;

                    // input fields
                    component.form.regimen = currTreatmentData.treatment_regimen;
                    component.form.startDateDfd = currTreatmentData.start_date_dfd;
                    component.form.stopDateDfd = currTreatmentData.stop_date_dfd;
                    component.form.pre_status = currTreatmentData.pre_status;
                    component.form.post_status = currTreatmentData.post_status;
                    component.form.notes = currTreatmentData.notes;

                    // dropdown fields
                    const treatmentDrugList = currTreatmentData.drugs ? currTreatmentData.drugs.split(', ') : [];
                    // const treatmentCategoriesList = currTreatmentData.categories.split(', ');
                    const treatmentDrugCombinations = currTreatmentData.drugCombinations ? currTreatmentData.drugCombinations.split(', ') : [];
                    component.form.selectedDrugs = treatmentDrugList.length > 0 ? component.drugOptions.filter(x => treatmentDrugList.includes(x.text)).map(x => x.value) : [];
                    component.form.selectedDrugCombinations = treatmentDrugCombinations.length > 0 ? component.drugCombinationOptions.filter(x => treatmentDrugCombinations.includes(x.text)).map(x => x.value) : [];
                    component.form.selectedStopReason = component.stopReasonOptions.filter(x => x.text === currTreatmentData.stop_reason)[0].value;
                    // component.form.selectedCategories = component.categoryOptions.filter(x => treatmentCategoriesList.includes(x.text)).map(x => x.value);

                    // lineOfTreatmentNameOptions and lineOfTreament (Display) Options have the same keys. Since the information from the treatment is in regards to the name,
                    // the selectedLineOfTreatment will be filtered through lineOfTreatmentNameOptions instead
                    component.form.selectedLineOfTreatment = currTreatmentData.line_of_treatment ? lineofTreatmentNameOptions.filter(x => x.text === currTreatmentData.line_of_treatment)[0].value : null;
                }))
                .finally(() => { loader.hide(); });
        },
        updateTreatmentRecord() {
            const loader = this.$loading.show();
            const component = this;

            const updatedTreatmentRecord = {
                // treatment_categories: this.form.selectedCategories,
                drugs: this.form.selectedDrugs,
                drug_combinations: this.form.selectedDrugCombinations,
                line_of_treatment: this.form.selectedLineOfTreatment,
                regimen: this.form.regimen === '' ? null : this.form.regimen,
                start_date: this.form.startDateDfd === '' ? null : this.form.startDateDfd,
                stop_date: this.form.stopDateDfd === '' ? null : this.form.stopDateDfd,
                stop_reason: this.form.selectedStopReason,
                pre_status: this.form.pre_status === '' ? null : this.form.pre_status,
                post_status: this.form.post_status === '' ? null : this.form.post_status,
                notes: this.form.notes === '' ? null : this.form.notes
            };

            axios.put(`${this.treatmentUrl}?id=${this.treatmentId}&cohort_id=${this.cohortId}`, updatedTreatmentRecord, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'DataEditorViewTreatmentsPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            editedCohortId: component.cohortId,
                            editedParticipantName: component.form.participantName,
                        }
                    });
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>