<template>
    <div>
        <h3>Update Clinical Update Category</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateClinicalUpdateCategory">
                <b-form-group label="Clinical update category name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="v$.form.updateCategoryName.$model" :state="v$.form.updateCategoryName.$dirty ? !v$.form.updateCategoryName.$error : null" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="v$.form.$invalid">
                    Update Clinical Update Category
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { required, minLength, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    props: {
        updateCategoryId: {
            type: Number,
            required: true
        }
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    data() {
        return {
            // URLs used
            sampleCoordinatorClinicalUpdateCategoriesUrl: getUrl('sampleCoordinatorClinicalUpdateCategoriesUrl'),

            // form data
            form: {
                // inputs
                updateCategoryName: null,
                notes: null
            },
            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
    },
    validations() {
        return { 
            form: {
                // dropdowns
                updateCategoryName: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(256)
                }
            }
        }
    },
    mounted() {
        this.populateClinUpdateCategoryEditForm();
    },
    methods: {
        populateClinUpdateCategoryEditForm() {
            const loader = this.$loading.show();
            const component = this;
            const url = `${this.sampleCoordinatorClinicalUpdateCategoriesUrl}?id=${this.updateCategoryId}`;

            axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((updateCategoryResp) => {
                    const categoryData = updateCategoryResp.data.clinical_update_categories[0];
                    this.form.updateCategoryName = categoryData.name;
                    this.form.notes = categoryData.notes;
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        },
        updateClinicalUpdateCategory() {
            const loader = this.$loading.show();
            const url = this.sampleCoordinatorClinicalUpdateCategoriesUrl;
            const clinUpdateUpdatedInfo = {
                id: this.updateCategoryId,
                category_name: this.form.updateCategoryName,
                notes: this.form.notes === '' ? null : this.form.notes
            };
            const component = this;
            axios.put(url, clinUpdateUpdatedInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'AdminViewClinicalUpdateCategoriesPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                        }
                    });
                })
                .catch((err) => {
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .then(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
