<template>
    <div>
        <h3>Update Diagnostic Test Category</h3>
        <hr>

        <b-alert :show="addError" variant="danger">
            ERROR: {{ errorResponse.data.error }}
        </b-alert>

        <b-col md="4">
            <b-form @submit.stop.prevent="updateDiagnosticTestCategory">
                <b-form-group label="Diagnostic test category name" label-class="font-weight-bold">
                    <b-form-input required size="sm" v-model="$v.form.testCategoryName.$model" :state="$v.form.testCategoryName.$dirty ? !$v.form.testCategoryName.$error : null" />
                </b-form-group>
                <b-form-group label="Notes [optional]" label-class="font-weight-bold">
                    <b-form-textarea size="sm" v-model="form.notes" />
                </b-form-group>
                <b-button type="submit" size="sm" style="font-size:0.85rem;" :disabled="$v.form.$invalid">
                    Update Diagnostic Test Category
                </b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import axios from 'axios';
import { required, minLength, maxLength } from '@vuelidate/validators';
import { getUrl } from '../../utils/directory';
import getUserToken from '../../utils/auth';

export default {
    props: {
        role: {
            type: String,
            required: true
        },
        testCategoryId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            // URLs used
            sampleCoordinatorDiagnosticTestCategoriesUrl: getUrl('sampleCoordinatorDiagnosticTestCategoriesUrl'),

            // form data
            form: {
                // inputs
                testCategoryName: null,
                notes: null
            },
            // error modal
            addError: false,
            errorResponse: {
                data: { error: '' }
            }
        };
    },
    watch: {
    },
    validations: {
        form: {
            // dropdowns
            testCategoryName: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(256)
            }
        }
    },
    mounted() {
        this.populateDiagTestCategoryEditForm();
    },
    methods: {
        populateDiagTestCategoryEditForm() {
            const loader = this.$loading.show();
            const component = this;
            const url = `${this.sampleCoordinatorDiagnosticTestCategoriesUrl}?id=${this.testCategoryId}`;

            axios.get(url, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((testCategoryResp) => {
                    const categoryData = testCategoryResp.data.diagnostic_test_categories[0];
                    this.form.testCategoryName = categoryData.name;
                    this.form.notes = categoryData.notes;
                })
                .catch((err) => {
                    loader.hide();
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => { loader.hide(); });
        },
        updateDiagnosticTestCategory() {
            const loader = this.$loading.show();
            const url = this.sampleCoordinatorDiagnosticTestCategoriesUrl;
            const diagTestUpdatedInfo = {
                id: this.testCategoryId,
                category_name: this.form.testCategoryName,
                notes: this.form.notes === '' ? null : this.form.notes
            };
            const component = this;
            axios.put(url, diagTestUpdatedInfo, { headers: { Authorization: `Bearer ${getUserToken()}` } })
                .then((resp) => {
                    component.$router.push({
                        name: 'SampleCoordinatorViewDiagnosticTestCategoriesPage',
                        params: {
                            needAlert: true,
                            ajaxResponse: resp,
                            role: this.role
                        }
                    });
                })
                .catch((err) => {
                    component.addError = true;
                    component.errorResponse = err.response;
                    window.scrollTo(0, 0);
                })
                .then(() => { loader.hide(); });
        }
    }

};
</script>

<style>

</style>
